import { Pipe, PipeTransform } from '@angular/core'
import * as Sentry from '@sentry/browser'
import { Invitation, Member, MemberRole } from '../../../generated/graphql.private'

@Pipe({
  name: 'memberRole'
})
export class MemberRolePipe implements PipeTransform {
  transform(
    memberOrInvitation: Pick<Member, 'role' | 'roleLabel'> | Pick<Invitation, 'memberRole' | 'memberRoleLabel'>
  ): string {
    const role = (memberOrInvitation as Member).role || (memberOrInvitation as Invitation).memberRole
    const roleLabel = (memberOrInvitation as Member).roleLabel || (memberOrInvitation as Invitation).memberRoleLabel

    switch (role) {
      case MemberRole.Client:
        return 'Klant'
      case MemberRole.Contractor:
        return 'Aannemer'
      case MemberRole.Subcontractor:
        return roleLabel || 'Onderaannemer'
      case MemberRole.Employee:
        return roleLabel || 'Medewerker'
      case MemberRole.Other:
        return roleLabel || 'Overige rol'
      default: {
        Sentry.captureException(`Unsupported role in MemberRolePipe: ${role}`)
        return role
      }
    }
  }
}
