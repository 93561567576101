import { Injectable } from '@angular/core'
import { ActionMenuService } from '@core/services/action-menu.service'
import { PopoverController } from '@ionic/angular'
import { ActionMenuPopoverComponent } from '@web/modules/shared/components/action-menu-popover.component'

@Injectable({
  providedIn: 'root'
})
export class WebActionMenuService implements ActionMenuService {
  constructor(private popoverController: PopoverController) {}

  async showActionMenu(buttons, $event: Event) {
    const popover = await this.popoverController.create({
      component: ActionMenuPopoverComponent,
      event: $event,
      translucent: true,
      componentProps: {
        buttons
      }
    })
    return await popover.present()
  }
}
