import { Injectable } from '@angular/core'
import { EnvService } from '@core/services/env.service'

import { environment } from '@web/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class WebEnvService implements EnvService {
  config() {
    return environment
  }
  externalBrowser() {
    return
  }
}
