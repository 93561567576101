import { FormControl } from '@angular/forms'
import { Component, Input, ElementRef } from '@angular/core'

@Component({
  selector: 'form-control-wrapper',
  template: `
    <ion-label position="stacked" (click)="onClickLabel()" *ngIf="label">
      {{ label }}
      <span class="invalid" *ngIf="control.invalid">*</span>
    </ion-label>
    <ng-content></ng-content>
    <ng-container *ngIf="control.touched">
      <div class="error" *ngIf="control.hasError('required')">
        Is verplicht
      </div>
      <div class="error" *ngIf="control.hasError('formatPassword')">
        Moet minimaal een letter, hoofdletter en cijfer bevatten. En in totaal minimaal 6 tekens lang zijn
      </div>
      <div class="error" *ngIf="control.hasError('minlength')">
        Moet minimaal {{ control.errors['minlength']['requiredLength'] }} tekens zijn
      </div>
      <div class="error" *ngIf="control.hasError('maxlength')">
        Mag maximaal {{ control.errors['maxlength']['requiredLength'] }} tekens zijn
      </div>
      <div class="error" *ngIf="control.hasError('emailInvalid')">
        Ongeldig e-mailadres
      </div>
      <div class="error" *ngIf="control.hasError('phoneInvalid')">
        Ongeldig telefoonnummer
      </div>
      <div class="error" *ngIf="control.hasError('cocNumberInvalid')">
        Ongeldig KvK-nummer
      </div>
      <div class="error" *ngIf="control.hasError('vatNumberInvalid')">
        Ongeldig BTW-nummer
      </div>
      <div class="error" *ngIf="control.hasError('hoursInvalid')">
        Ongeldig aantal uren
      </div>
      <div class="error" *ngIf="control.hasError('min')">
        Voer een geldig getal in van minimaal {{ control.errors['min']['min'] }}
      </div>
      <div class="error" *ngIf="control.hasError('max')">
        Voer een geldig getal in van maximaal {{ control.errors['max']['max'] }}
      </div>
      <div class="error" *ngIf="control.hasError('taken')">
        Is al in gebruik
      </div>
      <div class="error" *ngIf="control.hasError('confirmation')">
        Moet overeen komen
      </div>
      <div class="error" *ngIf="control.hasError('notFound')">
        Is niet gevonden
      </div>
      <div class="error" *ngIf="control.hasError('incorrect')">
        Is onjuist
      </div>
    </ng-container>
  `,
  styles: [
    `
      :host {
        margin: 0.5rem 0;
        width: 100%;
      }

      ion-label {
        pointer-events: all !important;
      }

      .error {
        font-size: 14px;
        color: var(--ion-color-danger);
      }

      .invalid {
        color: var(--ion-color-danger);
      }
    `
  ]
})
export class FormControlWrapperComponent {
  @Input() control: FormControl
  @Input() label: string

  onClickLabel() {
    const input = this.elementRef.nativeElement.querySelector('ion-input, ion-textarea')
    if (input) input.setFocus()
  }

  constructor(private elementRef: ElementRef) { }
}
