import { Component } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { ApolloQueryResult } from '@apollo/client/core'
import { NavController } from '@ionic/angular'
import { HomerunSDK, MediaQuery } from 'projects/core/src/generated/graphql.private'
import { Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'

@Component({
  selector: 'address-bar',
  template: `
    <ng-container *ngIf="route.params | async as params">
      <ion-toolbar *ngIf="params.path && (projectMediaQueryResult$ | async)?.data?.media as media">
        <ion-buttons slot="secondary">
          <ion-button (click)="navigateFolderback(params.projectId)" color="medium">
            <ion-icon size="large" name="chevron-back-circle-outline"></ion-icon>
          </ion-button>
        </ion-buttons>

        <input type="text" class="path" [value]="params.path ? '/' + params.path : '/'" disabled />
        <ion-buttons slot="primary" class="right">
          <media-actions [media]="media" alwaysOpenOptions="">
            <assigned-members slot="end" [members]="media.members"></assigned-members>
          </media-actions>
        </ion-buttons>
      </ion-toolbar>
    </ng-container>
  `,
  styles: [
    `
      media-actions {
        margin-right: 2rem;
      }
      .right {
        margin-left: 0.5rem;
      }
      .path {
        width: 100%;
        border: solid 1px var(--grid-border-color);
        padding: 0.4rem;
        font-size: 0.8rem;
      }
    `
  ]
})
export class AddressBarComponent {
  projectMediaQueryResult$: Observable<ApolloQueryResult<MediaQuery>>
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public navCtrl: NavController,
    private homerunSDK: HomerunSDK
  ) {}

  ngOnInit() {
    this.projectMediaQueryResult$ = this.route.params.pipe(
      switchMap((params) => this.homerunSDK.mediaWatch({ projectId: params.projectId, path: params.path }).valueChanges)
    )
  }
  async navigateFolderback(projectId) {
    await this.navCtrl.navigateBack([
      'projects',
      projectId,
      'media',
      'browse',
      ...(this.route.snapshot.params.path?.split('/').slice(0, -1) || [])
    ])
  }
}
