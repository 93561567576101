import { FormGroup } from '@angular/forms'
import { Component, Input } from '@angular/core'
import { Folder } from '@core/generated/graphql.private'

@Component({
  selector: 'folder-form',
  template: `
    <form *ngIf="form" [formGroup]="form">
      <ion-list lines="full">
        <ion-item>
          <form-control-wrapper label="Naam map" [control]="form.controls.description">
            <ion-input
              autocapitalize="on"
              placeholder="Voorbeeld: Bouwtekeningen"
              type="text"
              formControlName="description"
              required
            >
            </ion-input>
          </form-control-wrapper>
        </ion-item>
        <ng-content> </ng-content>
      </ion-list>
    </form>
  `,
  styles: [``]
})
export class FolderFormComponent {
  @Input() form: FormGroup
  @Input() folder: Partial<Folder>

  public setError(formControlName: string, error: string) {
    this.form.controls[formControlName].setErrors({ [error]: true })
    this.form.controls[formControlName].markAsTouched()
  }
}
