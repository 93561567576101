import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import * as ApolloCore from '@apollo/client/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  DateTime: any;
  /** An ISO 8601-encoded date */
  Date: any;
  Upload: any;
};



export enum AcceptAgreementErrorArgumentType {
  AgreementId = 'agreementId'
}

export type AcceptAgreementErrorType = {
  __typename: 'AcceptAgreementErrorType';
  argument: AcceptAgreementErrorArgumentType;
  error: ValidationError;
};

export type AcceptAgreementInputType = {
  agreementId: Scalars['ID'];
};

/** Autogenerated return type of AcceptAgreement */
export type AcceptAgreementPayload = {
  __typename: 'AcceptAgreementPayload';
  agreement?: Maybe<Agreement>;
  errors: Array<AcceptAgreementErrorType>;
};

export enum AcceptInvitationErrorArgumentType {
  InvitationToken = 'invitationToken'
}

export type AcceptInvitationErrorType = {
  __typename: 'AcceptInvitationErrorType';
  argument: AcceptInvitationErrorArgumentType;
  error: ValidationError;
};

export type AcceptInvitationInputType = {
  invitationToken: Scalars['String'];
};

/** Autogenerated return type of AcceptInvitation */
export type AcceptInvitationPayload = {
  __typename: 'AcceptInvitationPayload';
  errors: Array<AcceptInvitationErrorType>;
  invitation?: Maybe<Invitation>;
};

/** An activity in the project calendar */
export type Activity = {
  __typename: 'Activity';
  /** Activity is not archived */
  active: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  assignedMemberIds?: Maybe<Array<Scalars['ID']>>;
  assignedMembers?: Maybe<Array<Member>>;
  color: ActivityColor;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByMember: Member;
  createdByMemberId: Scalars['ID'];
  date: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  endDate: Scalars['Date'];
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  policy?: Maybe<ActivityPolicy>;
  position: Scalars['Int'];
  project: Project;
  projectId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum ActivityColor {
  /** orange */
  Orange = 'orange',
  /** yellow */
  Yellow = 'yellow',
  /** blue */
  Blue = 'blue',
  /** red */
  Red = 'red',
  /** green */
  Green = 'green',
  /** brown */
  Brown = 'brown',
  /** purple */
  Purple = 'purple'
}

/** An update on an activity in the general chat */
export type ActivityMessage = Event & {
  __typename: 'ActivityMessage';
  activity: Activity;
  activityMessageAction: ActivityMessageAction;
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdByCurrentUser: Scalars['Boolean'];
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  policy?: Maybe<EventPolicy>;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** Activity message action enumeration */
export enum ActivityMessageAction {
  Created = 'created',
  Updated = 'updated',
  Archived = 'archived'
}

export type ActivityPolicy = {
  __typename: 'ActivityPolicy';
  archive: Scalars['Boolean'];
  pdfActivities: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export type AddressInformation = {
  __typename: 'AddressInformation';
  addressObjectId?: Maybe<Scalars['ID']>;
  city?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lat?: Maybe<Scalars['Float']>;
  listingListedSince?: Maybe<Scalars['String']>;
  listingUrl?: Maybe<Scalars['String']>;
  lon?: Maybe<Scalars['Float']>;
  monumentIsMonument?: Maybe<Scalars['Boolean']>;
  monumentNumber?: Maybe<Scalars['String']>;
  monumentUrl?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  propertyConstructionYear?: Maybe<Scalars['String']>;
  propertyEnergyLabel?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  wozDate?: Maybe<Scalars['String']>;
  wozValue?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  zoningMapUrl?: Maybe<Scalars['String']>;
  zoningPlanUrl?: Maybe<Scalars['String']>;
};

export type AddressSearch = {
  __typename: 'AddressSearch';
  addressObjectId?: Maybe<Scalars['ID']>;
  city?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export enum AddressSearchErrorArgumentType {
  Query = 'query',
  SessionId = 'sessionId'
}

export type AddressSearchErrorType = {
  __typename: 'AddressSearchErrorType';
  argument: AddressSearchErrorArgumentType;
  error: ValidationError;
};

export type AddressSearchInputType = {
  query: Scalars['String'];
  sessionId: Scalars['String'];
};

/** Autogenerated return type of AddressSearch */
export type AddressSearchPayload = {
  __typename: 'AddressSearchPayload';
  errors: Array<AddressSearchErrorType>;
  results: Array<AddressSuggestion>;
};

export type AddressSuggestion = {
  __typename: 'AddressSuggestion';
  address: Scalars['String'];
  id: Scalars['ID'];
};

export type AddressValuation = {
  __typename: 'AddressValuation';
  bestEstimate?: Maybe<Scalars['Int']>;
  bottomEstimate?: Maybe<Scalars['Int']>;
  comparables: Array<AddressValuationResult>;
  id?: Maybe<Scalars['ID']>;
  topEstimate?: Maybe<Scalars['Int']>;
};

export enum AddressValuationBuilding {
  FamilyHouse = 'family_house',
  Apartment = 'apartment'
}

export enum AddressValuationNeighborRelation {
  GroundFloorApartment = 'ground_floor_apartment',
  UpstairsApartment = 'upstairs_apartment',
  Apartment = 'apartment',
  RowHouse = 'row_house',
  EndHouse = 'end_house',
  SemiDetachedHouse = 'semi_detached_house',
  DetachedHouse = 'detached_house',
  Other = 'other'
}

export type AddressValuationResult = {
  __typename: 'AddressValuationResult';
  addressObjectId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

/** An agreement between two members in a project */
export type Agreement = {
  __typename: 'Agreement';
  closedAt?: Maybe<Scalars['DateTime']>;
  counterpartyMember: Member;
  counterpartyMemberId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByMember: Member;
  createdByMemberId: Scalars['ID'];
  description: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  media: Array<Media>;
  name: Scalars['String'];
  number: Scalars['Int'];
  policy?: Maybe<AgreementPolicy>;
  price?: Maybe<Scalars['Float']>;
  priceType: AgreementPrice;
  project: Project;
  projectId: Scalars['ID'];
  signedTotalPrice: Scalars['Float'];
  status?: Maybe<AgreementStatus>;
  totalPrice?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatAmount?: Maybe<Scalars['Float']>;
  vatPercentage?: Maybe<Scalars['Float']>;
};

/** A message in a chat where an agreement was posted */
export type AgreementMessage = Event & {
  __typename: 'AgreementMessage';
  agreement: Agreement;
  agreementMessageAction: AgreementMessageAction;
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdByCurrentUser: Scalars['Boolean'];
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  policy?: Maybe<EventPolicy>;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** Agreement message action enumeration */
export enum AgreementMessageAction {
  Created = 'created',
  Updated = 'updated',
  Accepted = 'accepted',
  Rejected = 'rejected'
}

export type AgreementPolicy = {
  __typename: 'AgreementPolicy';
  accept: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  reject: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export enum AgreementPrice {
  /** none */
  None = 'none',
  /** increase */
  Increase = 'increase',
  /** decrease */
  Decrease = 'decrease'
}

export enum AgreementStatus {
  /** open */
  Open = 'open',
  /** accepted */
  Accepted = 'accepted',
  /** rejected */
  Rejected = 'rejected'
}

export enum ApproveExpenseErrorArgumentType {
  ExpenseId = 'expenseId'
}

export type ApproveExpenseErrorType = {
  __typename: 'ApproveExpenseErrorType';
  argument: ApproveExpenseErrorArgumentType;
  error: ValidationError;
};

export type ApproveExpenseInputType = {
  expenseId: Scalars['ID'];
};

/** Autogenerated return type of ApproveExpense */
export type ApproveExpensePayload = {
  __typename: 'ApproveExpensePayload';
  errors: Array<ApproveExpenseErrorType>;
  expense?: Maybe<Expense>;
};

export enum ApproveMaterialNoteErrorArgumentType {
  MaterialNoteId = 'materialNoteId'
}

export type ApproveMaterialNoteErrorType = {
  __typename: 'ApproveMaterialNoteErrorType';
  argument: ApproveMaterialNoteErrorArgumentType;
  error: ValidationError;
};

export type ApproveMaterialNoteInputType = {
  materialNoteId: Scalars['ID'];
};

/** Autogenerated return type of ApproveMaterialNote */
export type ApproveMaterialNotePayload = {
  __typename: 'ApproveMaterialNotePayload';
  errors: Array<ApproveMaterialNoteErrorType>;
  materialNote?: Maybe<MaterialNote>;
};

export enum ApproveTimesheetErrorArgumentType {
  TimesheetId = 'timesheetId'
}

export type ApproveTimesheetErrorType = {
  __typename: 'ApproveTimesheetErrorType';
  argument: ApproveTimesheetErrorArgumentType;
  error: ValidationError;
};

export type ApproveTimesheetInputType = {
  timesheetId: Scalars['ID'];
};

/** Autogenerated return type of ApproveTimesheet */
export type ApproveTimesheetPayload = {
  __typename: 'ApproveTimesheetPayload';
  errors: Array<ApproveTimesheetErrorType>;
  timesheet?: Maybe<Timesheet>;
};

export enum ArchiveActivityErrorArgumentType {
  ActivityId = 'activityId'
}

export type ArchiveActivityErrorType = {
  __typename: 'ArchiveActivityErrorType';
  argument: ArchiveActivityErrorArgumentType;
  error: ValidationError;
};

export type ArchiveActivityInputType = {
  activityId: Scalars['ID'];
};

/** Autogenerated return type of ArchiveActivity */
export type ArchiveActivityPayload = {
  __typename: 'ArchiveActivityPayload';
  activity?: Maybe<Activity>;
  errors: Array<ArchiveActivityErrorType>;
};

export enum ArchiveGroupChatErrorArgumentType {
  GroupChatId = 'groupChatId'
}

export type ArchiveGroupChatErrorType = {
  __typename: 'ArchiveGroupChatErrorType';
  argument: ArchiveGroupChatErrorArgumentType;
  error: ValidationError;
};

export type ArchiveGroupChatInputType = {
  groupChatId: Scalars['ID'];
};

/** Autogenerated return type of ArchiveGroupChat */
export type ArchiveGroupChatPayload = {
  __typename: 'ArchiveGroupChatPayload';
  errors: Array<ArchiveGroupChatErrorType>;
  groupChat?: Maybe<GroupChat>;
};

export enum ArchiveProjectErrorArgumentType {
  ProjectId = 'projectId'
}

export type ArchiveProjectErrorType = {
  __typename: 'ArchiveProjectErrorType';
  argument: ArchiveProjectErrorArgumentType;
  error: ValidationError;
};

export type ArchiveProjectInputType = {
  projectId: Scalars['ID'];
};

/** Autogenerated return type of ArchiveProject */
export type ArchiveProjectPayload = {
  __typename: 'ArchiveProjectPayload';
  errors: Array<ArchiveProjectErrorType>;
  project?: Maybe<Project>;
};

/** Autogenerated return type of CancelSubscription */
export type CancelSubscriptionPayload = {
  __typename: 'CancelSubscriptionPayload';
  subscribedToPlanAt?: Maybe<Scalars['DateTime']>;
};

export type Chat = {
  /** Current user is active participant and chat is not archived */
  active: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  /** Timestamp for current user deactivation */
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  events: EventConnection;
  general: Scalars['Boolean'];
  id: Scalars['ID'];
  imageFull?: Maybe<Scalars['String']>;
  imageThumbnail?: Maybe<Scalars['String']>;
  latestActivityAt: Scalars['DateTime'];
  members: Array<Member>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  participants: Array<Participant>;
  project: Project;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};


export type ChatEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum ClearPresenceErrorArgumentType {
  ChatId = 'chatId'
}

export type ClearPresenceErrorType = {
  __typename: 'ClearPresenceErrorType';
  argument: ClearPresenceErrorArgumentType;
  error: ValidationError;
};

export type ClearPresenceInputType = {
  chatId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of ClearPresence */
export type ClearPresencePayload = {
  __typename: 'ClearPresencePayload';
  errors: Array<ClearPresenceErrorType>;
};

/** A company has one or more users */
export type Company = {
  __typename: 'Company';
  address?: Maybe<Scalars['String']>;
  cocNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logoFull?: Maybe<Scalars['String']>;
  logoThumbnail?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  policy: CompanyPolicy;
  serviceDescription?: Maybe<Scalars['String']>;
  serviceMarketingBudgetMax?: Maybe<Scalars['Int']>;
  serviceMarketingBudgetMin?: Maybe<Scalars['Int']>;
  servicePriceMax?: Maybe<Scalars['Int']>;
  servicePriceMin?: Maybe<Scalars['Int']>;
  serviceRangeKm?: Maybe<Scalars['Int']>;
  serviceTags?: Maybe<Array<Scalars['String']>>;
  serviceZipcode?: Maybe<Scalars['String']>;
  /** Users that where previously invited to projects by the current_user */
  teamMemberSuggestions: Array<User>;
  teamMembers: Array<TeamMember>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users: Array<User>;
  vatNumber?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  companyPlace?: Maybe<Scalars['String']>;
  bouwmaatAccountNumber?: Maybe<Scalars['String']>;
};

export type CompanyPolicy = {
  __typename: 'CompanyPolicy';
  createTeamMember: Scalars['Boolean'];
  setupService: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export enum CreateActivityErrorArgumentType {
  ProjectId = 'projectId',
  AssignedMemberIds = 'assignedMemberIds',
  Name = 'name',
  Description = 'description',
  Color = 'color',
  Date = 'date',
  Duration = 'duration'
}

export type CreateActivityErrorType = {
  __typename: 'CreateActivityErrorType';
  argument: CreateActivityErrorArgumentType;
  error: ValidationError;
};

export type CreateActivityInputType = {
  projectId: Scalars['ID'];
  assignedMemberIds?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  color?: Maybe<ActivityColor>;
  date: Scalars['Date'];
  duration?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of CreateActivity */
export type CreateActivityPayload = {
  __typename: 'CreateActivityPayload';
  activity?: Maybe<Activity>;
  errors: Array<CreateActivityErrorType>;
};

export enum CreateAddressValuationErrorArgumentType {
  AddressObjectId = 'addressObjectId',
  LivingArea = 'livingArea',
  YardArea = 'yardArea',
  Rooms = 'rooms',
  Stories = 'stories',
  ConstructionYear = 'constructionYear',
  NeighborRelation = 'neighborRelation',
  BuildingType = 'buildingType'
}

export type CreateAddressValuationErrorType = {
  __typename: 'CreateAddressValuationErrorType';
  argument: CreateAddressValuationErrorArgumentType;
  error: ValidationError;
};

export type CreateAddressValuationInputType = {
  addressObjectId: Scalars['String'];
  livingArea: Scalars['Int'];
  yardArea?: Maybe<Scalars['Int']>;
  rooms: Scalars['Int'];
  stories: Scalars['Int'];
  constructionYear: Scalars['Int'];
  neighborRelation: AddressValuationNeighborRelation;
  buildingType: AddressValuationBuilding;
};

/** Autogenerated return type of CreateAddressValuation */
export type CreateAddressValuationPayload = {
  __typename: 'CreateAddressValuationPayload';
  addressValuation?: Maybe<AddressValuation>;
  errors: Array<CreateAddressValuationErrorType>;
};

export enum CreateAgreementErrorArgumentType {
  CounterpartyMemberId = 'counterpartyMemberId',
  Name = 'name',
  Description = 'description',
  Price = 'price',
  PriceType = 'priceType',
  VatPercentage = 'vatPercentage',
  Media = 'media'
}

export type CreateAgreementErrorType = {
  __typename: 'CreateAgreementErrorType';
  argument: CreateAgreementErrorArgumentType;
  error: ValidationError;
};

export type CreateAgreementInputType = {
  counterpartyMemberId: Scalars['ID'];
  name: Scalars['String'];
  description: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  priceType: AgreementPrice;
  vatPercentage?: Maybe<Scalars['Float']>;
  media?: Maybe<Array<MediaInputTypeForCreateAgreement>>;
};

/** Autogenerated return type of CreateAgreement */
export type CreateAgreementPayload = {
  __typename: 'CreateAgreementPayload';
  agreement?: Maybe<Agreement>;
  errors: Array<CreateAgreementErrorType>;
};

/** Autogenerated return type of CreateDemoProject */
export type CreateDemoProjectPayload = {
  __typename: 'CreateDemoProjectPayload';
  project?: Maybe<Project>;
};

export enum CreateExpenseErrorArgumentType {
  ProjectId = 'projectId',
  Description = 'description',
  Price = 'price',
  TotalPrice = 'totalPrice',
  Refund = 'refund',
  Media = 'media'
}

export type CreateExpenseErrorType = {
  __typename: 'CreateExpenseErrorType';
  argument: CreateExpenseErrorArgumentType;
  error: ValidationError;
};

export type CreateExpenseInputType = {
  projectId: Scalars['ID'];
  description: Scalars['String'];
  price: Scalars['Float'];
  totalPrice: Scalars['Float'];
  refund: Scalars['Boolean'];
  media?: Maybe<Array<MediaInputTypeForCreateExpense>>;
};

/** Autogenerated return type of CreateExpense */
export type CreateExpensePayload = {
  __typename: 'CreateExpensePayload';
  errors: Array<CreateExpenseErrorType>;
  expense?: Maybe<Expense>;
};

export enum CreateFolderErrorArgumentType {
  Description = 'description',
  ProjectId = 'projectId',
  Path = 'path',
  MemberIds = 'memberIds'
}

export type CreateFolderErrorType = {
  __typename: 'CreateFolderErrorType';
  argument: CreateFolderErrorArgumentType;
  error: ValidationError;
};

export type CreateFolderInputType = {
  description: Scalars['String'];
  projectId: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  memberIds: Array<Scalars['String']>;
};

/** Autogenerated return type of CreateFolder */
export type CreateFolderPayload = {
  __typename: 'CreateFolderPayload';
  errors: Array<CreateFolderErrorType>;
  folder?: Maybe<Folder>;
};

export enum CreateGroupChatErrorArgumentType {
  ProjectId = 'projectId',
  Name = 'name',
  Description = 'description',
  MemberIds = 'memberIds',
  Image = 'image'
}

export type CreateGroupChatErrorType = {
  __typename: 'CreateGroupChatErrorType';
  argument: CreateGroupChatErrorArgumentType;
  error: ValidationError;
};

export type CreateGroupChatInputType = {
  projectId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  memberIds: Array<Scalars['ID']>;
  image?: Maybe<Scalars['Upload']>;
};

/** Autogenerated return type of CreateGroupChat */
export type CreateGroupChatPayload = {
  __typename: 'CreateGroupChatPayload';
  errors: Array<CreateGroupChatErrorType>;
  groupChat?: Maybe<GroupChat>;
};

export enum CreateMaterialNoteErrorArgumentType {
  ProjectId = 'projectId'
}

export type CreateMaterialNoteErrorType = {
  __typename: 'CreateMaterialNoteErrorType';
  argument: CreateMaterialNoteErrorArgumentType;
  error: ValidationError;
};

export type CreateMaterialNoteInputType = {
  projectId: Scalars['ID'];
};

export enum CreateMaterialNoteItemErrorArgumentType {
  MaterialNoteId = 'materialNoteId',
  Name = 'name',
  Quantity = 'quantity'
}

export type CreateMaterialNoteItemErrorType = {
  __typename: 'CreateMaterialNoteItemErrorType';
  argument: CreateMaterialNoteItemErrorArgumentType;
  error: ValidationError;
};

export type CreateMaterialNoteItemInputType = {
  materialNoteId: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

/** Autogenerated return type of CreateMaterialNoteItem */
export type CreateMaterialNoteItemPayload = {
  __typename: 'CreateMaterialNoteItemPayload';
  errors: Array<CreateMaterialNoteItemErrorType>;
  materialNoteItem?: Maybe<MaterialNoteItem>;
};

/** Autogenerated return type of CreateMaterialNote */
export type CreateMaterialNotePayload = {
  __typename: 'CreateMaterialNotePayload';
  errors: Array<CreateMaterialNoteErrorType>;
  materialNote?: Maybe<MaterialNote>;
};

export enum CreateMediaErrorArgumentType {
  ProjectId = 'projectId',
  File = 'file',
  Description = 'description',
  Path = 'path'
}

export type CreateMediaErrorType = {
  __typename: 'CreateMediaErrorType';
  argument: CreateMediaErrorArgumentType;
  error: ValidationError;
};

export type CreateMediaInputType = {
  projectId: Scalars['ID'];
  file: Scalars['Upload'];
  description?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export enum CreateMediaMessageErrorArgumentType {
  ChatId = 'chatId',
  File = 'file',
  Payload = 'payload'
}

export type CreateMediaMessageErrorType = {
  __typename: 'CreateMediaMessageErrorType';
  argument: CreateMediaMessageErrorArgumentType;
  error: ValidationError;
};

export type CreateMediaMessageInputType = {
  chatId: Scalars['ID'];
  file: Scalars['Upload'];
  payload?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateMediaMessage */
export type CreateMediaMessagePayload = {
  __typename: 'CreateMediaMessagePayload';
  errors: Array<CreateMediaMessageErrorType>;
  mediaMessage?: Maybe<MediaMessage>;
};

/** Autogenerated return type of CreateMedia */
export type CreateMediaPayload = {
  __typename: 'CreateMediaPayload';
  errors: Array<CreateMediaErrorType>;
  media?: Maybe<Media>;
};

export enum CreateMessageErrorArgumentType {
  Payload = 'payload',
  ChatId = 'chatId'
}

export type CreateMessageErrorType = {
  __typename: 'CreateMessageErrorType';
  argument: CreateMessageErrorArgumentType;
  error: ValidationError;
};

export type CreateMessageInputType = {
  payload: Scalars['String'];
  chatId: Scalars['ID'];
};

/** Autogenerated return type of CreateMessage */
export type CreateMessagePayload = {
  __typename: 'CreateMessagePayload';
  errors: Array<CreateMessageErrorType>;
  message?: Maybe<Message>;
};

export enum CreateParticipantsErrorArgumentType {
  GroupChatId = 'groupChatId',
  MemberIds = 'memberIds'
}

export type CreateParticipantsErrorType = {
  __typename: 'CreateParticipantsErrorType';
  argument: CreateParticipantsErrorArgumentType;
  error: ValidationError;
};

export type CreateParticipantsInputType = {
  groupChatId: Scalars['ID'];
  memberIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of CreateParticipants */
export type CreateParticipantsPayload = {
  __typename: 'CreateParticipantsPayload';
  errors: Array<CreateParticipantsErrorType>;
  groupChat?: Maybe<GroupChat>;
};

export enum CreatePrivateChatErrorArgumentType {
  ProjectId = 'projectId',
  MemberId = 'memberId'
}

export type CreatePrivateChatErrorType = {
  __typename: 'CreatePrivateChatErrorType';
  argument: CreatePrivateChatErrorArgumentType;
  error: ValidationError;
};

export type CreatePrivateChatInputType = {
  projectId: Scalars['ID'];
  memberId: Scalars['ID'];
};

/** Autogenerated return type of CreatePrivateChat */
export type CreatePrivateChatPayload = {
  __typename: 'CreatePrivateChatPayload';
  errors: Array<CreatePrivateChatErrorType>;
  privateChat?: Maybe<PrivateChat>;
};

export enum CreateProjectErrorArgumentType {
  Name = 'name',
  Description = 'description',
  Reference = 'reference',
  Address = 'address',
  Image = 'image',
  AddTeam = 'addTeam',
  Demo = 'demo'
}

export type CreateProjectErrorType = {
  __typename: 'CreateProjectErrorType';
  argument: CreateProjectErrorArgumentType;
  error: ValidationError;
};

export type CreateProjectInputType = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
  addTeam?: Maybe<Scalars['Boolean']>;
  demo?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateProject */
export type CreateProjectPayload = {
  __typename: 'CreateProjectPayload';
  errors: Array<CreateProjectErrorType>;
  project?: Maybe<Project>;
};

export enum CreateReplyMessageErrorArgumentType {
  Payload = 'payload',
  EventId = 'eventId',
  ChatId = 'chatId'
}

export type CreateReplyMessageErrorType = {
  __typename: 'CreateReplyMessageErrorType';
  argument: CreateReplyMessageErrorArgumentType;
  error: ValidationError;
};

export type CreateReplyMessageInputType = {
  payload: Scalars['String'];
  eventId: Scalars['ID'];
  chatId: Scalars['ID'];
};

/** Autogenerated return type of CreateReplyMessage */
export type CreateReplyMessagePayload = {
  __typename: 'CreateReplyMessagePayload';
  errors: Array<CreateReplyMessageErrorType>;
  replyMessage?: Maybe<ReplyMessage>;
};

export enum CreateSubscriptionErrorArgumentType {
  CompanyName = 'companyName',
  Address = 'address',
  ZipCode = 'zipCode',
  CocNumber = 'cocNumber',
  VatNumber = 'vatNumber',
  SubscriptionType = 'subscriptionType',
  SubscriptionPeriod = 'subscriptionPeriod'
}

export type CreateSubscriptionErrorType = {
  __typename: 'CreateSubscriptionErrorType';
  argument: CreateSubscriptionErrorArgumentType;
  error: ValidationError;
};

export type CreateSubscriptionInputType = {
  companyName: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  cocNumber?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  subscriptionType?: Maybe<Scalars['String']>;
  subscriptionPeriod?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateSubscription */
export type CreateSubscriptionPayload = {
  __typename: 'CreateSubscriptionPayload';
  errors: Array<CreateSubscriptionErrorType>;
  subscribedToPlanAt?: Maybe<Scalars['DateTime']>;
  subscribedToTrialAt?: Maybe<Scalars['DateTime']>;
  subscriptionType?: Maybe<Scalars['String']>;
  mollieCheckoutUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export enum CreateTeamMemberErrorArgumentType {
  UserId = 'userId',
  Role = 'role',
  RoleLabel = 'roleLabel',
  EnableTimesheets = 'enableTimesheets',
  EnableExpenses = 'enableExpenses',
  EnableMaterialNotes = 'enableMaterialNotes'
}

export type CreateTeamMemberErrorType = {
  __typename: 'CreateTeamMemberErrorType';
  argument: CreateTeamMemberErrorArgumentType;
  error: ValidationError;
};

export type CreateTeamMemberInputType = {
  userId: Scalars['String'];
  role: MemberRole;
  roleLabel?: Maybe<Scalars['String']>;
  enableTimesheets?: Maybe<Scalars['Boolean']>;
  enableExpenses?: Maybe<Scalars['Boolean']>;
  enableMaterialNotes?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of CreateTeamMember */
export type CreateTeamMemberPayload = {
  __typename: 'CreateTeamMemberPayload';
  errors: Array<CreateTeamMemberErrorType>;
  teamMember?: Maybe<TeamMember>;
};

export enum CreateTenderErrorArgumentType {
  Name = 'name',
  Description = 'description',
  ZipCode = 'zipCode',
  ConstructionYear = 'constructionYear',
  BudgetMin = 'budgetMin',
  BudgetMax = 'budgetMax',
  Duration = 'duration',
  ServiceTags = 'serviceTags',
  Urgency = 'urgency'
}

export type CreateTenderErrorType = {
  __typename: 'CreateTenderErrorType';
  argument: CreateTenderErrorArgumentType;
  error: ValidationError;
};

export type CreateTenderInputType = {
  name: Scalars['String'];
  description: Scalars['String'];
  zipCode: Scalars['String'];
  constructionYear: Scalars['Int'];
  budgetMin: Scalars['Int'];
  budgetMax: Scalars['Int'];
  duration: Scalars['Int'];
  serviceTags: Array<Scalars['String']>;
  urgency: Scalars['String'];
};

/** Autogenerated return type of CreateTender */
export type CreateTenderPayload = {
  __typename: 'CreateTenderPayload';
  errors: Array<CreateTenderErrorType>;
  tender?: Maybe<Tender>;
};

export enum CreateTodoErrorArgumentType {
  ProjectId = 'projectId',
  AssignedMemberIds = 'assignedMemberIds',
  Name = 'name',
  Description = 'description',
  Media = 'media'
}

export type CreateTodoErrorType = {
  __typename: 'CreateTodoErrorType';
  argument: CreateTodoErrorArgumentType;
  error: ValidationError;
};

export type CreateTodoInputType = {
  projectId: Scalars['ID'];
  assignedMemberIds?: Maybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  media?: Maybe<Array<MediaInputTypeForCreateTodo>>;
};

/** Autogenerated return type of CreateTodo */
export type CreateTodoPayload = {
  __typename: 'CreateTodoPayload';
  errors: Array<CreateTodoErrorType>;
  todo?: Maybe<Todo>;
};



export enum DeactivateMemberErrorArgumentType {
  MemberId = 'memberId'
}

export type DeactivateMemberErrorType = {
  __typename: 'DeactivateMemberErrorType';
  argument: DeactivateMemberErrorArgumentType;
  error: ValidationError;
};

export type DeactivateMemberInputType = {
  memberId: Scalars['ID'];
};

/** Autogenerated return type of DeactivateMember */
export type DeactivateMemberPayload = {
  __typename: 'DeactivateMemberPayload';
  errors: Array<DeactivateMemberErrorType>;
  member?: Maybe<Member>;
};

export enum DeactivateParticipantErrorArgumentType {
  ParticipantId = 'participantId'
}

export type DeactivateParticipantErrorType = {
  __typename: 'DeactivateParticipantErrorType';
  argument: DeactivateParticipantErrorArgumentType;
  error: ValidationError;
};

export type DeactivateParticipantInputType = {
  participantId: Scalars['ID'];
};

/** Autogenerated return type of DeactivateParticipant */
export type DeactivateParticipantPayload = {
  __typename: 'DeactivateParticipantPayload';
  errors: Array<DeactivateParticipantErrorType>;
  participant?: Maybe<Participant>;
};

export enum DeleteAgreementErrorArgumentType {
  AgreementId = 'agreementId'
}

export type DeleteAgreementErrorType = {
  __typename: 'DeleteAgreementErrorType';
  argument: DeleteAgreementErrorArgumentType;
  error: ValidationError;
};

export type DeleteAgreementInputType = {
  agreementId: Scalars['ID'];
};

/** Autogenerated return type of DeleteAgreement */
export type DeleteAgreementPayload = {
  __typename: 'DeleteAgreementPayload';
  errors: Array<DeleteAgreementErrorType>;
};

/** reference of a deleted entity */
export type DeletedEntity = {
  __typename: 'DeletedEntity';
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
};

/** Shows where an event was deleted */
export type DeletedEvent = Event & {
  __typename: 'DeletedEvent';
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdByCurrentUser: Scalars['Boolean'];
  deletedEventType: DeletedEventType;
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  policy?: Maybe<EventPolicy>;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** Deleted event type enumeration */
export enum DeletedEventType {
  Message = 'message',
  ReplyMessage = 'reply_message',
  Image = 'image',
  Video = 'video',
  Document = 'document',
  Agreement = 'agreement'
}

export enum DeleteEventErrorArgumentType {
  EventId = 'eventId'
}

export type DeleteEventErrorType = {
  __typename: 'DeleteEventErrorType';
  argument: DeleteEventErrorArgumentType;
  error: ValidationError;
};

export type DeleteEventInputType = {
  eventId: Scalars['ID'];
};

/** Autogenerated return type of DeleteEvent */
export type DeleteEventPayload = {
  __typename: 'DeleteEventPayload';
  errors: Array<DeleteEventErrorType>;
};

export enum DeleteMaterialNoteErrorArgumentType {
  MaterialNoteId = 'materialNoteId'
}

export type DeleteMaterialNoteErrorType = {
  __typename: 'DeleteMaterialNoteErrorType';
  argument: DeleteMaterialNoteErrorArgumentType;
  error: ValidationError;
};

export type DeleteMaterialNoteInputType = {
  materialNoteId: Scalars['ID'];
};

export enum DeleteMaterialNoteItemErrorArgumentType {
  MaterialNoteItemId = 'materialNoteItemId'
}

export type DeleteMaterialNoteItemErrorType = {
  __typename: 'DeleteMaterialNoteItemErrorType';
  argument: DeleteMaterialNoteItemErrorArgumentType;
  error: ValidationError;
};

export type DeleteMaterialNoteItemInputType = {
  materialNoteItemId: Scalars['ID'];
};

/** Autogenerated return type of DeleteMaterialNoteItem */
export type DeleteMaterialNoteItemPayload = {
  __typename: 'DeleteMaterialNoteItemPayload';
  errors: Array<DeleteMaterialNoteItemErrorType>;
};

/** Autogenerated return type of DeleteMaterialNote */
export type DeleteMaterialNotePayload = {
  __typename: 'DeleteMaterialNotePayload';
  errors: Array<DeleteMaterialNoteErrorType>;
};

export enum DeleteMediaErrorArgumentType {
  MediaId = 'mediaId'
}

export type DeleteMediaErrorType = {
  __typename: 'DeleteMediaErrorType';
  argument: DeleteMediaErrorArgumentType;
  error: ValidationError;
};

export type DeleteMediaInputType = {
  mediaId: Scalars['ID'];
};

/** Autogenerated return type of DeleteMedia */
export type DeleteMediaPayload = {
  __typename: 'DeleteMediaPayload';
  errors: Array<DeleteMediaErrorType>;
};

export enum DeleteTeamMemberErrorArgumentType {
  TeamMemberId = 'teamMemberId'
}

export type DeleteTeamMemberErrorType = {
  __typename: 'DeleteTeamMemberErrorType';
  argument: DeleteTeamMemberErrorArgumentType;
  error: ValidationError;
};

export type DeleteTeamMemberInputType = {
  teamMemberId: Scalars['ID'];
};

/** Autogenerated return type of DeleteTeamMember */
export type DeleteTeamMemberPayload = {
  __typename: 'DeleteTeamMemberPayload';
  errors: Array<DeleteTeamMemberErrorType>;
};

export enum DeleteTodoErrorArgumentType {
  TodoId = 'todoId'
}

export type DeleteTodoErrorType = {
  __typename: 'DeleteTodoErrorType';
  argument: DeleteTodoErrorArgumentType;
  error: ValidationError;
};

export type DeleteTodoInputType = {
  todoId: Scalars['ID'];
};

/** Autogenerated return type of DeleteTodo */
export type DeleteTodoPayload = {
  __typename: 'DeleteTodoPayload';
  errors: Array<DeleteTodoErrorType>;
};

export enum DemoteMemberErrorArgumentType {
  MemberId = 'memberId'
}

export type DemoteMemberErrorType = {
  __typename: 'DemoteMemberErrorType';
  argument: DemoteMemberErrorArgumentType;
  error: ValidationError;
};

export type DemoteMemberInputType = {
  memberId: Scalars['ID'];
};

/** Autogenerated return type of DemoteMember */
export type DemoteMemberPayload = {
  __typename: 'DemoteMemberPayload';
  errors: Array<DemoteMemberErrorType>;
  member?: Maybe<Member>;
};

export enum DemoteParticipantErrorArgumentType {
  ParticipantId = 'participantId'
}

export type DemoteParticipantErrorType = {
  __typename: 'DemoteParticipantErrorType';
  argument: DemoteParticipantErrorArgumentType;
  error: ValidationError;
};

export type DemoteParticipantInputType = {
  participantId: Scalars['ID'];
};

/** Autogenerated return type of DemoteParticipant */
export type DemoteParticipantPayload = {
  __typename: 'DemoteParticipantPayload';
  errors: Array<DemoteParticipantErrorType>;
  participant?: Maybe<Participant>;
};

/** A device on which one or more users are using the app */
export type Device = {
  __typename: 'Device';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  platform?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: User;
};

/** A document that was posted to a chat */
export type Document = Media & {
  __typename: 'Document';
  contentType: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  downloadFile?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  fileExtension: Scalars['String'];
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  members: Array<Member>;
  metadata?: Maybe<MediaMetadata>;
  ownerId: Scalars['String'];
  ownerType: Scalars['String'];
  path: Scalars['String'];
  policy?: Maybe<MediaPolicy>;
  preview?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  tile?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
};

export type Event = {
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdByCurrentUser: Scalars['Boolean'];
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  policy?: Maybe<EventPolicy>;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** The connection type for Event. */
export type EventConnection = {
  __typename: 'EventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EventEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Event>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EventEdge = {
  __typename: 'EventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Event>;
};

export type EventPolicy = {
  __typename: 'EventPolicy';
  delete: Scalars['Boolean'];
};

/** An expense submitted by a project member for approval by a project admin */
export type Expense = {
  __typename: 'Expense';
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedByMember?: Maybe<Member>;
  approvedByMemberId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByMember: Member;
  createdByMemberId: Scalars['ID'];
  description: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  media: Array<Media>;
  policy?: Maybe<ExpensePolicy>;
  price: Scalars['Float'];
  project: Project;
  projectId: Scalars['ID'];
  refund: Scalars['Boolean'];
  status?: Maybe<ExpenseStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  totalPrice: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  vatAmount: Scalars['Float'];
};

/** A message in a chat where an expense was posted */
export type ExpenseMessage = Event & {
  __typename: 'ExpenseMessage';
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdByCurrentUser: Scalars['Boolean'];
  expense: Expense;
  expenseMessageAction: ExpenseMessageAction;
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  policy?: Maybe<EventPolicy>;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** Expense message action enumeration */
export enum ExpenseMessageAction {
  Approved = 'approved',
  Rejected = 'rejected'
}

export type ExpensePolicy = {
  __typename: 'ExpensePolicy';
  approve: Scalars['Boolean'];
  export: Scalars['Boolean'];
  reject: Scalars['Boolean'];
  submit: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export enum ExpenseStatus {
  /** open */
  Open = 'open',
  /** submitted */
  Submitted = 'submitted',
  /** approved */
  Approved = 'approved',
  /** rejected */
  Rejected = 'rejected'
}

export enum ExportExpenseErrorArgumentType {
  ProjectId = 'projectId',
  StartDate = 'startDate',
  EndDate = 'endDate'
}

export type ExportExpenseErrorType = {
  __typename: 'ExportExpenseErrorType';
  argument: ExportExpenseErrorArgumentType;
  error: ValidationError;
};

export type ExportExpenseInputType = {
  projectId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

/** Autogenerated return type of ExportExpense */
export type ExportExpensePayload = {
  __typename: 'ExportExpensePayload';
  csv?: Maybe<Scalars['String']>;
  errors: Array<ExportExpenseErrorType>;
};

export enum ExportMaterialNotesErrorArgumentType {
  ProjectId = 'projectId',
  StartDate = 'startDate',
  EndDate = 'endDate'
}

export type ExportMaterialNotesErrorType = {
  __typename: 'ExportMaterialNotesErrorType';
  argument: ExportMaterialNotesErrorArgumentType;
  error: ValidationError;
};

export type ExportMaterialNotesInputType = {
  projectId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

/** Autogenerated return type of ExportMaterialNotes */
export type ExportMaterialNotesPayload = {
  __typename: 'ExportMaterialNotesPayload';
  csv?: Maybe<Scalars['String']>;
  errors: Array<ExportMaterialNotesErrorType>;
};

export enum ExportTimesheetErrorArgumentType {
  ProjectId = 'projectId',
  StartDate = 'startDate',
  EndDate = 'endDate'
}

export type ExportTimesheetErrorType = {
  __typename: 'ExportTimesheetErrorType';
  argument: ExportTimesheetErrorArgumentType;
  error: ValidationError;
};

export type ExportTimesheetInputType = {
  projectId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

/** Autogenerated return type of ExportTimesheet */
export type ExportTimesheetPayload = {
  __typename: 'ExportTimesheetPayload';
  csv?: Maybe<Scalars['String']>;
  errors: Array<ExportTimesheetErrorType>;
};

/** A folder that contains other media */
export type Folder = Media & {
  __typename: 'Folder';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  downloadFile?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  media: MediaConnection;
  members: Array<Member>;
  metadata?: Maybe<MediaMetadata>;
  ownerId: Scalars['String'];
  ownerType: Scalars['String'];
  path: Scalars['String'];
  policy?: Maybe<MediaPolicy>;
  preview?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  tile?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
};


/** A folder that contains other media */
export type FolderMediaArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  type?: Maybe<MediaType>;
};

/** A named chat between two or more members of a project */
export type GroupChat = Chat & {
  __typename: 'GroupChat';
  /** Current user is active participant and chat is not archived */
  active: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  /** Timestamp for current user deactivation */
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  events: EventConnection;
  general: Scalars['Boolean'];
  id: Scalars['ID'];
  imageFull?: Maybe<Scalars['String']>;
  imageThumbnail?: Maybe<Scalars['String']>;
  latestActivityAt: Scalars['DateTime'];
  members: Array<Member>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  participants: Array<Participant>;
  policy?: Maybe<GroupChatPolicy>;
  project: Project;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};


/** A named chat between two or more members of a project */
export type GroupChatEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type GroupChatPolicy = {
  __typename: 'GroupChatPolicy';
  archive: Scalars['Boolean'];
  createAgreement: Scalars['Boolean'];
  createMessage: Scalars['Boolean'];
  createParticipants: Scalars['Boolean'];
  leave: Scalars['Boolean'];
  unarchive: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** An image that was posted to a chat */
export type Image = Media & {
  __typename: 'Image';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  downloadFile?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  /** @deprecated use tile or preview */
  full: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  members: Array<Member>;
  metadata?: Maybe<MediaMetadata>;
  /** @deprecated use tile or preview */
  optimized: Scalars['String'];
  ownerId: Scalars['String'];
  ownerType: Scalars['String'];
  path: Scalars['String'];
  policy?: Maybe<MediaPolicy>;
  preview?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  /** @deprecated use tile or preview */
  thumbnail?: Maybe<Scalars['String']>;
  /** @deprecated use tile or preview */
  thumbnailHeight?: Maybe<Scalars['Int']>;
  /** @deprecated use tile or preview */
  thumbnailWidth?: Maybe<Scalars['Int']>;
  tile?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
};

/** An invitation for a new or existing user to become a project member */
export type Invitation = {
  __typename: 'Invitation';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  createdByUserFirstName: Scalars['String'];
  createdByUserName: Scalars['String'];
  deepLink?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  invitedUserId?: Maybe<Scalars['ID']>;
  lastName?: Maybe<Scalars['String']>;
  memberId: Scalars['ID'];
  memberRole: MemberRole;
  memberRoleLabel?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  policy?: Maybe<InvitationPolicy>;
  projectDescription?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['ID']>;
  projectImageThumbnail?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type InvitationPolicy = {
  __typename: 'InvitationPolicy';
  accept: Scalars['Boolean'];
  mail: Scalars['Boolean'];
  open: Scalars['Boolean'];
  share: Scalars['Boolean'];
};

export enum InviteMemberErrorArgumentType {
  ProjectId = 'projectId',
  FirstName = 'firstName',
  LastName = 'lastName',
  Role = 'role',
  RoleLabel = 'roleLabel',
  EnableTimesheets = 'enableTimesheets',
  EnableExpenses = 'enableExpenses',
  EnableMaterialNotes = 'enableMaterialNotes',
  Message = 'message'
}

export type InviteMemberErrorType = {
  __typename: 'InviteMemberErrorType';
  argument: InviteMemberErrorArgumentType;
  error: ValidationError;
};

export type InviteMemberInputType = {
  projectId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  role: MemberRole;
  roleLabel?: Maybe<Scalars['String']>;
  enableTimesheets?: Maybe<Scalars['Boolean']>;
  enableExpenses?: Maybe<Scalars['Boolean']>;
  enableMaterialNotes?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of InviteMember */
export type InviteMemberPayload = {
  __typename: 'InviteMemberPayload';
  errors: Array<InviteMemberErrorType>;
  invitation?: Maybe<Invitation>;
};

export enum InviteUserErrorArgumentType {
  ProjectId = 'projectId',
  UserId = 'userId',
  Role = 'role',
  RoleLabel = 'roleLabel',
  EnableTimesheets = 'enableTimesheets',
  EnableExpenses = 'enableExpenses',
  EnableMaterialNotes = 'enableMaterialNotes',
  Message = 'message'
}

export type InviteUserErrorType = {
  __typename: 'InviteUserErrorType';
  argument: InviteUserErrorArgumentType;
  error: ValidationError;
};

export type InviteUserInputType = {
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
  role: MemberRole;
  roleLabel?: Maybe<Scalars['String']>;
  enableTimesheets?: Maybe<Scalars['Boolean']>;
  enableExpenses?: Maybe<Scalars['Boolean']>;
  enableMaterialNotes?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of InviteUser */
export type InviteUserPayload = {
  __typename: 'InviteUserPayload';
  errors: Array<InviteUserErrorType>;
  invitation?: Maybe<Invitation>;
};

export enum InviteUsersErrorArgumentType {
  ProjectId = 'projectId',
  UserIds = 'userIds'
}

export type InviteUsersErrorType = {
  __typename: 'InviteUsersErrorType';
  argument: InviteUsersErrorArgumentType;
  error: ValidationError;
};

export type InviteUsersInputType = {
  projectId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export enum CreateWkbAnswerErrorArgumentType {
  ProjectId = 'projectId',
  questionId = 'questionId',
  optionId = 'optionId',
  comment = 'comment'
}

export type CreateWkbAnswerErrorType = {
  __typename: 'CreateWkbAnswerErrorType';
  argument: CreateWkbAnswerErrorArgumentType;
  error: ValidationError;
};

export type CreateWkbAnswerInputType = {
  projectId: Scalars['ID'];
  questionId: Array<Scalars['ID']>;
  optionId: Array<Scalars['ID']>;
  comment: Maybe<Scalars['String']>;
};

/** Autogenerated return type of InviteUsers */
export type InviteUsersPayload = {
  __typename: 'InviteUsersPayload';
  errors: Array<InviteUsersErrorType>;
};

export enum LeaveGroupChatErrorArgumentType {
  GroupChatId = 'groupChatId'
}

export type LeaveGroupChatErrorType = {
  __typename: 'LeaveGroupChatErrorType';
  argument: LeaveGroupChatErrorArgumentType;
  error: ValidationError;
};

export type LeaveGroupChatInputType = {
  groupChatId: Scalars['ID'];
};

/** Autogenerated return type of LeaveGroupChat */
export type LeaveGroupChatPayload = {
  __typename: 'LeaveGroupChatPayload';
  errors: Array<LeaveGroupChatErrorType>;
  groupChat?: Maybe<GroupChat>;
  participant?: Maybe<Participant>;
};

export enum LogoutErrorArgumentType {
  DeviceReference = 'deviceReference'
}

export type LogoutErrorType = {
  __typename: 'LogoutErrorType';
  argument: LogoutErrorArgumentType;
  error: ValidationError;
};

export type LogoutInputType = {
  deviceReference?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Logout */
export type LogoutPayload = {
  __typename: 'LogoutPayload';
  errors: Array<LogoutErrorType>;
  user: User;
};

export enum MailInvitationErrorArgumentType {
  InvitationId = 'invitationId',
  Email = 'email'
}

export type MailInvitationErrorType = {
  __typename: 'MailInvitationErrorType';
  argument: MailInvitationErrorArgumentType;
  error: ValidationError;
};

export type MailInvitationInputType = {
  invitationId: Scalars['ID'];
  email: Scalars['String'];
};

/** Autogenerated return type of MailInvitation */
export type MailInvitationPayload = {
  __typename: 'MailInvitationPayload';
  errors: Array<MailInvitationErrorType>;
  invitation?: Maybe<Invitation>;
};

/** An material note submitted by a project member for approval by a project admin */
export type MaterialNote = {
  __typename: 'MaterialNote';
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedByMember?: Maybe<Member>;
  approvedByMemberId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByMember: Member;
  createdByMemberId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  materialNoteItems: Array<MaterialNoteItem>;
  policy?: Maybe<MaterialNotePolicy>;
  project: Project;
  projectId: Scalars['ID'];
  status?: Maybe<MaterialNoteStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** An material note item */
export type MaterialNoteItem = {
  __typename: 'MaterialNoteItem';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  materialNoteId: Scalars['ID'];
  name: Scalars['String'];
  policy?: Maybe<MaterialNoteItemPolicy>;
  quantity: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MaterialNoteItemPolicy = {
  __typename: 'MaterialNoteItemPolicy';
  approve: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  export: Scalars['Boolean'];
  reject: Scalars['Boolean'];
  submit: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** A message in a chat where an material_note was posted */
export type MaterialNoteMessage = Event & {
  __typename: 'MaterialNoteMessage';
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdByCurrentUser: Scalars['Boolean'];
  id: Scalars['ID'];
  materialNote: MaterialNote;
  materialNoteMessageAction: MaterialNoteMessageAction;
  payload?: Maybe<Scalars['String']>;
  policy?: Maybe<EventPolicy>;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** MaterialNote message action enumeration */
export enum MaterialNoteMessageAction {
  Approved = 'approved',
  Rejected = 'rejected'
}

export type MaterialNotePolicy = {
  __typename: 'MaterialNotePolicy';
  approve: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  export: Scalars['Boolean'];
  reject: Scalars['Boolean'];
  submit: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export enum MaterialNoteStatus {
  /** open */
  Open = 'open',
  /** submitted */
  Submitted = 'submitted',
  /** approved */
  Approved = 'approved',
  /** rejected */
  Rejected = 'rejected'
}

export type Media = {
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  downloadFile?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  members: Array<Member>;
  metadata?: Maybe<MediaMetadata>;
  ownerId: Scalars['String'];
  ownerType: Scalars['String'];
  path: Scalars['String'];
  policy?: Maybe<MediaPolicy>;
  preview?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  tile?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
};

/** The connection type for Media. */
export type MediaConnection = {
  __typename: 'MediaConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<MediaEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Media>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type MediaEdge = {
  __typename: 'MediaEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Media>;
};

export type MediaInputTypeForCreateAgreement = {
  description?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
};

export type MediaInputTypeForCreateExpense = {
  description?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
};

export type MediaInputTypeForCreateTodo = {
  description?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
};

export type MediaInputTypeForUpdateAgreement = {
  description?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
};

export type MediaInputTypeForUpdateExpense = {
  description?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
};

export type MediaInputTypeForUpdateTodo = {
  description?: Maybe<Scalars['String']>;
  file: Scalars['Upload'];
};

/** A message where an image or document was posted to a chat */
export type MediaMessage = Event & {
  __typename: 'MediaMessage';
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdByCurrentUser: Scalars['Boolean'];
  id: Scalars['ID'];
  media: Media;
  payload?: Maybe<Scalars['String']>;
  policy?: Maybe<EventPolicy>;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export type MediaMetadata = {
  __typename: 'MediaMetadata';
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  portrait?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
};

export type MediaPolicy = {
  __typename: 'MediaPolicy';
  delete: Scalars['Boolean'];
};

export enum MediaType {
  Image = 'image',
  Video = 'video',
  Document = 'document',
  Folder = 'folder'
}

/** A user can be a member of multiple projects */
export type Member = {
  __typename: 'Member';
  active: Scalars['Boolean'];
  admin: Scalars['Boolean'];
  avatarFull?: Maybe<Scalars['String']>;
  avatarThumbnail?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  current: Scalars['Boolean'];
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  enableExpenses: Scalars['Boolean'];
  enableMaterialNotes: Scalars['Boolean'];
  enableTimesheets: Scalars['Boolean'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  invitation?: Maybe<Invitation>;
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  policy?: Maybe<MemberPolicy>;
  project: Project;
  projectId: Scalars['ID'];
  role: MemberRole;
  roleLabel?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type MemberPolicy = {
  __typename: 'MemberPolicy';
  createAgreement: Scalars['Boolean'];
  createGroupChat: Scalars['Boolean'];
  createPrivateChat: Scalars['Boolean'];
  deactivate: Scalars['Boolean'];
  demote: Scalars['Boolean'];
  promote: Scalars['Boolean'];
  showContactDetails: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

/** Project member role enumeration */
export enum MemberRole {
  Client = 'client',
  Contractor = 'contractor',
  Subcontractor = 'subcontractor',
  Employee = 'employee',
  Other = 'other'
}

/** A text message that was posted to a chat */
export type Message = Event & {
  __typename: 'Message';
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdByCurrentUser: Scalars['Boolean'];
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  policy?: Maybe<EventPolicy>;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export enum MoveMediaErrorArgumentType {
  MediaId = 'mediaId',
  ProjectId = 'projectId',
  Path = 'path'
}

export type MoveMediaErrorType = {
  __typename: 'MoveMediaErrorType';
  argument: MoveMediaErrorArgumentType;
  error: ValidationError;
};

export type MoveMediaInputType = {
  mediaId: Scalars['ID'];
  projectId: Scalars['ID'];
  path?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of MoveMedia */
export type MoveMediaPayload = {
  __typename: 'MoveMediaPayload';
  errors: Array<MoveMediaErrorType>;
  media?: Maybe<Media>;
};

export enum OpenedInvitationErrorArgumentType {
  InvitationToken = 'invitationToken'
}

export type OpenedInvitationErrorType = {
  __typename: 'OpenedInvitationErrorType';
  argument: OpenedInvitationErrorArgumentType;
  error: ValidationError;
};

export type OpenedInvitationInputType = {
  invitationToken: Scalars['String'];
};

/** Autogenerated return type of OpenedInvitation */
export type OpenedInvitationPayload = {
  __typename: 'OpenedInvitationPayload';
  errors: Array<OpenedInvitationErrorType>;
  invitation?: Maybe<Invitation>;
};

export enum OrderActivitiesErrorArgumentType {
  ProjectId = 'projectId',
  ActivityIds = 'activityIds'
}

export type OrderActivitiesErrorType = {
  __typename: 'OrderActivitiesErrorType';
  argument: OrderActivitiesErrorArgumentType;
  error: ValidationError;
};

export type OrderActivitiesInputType = {
  projectId: Scalars['ID'];
  activityIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of OrderActivities */
export type OrderActivitiesPayload = {
  __typename: 'OrderActivitiesPayload';
  errors: Array<OrderActivitiesErrorType>;
};

export enum OrderTodosErrorArgumentType {
  ProjectId = 'projectId',
  TodoIds = 'todoIds'
}

export type OrderTodosErrorType = {
  __typename: 'OrderTodosErrorType';
  argument: OrderTodosErrorArgumentType;
  error: ValidationError;
};

export type OrderTodosInputType = {
  projectId: Scalars['ID'];
  todoIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of OrderTodos */
export type OrderTodosPayload = {
  __typename: 'OrderTodosPayload';
  errors: Array<OrderTodosErrorType>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** A project member participating in a chat */
export type Participant = {
  __typename: 'Participant';
  active: Scalars['Boolean'];
  admin: Scalars['Boolean'];
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  current: Scalars['Boolean'];
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  member: Member;
  memberId: Scalars['ID'];
  name: Scalars['String'];
  policy?: Maybe<ParticipantPolicy>;
  readAt?: Maybe<Scalars['DateTime']>;
  typingAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type ParticipantPolicy = {
  __typename: 'ParticipantPolicy';
  deactivate: Scalars['Boolean'];
  demote: Scalars['Boolean'];
  promote: Scalars['Boolean'];
};

export enum PdfActivitiesErrorArgumentType {
  ProjectId = 'projectId',
  StartDate = 'startDate',
  EndDate = 'endDate'
}

export type PdfActivitiesErrorType = {
  __typename: 'PdfActivitiesErrorType';
  argument: PdfActivitiesErrorArgumentType;
  error: ValidationError;
};

export type PdfActivitiesInputType = {
  projectId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
};

/** Autogenerated return type of PdfActivities */
export type PdfActivitiesPayload = {
  __typename: 'PdfActivitiesPayload';
  errors: Array<PdfActivitiesErrorType>;
  pdf?: Maybe<Scalars['String']>;
};

/** A chat between two members of a project */
export type PrivateChat = Chat & {
  __typename: 'PrivateChat';
  /** Current user is active participant and chat is not archived */
  active: Scalars['Boolean'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  /** Timestamp for current user deactivation */
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  events: EventConnection;
  general: Scalars['Boolean'];
  id: Scalars['ID'];
  imageFull?: Maybe<Scalars['String']>;
  imageThumbnail?: Maybe<Scalars['String']>;
  latestActivityAt: Scalars['DateTime'];
  members: Array<Member>;
  name?: Maybe<Scalars['String']>;
  notificationCount?: Maybe<Scalars['Int']>;
  otherMember: Member;
  otherMemberEmail?: Maybe<Scalars['String']>;
  otherMemberId: Scalars['ID'];
  participants: Array<Participant>;
  policy?: Maybe<PrivateChatPolicy>;
  project: Project;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};


/** A chat between two members of a project */
export type PrivateChatEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PrivateChatPolicy = {
  __typename: 'PrivateChatPolicy';
  createAgreement: Scalars['Boolean'];
  createMessage: Scalars['Boolean'];
};

export type PrivateMutation = {
  __typename: 'PrivateMutation';
  acceptAgreement?: Maybe<AcceptAgreementPayload>;
  acceptInvitation?: Maybe<AcceptInvitationPayload>;
  addressSearch: AddressSearchPayload;
  approveExpense: ApproveExpensePayload;
  approveMaterialNote: ApproveMaterialNotePayload;
  approveTimesheet: ApproveTimesheetPayload;
  archiveActivity: ArchiveActivityPayload;
  archiveGroupChat: ArchiveGroupChatPayload;
  archiveProject?: Maybe<ArchiveProjectPayload>;
  cancelSubscription: CancelSubscriptionPayload;
  clearPresence: ClearPresencePayload;
  createActivity: CreateActivityPayload;
  createAddressValuation: CreateAddressValuationPayload;
  createAgreement: CreateAgreementPayload;
  createDemoProject?: Maybe<CreateDemoProjectPayload>;
  createExpense: CreateExpensePayload;
  createFolder?: Maybe<CreateFolderPayload>;
  createGroupChat: CreateGroupChatPayload;
  createMaterialNote: CreateMaterialNotePayload;
  createMaterialNoteItem: CreateMaterialNoteItemPayload;
  createMedia?: Maybe<CreateMediaPayload>;
  createMediaMessage: CreateMediaMessagePayload;
  createMessage: CreateMessagePayload;
  createParticipants: CreateParticipantsPayload;
  createPrivateChat: CreatePrivateChatPayload;
  createProject?: Maybe<CreateProjectPayload>;
  createReplyMessage: CreateReplyMessagePayload;
  createSubscription: CreateSubscriptionPayload;
  createTeamMember: CreateTeamMemberPayload;
  createTender: CreateTenderPayload;
  createTodo: CreateTodoPayload;
  deactivateMember?: Maybe<DeactivateMemberPayload>;
  deactivateParticipant: DeactivateParticipantPayload;
  deleteAgreement?: Maybe<DeleteAgreementPayload>;
  deleteEvent: DeleteEventPayload;
  deleteMaterialNote?: Maybe<DeleteMaterialNotePayload>;
  deleteMaterialNoteItem: DeleteMaterialNoteItemPayload;
  deleteMedia: DeleteMediaPayload;
  deleteTeamMember: DeleteTeamMemberPayload;
  deleteTodo?: Maybe<DeleteTodoPayload>;
  demoteMember?: Maybe<DemoteMemberPayload>;
  demoteParticipant?: Maybe<DemoteParticipantPayload>;
  exportExpense: ExportExpensePayload;
  exportMaterialNotes: ExportMaterialNotesPayload;
  exportTimesheet: ExportTimesheetPayload;
  inviteMember?: Maybe<InviteMemberPayload>;
  inviteUser?: Maybe<InviteUserPayload>;
  inviteUsers?: Maybe<InviteUsersPayload>;
  leaveGroupChat: LeaveGroupChatPayload;
  logout?: Maybe<LogoutPayload>;
  mailInvitation?: Maybe<MailInvitationPayload>;
  moveMedia: MoveMediaPayload;
  openedInvitation?: Maybe<OpenedInvitationPayload>;
  orderActivities: OrderActivitiesPayload;
  orderTodos: OrderTodosPayload;
  pdfActivities: PdfActivitiesPayload;
  promoteMember?: Maybe<PromoteMemberPayload>;
  promoteParticipant?: Maybe<PromoteParticipantPayload>;
  refreshToken?: Maybe<RefreshTokenPayload>;
  registerDevice?: Maybe<RegisterDevicePayload>;
  rejectAgreement?: Maybe<RejectAgreementPayload>;
  rejectExpense: RejectExpensePayload;
  rejectMaterialNote: RejectMaterialNotePayload;
  rejectTimesheet: RejectTimesheetPayload;
  resetNotifications?: Maybe<ResetNotificationsPayload>;
  sendDasContact?: Maybe<SendDasContactPayload>;
  sendFeedback?: Maybe<SendFeedbackPayload>;
  setPresence: SetPresencePayload;
  setTyping: SetTypingPayload;
  shareInvitation?: Maybe<ShareInvitationPayload>;
  submitExpense: SubmitExpensePayload;
  submitMaterialNote: SubmitMaterialNotePayload;
  submitTimesheet: SubmitTimesheetPayload;
  unarchiveGroupChat: UnarchiveGroupChatPayload;
  unarchiveProject?: Maybe<UnarchiveProjectPayload>;
  unsubscribeEmail?: Maybe<UnsubscribeEmailPayload>;
  updateActivity: UpdateActivityPayload;
  updateAgreement: UpdateAgreementPayload;
  updateCompany?: Maybe<UpdateCompanyPayload>;
  updateExpense: UpdateExpensePayload;
  updateFolder?: Maybe<UpdateFolderPayload>;
  updateGroupChat: UpdateGroupChatPayload;
  updateMaterialNoteItem: UpdateMaterialNoteItemPayload;
  updateMember?: Maybe<UpdateMemberPayload>;
  updatePassword?: Maybe<UpdatePasswordPayload>;
  updateProject?: Maybe<UpdateProjectPayload>;
  updateTender: UpdateTenderPayload;
  updateTimesheet: UpdateTimesheetPayload;
  updateTodo: UpdateTodoPayload;
  updateUser?: Maybe<UpdateUserPayload>;
};


export type PrivateMutationAcceptAgreementArgs = {
  input: AcceptAgreementInputType;
};


export type PrivateMutationAcceptInvitationArgs = {
  input: AcceptInvitationInputType;
};


export type PrivateMutationAddressSearchArgs = {
  input: AddressSearchInputType;
};


export type PrivateMutationApproveExpenseArgs = {
  input: ApproveExpenseInputType;
};


export type PrivateMutationApproveMaterialNoteArgs = {
  input: ApproveMaterialNoteInputType;
};


export type PrivateMutationApproveTimesheetArgs = {
  input: ApproveTimesheetInputType;
};


export type PrivateMutationArchiveActivityArgs = {
  input: ArchiveActivityInputType;
};


export type PrivateMutationArchiveGroupChatArgs = {
  input: ArchiveGroupChatInputType;
};


export type PrivateMutationArchiveProjectArgs = {
  input: ArchiveProjectInputType;
};


export type PrivateMutationClearPresenceArgs = {
  input: ClearPresenceInputType;
};


export type PrivateMutationCreateActivityArgs = {
  input: CreateActivityInputType;
};


export type PrivateMutationCreateAddressValuationArgs = {
  input: CreateAddressValuationInputType;
};


export type PrivateMutationCreateAgreementArgs = {
  input: CreateAgreementInputType;
};


export type PrivateMutationCreateExpenseArgs = {
  input: CreateExpenseInputType;
};


export type PrivateMutationCreateFolderArgs = {
  input: CreateFolderInputType;
};


export type PrivateMutationCreateGroupChatArgs = {
  input: CreateGroupChatInputType;
};


export type PrivateMutationCreateMaterialNoteArgs = {
  input: CreateMaterialNoteInputType;
};


export type PrivateMutationCreateMaterialNoteItemArgs = {
  input: CreateMaterialNoteItemInputType;
};


export type PrivateMutationCreateMediaArgs = {
  input: CreateMediaInputType;
};


export type PrivateMutationCreateMediaMessageArgs = {
  input: CreateMediaMessageInputType;
};


export type PrivateMutationCreateMessageArgs = {
  input: CreateMessageInputType;
};


export type PrivateMutationCreateParticipantsArgs = {
  input: CreateParticipantsInputType;
};


export type PrivateMutationCreatePrivateChatArgs = {
  input: CreatePrivateChatInputType;
};


export type PrivateMutationCreateProjectArgs = {
  input: CreateProjectInputType;
};


export type PrivateMutationCreateReplyMessageArgs = {
  input: CreateReplyMessageInputType;
};


export type PrivateMutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInputType;
};


export type PrivateMutationCreateTeamMemberArgs = {
  input: CreateTeamMemberInputType;
};


export type PrivateMutationCreateTenderArgs = {
  input: CreateTenderInputType;
};


export type PrivateMutationCreateTodoArgs = {
  input: CreateTodoInputType;
};


export type PrivateMutationDeactivateMemberArgs = {
  input: DeactivateMemberInputType;
};


export type PrivateMutationDeactivateParticipantArgs = {
  input: DeactivateParticipantInputType;
};


export type PrivateMutationDeleteAgreementArgs = {
  input: DeleteAgreementInputType;
};


export type PrivateMutationDeleteEventArgs = {
  input: DeleteEventInputType;
};


export type PrivateMutationDeleteMaterialNoteArgs = {
  input: DeleteMaterialNoteInputType;
};


export type PrivateMutationDeleteMaterialNoteItemArgs = {
  input: DeleteMaterialNoteItemInputType;
};


export type PrivateMutationDeleteMediaArgs = {
  input: DeleteMediaInputType;
};


export type PrivateMutationDeleteTeamMemberArgs = {
  input: DeleteTeamMemberInputType;
};


export type PrivateMutationDeleteTodoArgs = {
  input: DeleteTodoInputType;
};


export type PrivateMutationDemoteMemberArgs = {
  input: DemoteMemberInputType;
};


export type PrivateMutationDemoteParticipantArgs = {
  input: DemoteParticipantInputType;
};


export type PrivateMutationExportExpenseArgs = {
  input: ExportExpenseInputType;
};


export type PrivateMutationExportMaterialNotesArgs = {
  input: ExportMaterialNotesInputType;
};


export type PrivateMutationExportTimesheetArgs = {
  input: ExportTimesheetInputType;
};


export type PrivateMutationInviteMemberArgs = {
  input: InviteMemberInputType;
};


export type PrivateMutationInviteUserArgs = {
  input: InviteUserInputType;
};


export type PrivateMutationInviteUsersArgs = {
  input: InviteUsersInputType;
};


export type PrivateMutationLeaveGroupChatArgs = {
  input: LeaveGroupChatInputType;
};


export type PrivateMutationLogoutArgs = {
  input: LogoutInputType;
};


export type PrivateMutationMailInvitationArgs = {
  input: MailInvitationInputType;
};


export type PrivateMutationMoveMediaArgs = {
  input: MoveMediaInputType;
};


export type PrivateMutationOpenedInvitationArgs = {
  input: OpenedInvitationInputType;
};


export type PrivateMutationOrderActivitiesArgs = {
  input: OrderActivitiesInputType;
};


export type PrivateMutationOrderTodosArgs = {
  input: OrderTodosInputType;
};


export type PrivateMutationPdfActivitiesArgs = {
  input: PdfActivitiesInputType;
};


export type PrivateMutationPromoteMemberArgs = {
  input: PromoteMemberInputType;
};


export type PrivateMutationPromoteParticipantArgs = {
  input: PromoteParticipantInputType;
};


export type PrivateMutationRefreshTokenArgs = {
  input: RefreshTokenInputType;
};


export type PrivateMutationRegisterDeviceArgs = {
  input: RegisterDeviceInputType;
};


export type PrivateMutationRejectAgreementArgs = {
  input: RejectAgreementInputType;
};


export type PrivateMutationRejectExpenseArgs = {
  input: RejectExpenseInputType;
};


export type PrivateMutationRejectMaterialNoteArgs = {
  input: RejectMaterialNoteInputType;
};


export type PrivateMutationRejectTimesheetArgs = {
  input: RejectTimesheetInputType;
};


export type PrivateMutationResetNotificationsArgs = {
  input: ResetNotificationsInputType;
};


export type PrivateMutationSendDasContactArgs = {
  input: SendDasContactInputType;
};


export type PrivateMutationSendFeedbackArgs = {
  input: SendFeedbackInputType;
};


export type PrivateMutationSetPresenceArgs = {
  input: SetPresenceInputType;
};


export type PrivateMutationSetTypingArgs = {
  input: SetTypingInputType;
};


export type PrivateMutationShareInvitationArgs = {
  input: ShareInvitationInputType;
};


export type PrivateMutationSubmitExpenseArgs = {
  input: SubmitExpenseInputType;
};


export type PrivateMutationSubmitMaterialNoteArgs = {
  input: SubmitMaterialNoteInputType;
};


export type PrivateMutationSubmitTimesheetArgs = {
  input: SubmitTimesheetInputType;
};


export type PrivateMutationUnarchiveGroupChatArgs = {
  input: UnarchiveGroupChatInputType;
};


export type PrivateMutationUnarchiveProjectArgs = {
  input: UnarchiveProjectInputType;
};


export type PrivateMutationUnsubscribeEmailArgs = {
  input: UnsubscribeEmailInputType;
};


export type PrivateMutationUpdateActivityArgs = {
  input: UpdateActivityInputType;
};


export type PrivateMutationUpdateAgreementArgs = {
  input: UpdateAgreementInputType;
};


export type PrivateMutationUpdateCompanyArgs = {
  input: UpdateCompanyInputType;
};


export type PrivateMutationUpdateExpenseArgs = {
  input: UpdateExpenseInputType;
};


export type PrivateMutationUpdateFolderArgs = {
  input: UpdateFolderInputType;
};


export type PrivateMutationUpdateGroupChatArgs = {
  input: UpdateGroupChatInputType;
};


export type PrivateMutationUpdateMaterialNoteItemArgs = {
  input: UpdateMaterialNoteItemInputType;
};


export type PrivateMutationUpdateMemberArgs = {
  input: UpdateMemberInputType;
};


export type PrivateMutationUpdatePasswordArgs = {
  input: UpdatePasswordInputType;
};


export type PrivateMutationUpdateProjectArgs = {
  input: UpdateProjectInputType;
};


export type PrivateMutationUpdateTenderArgs = {
  input: UpdateTenderInputType;
};


export type PrivateMutationUpdateTimesheetArgs = {
  input: UpdateTimesheetInputType;
};


export type PrivateMutationUpdateTodoArgs = {
  input: UpdateTodoInputType;
};


export type PrivateMutationUpdateUserArgs = {
  input: UpdateUserInputType;
};

/** The query root of this schema */
export type PrivateQuery = {
  __typename: 'PrivateQuery';
  /** All activities where the current_user is related to */
  activities?: Maybe<Array<Activity>>;
  /** Find an activity by ID */
  activity?: Maybe<Activity>;
  /** Address information */
  addressInformation?: Maybe<AddressInformation>;
  /** Address search */
  addressSearch?: Maybe<Array<AddressSearch>>;
  /** Find an agreement by ID */
  agreement?: Maybe<Agreement>;
  apiVersion: Scalars['Int'];
  /** Find a chat by ID */
  chat?: Maybe<Chat>;
  /** Gets the currently logged in user */
  currentUser?: Maybe<User>;
  /** Find an expense by ID */
  expense?: Maybe<Expense>;
  /** Get an invitation by ID */
  invitation?: Maybe<Invitation>;
  /** Get an invitation by token */
  invitationByToken?: Maybe<Invitation>;
  /** Unaccpted invitations for current_user */
  invitations?: Maybe<Array<Invitation>>;
  /** Find an material_note by ID */
  materialNote?: Maybe<MaterialNote>;
  /** Find an material_note_item by ID */
  materialNoteItem?: Maybe<MaterialNoteItem>;
  /** Find an image, video, folder or document by ID */
  media?: Maybe<Media>;
  /** Find a member by ID */
  member?: Maybe<Member>;
  /** All agreements where the current_user is related to */
  openAgreements?: Maybe<Array<Agreement>>;
  /** All todos where the current_user is related to */
  openTodos?: Maybe<Array<Todo>>;
  /** Get a project by ID */
  project?: Maybe<Project>;
  /** Get all projects for a authenticated user */
  projects?: Maybe<Array<Project>>;
  /** All expenses from projects where you are an admin that you can approve */
  submittedExpenses?: Maybe<Array<Expense>>;
  /** All material_notes from projects where you are an admin that you can approve */
  submittedMaterialNotes?: Maybe<Array<MaterialNote>>;
  /** All timesheets from projects where you are an admin that you can approve */
  submittedTimesheets?: Maybe<Array<Timesheet>>;
  /** Find an tender by ID */
  tender?: Maybe<Tender>;
  /** Find an timesheet by ID */
  timesheet?: Maybe<Timesheet>;
  /** Find an todo by ID */
  todo?: Maybe<Todo>;
};


/** The query root of this schema */
export type PrivateQueryActivityArgs = {
  activityId: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryAddressInformationArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryAddressSearchArgs = {
  address: Scalars['String'];
};


/** The query root of this schema */
export type PrivateQueryAgreementArgs = {
  agreementId: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryChatArgs = {
  chatId: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryExpenseArgs = {
  expenseId: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryInvitationArgs = {
  invitationId: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryInvitationByTokenArgs = {
  invitationToken: Scalars['String'];
};


/** The query root of this schema */
export type PrivateQueryMaterialNoteArgs = {
  materialNoteId: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryMaterialNoteItemArgs = {
  materialNoteItemId: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryMediaArgs = {
  mediaId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
};


/** The query root of this schema */
export type PrivateQueryMemberArgs = {
  memberId: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryProjectArgs = {
  projectId: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryTenderArgs = {
  tenderId: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryTimesheetArgs = {
  timesheetId: Scalars['ID'];
};


/** The query root of this schema */
export type PrivateQueryTodoArgs = {
  todoId: Scalars['ID'];
};

export type PrivateSubscription = {
  __typename: 'PrivateSubscription';
  activity: Activity;
  agreement: Agreement;
  chat: Chat;
  delete: DeletedEntity;
  event: Event;
  expense: Expense;
  materialNote: MaterialNote;
  media: Media;
  member: Member;
  participant: Participant;
  project: Project;
  timesheet: Timesheet;
  todo: Todo;
};

/** A project containing members, chats, agreements and activities */
export type Project = {
  __typename: 'Project';
  /** Current user is active member and project is not archived */
  active: Scalars['Boolean'];
  activities: Array<Activity>;
  address?: Maybe<Scalars['String']>;
  agreements: Array<Agreement>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  chats: Array<Chat>;
  companyId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  currentMemberTimesheets: Array<Timesheet>;
  /** Timestamp for current user deactivation */
  deactivatedAt?: Maybe<Scalars['DateTime']>;
  demo?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  expenses: Array<Expense>;
  id: Scalars['ID'];
  imageBanner?: Maybe<Scalars['String']>;
  imageFull?: Maybe<Scalars['String']>;
  imageThumbnail?: Maybe<Scalars['String']>;
  /** Users that where previously invited to projects by the current_user */
  invitationSuggestions: Array<User>;
  latestActivityAt: Scalars['DateTime'];
  materialNotes: Array<MaterialNote>;
  media: MediaConnection;
  members: Array<Member>;
  name?: Maybe<Scalars['String']>;
  nameOrAddress: Scalars['String'];
  notificationCount?: Maybe<Scalars['Int']>;
  chatNotificationCount?: Maybe<Scalars['Int']>;
  onboardingProject?: Maybe<Scalars['Boolean']>;
  policy?: Maybe<ProjectPolicy>;
  reference?: Maybe<Scalars['String']>;
  timesheets: Array<Timesheet>;
  todos: Array<Todo>;
  updatedAt: Scalars['DateTime'];
};

export type WkbTemplate = {
  __typename: 'wkbTemplates';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  totalQuestions: Maybe<Scalars['Int']>;
  postAnswers: Maybe<Scalars['Int']>;
};

export type WkbQuestion = {
  __typename: 'wkbQuestions';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  answer?: Maybe<WkbAnswer>;
};

export type WkbAnswer = {
  __typename: 'WkbAnswer';
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
};


/** A project containing members, chats, agreements and activities */
export type ProjectMediaArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  type?: Maybe<MediaType>;
  path?: Maybe<Scalars['String']>;
  folders?: Maybe<Scalars['Boolean']>;
};

export type ProjectPolicy = {
  __typename: 'ProjectPolicy';
  archive: Scalars['Boolean'];
  createActivity: Scalars['Boolean'];
  createAgreement: Scalars['Boolean'];
  createExpense: Scalars['Boolean'];
  createGroupChat: Scalars['Boolean'];
  createMaterialNote: Scalars['Boolean'];
  createMedia: Scalars['Boolean'];
  createPrivateChat: Scalars['Boolean'];
  createTodo: Scalars['Boolean'];
  exportExpense: Scalars['Boolean'];
  exportMaterialNotes: Scalars['Boolean'];
  exportTimesheets: Scalars['Boolean'];
  inviteMember: Scalars['Boolean'];
  inviteMemberLimitReached: Scalars['Boolean'];
  inviteUser: Scalars['Boolean'];
  inviteUsers: Scalars['Boolean'];
  manageExpenses: Scalars['Boolean'];
  manageMaterialNotes: Scalars['Boolean'];
  manageTimesheets: Scalars['Boolean'];
  orderActivities: Scalars['Boolean'];
  orderTodos: Scalars['Boolean'];
  pdfActivities: Scalars['Boolean'];
  unarchive: Scalars['Boolean'];
  update: Scalars['Boolean'];
  updateTimesheet: Scalars['Boolean'];
};

export enum PromoteMemberErrorArgumentType {
  MemberId = 'memberId'
}

export type PromoteMemberErrorType = {
  __typename: 'PromoteMemberErrorType';
  argument: PromoteMemberErrorArgumentType;
  error: ValidationError;
};

export type PromoteMemberInputType = {
  memberId: Scalars['ID'];
};

/** Autogenerated return type of PromoteMember */
export type PromoteMemberPayload = {
  __typename: 'PromoteMemberPayload';
  errors: Array<PromoteMemberErrorType>;
  member?: Maybe<Member>;
};

export enum PromoteParticipantErrorArgumentType {
  ParticipantId = 'participantId'
}

export type PromoteParticipantErrorType = {
  __typename: 'PromoteParticipantErrorType';
  argument: PromoteParticipantErrorArgumentType;
  error: ValidationError;
};

export type PromoteParticipantInputType = {
  participantId: Scalars['ID'];
};

/** Autogenerated return type of PromoteParticipant */
export type PromoteParticipantPayload = {
  __typename: 'PromoteParticipantPayload';
  errors: Array<PromoteParticipantErrorType>;
  participant?: Maybe<Participant>;
};

export enum RefreshTokenErrorArgumentType {
  RefreshToken = 'refreshToken'
}

export type RefreshTokenErrorType = {
  __typename: 'RefreshTokenErrorType';
  argument: RefreshTokenErrorArgumentType;
  error: ValidationError;
};

export type RefreshTokenInputType = {
  refreshToken: Scalars['String'];
};

/** Autogenerated return type of RefreshToken */
export type RefreshTokenPayload = {
  __typename: 'RefreshTokenPayload';
  accessToken?: Maybe<Scalars['String']>;
  errors: Array<RefreshTokenErrorType>;
  refreshToken?: Maybe<Scalars['String']>;
};

export enum RegisterDeviceErrorArgumentType {
  Reference = 'reference',
  Platform = 'platform'
}

export type RegisterDeviceErrorType = {
  __typename: 'RegisterDeviceErrorType';
  argument: RegisterDeviceErrorArgumentType;
  error: ValidationError;
};

export type RegisterDeviceInputType = {
  reference: Scalars['String'];
  platform: Scalars['String'];
};

/** Autogenerated return type of RegisterDevice */
export type RegisterDevicePayload = {
  __typename: 'RegisterDevicePayload';
  device: Device;
  errors: Array<RegisterDeviceErrorType>;
};

export enum RejectAgreementErrorArgumentType {
  AgreementId = 'agreementId'
}

export type RejectAgreementErrorType = {
  __typename: 'RejectAgreementErrorType';
  argument: RejectAgreementErrorArgumentType;
  error: ValidationError;
};

export type RejectAgreementInputType = {
  agreementId: Scalars['ID'];
};

/** Autogenerated return type of RejectAgreement */
export type RejectAgreementPayload = {
  __typename: 'RejectAgreementPayload';
  agreement?: Maybe<Agreement>;
  errors: Array<RejectAgreementErrorType>;
};

export enum RejectExpenseErrorArgumentType {
  ExpenseId = 'expenseId'
}

export type RejectExpenseErrorType = {
  __typename: 'RejectExpenseErrorType';
  argument: RejectExpenseErrorArgumentType;
  error: ValidationError;
};

export type RejectExpenseInputType = {
  expenseId: Scalars['ID'];
};

/** Autogenerated return type of RejectExpense */
export type RejectExpensePayload = {
  __typename: 'RejectExpensePayload';
  errors: Array<RejectExpenseErrorType>;
  expense?: Maybe<Expense>;
};

export enum RejectMaterialNoteErrorArgumentType {
  MaterialNoteId = 'materialNoteId'
}

export type RejectMaterialNoteErrorType = {
  __typename: 'RejectMaterialNoteErrorType';
  argument: RejectMaterialNoteErrorArgumentType;
  error: ValidationError;
};

export type RejectMaterialNoteInputType = {
  materialNoteId: Scalars['ID'];
};

/** Autogenerated return type of RejectMaterialNote */
export type RejectMaterialNotePayload = {
  __typename: 'RejectMaterialNotePayload';
  errors: Array<RejectMaterialNoteErrorType>;
  materialNote?: Maybe<MaterialNote>;
};

export enum RejectTimesheetErrorArgumentType {
  TimesheetId = 'timesheetId'
}

export type RejectTimesheetErrorType = {
  __typename: 'RejectTimesheetErrorType';
  argument: RejectTimesheetErrorArgumentType;
  error: ValidationError;
};

export type RejectTimesheetInputType = {
  timesheetId: Scalars['ID'];
};

/** Autogenerated return type of RejectTimesheet */
export type RejectTimesheetPayload = {
  __typename: 'RejectTimesheetPayload';
  errors: Array<RejectTimesheetErrorType>;
  timesheet?: Maybe<Timesheet>;
};

/** A message in a chat which is a reply to another event */
export type ReplyMessage = Event & {
  __typename: 'ReplyMessage';
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdByCurrentUser: Scalars['Boolean'];
  event: Event;
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  policy?: Maybe<EventPolicy>;
  projectId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

export enum ResetNotificationsErrorArgumentType {
  NotificationCount = 'notificationCount'
}

export type ResetNotificationsErrorType = {
  __typename: 'ResetNotificationsErrorType';
  argument: ResetNotificationsErrorArgumentType;
  error: ValidationError;
};

export type ResetNotificationsInputType = {
  notificationCount?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of ResetNotifications */
export type ResetNotificationsPayload = {
  __typename: 'ResetNotificationsPayload';
  errors: Array<ResetNotificationsErrorType>;
  notificationCount: Scalars['Int'];
};

export enum SendDasContactErrorArgumentType {
  Message = 'message'
}

export type SendDasContactErrorType = {
  __typename: 'SendDasContactErrorType';
  argument: SendDasContactErrorArgumentType;
  error: ValidationError;
};

export type SendDasContactInputType = {
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SendDasContact */
export type SendDasContactPayload = {
  __typename: 'SendDasContactPayload';
  errors: Array<SendDasContactErrorType>;
  message: Scalars['String'];
};

export enum SendFeedbackErrorArgumentType {
  Message = 'message'
}

export type SendFeedbackErrorType = {
  __typename: 'SendFeedbackErrorType';
  argument: SendFeedbackErrorArgumentType;
  error: ValidationError;
};

export type SendFeedbackInputType = {
  message?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SendFeedback */
export type SendFeedbackPayload = {
  __typename: 'SendFeedbackPayload';
  errors: Array<SendFeedbackErrorType>;
  message: Scalars['String'];
};

export enum SetPresenceErrorArgumentType {
  ChatId = 'chatId'
}

export type SetPresenceErrorType = {
  __typename: 'SetPresenceErrorType';
  argument: SetPresenceErrorArgumentType;
  error: ValidationError;
};

export type SetPresenceInputType = {
  chatId: Scalars['ID'];
};

/** Autogenerated return type of SetPresence */
export type SetPresencePayload = {
  __typename: 'SetPresencePayload';
  chat: Chat;
  errors: Array<SetPresenceErrorType>;
  project: Project;
};

export enum SetTypingErrorArgumentType {
  ChatId = 'chatId'
}

export type SetTypingErrorType = {
  __typename: 'SetTypingErrorType';
  argument: SetTypingErrorArgumentType;
  error: ValidationError;
};

export type SetTypingInputType = {
  chatId: Scalars['ID'];
};

/** Autogenerated return type of SetTyping */
export type SetTypingPayload = {
  __typename: 'SetTypingPayload';
  errors: Array<SetTypingErrorType>;
  participant: Participant;
};

export enum ShareInvitationErrorArgumentType {
  InvitationId = 'invitationId'
}

export type ShareInvitationErrorType = {
  __typename: 'ShareInvitationErrorType';
  argument: ShareInvitationErrorArgumentType;
  error: ValidationError;
};

export type ShareInvitationInputType = {
  invitationId: Scalars['ID'];
};

/** Autogenerated return type of ShareInvitation */
export type ShareInvitationPayload = {
  __typename: 'ShareInvitationPayload';
  errors: Array<ShareInvitationErrorType>;
  invitation?: Maybe<Invitation>;
};

export enum SubmitExpenseErrorArgumentType {
  ExpenseId = 'expenseId'
}

export type SubmitExpenseErrorType = {
  __typename: 'SubmitExpenseErrorType';
  argument: SubmitExpenseErrorArgumentType;
  error: ValidationError;
};

export type SubmitExpenseInputType = {
  expenseId: Scalars['ID'];
};

/** Autogenerated return type of SubmitExpense */
export type SubmitExpensePayload = {
  __typename: 'SubmitExpensePayload';
  errors: Array<SubmitExpenseErrorType>;
  expense?: Maybe<Expense>;
};

export enum SubmitMaterialNoteErrorArgumentType {
  MaterialNoteId = 'materialNoteId'
}

export type SubmitMaterialNoteErrorType = {
  __typename: 'SubmitMaterialNoteErrorType';
  argument: SubmitMaterialNoteErrorArgumentType;
  error: ValidationError;
};

export type SubmitMaterialNoteInputType = {
  materialNoteId: Scalars['ID'];
};

/** Autogenerated return type of SubmitMaterialNote */
export type SubmitMaterialNotePayload = {
  __typename: 'SubmitMaterialNotePayload';
  errors: Array<SubmitMaterialNoteErrorType>;
  materialNote?: Maybe<MaterialNote>;
};

export enum SubmitTimesheetErrorArgumentType {
  TimesheetId = 'timesheetId'
}

export type SubmitTimesheetErrorType = {
  __typename: 'SubmitTimesheetErrorType';
  argument: SubmitTimesheetErrorArgumentType;
  error: ValidationError;
};

export type SubmitTimesheetInputType = {
  timesheetId: Scalars['ID'];
};

/** Autogenerated return type of SubmitTimesheet */
export type SubmitTimesheetPayload = {
  __typename: 'SubmitTimesheetPayload';
  errors: Array<SubmitTimesheetErrorType>;
  timesheet?: Maybe<Timesheet>;
};

/** A message posted by the system about an event in a chat */
export type SystemMessage = Event & {
  __typename: 'SystemMessage';
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdByCurrentUser: Scalars['Boolean'];
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  policy?: Maybe<EventPolicy>;
  projectId: Scalars['ID'];
  referenceId: Scalars['ID'];
  referenceType: Scalars['String'];
  referenceValue?: Maybe<Scalars['String']>;
  systemMessageAction: SystemMessageAction;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** System message action enumeration */
export enum SystemMessageAction {
  GeneralChatCreated = 'general_chat_created',
  GroupChatCreated = 'group_chat_created',
  PrivateChatCreated = 'private_chat_created',
  Unaccepted = 'unaccepted',
  Accepted = 'accepted',
  ParticipantCreated = 'participant_created',
  ParticipantDeactivated = 'participant_deactivated',
  ParticipantLeft = 'participant_left',
  NameChanged = 'name_changed',
  DescriptionChanged = 'description_changed',
  Archived = 'archived',
  Unarchived = 'unarchived'
}

/** A user can be a team member of multiple companies */
export type TeamMember = {
  __typename: 'TeamMember';
  avatarFull?: Maybe<Scalars['String']>;
  avatarThumbnail?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  enableExpenses?: Maybe<Scalars['Boolean']>;
  enableMaterialNotes?: Maybe<Scalars['Boolean']>;
  enableTimesheets?: Maybe<Scalars['Boolean']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  policy: TeamMemberPolicy;
  role: MemberRole;
  roleLabel?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type TeamMemberPolicy = {
  __typename: 'TeamMemberPolicy';
  delete: Scalars['Boolean'];
};

/** A tender is created by a consumer for matchmaking with a contactor company */
export type Tender = {
  __typename: 'Tender';
  budgetMax?: Maybe<Scalars['Int']>;
  budgetMin?: Maybe<Scalars['Int']>;
  constructionYear?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  policy?: Maybe<TenderPolicy>;
  serviceTags?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  urgency: TenderUrgency;
  user?: Maybe<User>;
  zipCode?: Maybe<Scalars['String']>;
};

export type TenderPolicy = {
  __typename: 'TenderPolicy';
  update: Scalars['Boolean'];
};

export enum TenderUrgency {
  /** flexible */
  Flexible = 'flexible',
  /** as_soon_as_possible */
  AsSoonAsPossible = 'as_soon_as_possible',
  /** within_a_week */
  WithinAWeek = 'within_a_week',
  /** within_a_month */
  WithinAMonth = 'within_a_month',
  /** within_a_few_months */
  WithinAFewMonths = 'within_a_few_months',
  /** within_half_a_year */
  WithinHalfAYear = 'within_half_a_year'
}

/** A timesheet submitted by a project member for approval by a project admin */
export type Timesheet = {
  __typename: 'Timesheet';
  approvedAt?: Maybe<Scalars['DateTime']>;
  approvedByMember?: Maybe<Member>;
  approvedByMemberId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated User createdByMember.current 15/12 */
  createdByCurrentMember: Scalars['Boolean'];
  createdByMember: Member;
  createdByMemberId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  friday: Scalars['Date'];
  fridayHours?: Maybe<Scalars['Float']>;
  fridayNotes?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  monday: Scalars['Date'];
  mondayHours?: Maybe<Scalars['Float']>;
  mondayNotes?: Maybe<Scalars['String']>;
  policy?: Maybe<TimesheetPolicy>;
  project: Project;
  projectId: Scalars['ID'];
  saturday: Scalars['Date'];
  saturdayHours?: Maybe<Scalars['Float']>;
  saturdayNotes?: Maybe<Scalars['String']>;
  status?: Maybe<TimesheetStatus>;
  submittedAt?: Maybe<Scalars['DateTime']>;
  sunday: Scalars['Date'];
  sundayHours?: Maybe<Scalars['Float']>;
  sundayNotes?: Maybe<Scalars['String']>;
  thursday: Scalars['Date'];
  thursdayHours?: Maybe<Scalars['Float']>;
  thursdayNotes?: Maybe<Scalars['String']>;
  totalHours?: Maybe<Scalars['Int']>;
  tuesday: Scalars['Date'];
  tuesdayHours?: Maybe<Scalars['Float']>;
  tuesdayNotes?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wednesday: Scalars['Date'];
  wednesdayHours?: Maybe<Scalars['Float']>;
  wednesdayNotes?: Maybe<Scalars['String']>;
  week: Scalars['Int'];
  year: Scalars['Int'];
};

/** A message in a chat where a timesheet was posted */
export type TimesheetMessage = Event & {
  __typename: 'TimesheetMessage';
  chat: Chat;
  chatId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdByCurrentUser: Scalars['Boolean'];
  id: Scalars['ID'];
  payload?: Maybe<Scalars['String']>;
  policy?: Maybe<EventPolicy>;
  projectId: Scalars['ID'];
  timesheet: Timesheet;
  timesheetMessageAction: TimesheetMessageAction;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['ID'];
};

/** Timesheet message action enumeration */
export enum TimesheetMessageAction {
  Approved = 'approved',
  Rejected = 'rejected'
}

export type TimesheetPolicy = {
  __typename: 'TimesheetPolicy';
  approve: Scalars['Boolean'];
  reject: Scalars['Boolean'];
  submit: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export enum TimesheetStatus {
  /** open */
  Open = 'open',
  /** submitted */
  Submitted = 'submitted',
  /** approved */
  Approved = 'approved',
  /** rejected */
  Rejected = 'rejected'
}

/** A todo in the project */
export type Todo = {
  __typename: 'Todo';
  assignedMemberIds?: Maybe<Array<Scalars['ID']>>;
  assignedMembers?: Maybe<Array<Member>>;
  completedAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByMember: Member;
  createdByMemberId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  media: Array<Media>;
  name: Scalars['String'];
  policy?: Maybe<TodoPolicy>;
  position: Scalars['Int'];
  project: Project;
  projectId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TodoPolicy = {
  __typename: 'TodoPolicy';
  create: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export enum UnarchiveGroupChatErrorArgumentType {
  GroupChatId = 'groupChatId'
}

export type UnarchiveGroupChatErrorType = {
  __typename: 'UnarchiveGroupChatErrorType';
  argument: UnarchiveGroupChatErrorArgumentType;
  error: ValidationError;
};

export type UnarchiveGroupChatInputType = {
  groupChatId: Scalars['ID'];
};

/** Autogenerated return type of UnarchiveGroupChat */
export type UnarchiveGroupChatPayload = {
  __typename: 'UnarchiveGroupChatPayload';
  errors: Array<UnarchiveGroupChatErrorType>;
  groupChat?: Maybe<GroupChat>;
};

export enum UnarchiveProjectErrorArgumentType {
  ProjectId = 'projectId'
}

export type UnarchiveProjectErrorType = {
  __typename: 'UnarchiveProjectErrorType';
  argument: UnarchiveProjectErrorArgumentType;
  error: ValidationError;
};

export type UnarchiveProjectInputType = {
  projectId: Scalars['ID'];
};

/** Autogenerated return type of UnarchiveProject */
export type UnarchiveProjectPayload = {
  __typename: 'UnarchiveProjectPayload';
  errors: Array<UnarchiveProjectErrorType>;
  project?: Maybe<Project>;
};

export enum UnsubscribeEmailErrorArgumentType {
  Email = 'email'
}

export type UnsubscribeEmailErrorType = {
  __typename: 'UnsubscribeEmailErrorType';
  argument: UnsubscribeEmailErrorArgumentType;
  error: ValidationError;
};

export type UnsubscribeEmailInputType = {
  email: Scalars['String'];
};

/** Autogenerated return type of UnsubscribeEmail */
export type UnsubscribeEmailPayload = {
  __typename: 'UnsubscribeEmailPayload';
  errors: Array<UnsubscribeEmailErrorType>;
};

export enum UpdateActivityErrorArgumentType {
  ActivityId = 'activityId',
  AssignedMemberIds = 'assignedMemberIds',
  Name = 'name',
  Description = 'description',
  Color = 'color',
  Date = 'date',
  Duration = 'duration'
}

export type UpdateActivityErrorType = {
  __typename: 'UpdateActivityErrorType';
  argument: UpdateActivityErrorArgumentType;
  error: ValidationError;
};

export type UpdateActivityInputType = {
  activityId: Scalars['ID'];
  assignedMemberIds?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  color?: Maybe<ActivityColor>;
  date?: Maybe<Scalars['Date']>;
  duration?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateActivity */
export type UpdateActivityPayload = {
  __typename: 'UpdateActivityPayload';
  activity?: Maybe<Activity>;
  errors: Array<UpdateActivityErrorType>;
};

export enum UpdateAgreementErrorArgumentType {
  AgreementId = 'agreementId',
  Name = 'name',
  Description = 'description',
  Price = 'price',
  PriceType = 'priceType',
  VatPercentage = 'vatPercentage',
  Media = 'media',
  DeleteMedia = 'deleteMedia'
}

export type UpdateAgreementErrorType = {
  __typename: 'UpdateAgreementErrorType';
  argument: UpdateAgreementErrorArgumentType;
  error: ValidationError;
};

export type UpdateAgreementInputType = {
  agreementId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  priceType?: Maybe<AgreementPrice>;
  vatPercentage?: Maybe<Scalars['Float']>;
  media?: Maybe<Array<MediaInputTypeForUpdateAgreement>>;
  deleteMedia?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpdateAgreement */
export type UpdateAgreementPayload = {
  __typename: 'UpdateAgreementPayload';
  agreement?: Maybe<Agreement>;
  errors: Array<UpdateAgreementErrorType>;
};

export enum UpdateCompanyErrorArgumentType {
  Name = 'name',
  Email = 'email',
  Address = 'address',
  ZipCode = 'zipCode',
  CocNumber = 'cocNumber',
  VatNumber = 'vatNumber',
  Logo = 'logo',
  Phone = 'phone',
  ServiceTags = 'serviceTags',
  ServiceZipcode = 'serviceZipcode',
  ServiceRangeKm = 'serviceRangeKm',
  ServiceDescription = 'serviceDescription',
  ServicePriceMin = 'servicePriceMin',
  ServicePriceMax = 'servicePriceMax',
  ServiceMarketingBudgetMin = 'serviceMarketingBudgetMin',
  ServiceMarketingBudgetMax = 'serviceMarketingBudgetMax'
}

export type UpdateCompanyErrorType = {
  __typename: 'UpdateCompanyErrorType';
  argument: UpdateCompanyErrorArgumentType;
  error: ValidationError;
};

export type UpdateCompanyInputType = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  cocNumber?: Maybe<Scalars['String']>;
  vatNumber?: Maybe<Scalars['String']>;
  companyPlace?: Maybe<Scalars['String']>;
  bouwmaatAccountNumber?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['Upload']>;
  phone?: Maybe<Scalars['String']>;
  serviceTags?: Maybe<Array<Scalars['String']>>;
  serviceZipcode?: Maybe<Scalars['String']>;
  serviceRangeKm?: Maybe<Scalars['Int']>;
  serviceDescription?: Maybe<Scalars['String']>;
  servicePriceMin?: Maybe<Scalars['Int']>;
  servicePriceMax?: Maybe<Scalars['Int']>;
  serviceMarketingBudgetMin?: Maybe<Scalars['Int']>;
  serviceMarketingBudgetMax?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateCompany */
export type UpdateCompanyPayload = {
  __typename: 'UpdateCompanyPayload';
  company?: Maybe<Company>;
  errors: Array<UpdateCompanyErrorType>;
};

export enum UpdateExpenseErrorArgumentType {
  ExpenseId = 'expenseId',
  Description = 'description',
  Price = 'price',
  TotalPrice = 'totalPrice',
  Refund = 'refund',
  Media = 'media',
  DeleteMedia = 'deleteMedia'
}

export type UpdateExpenseErrorType = {
  __typename: 'UpdateExpenseErrorType';
  argument: UpdateExpenseErrorArgumentType;
  error: ValidationError;
};

export type UpdateExpenseInputType = {
  expenseId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  totalPrice?: Maybe<Scalars['Float']>;
  refund?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<MediaInputTypeForUpdateExpense>>;
  deleteMedia?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpdateExpense */
export type UpdateExpensePayload = {
  __typename: 'UpdateExpensePayload';
  errors: Array<UpdateExpenseErrorType>;
  expense?: Maybe<Expense>;
};

export enum UpdateFolderErrorArgumentType {
  MediaId = 'mediaId',
  Description = 'description',
  MemberIds = 'memberIds'
}

export type UpdateFolderErrorType = {
  __typename: 'UpdateFolderErrorType';
  argument: UpdateFolderErrorArgumentType;
  error: ValidationError;
};

export type UpdateFolderInputType = {
  mediaId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  memberIds?: Maybe<Array<Scalars['String']>>;
};

/** Autogenerated return type of UpdateFolder */
export type UpdateFolderPayload = {
  __typename: 'UpdateFolderPayload';
  errors: Array<UpdateFolderErrorType>;
  folder?: Maybe<Folder>;
};

export enum UpdateGroupChatErrorArgumentType {
  GroupChatId = 'groupChatId',
  Name = 'name',
  Description = 'description',
  Image = 'image'
}

export type UpdateGroupChatErrorType = {
  __typename: 'UpdateGroupChatErrorType';
  argument: UpdateGroupChatErrorArgumentType;
  error: ValidationError;
};

export type UpdateGroupChatInputType = {
  groupChatId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
};

/** Autogenerated return type of UpdateGroupChat */
export type UpdateGroupChatPayload = {
  __typename: 'UpdateGroupChatPayload';
  errors: Array<UpdateGroupChatErrorType>;
  groupChat?: Maybe<GroupChat>;
};

export enum UpdateMaterialNoteItemErrorArgumentType {
  MaterialNoteItemId = 'materialNoteItemId',
  Name = 'name',
  Quantity = 'quantity'
}

export type UpdateMaterialNoteItemErrorType = {
  __typename: 'UpdateMaterialNoteItemErrorType';
  argument: UpdateMaterialNoteItemErrorArgumentType;
  error: ValidationError;
};

export type UpdateMaterialNoteItemInputType = {
  materialNoteItemId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateMaterialNoteItem */
export type UpdateMaterialNoteItemPayload = {
  __typename: 'UpdateMaterialNoteItemPayload';
  errors: Array<UpdateMaterialNoteItemErrorType>;
  materialNoteItem?: Maybe<MaterialNoteItem>;
};

export enum UpdateMemberErrorArgumentType {
  MemberId = 'memberId',
  Role = 'role',
  RoleLabel = 'roleLabel',
  EnableTimesheets = 'enableTimesheets',
  EnableExpenses = 'enableExpenses',
  EnableMaterialNotes = 'enableMaterialNotes'
}

export type UpdateMemberErrorType = {
  __typename: 'UpdateMemberErrorType';
  argument: UpdateMemberErrorArgumentType;
  error: ValidationError;
};

export type UpdateMemberInputType = {
  memberId: Scalars['ID'];
  role?: Maybe<MemberRole>;
  roleLabel?: Maybe<Scalars['String']>;
  enableTimesheets?: Maybe<Scalars['Boolean']>;
  enableExpenses?: Maybe<Scalars['Boolean']>;
  enableMaterialNotes?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateMember */
export type UpdateMemberPayload = {
  __typename: 'UpdateMemberPayload';
  errors: Array<UpdateMemberErrorType>;
  member?: Maybe<Member>;
};

export enum UpdatePasswordErrorArgumentType {
  CurrentPassword = 'currentPassword',
  NewPassword = 'newPassword'
}

export type UpdatePasswordErrorType = {
  __typename: 'UpdatePasswordErrorType';
  argument: UpdatePasswordErrorArgumentType;
  error: ValidationError;
};

export type UpdatePasswordInputType = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

/** Autogenerated return type of UpdatePassword */
export type UpdatePasswordPayload = {
  __typename: 'UpdatePasswordPayload';
  errors: Array<UpdatePasswordErrorType>;
  user?: Maybe<User>;
};

export enum UpdateProjectErrorArgumentType {
  ProjectId = 'projectId',
  Name = 'name',
  Description = 'description',
  Reference = 'reference',
  Address = 'address',
  Image = 'image'
}

export type UpdateProjectErrorType = {
  __typename: 'UpdateProjectErrorType';
  argument: UpdateProjectErrorArgumentType;
  error: ValidationError;
};

export type UpdateProjectInputType = {
  projectId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['Upload']>;
};

/** Autogenerated return type of UpdateProject */
export type UpdateProjectPayload = {
  __typename: 'UpdateProjectPayload';
  errors: Array<UpdateProjectErrorType>;
  project?: Maybe<Project>;
};

export enum UpdateTenderErrorArgumentType {
  TenderId = 'tenderId',
  Name = 'name',
  Description = 'description',
  ZipCode = 'zipCode',
  ConstructionYear = 'constructionYear',
  BudgetMin = 'budgetMin',
  BudgetMax = 'budgetMax',
  Duration = 'duration',
  ServiceTags = 'serviceTags',
  Urgency = 'urgency'
}

export type UpdateTenderErrorType = {
  __typename: 'UpdateTenderErrorType';
  argument: UpdateTenderErrorArgumentType;
  error: ValidationError;
};

export type UpdateTenderInputType = {
  tenderId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  constructionYear?: Maybe<Scalars['Int']>;
  budgetMin?: Maybe<Scalars['Int']>;
  budgetMax?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  serviceTags?: Maybe<Array<Scalars['String']>>;
  urgency?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateTender */
export type UpdateTenderPayload = {
  __typename: 'UpdateTenderPayload';
  errors: Array<UpdateTenderErrorType>;
  tender?: Maybe<Tender>;
};

export enum UpdateTimesheetErrorArgumentType {
  ProjectId = 'projectId',
  Monday = 'monday',
  MondayHours = 'mondayHours',
  TuesdayHours = 'tuesdayHours',
  WednesdayHours = 'wednesdayHours',
  ThursdayHours = 'thursdayHours',
  FridayHours = 'fridayHours',
  SaturdayHours = 'saturdayHours',
  SundayHours = 'sundayHours',
  Description = 'description',
  MondayNotes = 'mondayNotes',
  TuesdayNotes = 'tuesdayNotes',
  WednesdayNotes = 'wednesdayNotes',
  ThursdayNotes = 'thursdayNotes',
  FridayNotes = 'fridayNotes',
  SaturdayNotes = 'saturdayNotes',
  SundayNotes = 'sundayNotes'
}

export type UpdateTimesheetErrorType = {
  __typename: 'UpdateTimesheetErrorType';
  argument: UpdateTimesheetErrorArgumentType;
  error: ValidationError;
};

export type UpdateTimesheetInputType = {
  projectId: Scalars['ID'];
  monday: Scalars['Date'];
  mondayHours?: Maybe<Scalars['Float']>;
  tuesdayHours?: Maybe<Scalars['Float']>;
  wednesdayHours?: Maybe<Scalars['Float']>;
  thursdayHours?: Maybe<Scalars['Float']>;
  fridayHours?: Maybe<Scalars['Float']>;
  saturdayHours?: Maybe<Scalars['Float']>;
  sundayHours?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  mondayNotes?: Maybe<Scalars['String']>;
  tuesdayNotes?: Maybe<Scalars['String']>;
  wednesdayNotes?: Maybe<Scalars['String']>;
  thursdayNotes?: Maybe<Scalars['String']>;
  fridayNotes?: Maybe<Scalars['String']>;
  saturdayNotes?: Maybe<Scalars['String']>;
  sundayNotes?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateTimesheet */
export type UpdateTimesheetPayload = {
  __typename: 'UpdateTimesheetPayload';
  errors: Array<UpdateTimesheetErrorType>;
  timesheet?: Maybe<Timesheet>;
};

export enum UpdateTodoErrorArgumentType {
  TodoId = 'todoId',
  AssignedMemberIds = 'assignedMemberIds',
  Name = 'name',
  Description = 'description',
  Complete = 'complete',
  Media = 'media',
  DeleteMedia = 'deleteMedia'
}

export type UpdateTodoErrorType = {
  __typename: 'UpdateTodoErrorType';
  argument: UpdateTodoErrorArgumentType;
  error: ValidationError;
};

export type UpdateTodoInputType = {
  todoId: Scalars['ID'];
  assignedMemberIds?: Maybe<Array<Scalars['ID']>>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  complete?: Maybe<Scalars['Boolean']>;
  media?: Maybe<Array<MediaInputTypeForUpdateTodo>>;
  deleteMedia?: Maybe<Array<Scalars['ID']>>;
};

/** Autogenerated return type of UpdateTodo */
export type UpdateTodoPayload = {
  __typename: 'UpdateTodoPayload';
  errors: Array<UpdateTodoErrorType>;
  todo?: Maybe<Todo>;
};

export enum UpdateUserErrorArgumentType {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Phone = 'phone',
  Avatar = 'avatar',
  SendTransactionalEmail = 'sendTransactionalEmail',
  SendNewsletterEmail = 'sendNewsletterEmail'
}

export type UpdateUserErrorType = {
  __typename: 'UpdateUserErrorType';
  argument: UpdateUserErrorArgumentType;
  error: ValidationError;
};

export type UpdateUserInputType = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
  sendTransactionalEmail?: Maybe<Scalars['Boolean']>;
  sendNewsletterEmail?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename: 'UpdateUserPayload';
  errors: Array<UpdateUserErrorType>;
  user?: Maybe<User>;
};


/** A user who can be a member of multiple projects */
export type User = {
  __typename: 'User';
  avatarFull?: Maybe<Scalars['String']>;
  avatarThumbnail?: Maybe<Scalars['String']>;
  bouwmaat: Scalars['Boolean'];
  bouwmaatOption?: Scalars['Boolean'];
  bouwmaatSubscriptionCompleted?: Scalars['Boolean'];
  bouwmaatAccountNumber?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  createdAt: Scalars['DateTime'];
  current: Scalars['Boolean'];
  devices: Array<Device>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  policy?: Maybe<UserPolicy>;
  /** @deprecated Use company */
  professional: Scalars['Boolean'];
  projects?: Maybe<Array<Project>>;
  sendNewsletterEmail: Scalars['Boolean'];
  sendTransactionalEmail: Scalars['Boolean'];
  subscribedToPlanAt?: Maybe<Scalars['DateTime']>;
  subscribedToTrialAt?: Maybe<Scalars['DateTime']>;
  subscriptionPeriod?: Maybe<Scalars['String']>;
  subscriptionType?: Maybe<Scalars['String']>;
  tenders: Array<Tender>;
  trialSubscriptionEndsAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export type UserPolicy = {
  __typename: 'UserPolicy';
  addTeam: Scalars['Boolean'];
  createAddressValuation: Scalars['Boolean'];
  createDemoProject: Scalars['Boolean'];
  createProject: Scalars['Boolean'];
  createProjectLimitReached: Scalars['Boolean'];
  createTender: Scalars['Boolean'];
  das: Scalars['Boolean'];
  dashboard: Scalars['Boolean'];
  nudgePro: Scalars['Boolean'];
  onboarding: Scalars['Boolean'];
  onboardingActivity: Scalars['Boolean'];
  onboardingInviteMember: Scalars['Boolean'];
  setupCompanyService: Scalars['Boolean'];
  trial: Scalars['Boolean'];
  trialExpired: Scalars['Boolean'];
  tutorialSlider: Scalars['Boolean'];
  webAccess: Scalars['Boolean'];
};

/** Validation errors that may occur */
export enum ValidationError {
  Accepted = 'accepted',
  Blank = 'blank',
  Confirmation = 'confirmation',
  Empty = 'empty',
  EqualTo = 'equalTo',
  Even = 'even',
  Exclusion = 'exclusion',
  GreaterThan = 'greaterThan',
  GreaterThanOrEqualTo = 'greaterThanOrEqualTo',
  Inclusion = 'inclusion',
  Invalid = 'invalid',
  LessThan = 'lessThan',
  LessThanOrEqualTo = 'lessThanOrEqualTo',
  ModelInvalid = 'modelInvalid',
  NotANumber = 'notANumber',
  NotAnInteger = 'notAnInteger',
  Odd = 'odd',
  OtherThan = 'otherThan',
  Present = 'present',
  Required = 'required',
  Taken = 'taken',
  TooLong = 'tooLong',
  TooShort = 'tooShort',
  WrongLength = 'wrongLength',
  NotFound = 'notFound',
  Incorrect = 'incorrect',
  NotSameChat = 'notSameChat'
}

/** An video that was posted to a chat */
export type Video = Media & {
  __typename: 'Video';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  downloadFile?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  members: Array<Member>;
  metadata?: Maybe<MediaMetadata>;
  ownerId: Scalars['String'];
  ownerType: Scalars['String'];
  path: Scalars['String'];
  policy?: Maybe<MediaPolicy>;
  preview?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  /** @deprecated use tile or preview */
  thumbnail?: Maybe<Scalars['String']>;
  /** @deprecated use tile or preview */
  thumbnailHeight?: Maybe<Scalars['Int']>;
  /** @deprecated use tile or preview */
  thumbnailWidth?: Maybe<Scalars['Int']>;
  tile?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: User;
};

export type ActivityFieldsFragment = (
  { __typename: 'Activity' }
  & Pick<Activity, 'id' | 'name' | 'description' | 'color' | 'date' | 'duration' | 'endDate' | 'projectId' | 'active' | 'position' | 'createdAt' | 'updatedAt' | 'archivedAt'>
  & {
    policy?: Maybe<(
      { __typename: 'ActivityPolicy' }
      & Pick<ActivityPolicy, 'update' | 'archive' | 'pdfActivities'>
    )>
  }
);

export type AgreementFieldsFragment = (
  { __typename: 'Agreement' }
  & Pick<Agreement, 'id' | 'number' | 'name' | 'description' | 'status' | 'projectId' | 'priceType' | 'price' | 'vatPercentage' | 'vatAmount' | 'totalPrice' | 'signedTotalPrice' | 'createdAt' | 'updatedAt' | 'closedAt'>
  & {
    policy?: Maybe<(
      { __typename: 'AgreementPolicy' }
      & Pick<AgreementPolicy, 'update' | 'accept' | 'reject' | 'delete'>
    )>
  }
);

type ChatFields_GroupChat_Fragment = (
  { __typename: 'GroupChat' }
  & Pick<GroupChat, 'description' | 'id' | 'name' | 'imageThumbnail' | 'active' | 'general' | 'createdAt' | 'projectId' | 'latestActivityAt' | 'notificationCount' | 'deactivatedAt' | 'archivedAt'>
  & {
    policy?: Maybe<(
      { __typename: 'GroupChatPolicy' }
      & Pick<GroupChatPolicy, 'update' | 'archive' | 'unarchive' | 'createParticipants' | 'leave' | 'createMessage' | 'createAgreement'>
    )>
  }
);

type ChatFields_PrivateChat_Fragment = (
  { __typename: 'PrivateChat' }
  & Pick<PrivateChat, 'otherMemberId' | 'otherMemberEmail' | 'id' | 'name' | 'imageThumbnail' | 'active' | 'general' | 'createdAt' | 'projectId' | 'latestActivityAt' | 'notificationCount' | 'deactivatedAt' | 'archivedAt'>
  & {
    policy?: Maybe<(
      { __typename: 'PrivateChatPolicy' }
      & Pick<PrivateChatPolicy, 'createMessage' | 'createAgreement'>
    )>
  }
);

export type ChatFieldsFragment = ChatFields_GroupChat_Fragment | ChatFields_PrivateChat_Fragment;

export type CompanyFieldsFragment = (
  { __typename: 'Company' }
  & Pick<Company, 'id' | 'name' | 'email' | 'address' | 'zipCode' | 'cocNumber' | 'vatNumber' | 'companyPlace' | 'bouwmaatAccountNumber' | 'createdAt' | 'updatedAt' | 'logoThumbnail' | 'logoFull' | 'serviceZipcode' | 'serviceRangeKm' | 'serviceDescription' | 'serviceTags' | 'servicePriceMax' | 'servicePriceMin' | 'phone'>
  & {
    policy: (
      { __typename: 'CompanyPolicy' }
      & Pick<CompanyPolicy, 'update' | 'createTeamMember'>
    )
  }
);

type EventFields_MediaMessage_Fragment = (
  { __typename: 'MediaMessage' }
  & Pick<MediaMessage, 'id' | 'payload' | 'createdByCurrentUser' | 'createdAt' | 'updatedAt' | 'userId' | 'chatId' | 'projectId'>
  & {
    media: (
      { __typename: 'Folder' }
      & MediaFields_Folder_Fragment
    ) | (
      { __typename: 'Document' }
      & MediaFields_Document_Fragment
    ) | (
      { __typename: 'Image' }
      & MediaFields_Image_Fragment
    ) | (
      { __typename: 'Video' }
      & MediaFields_Video_Fragment
    ), policy?: Maybe<(
      { __typename: 'EventPolicy' }
      & Pick<EventPolicy, 'delete'>
    )>
  }
);

type EventFields_Message_Fragment = (
  { __typename: 'Message' }
  & Pick<Message, 'id' | 'payload' | 'createdByCurrentUser' | 'createdAt' | 'updatedAt' | 'userId' | 'chatId' | 'projectId'>
  & {
    policy?: Maybe<(
      { __typename: 'EventPolicy' }
      & Pick<EventPolicy, 'delete'>
    )>
  }
);

type EventFields_ReplyMessage_Fragment = (
  { __typename: 'ReplyMessage' }
  & Pick<ReplyMessage, 'id' | 'payload' | 'createdByCurrentUser' | 'createdAt' | 'updatedAt' | 'userId' | 'chatId' | 'projectId'>
  & {
    event: (
      { __typename: 'MediaMessage' }
      & Pick<MediaMessage, 'id' | 'payload'>
      & {
        media: (
          { __typename: 'Folder' }
          & MediaFields_Folder_Fragment
        ) | (
          { __typename: 'Document' }
          & MediaFields_Document_Fragment
        ) | (
          { __typename: 'Image' }
          & MediaFields_Image_Fragment
        ) | (
          { __typename: 'Video' }
          & MediaFields_Video_Fragment
        ), user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
    ) | (
      { __typename: 'Message' }
      & Pick<Message, 'id' | 'payload'>
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
    ) | (
      { __typename: 'ReplyMessage' }
      & Pick<ReplyMessage, 'id' | 'payload'>
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
    ) | (
      { __typename: 'ActivityMessage' }
      & Pick<ActivityMessage, 'id' | 'payload'>
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
    ) | (
      { __typename: 'AgreementMessage' }
      & Pick<AgreementMessage, 'id' | 'payload'>
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
    ) | (
      { __typename: 'DeletedEvent' }
      & Pick<DeletedEvent, 'id' | 'payload'>
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
    ) | (
      { __typename: 'ExpenseMessage' }
      & Pick<ExpenseMessage, 'id' | 'payload'>
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
    ) | (
      { __typename: 'MaterialNoteMessage' }
      & Pick<MaterialNoteMessage, 'id' | 'payload'>
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
    ) | (
      { __typename: 'SystemMessage' }
      & Pick<SystemMessage, 'id' | 'payload'>
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
    ) | (
      { __typename: 'TimesheetMessage' }
      & Pick<TimesheetMessage, 'id' | 'payload'>
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
    ), policy?: Maybe<(
      { __typename: 'EventPolicy' }
      & Pick<EventPolicy, 'delete'>
    )>
  }
);

type EventFields_ActivityMessage_Fragment = (
  { __typename: 'ActivityMessage' }
  & Pick<ActivityMessage, 'activityMessageAction' | 'id' | 'payload' | 'createdByCurrentUser' | 'createdAt' | 'updatedAt' | 'userId' | 'chatId' | 'projectId'>
  & {
    activity: (
      { __typename: 'Activity' }
      & ActivityFieldsFragment
    ), policy?: Maybe<(
      { __typename: 'EventPolicy' }
      & Pick<EventPolicy, 'delete'>
    )>
  }
);

type EventFields_AgreementMessage_Fragment = (
  { __typename: 'AgreementMessage' }
  & Pick<AgreementMessage, 'agreementMessageAction' | 'id' | 'payload' | 'createdByCurrentUser' | 'createdAt' | 'updatedAt' | 'userId' | 'chatId' | 'projectId'>
  & {
    agreement: (
      { __typename: 'Agreement' }
      & {
        createdByMember: (
          { __typename: 'Member' }
          & MemberFieldsFragment
        ), counterpartyMember: (
          { __typename: 'Member' }
          & MemberFieldsFragment
        )
      }
      & AgreementFieldsFragment
    ), policy?: Maybe<(
      { __typename: 'EventPolicy' }
      & Pick<EventPolicy, 'delete'>
    )>
  }
);

type EventFields_DeletedEvent_Fragment = (
  { __typename: 'DeletedEvent' }
  & Pick<DeletedEvent, 'deletedEventType' | 'id' | 'payload' | 'createdByCurrentUser' | 'createdAt' | 'updatedAt' | 'userId' | 'chatId' | 'projectId'>
  & {
    policy?: Maybe<(
      { __typename: 'EventPolicy' }
      & Pick<EventPolicy, 'delete'>
    )>
  }
);

type EventFields_ExpenseMessage_Fragment = (
  { __typename: 'ExpenseMessage' }
  & Pick<ExpenseMessage, 'expenseMessageAction' | 'id' | 'payload' | 'createdByCurrentUser' | 'createdAt' | 'updatedAt' | 'userId' | 'chatId' | 'projectId'>
  & {
    expense: (
      { __typename: 'Expense' }
      & ExpenseFieldsFragment
    ), policy?: Maybe<(
      { __typename: 'EventPolicy' }
      & Pick<EventPolicy, 'delete'>
    )>
  }
);

type EventFields_MaterialNoteMessage_Fragment = (
  { __typename: 'MaterialNoteMessage' }
  & Pick<MaterialNoteMessage, 'materialNoteMessageAction' | 'id' | 'payload' | 'createdByCurrentUser' | 'createdAt' | 'updatedAt' | 'userId' | 'chatId' | 'projectId'>
  & {
    materialNote: (
      { __typename: 'MaterialNote' }
      & MaterialNoteFieldsFragment
    ), policy?: Maybe<(
      { __typename: 'EventPolicy' }
      & Pick<EventPolicy, 'delete'>
    )>
  }
);

type EventFields_SystemMessage_Fragment = (
  { __typename: 'SystemMessage' }
  & Pick<SystemMessage, 'systemMessageAction' | 'referenceValue' | 'id' | 'payload' | 'createdByCurrentUser' | 'createdAt' | 'updatedAt' | 'userId' | 'chatId' | 'projectId'>
  & {
    policy?: Maybe<(
      { __typename: 'EventPolicy' }
      & Pick<EventPolicy, 'delete'>
    )>
  }
);

type EventFields_TimesheetMessage_Fragment = (
  { __typename: 'TimesheetMessage' }
  & Pick<TimesheetMessage, 'timesheetMessageAction' | 'id' | 'payload' | 'createdByCurrentUser' | 'createdAt' | 'updatedAt' | 'userId' | 'chatId' | 'projectId'>
  & {
    timesheet: (
      { __typename: 'Timesheet' }
      & TimesheetFieldsFragment
    ), policy?: Maybe<(
      { __typename: 'EventPolicy' }
      & Pick<EventPolicy, 'delete'>
    )>
  }
);

export type EventFieldsFragment = EventFields_MediaMessage_Fragment | EventFields_Message_Fragment | EventFields_ReplyMessage_Fragment | EventFields_ActivityMessage_Fragment | EventFields_AgreementMessage_Fragment | EventFields_DeletedEvent_Fragment | EventFields_ExpenseMessage_Fragment | EventFields_MaterialNoteMessage_Fragment | EventFields_SystemMessage_Fragment | EventFields_TimesheetMessage_Fragment;

export type ExpenseFieldsFragment = (
  { __typename: 'Expense' }
  & Pick<Expense, 'id' | 'description' | 'status' | 'createdAt' | 'updatedAt' | 'submittedAt' | 'approvedAt' | 'price' | 'vatAmount' | 'totalPrice' | 'refund' | 'projectId' | 'createdByMemberId' | 'approvedByMemberId'>
  & {
    policy?: Maybe<(
      { __typename: 'ExpensePolicy' }
      & Pick<ExpensePolicy, 'update' | 'submit' | 'approve' | 'reject'>
    )>, createdByMember: (
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'name'>
    )
  }
);

export type InvitationFieldsFragment = (
  { __typename: 'Invitation' }
  & Pick<Invitation, 'id' | 'token' | 'deepLink' | 'firstName' | 'lastName' | 'name' | 'message' | 'email' | 'createdByUserName' | 'createdByUserFirstName' | 'memberId' | 'memberRole' | 'memberRoleLabel' | 'projectId' | 'projectName' | 'projectDescription' | 'projectImageThumbnail' | 'acceptedAt' | 'createdAt' | 'invitedUserId'>
  & {
    policy?: Maybe<(
      { __typename: 'InvitationPolicy' }
      & Pick<InvitationPolicy, 'mail' | 'accept' | 'open' | 'share'>
    )>
  }
);

export type MaterialNoteFieldsFragment = (
  { __typename: 'MaterialNote' }
  & Pick<MaterialNote, 'id' | 'createdAt' | 'updatedAt' | 'submittedAt' | 'approvedAt' | 'status' | 'projectId' | 'createdByMemberId' | 'approvedByMemberId'>
  & {
    policy?: Maybe<(
      { __typename: 'MaterialNotePolicy' }
      & Pick<MaterialNotePolicy, 'update' | 'submit' | 'approve' | 'delete' | 'export' | 'reject'>
    )>, createdByMember: (
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'name' | 'avatarThumbnail'>
    )
  }
);

export type MaterialNoteItemFieldsFragment = (
  { __typename: 'MaterialNoteItem' }
  & Pick<MaterialNoteItem, 'id' | 'name' | 'quantity' | 'createdAt' | 'updatedAt' | 'materialNoteId'>
  & {
    policy?: Maybe<(
      { __typename: 'MaterialNoteItemPolicy' }
      & Pick<MaterialNoteItemPolicy, 'delete'>
    )>
  }
);

type MediaFields_Folder_Fragment = (
  { __typename: 'Folder' }
  & Pick<Folder, 'path' | 'id' | 'description' | 'filename' | 'ownerType' | 'projectId' | 'createdAt' | 'updatedAt'>
  & {
    policy?: Maybe<(
      { __typename: 'MediaPolicy' }
      & Pick<MediaPolicy, 'delete'>
    )>, members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'avatarThumbnail'>
    )>, user: (
      { __typename: 'User' }
      & UserFieldsFragment
    )
  }
);

type MediaFields_Document_Fragment = (
  { __typename: 'Document' }
  & Pick<Document, 'file' | 'downloadFile' | 'tile' | 'preview' | 'path' | 'fileExtension' | 'contentType' | 'id' | 'description' | 'filename' | 'ownerType' | 'projectId' | 'createdAt' | 'updatedAt'>
  & {
    metadata?: Maybe<(
      { __typename: 'MediaMetadata' }
      & Pick<MediaMetadata, 'width' | 'height' | 'duration' | 'portrait'>
    )>, policy?: Maybe<(
      { __typename: 'MediaPolicy' }
      & Pick<MediaPolicy, 'delete'>
    )>, members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'avatarThumbnail'>
    )>, user: (
      { __typename: 'User' }
      & UserFieldsFragment
    )
  }
);

type MediaFields_Image_Fragment = (
  { __typename: 'Image' }
  & Pick<Image, 'file' | 'downloadFile' | 'tile' | 'preview' | 'path' | 'id' | 'description' | 'filename' | 'ownerType' | 'projectId' | 'createdAt' | 'updatedAt'>
  & {
    metadata?: Maybe<(
      { __typename: 'MediaMetadata' }
      & Pick<MediaMetadata, 'width' | 'height' | 'duration' | 'portrait'>
    )>, policy?: Maybe<(
      { __typename: 'MediaPolicy' }
      & Pick<MediaPolicy, 'delete'>
    )>, members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'avatarThumbnail'>
    )>, user: (
      { __typename: 'User' }
      & UserFieldsFragment
    )
  }
);

type MediaFields_Video_Fragment = (
  { __typename: 'Video' }
  & Pick<Video, 'file' | 'downloadFile' | 'tile' | 'preview' | 'path' | 'id' | 'description' | 'filename' | 'ownerType' | 'projectId' | 'createdAt' | 'updatedAt'>
  & {
    metadata?: Maybe<(
      { __typename: 'MediaMetadata' }
      & Pick<MediaMetadata, 'width' | 'height' | 'duration' | 'portrait'>
    )>, policy?: Maybe<(
      { __typename: 'MediaPolicy' }
      & Pick<MediaPolicy, 'delete'>
    )>, members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'avatarThumbnail'>
    )>, user: (
      { __typename: 'User' }
      & UserFieldsFragment
    )
  }
);

export type MediaFieldsFragment = MediaFields_Folder_Fragment | MediaFields_Document_Fragment | MediaFields_Image_Fragment | MediaFields_Video_Fragment;

export type MemberFieldsFragment = (
  { __typename: 'Member' }
  & Pick<Member, 'id' | 'name' | 'firstName' | 'lastName' | 'email' | 'phone' | 'createdAt' | 'updatedAt' | 'deactivatedAt' | 'role' | 'roleLabel' | 'admin' | 'enableTimesheets' | 'enableExpenses' | 'enableMaterialNotes' | 'active' | 'current' | 'avatarThumbnail' | 'projectId' | 'userId'>
  & {
    invitation?: Maybe<(
      { __typename: 'Invitation' }
      & InvitationFieldsFragment
    )>, user?: Maybe<(
      { __typename: 'User' }
      & UserFieldsFragment
    )>, policy?: Maybe<(
      { __typename: 'MemberPolicy' }
      & Pick<MemberPolicy, 'update' | 'promote' | 'demote' | 'deactivate' | 'showContactDetails' | 'createPrivateChat' | 'createGroupChat' | 'createAgreement'>
    )>
  }
);

export type ParticipantFieldsFragment = (
  { __typename: 'Participant' }
  & Pick<Participant, 'id' | 'admin' | 'createdAt' | 'updatedAt' | 'readAt' | 'deactivatedAt' | 'active' | 'chatId' | 'current' | 'typingAt'>
  & {
    policy?: Maybe<(
      { __typename: 'ParticipantPolicy' }
      & Pick<ParticipantPolicy, 'deactivate' | 'promote' | 'demote'>
    )>
  }
);

export type ProjectFieldsFragment = (
  { __typename: 'Project' }
  & Pick<Project, 'id' | 'name' | 'nameOrAddress' | 'description' | 'reference' | 'address' | 'createdAt' | 'archivedAt' | 'latestActivityAt' | 'notificationCount' | 'chatNotificationCount' | 'active' | 'deactivatedAt' | 'imageThumbnail' | 'imageBanner' | 'imageFull' | 'onboardingProject' | 'demo' | 'companyId'>
  & {
    policy?: Maybe<(
      { __typename: 'ProjectPolicy' }
      & Pick<ProjectPolicy, 'update' | 'archive' | 'unarchive' | 'inviteUser' | 'inviteUsers' | 'inviteMember' | 'createMedia' | 'createPrivateChat' | 'createGroupChat' | 'createAgreement' | 'createActivity' | 'orderActivities' | 'updateTimesheet' | 'exportTimesheets' | 'exportExpense' | 'manageTimesheets' | 'createExpense' | 'manageExpenses' | 'createTodo' | 'orderTodos' | 'pdfActivities' | 'manageMaterialNotes' | 'createMaterialNote' | 'exportMaterialNotes' | 'inviteMemberLimitReached'>
    )>
  }
);

export type WkbTemplatesFieldsFragment = (
  { __typename: 'wkbTemplates' }
  & Pick<WkbTemplate, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'totalQuestions' | 'postAnswers'>
);

export type WkbQuestionsCompletedFieldsFragment = (
  { __typename: 'wkbQuestionsCompleted' }
  & Pick<WkbTemplate, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'totalQuestions' | 'postAnswers'>
);

export type WkbQuestionsFieldsFragment = (
  { __typename: 'wkbTemplates' }
  & Pick<WkbQuestion, 'id' | 'name' | 'createdAt' | 'updatedAt'>
);

export type TeamMemberFieldsFragment = (
  { __typename: 'TeamMember' }
  & Pick<TeamMember, 'id' | 'name' | 'firstName' | 'lastName' | 'email' | 'phone' | 'role' | 'createdAt' | 'updatedAt' | 'avatarFull' | 'avatarThumbnail' | 'enableExpenses' | 'enableTimesheets' | 'enableMaterialNotes'>
  & {
    policy: (
      { __typename: 'TeamMemberPolicy' }
      & Pick<TeamMemberPolicy, 'delete'>
    ), user?: Maybe<(
      { __typename: 'User' }
      & UserFieldsFragment
    )>
  }
);

export type TimesheetFieldsFragment = (
  { __typename: 'Timesheet' }
  & Pick<Timesheet, 'id' | 'description' | 'year' | 'week' | 'status' | 'createdAt' | 'updatedAt' | 'submittedAt' | 'approvedAt' | 'monday' | 'mondayHours' | 'mondayNotes' | 'tuesday' | 'tuesdayHours' | 'tuesdayNotes' | 'wednesday' | 'wednesdayHours' | 'wednesdayNotes' | 'thursday' | 'thursdayHours' | 'thursdayNotes' | 'friday' | 'fridayHours' | 'fridayNotes' | 'saturday' | 'saturdayHours' | 'saturdayNotes' | 'sunday' | 'sundayHours' | 'sundayNotes' | 'totalHours' | 'projectId' | 'createdByMemberId' | 'approvedByMemberId'>
  & {
    policy?: Maybe<(
      { __typename: 'TimesheetPolicy' }
      & Pick<TimesheetPolicy, 'update' | 'submit' | 'approve' | 'reject'>
    )>, createdByMember: (
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'name' | 'current'>
    )
  }
);

export type TodoFieldsFragment = (
  { __typename: 'Todo' }
  & Pick<Todo, 'id' | 'name' | 'description' | 'projectId' | 'position' | 'completedAt' | 'createdAt' | 'updatedAt'>
  & {
    media: Array<(
      { __typename: 'Folder' }
      & MediaFields_Folder_Fragment
    ) | (
        { __typename: 'Document' }
        & MediaFields_Document_Fragment
      ) | (
        { __typename: 'Image' }
        & MediaFields_Image_Fragment
      ) | (
        { __typename: 'Video' }
        & MediaFields_Video_Fragment
      )>, policy?: Maybe<(
        { __typename: 'TodoPolicy' }
        & Pick<TodoPolicy, 'update' | 'create' | 'delete'>
      )>
  }
);

export type UserFieldsFragment = (
  { __typename: 'User' }
  & Pick<User, 'id' | 'professional' | 'email' | 'phone' | 'firstName' | 'lastName' | 'name' | 'avatarThumbnail' | 'current' | 'subscribedToPlanAt' | 'sendTransactionalEmail' | 'sendNewsletterEmail' | 'bouwmaat' | 'bouwmaatOption' | 'bouwmaatSubscriptionCompleted' | 'bouwmaatAccountNumber'>
);

export type AcceptAgreementMutationVariables = Exact<{
  input: AcceptAgreementInputType;
}>;


export type AcceptAgreementMutation = (
  { __typename: 'PrivateMutation' }
  & {
    acceptAgreement?: Maybe<(
      { __typename: 'AcceptAgreementPayload' }
      & {
        agreement?: Maybe<(
          { __typename: 'Agreement' }
          & AgreementFieldsFragment
        )>, errors: Array<(
          { __typename: 'AcceptAgreementErrorType' }
          & Pick<AcceptAgreementErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type AcceptInvitationMutationVariables = Exact<{
  input: AcceptInvitationInputType;
}>;


export type AcceptInvitationMutation = (
  { __typename: 'PrivateMutation' }
  & {
    acceptInvitation?: Maybe<(
      { __typename: 'AcceptInvitationPayload' }
      & {
        invitation?: Maybe<(
          { __typename: 'Invitation' }
          & Pick<Invitation, 'projectId'>
        )>, errors: Array<(
          { __typename: 'AcceptInvitationErrorType' }
          & Pick<AcceptInvitationErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type AddressSearchMutationVariables = Exact<{
  input: AddressSearchInputType;
}>;


export type AddressSearchMutation = (
  { __typename: 'PrivateMutation' }
  & {
    addressSearch: (
      { __typename: 'AddressSearchPayload' }
      & {
        results: Array<(
          { __typename: 'AddressSuggestion' }
          & Pick<AddressSuggestion, 'id' | 'address'>
        )>, errors: Array<(
          { __typename: 'AddressSearchErrorType' }
          & Pick<AddressSearchErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type ApproveExpenseMutationVariables = Exact<{
  input: ApproveExpenseInputType;
}>;


export type ApproveExpenseMutation = (
  { __typename: 'PrivateMutation' }
  & {
    approveExpense: (
      { __typename: 'ApproveExpensePayload' }
      & {
        errors: Array<(
          { __typename: 'ApproveExpenseErrorType' }
          & Pick<ApproveExpenseErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type ApproveMaterialNoteMutationVariables = Exact<{
  input: ApproveMaterialNoteInputType;
}>;


export type ApproveMaterialNoteMutation = (
  { __typename: 'PrivateMutation' }
  & {
    approveMaterialNote: (
      { __typename: 'ApproveMaterialNotePayload' }
      & {
        errors: Array<(
          { __typename: 'ApproveMaterialNoteErrorType' }
          & Pick<ApproveMaterialNoteErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type ApproveTimesheetMutationVariables = Exact<{
  input: ApproveTimesheetInputType;
}>;


export type ApproveTimesheetMutation = (
  { __typename: 'PrivateMutation' }
  & {
    approveTimesheet: (
      { __typename: 'ApproveTimesheetPayload' }
      & {
        errors: Array<(
          { __typename: 'ApproveTimesheetErrorType' }
          & Pick<ApproveTimesheetErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type ArchiveActivityMutationVariables = Exact<{
  input: ArchiveActivityInputType;
}>;


export type ArchiveActivityMutation = (
  { __typename: 'PrivateMutation' }
  & {
    archiveActivity: (
      { __typename: 'ArchiveActivityPayload' }
      & {
        activity?: Maybe<(
          { __typename: 'Activity' }
          & Pick<Activity, 'id' | 'archivedAt' | 'active'>
          & {
            policy?: Maybe<(
              { __typename: 'ActivityPolicy' }
              & Pick<ActivityPolicy, 'update' | 'archive'>
            )>
          }
        )>
      }
    )
  }
);

export type ArchiveGroupChatMutationVariables = Exact<{
  input: ArchiveGroupChatInputType;
}>;


export type ArchiveGroupChatMutation = (
  { __typename: 'PrivateMutation' }
  & {
    archiveGroupChat: (
      { __typename: 'ArchiveGroupChatPayload' }
      & {
        groupChat?: Maybe<(
          { __typename: 'GroupChat' }
          & ChatFields_GroupChat_Fragment
        )>, errors: Array<(
          { __typename: 'ArchiveGroupChatErrorType' }
          & Pick<ArchiveGroupChatErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type ArchiveProjectMutationVariables = Exact<{
  input: ArchiveProjectInputType;
}>;


export type ArchiveProjectMutation = (
  { __typename: 'PrivateMutation' }
  & {
    archiveProject?: Maybe<(
      { __typename: 'ArchiveProjectPayload' }
      & {
        project?: Maybe<(
          { __typename: 'Project' }
          & {
            members: Array<(
              { __typename: 'Member' }
              & MemberFieldsFragment
            )>, chats: Array<(
              { __typename: 'GroupChat' }
              & ChatFields_GroupChat_Fragment
            ) | (
                { __typename: 'PrivateChat' }
                & ChatFields_PrivateChat_Fragment
              )>, agreements: Array<(
                { __typename: 'Agreement' }
                & AgreementFieldsFragment
              )>
          }
          & ProjectFieldsFragment
        )>, errors: Array<(
          { __typename: 'ArchiveProjectErrorType' }
          & Pick<ArchiveProjectErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type CancelSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelSubscriptionMutation = (
  { __typename: 'PrivateMutation' }
  & {
    cancelSubscription: (
      { __typename: 'CancelSubscriptionPayload' }
      & Pick<CancelSubscriptionPayload, 'subscribedToPlanAt'>
    )
  }
);

export type ContactDasMutationVariables = Exact<{
  input: SendDasContactInputType;
}>;


export type ContactDasMutation = (
  { __typename: 'PrivateMutation' }
  & {
    sendDasContact?: Maybe<(
      { __typename: 'SendDasContactPayload' }
      & Pick<SendDasContactPayload, 'message'>
      & {
        errors: Array<(
          { __typename: 'SendDasContactErrorType' }
          & Pick<SendDasContactErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type CreateActivityMutationVariables = Exact<{
  input: CreateActivityInputType;
}>;


export type CreateActivityMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createActivity: (
      { __typename: 'CreateActivityPayload' }
      & {
        activity?: Maybe<(
          { __typename: 'Activity' }
          & {
            createdByMember: (
              { __typename: 'Member' }
              & MemberFieldsFragment
            ), assignedMembers?: Maybe<Array<(
              { __typename: 'Member' }
              & MemberFieldsFragment
            )>>
          }
          & ActivityFieldsFragment
        )>, errors: Array<(
          { __typename: 'CreateActivityErrorType' }
          & Pick<CreateActivityErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateAddressValuationMutationVariables = Exact<{
  input: CreateAddressValuationInputType;
}>;


export type CreateAddressValuationMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createAddressValuation: (
      { __typename: 'CreateAddressValuationPayload' }
      & {
        addressValuation?: Maybe<(
          { __typename: 'AddressValuation' }
          & Pick<AddressValuation, 'bestEstimate' | 'bottomEstimate' | 'id' | 'topEstimate'>
        )>, errors: Array<(
          { __typename: 'CreateAddressValuationErrorType' }
          & Pick<CreateAddressValuationErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateAgreementMutationVariables = Exact<{
  input: CreateAgreementInputType;
}>;


export type CreateAgreementMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createAgreement: (
      { __typename: 'CreateAgreementPayload' }
      & {
        agreement?: Maybe<(
          { __typename: 'Agreement' }
          & {
            createdByMember: (
              { __typename: 'Member' }
              & MemberFieldsFragment
            ), counterpartyMember: (
              { __typename: 'Member' }
              & MemberFieldsFragment
            ), media: Array<(
              { __typename: 'Folder' }
              & MediaFields_Folder_Fragment
            ) | (
                { __typename: 'Document' }
                & MediaFields_Document_Fragment
              ) | (
                { __typename: 'Image' }
                & MediaFields_Image_Fragment
              ) | (
                { __typename: 'Video' }
                & MediaFields_Video_Fragment
              )>
          }
          & AgreementFieldsFragment
        )>, errors: Array<(
          { __typename: 'CreateAgreementErrorType' }
          & Pick<CreateAgreementErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateDemoProjectMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateDemoProjectMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createDemoProject?: Maybe<(
      { __typename: 'CreateDemoProjectPayload' }
      & {
        project?: Maybe<(
          { __typename: 'Project' }
          & {
            chats: Array<(
              { __typename: 'GroupChat' }
              & ChatFields_GroupChat_Fragment
            ) | (
                { __typename: 'PrivateChat' }
                & ChatFields_PrivateChat_Fragment
              )>, members: Array<(
                { __typename: 'Member' }
                & MemberFieldsFragment
              )>
          }
          & ProjectFieldsFragment
        )>
      }
    )>
  }
);

export type CreateExpenseMutationVariables = Exact<{
  input: CreateExpenseInputType;
}>;


export type CreateExpenseMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createExpense: (
      { __typename: 'CreateExpensePayload' }
      & {
        expense?: Maybe<(
          { __typename: 'Expense' }
          & Pick<Expense, 'id' | 'projectId'>
        )>, errors: Array<(
          { __typename: 'CreateExpenseErrorType' }
          & Pick<CreateExpenseErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateFolderMutationVariables = Exact<{
  input: CreateFolderInputType;
}>;


export type CreateFolderMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createFolder?: Maybe<(
      { __typename: 'CreateFolderPayload' }
      & {
        folder?: Maybe<(
          { __typename: 'Folder' }
          & MediaFields_Folder_Fragment
        )>, errors: Array<(
          { __typename: 'CreateFolderErrorType' }
          & Pick<CreateFolderErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type CreateGroupChatMutationVariables = Exact<{
  input: CreateGroupChatInputType;
}>;


export type CreateGroupChatMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createGroupChat: (
      { __typename: 'CreateGroupChatPayload' }
      & {
        groupChat?: Maybe<(
          { __typename: 'GroupChat' }
          & {
            participants: Array<(
              { __typename: 'Participant' }
              & ParticipantFieldsFragment
            )>
          }
          & ChatFields_GroupChat_Fragment
        )>, errors: Array<(
          { __typename: 'CreateGroupChatErrorType' }
          & Pick<CreateGroupChatErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateMaterialNoteItemMutationVariables = Exact<{
  input: CreateMaterialNoteItemInputType;
}>;


export type CreateMaterialNoteItemMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createMaterialNoteItem: (
      { __typename: 'CreateMaterialNoteItemPayload' }
      & {
        materialNoteItem?: Maybe<(
          { __typename: 'MaterialNoteItem' }
          & Pick<MaterialNoteItem, 'id' | 'materialNoteId'>
        )>, errors: Array<(
          { __typename: 'CreateMaterialNoteItemErrorType' }
          & Pick<CreateMaterialNoteItemErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateMaterialNoteMutationVariables = Exact<{
  input: CreateMaterialNoteInputType;
}>;


export type CreateMaterialNoteMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createMaterialNote: (
      { __typename: 'CreateMaterialNotePayload' }
      & {
        materialNote?: Maybe<(
          { __typename: 'MaterialNote' }
          & Pick<MaterialNote, 'id' | 'projectId'>
        )>, errors: Array<(
          { __typename: 'CreateMaterialNoteErrorType' }
          & Pick<CreateMaterialNoteErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateMediaMessageMutationVariables = Exact<{
  input: CreateMediaMessageInputType;
}>;


export type CreateMediaMessageMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createMediaMessage: (
      { __typename: 'CreateMediaMessagePayload' }
      & {
        mediaMessage?: Maybe<(
          { __typename: 'MediaMessage' }
          & {
            user: (
              { __typename: 'User' }
              & UserFieldsFragment
            )
          }
          & EventFields_MediaMessage_Fragment
        )>, errors: Array<(
          { __typename: 'CreateMediaMessageErrorType' }
          & Pick<CreateMediaMessageErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateMediaMutationVariables = Exact<{
  input: CreateMediaInputType;
}>;


export type CreateMediaMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createMedia?: Maybe<(
      { __typename: 'CreateMediaPayload' }
      & {
        media?: Maybe<(
          { __typename: 'Folder' }
          & MediaFields_Folder_Fragment
        ) | (
            { __typename: 'Document' }
            & MediaFields_Document_Fragment
          ) | (
            { __typename: 'Image' }
            & MediaFields_Image_Fragment
          ) | (
            { __typename: 'Video' }
            & MediaFields_Video_Fragment
          )>, errors: Array<(
            { __typename: 'CreateMediaErrorType' }
            & Pick<CreateMediaErrorType, 'argument' | 'error'>
          )>
      }
    )>
  }
);

export type CreateMessageMutationVariables = Exact<{
  input: CreateMessageInputType;
}>;


export type CreateMessageMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createMessage: (
      { __typename: 'CreateMessagePayload' }
      & {
        message?: Maybe<(
          { __typename: 'Message' }
          & {
            user: (
              { __typename: 'User' }
              & UserFieldsFragment
            )
          }
          & EventFields_Message_Fragment
        )>, errors: Array<(
          { __typename: 'CreateMessageErrorType' }
          & Pick<CreateMessageErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateParticipantsMutationVariables = Exact<{
  input: CreateParticipantsInputType;
}>;


export type CreateParticipantsMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createParticipants: (
      { __typename: 'CreateParticipantsPayload' }
      & {
        errors: Array<(
          { __typename: 'CreateParticipantsErrorType' }
          & Pick<CreateParticipantsErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreatePrivateChatMutationVariables = Exact<{
  input: CreatePrivateChatInputType;
}>;


export type CreatePrivateChatMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createPrivateChat: (
      { __typename: 'CreatePrivateChatPayload' }
      & {
        privateChat?: Maybe<(
          { __typename: 'PrivateChat' }
          & {
            participants: Array<(
              { __typename: 'Participant' }
              & ParticipantFieldsFragment
            )>
          }
          & ChatFields_PrivateChat_Fragment
        )>, errors: Array<(
          { __typename: 'CreatePrivateChatErrorType' }
          & Pick<CreatePrivateChatErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInputType;
}>;


export type CreateProjectMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createProject?: Maybe<(
      { __typename: 'CreateProjectPayload' }
      & {
        project?: Maybe<(
          { __typename: 'Project' }
          & {
            members: Array<(
              { __typename: 'Member' }
              & MemberFieldsFragment
            )>
          }
          & ProjectFieldsFragment
        )>, errors: Array<(
          { __typename: 'CreateProjectErrorType' }
          & Pick<CreateProjectErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type CreateReplyMessageMutationVariables = Exact<{
  input: CreateReplyMessageInputType;
}>;


export type CreateReplyMessageMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createReplyMessage: (
      { __typename: 'CreateReplyMessagePayload' }
      & {
        replyMessage?: Maybe<(
          { __typename: 'ReplyMessage' }
          & {
            user: (
              { __typename: 'User' }
              & UserFieldsFragment
            )
          }
          & EventFields_ReplyMessage_Fragment
        )>, errors: Array<(
          { __typename: 'CreateReplyMessageErrorType' }
          & Pick<CreateReplyMessageErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInputType;
}>;


export type CreateSubscriptionMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createSubscription: (
      { __typename: 'CreateSubscriptionPayload' }
      & Pick<CreateSubscriptionPayload, 'mollieCheckoutUrl'>
      & Pick<CreateSubscriptionPayload, 'status'>
      & {
        errors: Array<(
          { __typename: 'CreateSubscriptionErrorType' }
          & Pick<CreateSubscriptionErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateTeamMemberMutationVariables = Exact<{
  input: CreateTeamMemberInputType;
}>;


export type CreateTeamMemberMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createTeamMember: (
      { __typename: 'CreateTeamMemberPayload' }
      & {
        teamMember?: Maybe<(
          { __typename: 'TeamMember' }
          & TeamMemberFieldsFragment
        )>, errors: Array<(
          { __typename: 'CreateTeamMemberErrorType' }
          & Pick<CreateTeamMemberErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateTenderMutationVariables = Exact<{
  input: CreateTenderInputType;
}>;


export type CreateTenderMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createTender: (
      { __typename: 'CreateTenderPayload' }
      & {
        tender?: Maybe<(
          { __typename: 'Tender' }
          & Pick<Tender, 'id'>
        )>, errors: Array<(
          { __typename: 'CreateTenderErrorType' }
          & Pick<CreateTenderErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type CreateTodoMutationVariables = Exact<{
  input: CreateTodoInputType;
}>;


export type CreateTodoMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createTodo: (
      { __typename: 'CreateTodoPayload' }
      & {
        todo?: Maybe<(
          { __typename: 'Todo' }
          & {
            createdByMember: (
              { __typename: 'Member' }
              & MemberFieldsFragment
            ), assignedMembers?: Maybe<Array<(
              { __typename: 'Member' }
              & MemberFieldsFragment
            )>>
          }
          & TodoFieldsFragment
        )>, errors: Array<(
          { __typename: 'CreateTodoErrorType' }
          & Pick<CreateTodoErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type DeactivateMemberMutationVariables = Exact<{
  input: DeactivateMemberInputType;
}>;


export type DeactivateMemberMutation = (
  { __typename: 'PrivateMutation' }
  & {
    deactivateMember?: Maybe<(
      { __typename: 'DeactivateMemberPayload' }
      & {
        member?: Maybe<(
          { __typename: 'Member' }
          & MemberFieldsFragment
        )>, errors: Array<(
          { __typename: 'DeactivateMemberErrorType' }
          & Pick<DeactivateMemberErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type DeactivateParticipantMutationVariables = Exact<{
  input: DeactivateParticipantInputType;
}>;


export type DeactivateParticipantMutation = (
  { __typename: 'PrivateMutation' }
  & {
    deactivateParticipant: (
      { __typename: 'DeactivateParticipantPayload' }
      & {
        participant?: Maybe<(
          { __typename: 'Participant' }
          & ParticipantFieldsFragment
        )>, errors: Array<(
          { __typename: 'DeactivateParticipantErrorType' }
          & Pick<DeactivateParticipantErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type DeleteAgreementMutationVariables = Exact<{
  input: DeleteAgreementInputType;
}>;


export type DeleteAgreementMutation = (
  { __typename: 'PrivateMutation' }
  & {
    deleteAgreement?: Maybe<(
      { __typename: 'DeleteAgreementPayload' }
      & {
        errors: Array<(
          { __typename: 'DeleteAgreementErrorType' }
          & Pick<DeleteAgreementErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type DeleteEventMutationVariables = Exact<{
  input: DeleteEventInputType;
}>;


export type DeleteEventMutation = (
  { __typename: 'PrivateMutation' }
  & {
    deleteEvent: (
      { __typename: 'DeleteEventPayload' }
      & {
        errors: Array<(
          { __typename: 'DeleteEventErrorType' }
          & Pick<DeleteEventErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type DeleteMaterialNoteItemMutationVariables = Exact<{
  input: DeleteMaterialNoteItemInputType;
}>;


export type DeleteMaterialNoteItemMutation = (
  { __typename: 'PrivateMutation' }
  & {
    deleteMaterialNoteItem: (
      { __typename: 'DeleteMaterialNoteItemPayload' }
      & {
        errors: Array<(
          { __typename: 'DeleteMaterialNoteItemErrorType' }
          & Pick<DeleteMaterialNoteItemErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type DeleteMaterialNoteMutationVariables = Exact<{
  input: DeleteMaterialNoteInputType;
}>;


export type DeleteMaterialNoteMutation = (
  { __typename: 'PrivateMutation' }
  & {
    deleteMaterialNote?: Maybe<(
      { __typename: 'DeleteMaterialNotePayload' }
      & {
        errors: Array<(
          { __typename: 'DeleteMaterialNoteErrorType' }
          & Pick<DeleteMaterialNoteErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type DeleteMediaMutationVariables = Exact<{
  input: DeleteMediaInputType;
}>;


export type DeleteMediaMutation = (
  { __typename: 'PrivateMutation' }
  & {
    deleteMedia: (
      { __typename: 'DeleteMediaPayload' }
      & {
        errors: Array<(
          { __typename: 'DeleteMediaErrorType' }
          & Pick<DeleteMediaErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type DeleteTeamMemberMutationVariables = Exact<{
  input: DeleteTeamMemberInputType;
}>;


export type DeleteTeamMemberMutation = (
  { __typename: 'PrivateMutation' }
  & {
    deleteTeamMember: (
      { __typename: 'DeleteTeamMemberPayload' }
      & {
        errors: Array<(
          { __typename: 'DeleteTeamMemberErrorType' }
          & Pick<DeleteTeamMemberErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type DeleteTodoMutationVariables = Exact<{
  input: DeleteTodoInputType;
}>;


export type DeleteTodoMutation = (
  { __typename: 'PrivateMutation' }
  & {
    deleteTodo?: Maybe<(
      { __typename: 'DeleteTodoPayload' }
      & {
        errors: Array<(
          { __typename: 'DeleteTodoErrorType' }
          & Pick<DeleteTodoErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type DemoteMemberMutationVariables = Exact<{
  input: DemoteMemberInputType;
}>;


export type DemoteMemberMutation = (
  { __typename: 'PrivateMutation' }
  & {
    demoteMember?: Maybe<(
      { __typename: 'DemoteMemberPayload' }
      & {
        member?: Maybe<(
          { __typename: 'Member' }
          & MemberFieldsFragment
        )>, errors: Array<(
          { __typename: 'DemoteMemberErrorType' }
          & Pick<DemoteMemberErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type DemoteParticipantMutationVariables = Exact<{
  input: DemoteParticipantInputType;
}>;


export type DemoteParticipantMutation = (
  { __typename: 'PrivateMutation' }
  & {
    demoteParticipant?: Maybe<(
      { __typename: 'DemoteParticipantPayload' }
      & {
        participant?: Maybe<(
          { __typename: 'Participant' }
          & ParticipantFieldsFragment
        )>, errors: Array<(
          { __typename: 'DemoteParticipantErrorType' }
          & Pick<DemoteParticipantErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type ExportMaterialNotesMutationVariables = Exact<{
  input: ExportMaterialNotesInputType;
}>;


export type ExportMaterialNotesMutation = (
  { __typename: 'PrivateMutation' }
  & {
    exportMaterialNotes: (
      { __typename: 'ExportMaterialNotesPayload' }
      & Pick<ExportMaterialNotesPayload, 'csv'>
    )
  }
);

export type ExportExpenseMutationVariables = Exact<{
  input: ExportExpenseInputType;
}>;


export type ExportExpenseMutation = (
  { __typename: 'PrivateMutation' }
  & {
    exportExpense: (
      { __typename: 'ExportExpensePayload' }
      & Pick<ExportExpensePayload, 'csv'>
    )
  }
);

export type ExportTimesheetMutationVariables = Exact<{
  input: ExportTimesheetInputType;
}>;


export type ExportTimesheetMutation = (
  { __typename: 'PrivateMutation' }
  & {
    exportTimesheet: (
      { __typename: 'ExportTimesheetPayload' }
      & Pick<ExportTimesheetPayload, 'csv'>
    )
  }
);

export type InviteMemberMutationVariables = Exact<{
  input: InviteMemberInputType;
}>;


export type InviteMemberMutation = (
  { __typename: 'PrivateMutation' }
  & {
    inviteMember?: Maybe<(
      { __typename: 'InviteMemberPayload' }
      & {
        invitation?: Maybe<(
          { __typename: 'Invitation' }
          & Pick<Invitation, 'id'>
        )>, errors: Array<(
          { __typename: 'InviteMemberErrorType' }
          & Pick<InviteMemberErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type InviteUserMutationVariables = Exact<{
  input: InviteUserInputType;
}>;


export type InviteUserMutation = (
  { __typename: 'PrivateMutation' }
  & {
    inviteUser?: Maybe<(
      { __typename: 'InviteUserPayload' }
      & {
        invitation?: Maybe<(
          { __typename: 'Invitation' }
          & Pick<Invitation, 'id'>
        )>, errors: Array<(
          { __typename: 'InviteUserErrorType' }
          & Pick<InviteUserErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type InviteUsersMutationVariables = Exact<{
  input: InviteUsersInputType;
}>;


export type InviteUsersMutation = (
  { __typename: 'PrivateMutation' }
  & {
    inviteUsers?: Maybe<(
      { __typename: 'InviteUsersPayload' }
      & {
        errors: Array<(
          { __typename: 'InviteUsersErrorType' }
          & Pick<InviteUsersErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type CreateWkbAnswerMutationVariables = Exact<{
  input: CreateWkbAnswerInputType;
}>;

export type CreateWkbAnswerMutation = (
  { __typename: 'PrivateMutation' }
  & {
    createWkbAnswer?: Maybe<(
      { __typename: 'CreateWkbAnswerPayload' }
      & {
        errors: Array<(
          { __typename: 'CreateWkbAnswerErrorType' }
          & Pick<CreateWkbAnswerErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type LeaveGroupChatMutationVariables = Exact<{
  input: LeaveGroupChatInputType;
}>;


export type LeaveGroupChatMutation = (
  { __typename: 'PrivateMutation' }
  & {
    leaveGroupChat: (
      { __typename: 'LeaveGroupChatPayload' }
      & {
        groupChat?: Maybe<(
          { __typename: 'GroupChat' }
          & ChatFields_GroupChat_Fragment
        )>, errors: Array<(
          { __typename: 'LeaveGroupChatErrorType' }
          & Pick<LeaveGroupChatErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type LogoutMutationVariables = Exact<{
  input: LogoutInputType;
}>;


export type LogoutMutation = (
  { __typename: 'PrivateMutation' }
  & {
    logout?: Maybe<(
      { __typename: 'LogoutPayload' }
      & {
        user: (
          { __typename: 'User' }
          & Pick<User, 'id'>
        ), errors: Array<(
          { __typename: 'LogoutErrorType' }
          & Pick<LogoutErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type MailInvitationMutationVariables = Exact<{
  input: MailInvitationInputType;
}>;


export type MailInvitationMutation = (
  { __typename: 'PrivateMutation' }
  & {
    mailInvitation?: Maybe<(
      { __typename: 'MailInvitationPayload' }
      & {
        invitation?: Maybe<(
          { __typename: 'Invitation' }
          & InvitationFieldsFragment
        )>, errors: Array<(
          { __typename: 'MailInvitationErrorType' }
          & Pick<MailInvitationErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type MoveMediaMutationVariables = Exact<{
  input: MoveMediaInputType;
}>;


export type MoveMediaMutation = (
  { __typename: 'PrivateMutation' }
  & {
    moveMedia: (
      { __typename: 'MoveMediaPayload' }
      & {
        media?: Maybe<(
          { __typename: 'Folder' }
          & Pick<Folder, 'id'>
        ) | (
            { __typename: 'Document' }
            & Pick<Document, 'id'>
          ) | (
            { __typename: 'Image' }
            & Pick<Image, 'id'>
          ) | (
            { __typename: 'Video' }
            & Pick<Video, 'id'>
          )>
      }
    )
  }
);

export type OpenedInvitationMutationVariables = Exact<{
  input: OpenedInvitationInputType;
}>;


export type OpenedInvitationMutation = (
  { __typename: 'PrivateMutation' }
  & {
    openedInvitation?: Maybe<(
      { __typename: 'OpenedInvitationPayload' }
      & {
        invitation?: Maybe<(
          { __typename: 'Invitation' }
          & Pick<Invitation, 'id'>
        )>, errors: Array<(
          { __typename: 'OpenedInvitationErrorType' }
          & Pick<OpenedInvitationErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type OrderActivitiesMutationVariables = Exact<{
  input: OrderActivitiesInputType;
}>;


export type OrderActivitiesMutation = (
  { __typename: 'PrivateMutation' }
  & {
    orderActivities: (
      { __typename: 'OrderActivitiesPayload' }
      & {
        errors: Array<(
          { __typename: 'OrderActivitiesErrorType' }
          & Pick<OrderActivitiesErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type OrderTodosMutationVariables = Exact<{
  input: OrderTodosInputType;
}>;


export type OrderTodosMutation = (
  { __typename: 'PrivateMutation' }
  & {
    orderTodos: (
      { __typename: 'OrderTodosPayload' }
      & {
        errors: Array<(
          { __typename: 'OrderTodosErrorType' }
          & Pick<OrderTodosErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type PdfActivitiesMutationVariables = Exact<{
  input: PdfActivitiesInputType;
}>;


export type PdfActivitiesMutation = (
  { __typename: 'PrivateMutation' }
  & {
    pdfActivities: (
      { __typename: 'PdfActivitiesPayload' }
      & Pick<PdfActivitiesPayload, 'pdf'>
    )
  }
);

export type PromoteMemberMutationVariables = Exact<{
  input: PromoteMemberInputType;
}>;


export type PromoteMemberMutation = (
  { __typename: 'PrivateMutation' }
  & {
    promoteMember?: Maybe<(
      { __typename: 'PromoteMemberPayload' }
      & {
        member?: Maybe<(
          { __typename: 'Member' }
          & MemberFieldsFragment
        )>, errors: Array<(
          { __typename: 'PromoteMemberErrorType' }
          & Pick<PromoteMemberErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type PromoteParticipantMutationVariables = Exact<{
  input: PromoteParticipantInputType;
}>;


export type PromoteParticipantMutation = (
  { __typename: 'PrivateMutation' }
  & {
    promoteParticipant?: Maybe<(
      { __typename: 'PromoteParticipantPayload' }
      & {
        participant?: Maybe<(
          { __typename: 'Participant' }
          & ParticipantFieldsFragment
        )>, errors: Array<(
          { __typename: 'PromoteParticipantErrorType' }
          & Pick<PromoteParticipantErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type RefreshTokenMutationVariables = Exact<{
  input: RefreshTokenInputType;
}>;


export type RefreshTokenMutation = (
  { __typename: 'PrivateMutation' }
  & {
    refreshToken?: Maybe<(
      { __typename: 'RefreshTokenPayload' }
      & Pick<RefreshTokenPayload, 'accessToken' | 'refreshToken'>
    )>
  }
);

export type RegisterDeviceMutationVariables = Exact<{
  input: RegisterDeviceInputType;
}>;


export type RegisterDeviceMutation = (
  { __typename: 'PrivateMutation' }
  & {
    registerDevice?: Maybe<(
      { __typename: 'RegisterDevicePayload' }
      & {
        device: (
          { __typename: 'Device' }
          & Pick<Device, 'id'>
        ), errors: Array<(
          { __typename: 'RegisterDeviceErrorType' }
          & Pick<RegisterDeviceErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type RejectAgreementMutationVariables = Exact<{
  input: RejectAgreementInputType;
}>;


export type RejectAgreementMutation = (
  { __typename: 'PrivateMutation' }
  & {
    rejectAgreement?: Maybe<(
      { __typename: 'RejectAgreementPayload' }
      & {
        agreement?: Maybe<(
          { __typename: 'Agreement' }
          & AgreementFieldsFragment
        )>, errors: Array<(
          { __typename: 'RejectAgreementErrorType' }
          & Pick<RejectAgreementErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type RejectExpenseMutationVariables = Exact<{
  input: RejectExpenseInputType;
}>;


export type RejectExpenseMutation = (
  { __typename: 'PrivateMutation' }
  & {
    rejectExpense: (
      { __typename: 'RejectExpensePayload' }
      & {
        errors: Array<(
          { __typename: 'RejectExpenseErrorType' }
          & Pick<RejectExpenseErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type RejectMaterialNoteMutationVariables = Exact<{
  input: RejectMaterialNoteInputType;
}>;


export type RejectMaterialNoteMutation = (
  { __typename: 'PrivateMutation' }
  & {
    rejectMaterialNote: (
      { __typename: 'RejectMaterialNotePayload' }
      & {
        errors: Array<(
          { __typename: 'RejectMaterialNoteErrorType' }
          & Pick<RejectMaterialNoteErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type RejectTimesheetMutationVariables = Exact<{
  input: RejectTimesheetInputType;
}>;


export type RejectTimesheetMutation = (
  { __typename: 'PrivateMutation' }
  & {
    rejectTimesheet: (
      { __typename: 'RejectTimesheetPayload' }
      & {
        errors: Array<(
          { __typename: 'RejectTimesheetErrorType' }
          & Pick<RejectTimesheetErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type ResetNotificationsMutationVariables = Exact<{
  input: ResetNotificationsInputType;
}>;


export type ResetNotificationsMutation = (
  { __typename: 'PrivateMutation' }
  & {
    resetNotifications?: Maybe<(
      { __typename: 'ResetNotificationsPayload' }
      & Pick<ResetNotificationsPayload, 'notificationCount'>
      & {
        errors: Array<(
          { __typename: 'ResetNotificationsErrorType' }
          & Pick<ResetNotificationsErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type SendFeedbackMutationVariables = Exact<{
  input: SendFeedbackInputType;
}>;


export type SendFeedbackMutation = (
  { __typename: 'PrivateMutation' }
  & {
    sendFeedback?: Maybe<(
      { __typename: 'SendFeedbackPayload' }
      & Pick<SendFeedbackPayload, 'message'>
      & {
        errors: Array<(
          { __typename: 'SendFeedbackErrorType' }
          & Pick<SendFeedbackErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type SetTypingMutationVariables = Exact<{
  input: SetTypingInputType;
}>;


export type SetTypingMutation = (
  { __typename: 'PrivateMutation' }
  & {
    setTyping: (
      { __typename: 'SetTypingPayload' }
      & {
        participant: (
          { __typename: 'Participant' }
          & Pick<Participant, 'id' | 'typingAt'>
        )
      }
    )
  }
);

export type SetPresenceMutationVariables = Exact<{
  input: SetPresenceInputType;
}>;


export type SetPresenceMutation = (
  { __typename: 'PrivateMutation' }
  & {
    setPresence: (
      { __typename: 'SetPresencePayload' }
      & {
        chat: (
          { __typename: 'GroupChat' }
          & Pick<GroupChat, 'id' | 'latestActivityAt' | 'notificationCount'>
        ) | (
          { __typename: 'PrivateChat' }
          & Pick<PrivateChat, 'id' | 'latestActivityAt' | 'notificationCount'>
        ), project: (
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'latestActivityAt' | 'notificationCount'>
        ), errors: Array<(
          { __typename: 'SetPresenceErrorType' }
          & Pick<SetPresenceErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type ShareInvitationMutationVariables = Exact<{
  input: ShareInvitationInputType;
}>;


export type ShareInvitationMutation = (
  { __typename: 'PrivateMutation' }
  & {
    shareInvitation?: Maybe<(
      { __typename: 'ShareInvitationPayload' }
      & {
        invitation?: Maybe<(
          { __typename: 'Invitation' }
          & Pick<Invitation, 'id'>
        )>, errors: Array<(
          { __typename: 'ShareInvitationErrorType' }
          & Pick<ShareInvitationErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type SubmitExpenseMutationVariables = Exact<{
  input: SubmitExpenseInputType;
}>;


export type SubmitExpenseMutation = (
  { __typename: 'PrivateMutation' }
  & {
    submitExpense: (
      { __typename: 'SubmitExpensePayload' }
      & {
        errors: Array<(
          { __typename: 'SubmitExpenseErrorType' }
          & Pick<SubmitExpenseErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type SubmitMaterialNoteMutationVariables = Exact<{
  input: SubmitMaterialNoteInputType;
}>;


export type SubmitMaterialNoteMutation = (
  { __typename: 'PrivateMutation' }
  & {
    submitMaterialNote: (
      { __typename: 'SubmitMaterialNotePayload' }
      & {
        errors: Array<(
          { __typename: 'SubmitMaterialNoteErrorType' }
          & Pick<SubmitMaterialNoteErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type SubmitTimesheetMutationVariables = Exact<{
  input: SubmitTimesheetInputType;
}>;


export type SubmitTimesheetMutation = (
  { __typename: 'PrivateMutation' }
  & {
    submitTimesheet: (
      { __typename: 'SubmitTimesheetPayload' }
      & {
        errors: Array<(
          { __typename: 'SubmitTimesheetErrorType' }
          & Pick<SubmitTimesheetErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type UnarchiveGroupChatMutationVariables = Exact<{
  input: UnarchiveGroupChatInputType;
}>;


export type UnarchiveGroupChatMutation = (
  { __typename: 'PrivateMutation' }
  & {
    unarchiveGroupChat: (
      { __typename: 'UnarchiveGroupChatPayload' }
      & {
        groupChat?: Maybe<(
          { __typename: 'GroupChat' }
          & ChatFields_GroupChat_Fragment
        )>, errors: Array<(
          { __typename: 'UnarchiveGroupChatErrorType' }
          & Pick<UnarchiveGroupChatErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type UnarchiveProjectMutationVariables = Exact<{
  input: UnarchiveProjectInputType;
}>;


export type UnarchiveProjectMutation = (
  { __typename: 'PrivateMutation' }
  & {
    unarchiveProject?: Maybe<(
      { __typename: 'UnarchiveProjectPayload' }
      & {
        project?: Maybe<(
          { __typename: 'Project' }
          & {
            members: Array<(
              { __typename: 'Member' }
              & MemberFieldsFragment
            )>, chats: Array<(
              { __typename: 'GroupChat' }
              & ChatFields_GroupChat_Fragment
            ) | (
                { __typename: 'PrivateChat' }
                & ChatFields_PrivateChat_Fragment
              )>, agreements: Array<(
                { __typename: 'Agreement' }
                & AgreementFieldsFragment
              )>
          }
          & ProjectFieldsFragment
        )>, errors: Array<(
          { __typename: 'UnarchiveProjectErrorType' }
          & Pick<UnarchiveProjectErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type UnsubscribeEmailMutationVariables = Exact<{
  input: UnsubscribeEmailInputType;
}>;


export type UnsubscribeEmailMutation = (
  { __typename: 'PrivateMutation' }
  & {
    unsubscribeEmail?: Maybe<(
      { __typename: 'UnsubscribeEmailPayload' }
      & {
        errors: Array<(
          { __typename: 'UnsubscribeEmailErrorType' }
          & Pick<UnsubscribeEmailErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type UpdateActivityMutationVariables = Exact<{
  input: UpdateActivityInputType;
}>;


export type UpdateActivityMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateActivity: (
      { __typename: 'UpdateActivityPayload' }
      & {
        activity?: Maybe<(
          { __typename: 'Activity' }
          & {
            assignedMembers?: Maybe<Array<(
              { __typename: 'Member' }
              & MemberFieldsFragment
            )>>
          }
          & ActivityFieldsFragment
        )>, errors: Array<(
          { __typename: 'UpdateActivityErrorType' }
          & Pick<UpdateActivityErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type UpdateAgreementMutationVariables = Exact<{
  input: UpdateAgreementInputType;
}>;


export type UpdateAgreementMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateAgreement: (
      { __typename: 'UpdateAgreementPayload' }
      & {
        agreement?: Maybe<(
          { __typename: 'Agreement' }
          & {
            media: Array<(
              { __typename: 'Folder' }
              & MediaFields_Folder_Fragment
            ) | (
                { __typename: 'Document' }
                & MediaFields_Document_Fragment
              ) | (
                { __typename: 'Image' }
                & MediaFields_Image_Fragment
              ) | (
                { __typename: 'Video' }
                & MediaFields_Video_Fragment
              )>
          }
          & AgreementFieldsFragment
        )>, errors: Array<(
          { __typename: 'UpdateAgreementErrorType' }
          & Pick<UpdateAgreementErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInputType;
}>;


export type UpdateCompanyMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateCompany?: Maybe<(
      { __typename: 'UpdateCompanyPayload' }
      & {
        company?: Maybe<(
          { __typename: 'Company' }
          & CompanyFieldsFragment
        )>, errors: Array<(
          { __typename: 'UpdateCompanyErrorType' }
          & Pick<UpdateCompanyErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type UpdateExpenseMutationVariables = Exact<{
  input: UpdateExpenseInputType;
}>;


export type UpdateExpenseMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateExpense: (
      { __typename: 'UpdateExpensePayload' }
      & {
        errors: Array<(
          { __typename: 'UpdateExpenseErrorType' }
          & Pick<UpdateExpenseErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type UpdateFolderMutationVariables = Exact<{
  input: UpdateFolderInputType;
}>;


export type UpdateFolderMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateFolder?: Maybe<(
      { __typename: 'UpdateFolderPayload' }
      & {
        folder?: Maybe<(
          { __typename: 'Folder' }
          & MediaFields_Folder_Fragment
        )>, errors: Array<(
          { __typename: 'UpdateFolderErrorType' }
          & Pick<UpdateFolderErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type UpdateGroupChatMutationVariables = Exact<{
  input: UpdateGroupChatInputType;
}>;


export type UpdateGroupChatMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateGroupChat: (
      { __typename: 'UpdateGroupChatPayload' }
      & {
        groupChat?: Maybe<(
          { __typename: 'GroupChat' }
          & ChatFields_GroupChat_Fragment
        )>, errors: Array<(
          { __typename: 'UpdateGroupChatErrorType' }
          & Pick<UpdateGroupChatErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type UpdateMaterialNoteItemMutationVariables = Exact<{
  input: UpdateMaterialNoteItemInputType;
}>;


export type UpdateMaterialNoteItemMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateMaterialNoteItem: (
      { __typename: 'UpdateMaterialNoteItemPayload' }
      & {
        materialNoteItem?: Maybe<(
          { __typename: 'MaterialNoteItem' }
          & Pick<MaterialNoteItem, 'id' | 'materialNoteId'>
        )>, errors: Array<(
          { __typename: 'UpdateMaterialNoteItemErrorType' }
          & Pick<UpdateMaterialNoteItemErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type UpdateMemberMutationVariables = Exact<{
  input: UpdateMemberInputType;
}>;


export type UpdateMemberMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateMember?: Maybe<(
      { __typename: 'UpdateMemberPayload' }
      & {
        errors: Array<(
          { __typename: 'UpdateMemberErrorType' }
          & Pick<UpdateMemberErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type UpdatePasswordMutationVariables = Exact<{
  input: UpdatePasswordInputType;
}>;


export type UpdatePasswordMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updatePassword?: Maybe<(
      { __typename: 'UpdatePasswordPayload' }
      & {
        user?: Maybe<(
          { __typename: 'User' }
          & UserFieldsFragment
        )>, errors: Array<(
          { __typename: 'UpdatePasswordErrorType' }
          & Pick<UpdatePasswordErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInputType;
}>;


export type UpdateProjectMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateProject?: Maybe<(
      { __typename: 'UpdateProjectPayload' }
      & {
        project?: Maybe<(
          { __typename: 'Project' }
          & ProjectFieldsFragment
        )>, errors: Array<(
          { __typename: 'UpdateProjectErrorType' }
          & Pick<UpdateProjectErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type UpdateTimesheetMutationVariables = Exact<{
  input: UpdateTimesheetInputType;
}>;


export type UpdateTimesheetMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateTimesheet: (
      { __typename: 'UpdateTimesheetPayload' }
      & {
        errors: Array<(
          { __typename: 'UpdateTimesheetErrorType' }
          & Pick<UpdateTimesheetErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type UpdateTodoMutationVariables = Exact<{
  input: UpdateTodoInputType;
}>;


export type UpdateTodoMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateTodo: (
      { __typename: 'UpdateTodoPayload' }
      & {
        todo?: Maybe<(
          { __typename: 'Todo' }
          & {
            assignedMembers?: Maybe<Array<(
              { __typename: 'Member' }
              & MemberFieldsFragment
            )>>
          }
          & TodoFieldsFragment
        )>, errors: Array<(
          { __typename: 'UpdateTodoErrorType' }
          & Pick<UpdateTodoErrorType, 'argument' | 'error'>
        )>
      }
    )
  }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInputType;
}>;


export type UpdateUserMutation = (
  { __typename: 'PrivateMutation' }
  & {
    updateUser?: Maybe<(
      { __typename: 'UpdateUserPayload' }
      & {
        user?: Maybe<(
          { __typename: 'User' }
          & UserFieldsFragment
        )>, errors: Array<(
          { __typename: 'UpdateUserErrorType' }
          & Pick<UpdateUserErrorType, 'argument' | 'error'>
        )>
      }
    )>
  }
);

export type ActivitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type ActivitiesQuery = (
  { __typename: 'PrivateQuery' }
  & {
    activities?: Maybe<Array<(
      { __typename: 'Activity' }
      & {
        createdByMember: (
          { __typename: 'Member' }
          & MemberFieldsFragment
        ), assignedMembers?: Maybe<Array<(
          { __typename: 'Member' }
          & MemberFieldsFragment
        )>>, project: (
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'description' | 'nameOrAddress'>
          & {
            policy?: Maybe<(
              { __typename: 'ProjectPolicy' }
              & Pick<ProjectPolicy, 'createActivity' | 'orderActivities'>
            )>
          }
        )
      }
      & ActivityFieldsFragment
    )>>
  }
);

export type ActivityQueryVariables = Exact<{
  activityId: Scalars['ID'];
}>;


export type ActivityQuery = (
  { __typename: 'PrivateQuery' }
  & {
    activity?: Maybe<(
      { __typename: 'Activity' }
      & {
        createdByMember: (
          { __typename: 'Member' }
          & MemberFieldsFragment
        ), assignedMembers?: Maybe<Array<(
          { __typename: 'Member' }
          & MemberFieldsFragment
        )>>
      }
      & ActivityFieldsFragment
    )>
  }
);

export type AgreementQueryVariables = Exact<{
  agreementId: Scalars['ID'];
}>;


export type AgreementQuery = (
  { __typename: 'PrivateQuery' }
  & {
    agreement?: Maybe<(
      { __typename: 'Agreement' }
      & {
        createdByMember: (
          { __typename: 'Member' }
          & MemberFieldsFragment
        ), counterpartyMember: (
          { __typename: 'Member' }
          & MemberFieldsFragment
        ), media: Array<(
          { __typename: 'Folder' }
          & MediaFields_Folder_Fragment
        ) | (
            { __typename: 'Document' }
            & MediaFields_Document_Fragment
          ) | (
            { __typename: 'Image' }
            & MediaFields_Image_Fragment
          ) | (
            { __typename: 'Video' }
            & MediaFields_Video_Fragment
          )>
      }
      & AgreementFieldsFragment
    )>
  }
);

export type ChatEventsQueryVariables = Exact<{
  chatId: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
}>;


export type ChatEventsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    chat?: Maybe<(
      { __typename: 'GroupChat' }
      & Pick<GroupChat, 'id'>
      & {
        events: (
          { __typename: 'EventConnection' }
          & {
            pageInfo: (
              { __typename: 'PageInfo' }
              & Pick<PageInfo, 'hasPreviousPage'>
            ), edges?: Maybe<Array<Maybe<(
              { __typename: 'EventEdge' }
              & Pick<EventEdge, 'cursor'>
              & {
                node?: Maybe<(
                  { __typename: 'MediaMessage' }
                  & {
                    user: (
                      { __typename: 'User' }
                      & UserFieldsFragment
                    )
                  }
                  & EventFields_MediaMessage_Fragment
                ) | (
                    { __typename: 'Message' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & EventFields_Message_Fragment
                  ) | (
                    { __typename: 'ReplyMessage' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & EventFields_ReplyMessage_Fragment
                  ) | (
                    { __typename: 'ActivityMessage' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & EventFields_ActivityMessage_Fragment
                  ) | (
                    { __typename: 'AgreementMessage' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & EventFields_AgreementMessage_Fragment
                  ) | (
                    { __typename: 'DeletedEvent' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & EventFields_DeletedEvent_Fragment
                  ) | (
                    { __typename: 'ExpenseMessage' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & EventFields_ExpenseMessage_Fragment
                  ) | (
                    { __typename: 'MaterialNoteMessage' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & EventFields_MaterialNoteMessage_Fragment
                  ) | (
                    { __typename: 'SystemMessage' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & EventFields_SystemMessage_Fragment
                  ) | (
                    { __typename: 'TimesheetMessage' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & EventFields_TimesheetMessage_Fragment
                  )>
              }
            )>>>
          }
        )
      }
    ) | (
        { __typename: 'PrivateChat' }
        & Pick<PrivateChat, 'id'>
        & {
          events: (
            { __typename: 'EventConnection' }
            & {
              pageInfo: (
                { __typename: 'PageInfo' }
                & Pick<PageInfo, 'hasPreviousPage'>
              ), edges?: Maybe<Array<Maybe<(
                { __typename: 'EventEdge' }
                & Pick<EventEdge, 'cursor'>
                & {
                  node?: Maybe<(
                    { __typename: 'MediaMessage' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & EventFields_MediaMessage_Fragment
                  ) | (
                      { __typename: 'Message' }
                      & {
                        user: (
                          { __typename: 'User' }
                          & UserFieldsFragment
                        )
                      }
                      & EventFields_Message_Fragment
                    ) | (
                      { __typename: 'ReplyMessage' }
                      & {
                        user: (
                          { __typename: 'User' }
                          & UserFieldsFragment
                        )
                      }
                      & EventFields_ReplyMessage_Fragment
                    ) | (
                      { __typename: 'ActivityMessage' }
                      & {
                        user: (
                          { __typename: 'User' }
                          & UserFieldsFragment
                        )
                      }
                      & EventFields_ActivityMessage_Fragment
                    ) | (
                      { __typename: 'AgreementMessage' }
                      & {
                        user: (
                          { __typename: 'User' }
                          & UserFieldsFragment
                        )
                      }
                      & EventFields_AgreementMessage_Fragment
                    ) | (
                      { __typename: 'DeletedEvent' }
                      & {
                        user: (
                          { __typename: 'User' }
                          & UserFieldsFragment
                        )
                      }
                      & EventFields_DeletedEvent_Fragment
                    ) | (
                      { __typename: 'ExpenseMessage' }
                      & {
                        user: (
                          { __typename: 'User' }
                          & UserFieldsFragment
                        )
                      }
                      & EventFields_ExpenseMessage_Fragment
                    ) | (
                      { __typename: 'MaterialNoteMessage' }
                      & {
                        user: (
                          { __typename: 'User' }
                          & UserFieldsFragment
                        )
                      }
                      & EventFields_MaterialNoteMessage_Fragment
                    ) | (
                      { __typename: 'SystemMessage' }
                      & {
                        user: (
                          { __typename: 'User' }
                          & UserFieldsFragment
                        )
                      }
                      & EventFields_SystemMessage_Fragment
                    ) | (
                      { __typename: 'TimesheetMessage' }
                      & {
                        user: (
                          { __typename: 'User' }
                          & UserFieldsFragment
                        )
                      }
                      & EventFields_TimesheetMessage_Fragment
                    )>
                }
              )>>>
            }
          )
        }
      )>
  }
);

export type ChatQueryVariables = Exact<{
  chatId: Scalars['ID'];
}>;


export type ChatQuery = (
  { __typename: 'PrivateQuery' }
  & {
    chat?: Maybe<(
      { __typename: 'GroupChat' }
      & {
        participants: Array<(
          { __typename: 'Participant' }
          & {
            member: (
              { __typename: 'Member' }
              & MemberFieldsFragment
            )
          }
          & ParticipantFieldsFragment
        )>
      }
      & ChatFields_GroupChat_Fragment
    ) | (
        { __typename: 'PrivateChat' }
        & {
          participants: Array<(
            { __typename: 'Participant' }
            & {
              member: (
                { __typename: 'Member' }
                & MemberFieldsFragment
              )
            }
            & ParticipantFieldsFragment
          )>
        }
        & ChatFields_PrivateChat_Fragment
      )>
  }
);

export type CompanyTeamMemberSuggestionsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyTeamMemberSuggestionsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    currentUser?: Maybe<(
      { __typename: 'User' }
      & {
        policy?: Maybe<(
          { __typename: 'UserPolicy' }
          & Pick<UserPolicy, 'createProject' | 'createDemoProject' | 'createTender' | 'createAddressValuation' | 'setupCompanyService' | 'dashboard' | 'addTeam' | 'das' | 'tutorialSlider'>
        )>, company?: Maybe<(
          { __typename: 'Company' }
          & {
            teamMemberSuggestions: Array<(
              { __typename: 'User' }
              & UserFieldsFragment
            )>
          }
          & CompanyFieldsFragment
        )>
      }
      & UserFieldsFragment
    )>
  }
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename: 'PrivateQuery' }
  & {
    currentUser?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'subscriptionType' | 'subscriptionPeriod' | 'trialSubscriptionEndsAt'>
      & {
        company?: Maybe<(
          { __typename: 'Company' }
          & CompanyFieldsFragment
        )>, policy?: Maybe<(
          { __typename: 'UserPolicy' }
          & Pick<UserPolicy, 'createProject' | 'createDemoProject' | 'createTender' | 'createAddressValuation' | 'setupCompanyService' | 'addTeam' | 'dashboard' | 'das' | 'tutorialSlider' | 'onboarding' | 'onboardingActivity' | 'onboardingInviteMember' | 'webAccess' | 'nudgePro' | 'createProjectLimitReached' | 'trialExpired' | 'trial'>
        )>
      }
      & UserFieldsFragment
    )>
  }
);

export type ExpenseQueryVariables = Exact<{
  expenseId: Scalars['ID'];
}>;


export type ExpenseQuery = (
  { __typename: 'PrivateQuery' }
  & {
    expense?: Maybe<(
      { __typename: 'Expense' }
      & {
        media: Array<(
          { __typename: 'Folder' }
          & MediaFields_Folder_Fragment
        ) | (
            { __typename: 'Document' }
            & MediaFields_Document_Fragment
          ) | (
            { __typename: 'Image' }
            & MediaFields_Image_Fragment
          ) | (
            { __typename: 'Video' }
            & MediaFields_Video_Fragment
          )>
      }
      & ExpenseFieldsFragment
    )>
  }
);

export type InvitationByTokenQueryVariables = Exact<{
  invitationToken: Scalars['String'];
}>;


export type InvitationByTokenQuery = (
  { __typename: 'PrivateQuery' }
  & {
    invitationByToken?: Maybe<(
      { __typename: 'Invitation' }
      & InvitationFieldsFragment
    )>
  }
);

export type InvitationQueryVariables = Exact<{
  invitationId: Scalars['ID'];
}>;


export type InvitationQuery = (
  { __typename: 'PrivateQuery' }
  & {
    invitation?: Maybe<(
      { __typename: 'Invitation' }
      & InvitationFieldsFragment
    )>
  }
);

export type InvitationsQueryVariables = Exact<{ [key: string]: never; }>;


export type InvitationsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    invitations?: Maybe<Array<(
      { __typename: 'Invitation' }
      & InvitationFieldsFragment
    )>>
  }
);

export type MaterialNoteItemQueryVariables = Exact<{
  materialNoteItemId: Scalars['ID'];
}>;


export type MaterialNoteItemQuery = (
  { __typename: 'PrivateQuery' }
  & {
    materialNoteItem?: Maybe<(
      { __typename: 'MaterialNoteItem' }
      & MaterialNoteItemFieldsFragment
    )>
  }
);

export type MaterialNoteQueryVariables = Exact<{
  materialNoteId: Scalars['ID'];
}>;


export type MaterialNoteQuery = (
  { __typename: 'PrivateQuery' }
  & {
    materialNote?: Maybe<(
      { __typename: 'MaterialNote' }
      & {
        materialNoteItems: Array<(
          { __typename: 'MaterialNoteItem' }
          & MaterialNoteItemFieldsFragment
        )>
      }
      & MaterialNoteFieldsFragment
    )>
  }
);

export type MediaQueryVariables = Exact<{
  mediaId?: Maybe<Scalars['ID']>;
  projectId?: Maybe<Scalars['ID']>;
  path?: Maybe<Scalars['String']>;
}>;


export type MediaQuery = (
  { __typename: 'PrivateQuery' }
  & {
    media?: Maybe<(
      { __typename: 'Folder' }
      & MediaFields_Folder_Fragment
    ) | (
        { __typename: 'Document' }
        & MediaFields_Document_Fragment
      ) | (
        { __typename: 'Image' }
        & MediaFields_Image_Fragment
      ) | (
        { __typename: 'Video' }
        & MediaFields_Video_Fragment
      )>
  }
);

export type MemberQueryVariables = Exact<{
  memberId: Scalars['ID'];
}>;


export type MemberQuery = (
  { __typename: 'PrivateQuery' }
  & {
    member?: Maybe<(
      { __typename: 'Member' }
      & {
        company?: Maybe<(
          { __typename: 'Company' }
          & CompanyFieldsFragment
        )>
      }
      & MemberFieldsFragment
    )>
  }
);

export type OpenAgreementsQueryVariables = Exact<{ [key: string]: never; }>;


export type OpenAgreementsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    openAgreements?: Maybe<Array<(
      { __typename: 'Agreement' }
      & AgreementFieldsFragment
    )>>
  }
);

export type OpenTodosQueryVariables = Exact<{ [key: string]: never; }>;


export type OpenTodosQuery = (
  { __typename: 'PrivateQuery' }
  & {
    openTodos?: Maybe<Array<(
      { __typename: 'Todo' }
      & TodoFieldsFragment
    )>>
  }
);

export type ProjectActivitiesQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectActivitiesQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        policy?: Maybe<(
          { __typename: 'ProjectPolicy' }
          & Pick<ProjectPolicy, 'createActivity' | 'orderActivities' | 'pdfActivities'>
        )>, activities: Array<(
          { __typename: 'Activity' }
          & {
            createdByMember: (
              { __typename: 'Member' }
              & MemberFieldsFragment
            ), assignedMembers?: Maybe<Array<(
              { __typename: 'Member' }
              & MemberFieldsFragment
            )>>
          }
          & ActivityFieldsFragment
        )>
      }
    )>
  }
);

export type ProjectAgreementsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectAgreementsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        policy?: Maybe<(
          { __typename: 'ProjectPolicy' }
          & Pick<ProjectPolicy, 'createAgreement'>
        )>, agreements: Array<(
          { __typename: 'Agreement' }
          & {
            createdByMember: (
              { __typename: 'Member' }
              & Pick<Member, 'id' | 'name' | 'current'>
            ), counterpartyMember: (
              { __typename: 'Member' }
              & Pick<Member, 'id' | 'name' | 'current'>
            )
          }
          & AgreementFieldsFragment
        )>
      }
    )>
  }
);

export type ProjectChatsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectChatsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        policy?: Maybe<(
          { __typename: 'ProjectPolicy' }
          & Pick<ProjectPolicy, 'createPrivateChat' | 'createGroupChat'>
        )>, chats: Array<(
          { __typename: 'GroupChat' }
          & {
            participants: Array<(
              { __typename: 'Participant' }
              & {
                member: (
                  { __typename: 'Member' }
                  & MemberFieldsFragment
                )
              }
              & ParticipantFieldsFragment
            )>, events: (
              { __typename: 'EventConnection' }
              & {
                pageInfo: (
                  { __typename: 'PageInfo' }
                  & Pick<PageInfo, 'hasPreviousPage'>
                ), edges?: Maybe<Array<Maybe<(
                  { __typename: 'EventEdge' }
                  & Pick<EventEdge, 'cursor'>
                  & {
                    node?: Maybe<(
                      { __typename: 'MediaMessage' }
                      & {
                        user: (
                          { __typename: 'User' }
                          & UserFieldsFragment
                        )
                      }
                      & EventFields_MediaMessage_Fragment
                    ) | (
                        { __typename: 'Message' }
                        & {
                          user: (
                            { __typename: 'User' }
                            & UserFieldsFragment
                          )
                        }
                        & EventFields_Message_Fragment
                      ) | (
                        { __typename: 'ReplyMessage' }
                        & {
                          user: (
                            { __typename: 'User' }
                            & UserFieldsFragment
                          )
                        }
                        & EventFields_ReplyMessage_Fragment
                      ) | (
                        { __typename: 'ActivityMessage' }
                        & {
                          user: (
                            { __typename: 'User' }
                            & UserFieldsFragment
                          )
                        }
                        & EventFields_ActivityMessage_Fragment
                      ) | (
                        { __typename: 'AgreementMessage' }
                        & {
                          user: (
                            { __typename: 'User' }
                            & UserFieldsFragment
                          )
                        }
                        & EventFields_AgreementMessage_Fragment
                      ) | (
                        { __typename: 'DeletedEvent' }
                        & {
                          user: (
                            { __typename: 'User' }
                            & UserFieldsFragment
                          )
                        }
                        & EventFields_DeletedEvent_Fragment
                      ) | (
                        { __typename: 'ExpenseMessage' }
                        & {
                          user: (
                            { __typename: 'User' }
                            & UserFieldsFragment
                          )
                        }
                        & EventFields_ExpenseMessage_Fragment
                      ) | (
                        { __typename: 'MaterialNoteMessage' }
                        & {
                          user: (
                            { __typename: 'User' }
                            & UserFieldsFragment
                          )
                        }
                        & EventFields_MaterialNoteMessage_Fragment
                      ) | (
                        { __typename: 'SystemMessage' }
                        & {
                          user: (
                            { __typename: 'User' }
                            & UserFieldsFragment
                          )
                        }
                        & EventFields_SystemMessage_Fragment
                      ) | (
                        { __typename: 'TimesheetMessage' }
                        & {
                          user: (
                            { __typename: 'User' }
                            & UserFieldsFragment
                          )
                        }
                        & EventFields_TimesheetMessage_Fragment
                      )>
                  }
                )>>>
              }
            )
          }
          & ChatFields_GroupChat_Fragment
        ) | (
            { __typename: 'PrivateChat' }
            & {
              participants: Array<(
                { __typename: 'Participant' }
                & {
                  member: (
                    { __typename: 'Member' }
                    & MemberFieldsFragment
                  )
                }
                & ParticipantFieldsFragment
              )>, events: (
                { __typename: 'EventConnection' }
                & {
                  pageInfo: (
                    { __typename: 'PageInfo' }
                    & Pick<PageInfo, 'hasPreviousPage'>
                  ), edges?: Maybe<Array<Maybe<(
                    { __typename: 'EventEdge' }
                    & Pick<EventEdge, 'cursor'>
                    & {
                      node?: Maybe<(
                        { __typename: 'MediaMessage' }
                        & {
                          user: (
                            { __typename: 'User' }
                            & UserFieldsFragment
                          )
                        }
                        & EventFields_MediaMessage_Fragment
                      ) | (
                          { __typename: 'Message' }
                          & {
                            user: (
                              { __typename: 'User' }
                              & UserFieldsFragment
                            )
                          }
                          & EventFields_Message_Fragment
                        ) | (
                          { __typename: 'ReplyMessage' }
                          & {
                            user: (
                              { __typename: 'User' }
                              & UserFieldsFragment
                            )
                          }
                          & EventFields_ReplyMessage_Fragment
                        ) | (
                          { __typename: 'ActivityMessage' }
                          & {
                            user: (
                              { __typename: 'User' }
                              & UserFieldsFragment
                            )
                          }
                          & EventFields_ActivityMessage_Fragment
                        ) | (
                          { __typename: 'AgreementMessage' }
                          & {
                            user: (
                              { __typename: 'User' }
                              & UserFieldsFragment
                            )
                          }
                          & EventFields_AgreementMessage_Fragment
                        ) | (
                          { __typename: 'DeletedEvent' }
                          & {
                            user: (
                              { __typename: 'User' }
                              & UserFieldsFragment
                            )
                          }
                          & EventFields_DeletedEvent_Fragment
                        ) | (
                          { __typename: 'ExpenseMessage' }
                          & {
                            user: (
                              { __typename: 'User' }
                              & UserFieldsFragment
                            )
                          }
                          & EventFields_ExpenseMessage_Fragment
                        ) | (
                          { __typename: 'MaterialNoteMessage' }
                          & {
                            user: (
                              { __typename: 'User' }
                              & UserFieldsFragment
                            )
                          }
                          & EventFields_MaterialNoteMessage_Fragment
                        ) | (
                          { __typename: 'SystemMessage' }
                          & {
                            user: (
                              { __typename: 'User' }
                              & UserFieldsFragment
                            )
                          }
                          & EventFields_SystemMessage_Fragment
                        ) | (
                          { __typename: 'TimesheetMessage' }
                          & {
                            user: (
                              { __typename: 'User' }
                              & UserFieldsFragment
                            )
                          }
                          & EventFields_TimesheetMessage_Fragment
                        )>
                    }
                  )>>>
                }
              )
            }
            & ChatFields_PrivateChat_Fragment
          )>
      }
    )>
  }
);

export type ProjectCostsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectCostsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        policy?: Maybe<(
          { __typename: 'ProjectPolicy' }
          & Pick<ProjectPolicy, 'createExpense' | 'manageExpenses' | 'createMaterialNote' | 'manageMaterialNotes'>
        )>, expenses: Array<(
          { __typename: 'Expense' }
          & ExpenseFieldsFragment
        )>, materialNotes: Array<(
          { __typename: 'MaterialNote' }
          & {
            materialNoteItems: Array<(
              { __typename: 'MaterialNoteItem' }
              & MaterialNoteItemFieldsFragment
            )>
          }
          & MaterialNoteFieldsFragment
        )>
      }
    )>
  }
);

export type ProjectCurrentMemberTimesheetsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectCurrentMemberTimesheetsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        currentMemberTimesheets: Array<(
          { __typename: 'Timesheet' }
          & TimesheetFieldsFragment
        )>
      }
    )>
  }
);

export type ProjectExpensesQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectExpensesQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        policy?: Maybe<(
          { __typename: 'ProjectPolicy' }
          & Pick<ProjectPolicy, 'createExpense' | 'manageExpenses' | 'createMaterialNote' | 'manageMaterialNotes'>
        )>, expenses: Array<(
          { __typename: 'Expense' }
          & ExpenseFieldsFragment
        )>
      }
    )>
  }
);

export type ProjectInvitationSuggestionUsersQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectInvitationSuggestionUsersQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        invitationSuggestions: Array<(
          { __typename: 'User' }
          & {
            company?: Maybe<(
              { __typename: 'Company' }
              & Pick<Company, 'name'>
            )>
          }
          & UserFieldsFragment
        )>
      }
    )>
  }
);

export type ProjectMaterialNotesQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectMaterialNotesQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        policy?: Maybe<(
          { __typename: 'ProjectPolicy' }
          & Pick<ProjectPolicy, 'createMaterialNote' | 'manageMaterialNotes'>
        )>, materialNotes: Array<(
          { __typename: 'MaterialNote' }
          & {
            materialNoteItems: Array<(
              { __typename: 'MaterialNoteItem' }
              & MaterialNoteItemFieldsFragment
            )>
          }
          & MaterialNoteFieldsFragment
        )>
      }
    )>
  }
);

export type ProjectMediaMoveFolderQueryVariables = Exact<{
  projectId: Scalars['ID'];
  type?: Maybe<MediaType>;
  after?: Maybe<Scalars['String']>;
  perPage?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
}>;


export type ProjectMediaMoveFolderQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        policy?: Maybe<(
          { __typename: 'ProjectPolicy' }
          & Pick<ProjectPolicy, 'createMedia'>
        )>, media: (
          { __typename: 'MediaConnection' }
          & {
            pageInfo: (
              { __typename: 'PageInfo' }
              & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
            ), edges?: Maybe<Array<Maybe<(
              { __typename: 'MediaEdge' }
              & Pick<MediaEdge, 'cursor'>
              & {
                node?: Maybe<(
                  { __typename: 'Folder' }
                  & {
                    user: (
                      { __typename: 'User' }
                      & UserFieldsFragment
                    )
                  }
                  & MediaFields_Folder_Fragment
                ) | (
                    { __typename: 'Document' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & MediaFields_Document_Fragment
                  ) | (
                    { __typename: 'Image' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & MediaFields_Image_Fragment
                  ) | (
                    { __typename: 'Video' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & MediaFields_Video_Fragment
                  )>
              }
            )>>>
          }
        )
      }
    )>
  }
);

export type ProjectMediaQueryVariables = Exact<{
  projectId: Scalars['ID'];
  type?: Maybe<MediaType>;
  after?: Maybe<Scalars['String']>;
  perPage?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
}>;


export type ProjectMediaQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        policy?: Maybe<(
          { __typename: 'ProjectPolicy' }
          & Pick<ProjectPolicy, 'createMedia'>
        )>, media: (
          { __typename: 'MediaConnection' }
          & {
            pageInfo: (
              { __typename: 'PageInfo' }
              & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
            ), edges?: Maybe<Array<Maybe<(
              { __typename: 'MediaEdge' }
              & Pick<MediaEdge, 'cursor'>
              & {
                node?: Maybe<(
                  { __typename: 'Folder' }
                  & {
                    user: (
                      { __typename: 'User' }
                      & UserFieldsFragment
                    )
                  }
                  & MediaFields_Folder_Fragment
                ) | (
                    { __typename: 'Document' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & MediaFields_Document_Fragment
                  ) | (
                    { __typename: 'Image' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & MediaFields_Image_Fragment
                  ) | (
                    { __typename: 'Video' }
                    & {
                      user: (
                        { __typename: 'User' }
                        & UserFieldsFragment
                      )
                    }
                    & MediaFields_Video_Fragment
                  )>
              }
            )>>>
          }
        )
      }
    )>
  }
);

export type ProjectTimesheetsQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectTimesheetsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        policy?: Maybe<(
          { __typename: 'ProjectPolicy' }
          & Pick<ProjectPolicy, 'updateTimesheet' | 'manageTimesheets'>
        )>, timesheets: Array<(
          { __typename: 'Timesheet' }
          & TimesheetFieldsFragment
        )>
      }
    )>
  }
);

export type ProjectTodosQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectTodosQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        policy?: Maybe<(
          { __typename: 'ProjectPolicy' }
          & Pick<ProjectPolicy, 'createTodo' | 'orderTodos'>
        )>, todos: Array<(
          { __typename: 'Todo' }
          & {
            createdByMember: (
              { __typename: 'Member' }
              & MemberFieldsFragment
            ), assignedMembers?: Maybe<Array<(
              { __typename: 'Member' }
              & MemberFieldsFragment
            )>>
          }
          & TodoFieldsFragment
        )>
      }
    )>
  }
);

export type ProjectQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type WkbTemplatesQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;

export type WkbQuestionsQueryVariables = Exact<{
  projectId: Scalars['ID'];
  templateIds?: Maybe<Array<Scalars['ID']>>;
}>;


export type ProjectQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & ProjectFieldsFragment
    )>
  }
);

export type WkbTemplatesQuery = (
  { __typename: 'PrivateQuery' }
  & {
    wkbTemplates?: Maybe<(
      { __typename: 'wkbTemplates' }
      & WkbTemplatesFieldsFragment
    )>
  }
);

export type WkbQuestionsCompletedQuery = (
  { __typename: 'PrivateQuery' }
  & {
    wkbQuestionsCompleted?: Maybe<(
      { __typename: 'wkbQuestionsCompleted' }
      & WkbQuestionsCompletedFieldsFragment
    )>
  }
);

export type WkbQuestionsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    wkbQuestions?: Maybe<(
      { __typename: 'wkbQuestions' }
      & Pick<WkbQuestion, 'id' | 'name' | 'createdAt' | 'updatedAt'>
      & {
        answer?: Maybe<(
          { __typename: 'Anwer' }
          & Pick<WkbAnswer, 'id' | 'comment' | 'createdAt' | 'updatedAt'>
        )>
      }
      & WkbQuestionsFieldsFragment
    )>
  }
);

export type ProjectMembersQueryVariables = Exact<{
  projectId: Scalars['ID'];
}>;


export type ProjectMembersQuery = (
  { __typename: 'PrivateQuery' }
  & {
    project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & {
        policy?: Maybe<(
          { __typename: 'ProjectPolicy' }
          & Pick<ProjectPolicy, 'inviteMember' | 'inviteUser'>
        )>, members: Array<(
          { __typename: 'Member' }
          & {
            company?: Maybe<(
              { __typename: 'Company' }
              & CompanyFieldsFragment
            )>
          }
          & MemberFieldsFragment
        )>
      }
    )>
  }
);

export type ProjectsWithMembersQueryVariables = Exact<{
  pageNumber: Maybe<Scalars['Int']>;
  activeProject: Maybe<Scalars['String']>;
  searchText: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  offset: Maybe<Scalars['Int']>;
}>;


export type ProjectsWithMembersQuery = (
  { __typename: 'PrivateQuery' }
  & {
    projects?: Maybe<Array<(
      { __typename: 'Project' }
      & {
        members: Array<(
          { __typename: 'Member' }
          & MemberFieldsFragment
        )>
      }
      & ProjectFieldsFragment
    )>>
  }
);

export type ProjectsQueryVariables = Exact<{
  pageNumber: Maybe<Scalars['Int']>;
  activeProject: Maybe<Scalars['String']>;
  offset: Maybe<Scalars['Int']>;
  limit: Maybe<Scalars['Int']>;
}>;

export type ProjectsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    projects?: Maybe<Array<(
      { __typename: 'Project' }
      & ProjectFieldsFragment
    )>>
  }
);

export type SubmittedExpensesQueryVariables = Exact<{ [key: string]: never; }>;


export type SubmittedExpensesQuery = (
  { __typename: 'PrivateQuery' }
  & {
    submittedExpenses?: Maybe<Array<(
      { __typename: 'Expense' }
      & ExpenseFieldsFragment
    )>>
  }
);

export type SubmittedMaterialNotesQueryVariables = Exact<{ [key: string]: never; }>;


export type SubmittedMaterialNotesQuery = (
  { __typename: 'PrivateQuery' }
  & {
    submittedMaterialNotes?: Maybe<Array<(
      { __typename: 'MaterialNote' }
      & {
        materialNoteItems: Array<(
          { __typename: 'MaterialNoteItem' }
          & MaterialNoteItemFieldsFragment
        )>
      }
      & MaterialNoteFieldsFragment
    )>>
  }
);

export type SubmittedTimesheetsQueryVariables = Exact<{ [key: string]: never; }>;


export type SubmittedTimesheetsQuery = (
  { __typename: 'PrivateQuery' }
  & {
    submittedTimesheets?: Maybe<Array<(
      { __typename: 'Timesheet' }
      & TimesheetFieldsFragment
    )>>
  }
);

export type TeamQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamQuery = (
  { __typename: 'PrivateQuery' }
  & {
    currentUser?: Maybe<(
      { __typename: 'User' }
      & {
        company?: Maybe<(
          { __typename: 'Company' }
          & {
            teamMembers: Array<(
              { __typename: 'TeamMember' }
              & TeamMemberFieldsFragment
            )>
          }
          & CompanyFieldsFragment
        )>, policy?: Maybe<(
          { __typename: 'UserPolicy' }
          & Pick<UserPolicy, 'createProject' | 'createDemoProject' | 'createTender' | 'createAddressValuation' | 'setupCompanyService' | 'addTeam' | 'dashboard' | 'das' | 'tutorialSlider'>
        )>
      }
      & UserFieldsFragment
    )>
  }
);

export type TimesheetQueryVariables = Exact<{
  timesheetId: Scalars['ID'];
}>;


export type TimesheetQuery = (
  { __typename: 'PrivateQuery' }
  & {
    timesheet?: Maybe<(
      { __typename: 'Timesheet' }
      & TimesheetFieldsFragment
    )>
  }
);

export type TodoQueryVariables = Exact<{
  todoId: Scalars['ID'];
}>;


export type TodoQuery = (
  { __typename: 'PrivateQuery' }
  & {
    todo?: Maybe<(
      { __typename: 'Todo' }
      & {
        createdByMember: (
          { __typename: 'Member' }
          & MemberFieldsFragment
        ), assignedMembers?: Maybe<Array<(
          { __typename: 'Member' }
          & MemberFieldsFragment
        )>>
      }
      & TodoFieldsFragment
    )>
  }
);

export type WalterLivingAddressInformationQueryVariables = Exact<{
  addressObjectId: Scalars['ID'];
}>;


export type WalterLivingAddressInformationQuery = (
  { __typename: 'PrivateQuery' }
  & {
    addressInformation?: Maybe<(
      { __typename: 'AddressInformation' }
      & Pick<AddressInformation, 'addressObjectId' | 'city' | 'extension' | 'id' | 'lat' | 'lon' | 'number' | 'propertyConstructionYear' | 'propertyEnergyLabel' | 'street' | 'wozDate' | 'wozValue' | 'zipcode'>
    )>
  }
);

export type WalterLivingAddressSearchQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type WalterLivingAddressSearchQuery = (
  { __typename: 'PrivateQuery' }
  & {
    addressSearch?: Maybe<Array<(
      { __typename: 'AddressSearch' }
      & Pick<AddressSearch, 'addressObjectId' | 'city' | 'extension' | 'id' | 'lat' | 'lon' | 'number' | 'street' | 'zipcode'>
    )>>
  }
);

export type ActivitySubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ActivitySubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    activity: (
      { __typename: 'Activity' }
      & {
        createdByMember: (
          { __typename: 'Member' }
          & MemberFieldsFragment
        ), assignedMembers?: Maybe<Array<(
          { __typename: 'Member' }
          & MemberFieldsFragment
        )>>
      }
      & ActivityFieldsFragment
    )
  }
);

export type AgreementSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AgreementSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    agreement: (
      { __typename: 'Agreement' }
      & {
        createdByMember: (
          { __typename: 'Member' }
          & MemberFieldsFragment
        ), counterpartyMember: (
          { __typename: 'Member' }
          & MemberFieldsFragment
        ), media: Array<(
          { __typename: 'Folder' }
          & MediaFields_Folder_Fragment
        ) | (
            { __typename: 'Document' }
            & MediaFields_Document_Fragment
          ) | (
            { __typename: 'Image' }
            & MediaFields_Image_Fragment
          ) | (
            { __typename: 'Video' }
            & MediaFields_Video_Fragment
          )>
      }
      & AgreementFieldsFragment
    )
  }
);

export type ChatSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChatSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    chat: (
      { __typename: 'GroupChat' }
      & {
        participants: Array<(
          { __typename: 'Participant' }
          & {
            member: (
              { __typename: 'Member' }
              & MemberFieldsFragment
            )
          }
          & ParticipantFieldsFragment
        )>
      }
      & ChatFields_GroupChat_Fragment
    ) | (
      { __typename: 'PrivateChat' }
      & {
        participants: Array<(
          { __typename: 'Participant' }
          & {
            member: (
              { __typename: 'Member' }
              & MemberFieldsFragment
            )
          }
          & ParticipantFieldsFragment
        )>
      }
      & ChatFields_PrivateChat_Fragment
    )
  }
);

export type DeleteSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type DeleteSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    delete: (
      { __typename: 'DeletedEntity' }
      & Pick<DeletedEntity, 'id' | 'type'>
    )
  }
);

export type EventSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type EventSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    event: (
      { __typename: 'MediaMessage' }
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
      & EventFields_MediaMessage_Fragment
    ) | (
      { __typename: 'Message' }
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
      & EventFields_Message_Fragment
    ) | (
      { __typename: 'ReplyMessage' }
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
      & EventFields_ReplyMessage_Fragment
    ) | (
      { __typename: 'ActivityMessage' }
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
      & EventFields_ActivityMessage_Fragment
    ) | (
      { __typename: 'AgreementMessage' }
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
      & EventFields_AgreementMessage_Fragment
    ) | (
      { __typename: 'DeletedEvent' }
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
      & EventFields_DeletedEvent_Fragment
    ) | (
      { __typename: 'ExpenseMessage' }
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
      & EventFields_ExpenseMessage_Fragment
    ) | (
      { __typename: 'MaterialNoteMessage' }
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
      & EventFields_MaterialNoteMessage_Fragment
    ) | (
      { __typename: 'SystemMessage' }
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
      & EventFields_SystemMessage_Fragment
    ) | (
      { __typename: 'TimesheetMessage' }
      & {
        user: (
          { __typename: 'User' }
          & UserFieldsFragment
        )
      }
      & EventFields_TimesheetMessage_Fragment
    )
  }
);

export type ExpenseSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ExpenseSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    expense: (
      { __typename: 'Expense' }
      & {
        media: Array<(
          { __typename: 'Folder' }
          & MediaFields_Folder_Fragment
        ) | (
            { __typename: 'Document' }
            & MediaFields_Document_Fragment
          ) | (
            { __typename: 'Image' }
            & MediaFields_Image_Fragment
          ) | (
            { __typename: 'Video' }
            & MediaFields_Video_Fragment
          )>
      }
      & ExpenseFieldsFragment
    )
  }
);

export type MaterialNoteSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MaterialNoteSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    materialNote: (
      { __typename: 'MaterialNote' }
      & {
        materialNoteItems: Array<(
          { __typename: 'MaterialNoteItem' }
          & MaterialNoteItemFieldsFragment
        )>
      }
      & MaterialNoteFieldsFragment
    )
  }
);

export type MediaSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MediaSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    media: (
      { __typename: 'Folder' }
      & MediaFields_Folder_Fragment
    ) | (
      { __typename: 'Document' }
      & MediaFields_Document_Fragment
    ) | (
      { __typename: 'Image' }
      & MediaFields_Image_Fragment
    ) | (
      { __typename: 'Video' }
      & MediaFields_Video_Fragment
    )
  }
);

export type MemberSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type MemberSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    member: (
      { __typename: 'Member' }
      & {
        company?: Maybe<(
          { __typename: 'Company' }
          & CompanyFieldsFragment
        )>
      }
      & MemberFieldsFragment
    )
  }
);

export type ParticipantSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ParticipantSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    participant: (
      { __typename: 'Participant' }
      & {
        member: (
          { __typename: 'Member' }
          & Pick<Member, 'id'>
        )
      }
      & ParticipantFieldsFragment
    )
  }
);

export type ProjectSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ProjectSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    project: (
      { __typename: 'Project' }
      & ProjectFieldsFragment
    )
  }
);

export type TimesheetSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TimesheetSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    timesheet: (
      { __typename: 'Timesheet' }
      & TimesheetFieldsFragment
    )
  }
);

export type TodoSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TodoSubscription = (
  { __typename: 'PrivateSubscription' }
  & {
    todo: (
      { __typename: 'Todo' }
      & {
        createdByMember: (
          { __typename: 'Member' }
          & MemberFieldsFragment
        ), assignedMembers?: Maybe<Array<(
          { __typename: 'Member' }
          & MemberFieldsFragment
        )>>
      }
      & TodoFieldsFragment
    )
  }
);

export const ChatFieldsFragmentDoc = gql`
    fragment ChatFields on Chat {
  id
  name
  imageThumbnail
  active
  general
  createdAt
  projectId
  latestActivityAt
  notificationCount
  deactivatedAt
  archivedAt
  ... on PrivateChat {
    otherMemberId
    otherMemberEmail
    policy {
      createMessage
      createAgreement
    }
  }
  ... on GroupChat {
    description
    policy {
      update
      archive
      unarchive
      createParticipants
      leave
      createMessage
      createAgreement
    }
  }
}
    `;
export const CompanyFieldsFragmentDoc = gql`
    fragment CompanyFields on Company {
  id
  name
  email
  address
  zipCode
  cocNumber
  vatNumber
  companyPlace
  bouwmaatAccountNumber
  createdAt
  updatedAt
  logoThumbnail
  logoFull
  serviceZipcode
  serviceRangeKm
  serviceDescription
  serviceTags
  servicePriceMax
  servicePriceMin
  phone
  policy {
    update
    createTeamMember
  }
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  professional
  email
  phone
  firstName
  lastName
  name
  avatarThumbnail
  current
  subscribedToPlanAt
  sendTransactionalEmail
  sendNewsletterEmail
  bouwmaat
  bouwmaatOption
  bouwmaatSubscriptionCompleted
  bouwmaatAccountNumber
}
    `;
export const MediaFieldsFragmentDoc = gql`
    fragment MediaFields on Media {
  id
  description
  filename
  ownerType
  projectId
  createdAt
  updatedAt
  policy {
    delete
  }
  members {
    id
    avatarThumbnail
  }
  user {
    ...UserFields
  }
  ... on Document {
    file
    downloadFile
    tile
    preview
    path
    metadata {
      width
      height
      duration
      portrait
    }
  }
  ... on Image {
    file
    downloadFile
    tile
    preview
    path
    metadata {
      width
      height
      duration
      portrait
    }
  }
  ... on Video {
    file
    downloadFile
    tile
    preview
    path
    metadata {
      width
      height
      duration
      portrait
    }
  }
  ... on Folder {
    path
  }
  ... on Document {
    fileExtension
    contentType
    path
  }
}
    ${UserFieldsFragmentDoc}`;
export const AgreementFieldsFragmentDoc = gql`
    fragment AgreementFields on Agreement {
  id
  number
  name
  description
  status
  projectId
  priceType
  price
  vatPercentage
  vatAmount
  totalPrice
  signedTotalPrice
  createdAt
  updatedAt
  closedAt
  policy {
    update
    accept
    reject
    delete
  }
}
    `;
export const InvitationFieldsFragmentDoc = gql`
    fragment InvitationFields on Invitation {
  id
  token
  deepLink
  firstName
  lastName
  name
  message
  email
  createdByUserName
  createdByUserFirstName
  memberId
  memberRole
  memberRoleLabel
  projectId
  projectName
  projectDescription
  projectImageThumbnail
  acceptedAt
  createdAt
  invitedUserId
  policy {
    mail
    accept
    open
    share
  }
}
    `;
export const MemberFieldsFragmentDoc = gql`
    fragment MemberFields on Member {
  id
  name
  firstName
  lastName
  email
  phone
  createdAt
  updatedAt
  deactivatedAt
  role
  roleLabel
  admin
  enableTimesheets
  enableExpenses
  enableMaterialNotes
  active
  current
  avatarThumbnail
  deactivatedAt
  projectId
  userId
  invitation {
    ...InvitationFields
  }
  user {
    ...UserFields
  }
  policy {
    update
    promote
    demote
    deactivate
    showContactDetails
    createPrivateChat
    createGroupChat
    createAgreement
  }
}
    ${InvitationFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;
export const ActivityFieldsFragmentDoc = gql`
    fragment ActivityFields on Activity {
  id
  name
  description
  color
  date
  duration
  endDate
  projectId
  active
  position
  createdAt
  updatedAt
  archivedAt
  policy {
    update
    archive
    pdfActivities
  }
}
    `;
export const TimesheetFieldsFragmentDoc = gql`
    fragment TimesheetFields on Timesheet {
  id
  description
  year
  week
  status
  createdAt
  updatedAt
  submittedAt
  approvedAt
  monday
  mondayHours
  mondayNotes
  tuesday
  tuesdayHours
  tuesdayNotes
  wednesday
  wednesdayHours
  wednesdayNotes
  thursday
  thursdayHours
  thursdayNotes
  friday
  fridayHours
  fridayNotes
  saturday
  saturdayHours
  saturdayNotes
  sunday
  sundayHours
  sundayNotes
  totalHours
  projectId
  createdByMemberId
  approvedByMemberId
  policy {
    update
    submit
    approve
    reject
  }
  createdByMember {
    id
    name
    current
  }
}
    `;
export const ExpenseFieldsFragmentDoc = gql`
    fragment ExpenseFields on Expense {
  id
  description
  status
  createdAt
  updatedAt
  submittedAt
  approvedAt
  price
  vatAmount
  totalPrice
  refund
  projectId
  createdByMemberId
  approvedByMemberId
  policy {
    update
    submit
    approve
    reject
  }
  createdByMember {
    id
    name
  }
}
    `;
export const MaterialNoteFieldsFragmentDoc = gql`
    fragment MaterialNoteFields on MaterialNote {
  id
  createdAt
  updatedAt
  submittedAt
  approvedAt
  status
  projectId
  createdByMemberId
  approvedByMemberId
  policy {
    update
    submit
    approve
    delete
    export
    reject
  }
  createdByMember {
    id
    name
    avatarThumbnail
  }
}
    `;
export const EventFieldsFragmentDoc = gql`
    fragment EventFields on Event {
  id
  payload
  createdByCurrentUser
  createdAt
  updatedAt
  userId
  chatId
  projectId
  policy {
    delete
  }
  ... on MediaMessage {
    media {
      ...MediaFields
    }
  }
  ... on SystemMessage {
    systemMessageAction
    referenceValue
  }
  ... on AgreementMessage {
    agreementMessageAction
    agreement {
      ...AgreementFields
      createdByMember {
        ...MemberFields
      }
      counterpartyMember {
        ...MemberFields
      }
    }
  }
  ... on ActivityMessage {
    activityMessageAction
    activity {
      ...ActivityFields
    }
  }
  ... on TimesheetMessage {
    timesheetMessageAction
    timesheet {
      ...TimesheetFields
    }
  }
  ... on ExpenseMessage {
    expenseMessageAction
    expense {
      ...ExpenseFields
    }
  }
  ... on MaterialNoteMessage {
    materialNoteMessageAction
    materialNote {
      ...MaterialNoteFields
    }
  }
  ... on DeletedEvent {
    deletedEventType
  }
  ... on ReplyMessage {
    event {
      id
      payload
      user {
        ...UserFields
      }
      ... on MediaMessage {
        id
        media {
          ...MediaFields
        }
      }
    }
  }
}
    ${MediaFieldsFragmentDoc}
${AgreementFieldsFragmentDoc}
${MemberFieldsFragmentDoc}
${ActivityFieldsFragmentDoc}
${TimesheetFieldsFragmentDoc}
${ExpenseFieldsFragmentDoc}
${MaterialNoteFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;
export const MaterialNoteItemFieldsFragmentDoc = gql`
    fragment MaterialNoteItemFields on MaterialNoteItem {
  id
  name
  quantity
  createdAt
  updatedAt
  materialNoteId
  policy {
    delete
  }
}
    `;
export const ParticipantFieldsFragmentDoc = gql`
    fragment ParticipantFields on Participant {
  id
  admin
  createdAt
  updatedAt
  readAt
  deactivatedAt
  active
  chatId
  current
  typingAt
  policy {
    deactivate
    promote
    demote
  }
}
    `;
export const ProjectFieldsFragmentDoc = gql`
    fragment ProjectFields on Project {
  id
  name
  nameOrAddress
  description
  reference
  address
  createdAt
  archivedAt
  latestActivityAt
  notificationCount
  chatNotificationCount
  active
  deactivatedAt
  imageThumbnail
  imageBanner
  imageFull
  onboardingProject
  demo
  companyId
  policy {
    update
    archive
    unarchive
    inviteUser
    inviteUsers
    inviteMember
    createMedia
    createPrivateChat
    createGroupChat
    createAgreement
    createActivity
    orderActivities
    updateTimesheet
    exportTimesheets
    exportExpense
    manageTimesheets
    createExpense
    manageExpenses
    createTodo
    orderTodos
    pdfActivities
    manageMaterialNotes
    createMaterialNote
    exportMaterialNotes
    inviteMemberLimitReached
  }
}
    `;
export const ProjectFieldsFragmentDoc2 = gql`
    fragment ProjectFields on Project {
  id
  name
  nameOrAddress
  description
  reference
  address
  createdAt
  archivedAt
  latestActivityAt
  notificationCount
  chatNotificationCount
  active
  deactivatedAt
  imageThumbnail
  onboardingProject
  demo
  companyId
}
    `;
export const TeamMemberFieldsFragmentDoc = gql`
    fragment TeamMemberFields on TeamMember {
  id
  name
  firstName
  lastName
  email
  phone
  role
  createdAt
  updatedAt
  avatarFull
  avatarThumbnail
  enableExpenses
  enableTimesheets
  enableMaterialNotes
  policy {
    delete
  }
  user {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export const TodoFieldsFragmentDoc = gql`
    fragment TodoFields on Todo {
  id
  name
  description
  projectId
  position
  completedAt
  media {
    ...MediaFields
  }
  createdAt
  updatedAt
  policy {
    update
    create
    delete
  }
}
    ${MediaFieldsFragmentDoc}`;
export const AcceptAgreementDocument = gql`
    mutation acceptAgreement($input: AcceptAgreementInputType!) {
  acceptAgreement(input: $input) {
    agreement {
      ...AgreementFields
    }
    errors {
      argument
      error
    }
  }
}
    ${AgreementFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class AcceptAgreementMutationService extends Apollo.Mutation<AcceptAgreementMutation, AcceptAgreementMutationVariables> {
  document = AcceptAgreementDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AcceptInvitationDocument = gql`
    mutation acceptInvitation($input: AcceptInvitationInputType!) {
  acceptInvitation(input: $input) {
    invitation {
      projectId
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class AcceptInvitationMutationService extends Apollo.Mutation<AcceptInvitationMutation, AcceptInvitationMutationVariables> {
  document = AcceptInvitationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddressSearchDocument = gql`
    mutation addressSearch($input: AddressSearchInputType!) {
  addressSearch(input: $input) {
    results {
      id
      address
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class AddressSearchMutationService extends Apollo.Mutation<AddressSearchMutation, AddressSearchMutationVariables> {
  document = AddressSearchDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ApproveExpenseDocument = gql`
    mutation approveExpense($input: ApproveExpenseInputType!) {
  approveExpense(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class ApproveExpenseMutationService extends Apollo.Mutation<ApproveExpenseMutation, ApproveExpenseMutationVariables> {
  document = ApproveExpenseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ApproveMaterialNoteDocument = gql`
    mutation approveMaterialNote($input: ApproveMaterialNoteInputType!) {
  approveMaterialNote(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class ApproveMaterialNoteMutationService extends Apollo.Mutation<ApproveMaterialNoteMutation, ApproveMaterialNoteMutationVariables> {
  document = ApproveMaterialNoteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ApproveTimesheetDocument = gql`
    mutation approveTimesheet($input: ApproveTimesheetInputType!) {
  approveTimesheet(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class ApproveTimesheetMutationService extends Apollo.Mutation<ApproveTimesheetMutation, ApproveTimesheetMutationVariables> {
  document = ApproveTimesheetDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ArchiveActivityDocument = gql`
    mutation archiveActivity($input: ArchiveActivityInputType!) {
  archiveActivity(input: $input) {
    activity {
      id
      archivedAt
      active
      policy {
        update
        archive
      }
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class ArchiveActivityMutationService extends Apollo.Mutation<ArchiveActivityMutation, ArchiveActivityMutationVariables> {
  document = ArchiveActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ArchiveGroupChatDocument = gql`
    mutation archiveGroupChat($input: ArchiveGroupChatInputType!) {
  archiveGroupChat(input: $input) {
    groupChat {
      ...ChatFields
    }
    errors {
      argument
      error
    }
  }
}
    ${ChatFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ArchiveGroupChatMutationService extends Apollo.Mutation<ArchiveGroupChatMutation, ArchiveGroupChatMutationVariables> {
  document = ArchiveGroupChatDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ArchiveProjectDocument = gql`
    mutation archiveProject($input: ArchiveProjectInputType!) {
  archiveProject(input: $input) {
    project {
      ...ProjectFields
      members {
        ...MemberFields
      }
      chats {
        ...ChatFields
      }
      agreements {
        ...AgreementFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${ProjectFieldsFragmentDoc}
${MemberFieldsFragmentDoc}
${ChatFieldsFragmentDoc}
${AgreementFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ArchiveProjectMutationService extends Apollo.Mutation<ArchiveProjectMutation, ArchiveProjectMutationVariables> {
  document = ArchiveProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CancelSubscriptionDocument = gql`
    mutation CancelSubscription {
  cancelSubscription {
    subscribedToPlanAt
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class CancelSubscriptionMutationService extends Apollo.Mutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables> {
  document = CancelSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ContactDasDocument = gql`
    mutation contactDas($input: SendDasContactInputType!) {
  sendDasContact(input: $input) {
    message
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class ContactDasMutationService extends Apollo.Mutation<ContactDasMutation, ContactDasMutationVariables> {
  document = ContactDasDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateActivityDocument = gql`
    mutation CreateActivity($input: CreateActivityInputType!) {
  createActivity(input: $input) {
    activity {
      ...ActivityFields
      createdByMember {
        ...MemberFields
      }
      assignedMembers {
        ...MemberFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${ActivityFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateActivityMutationService extends Apollo.Mutation<CreateActivityMutation, CreateActivityMutationVariables> {
  document = CreateActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateAddressValuationDocument = gql`
    mutation createAddressValuation($input: CreateAddressValuationInputType!) {
  createAddressValuation(input: $input) {
    addressValuation {
      bestEstimate
      bottomEstimate
      id
      topEstimate
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class CreateAddressValuationMutationService extends Apollo.Mutation<CreateAddressValuationMutation, CreateAddressValuationMutationVariables> {
  document = CreateAddressValuationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateAgreementDocument = gql`
    mutation CreateAgreement($input: CreateAgreementInputType!) {
  createAgreement(input: $input) {
    agreement {
      ...AgreementFields
      createdByMember {
        ...MemberFields
      }
      counterpartyMember {
        ...MemberFields
      }
      media {
        ...MediaFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${AgreementFieldsFragmentDoc}
${MemberFieldsFragmentDoc}
${MediaFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateAgreementMutationService extends Apollo.Mutation<CreateAgreementMutation, CreateAgreementMutationVariables> {
  document = CreateAgreementDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateDemoProjectDocument = gql`
    mutation createDemoProject {
  createDemoProject {
    project {
      ...ProjectFields
      chats {
        ...ChatFields
      }
      members {
        ...MemberFields
      }
    }
  }
}
    ${ProjectFieldsFragmentDoc}
${ChatFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateDemoProjectMutationService extends Apollo.Mutation<CreateDemoProjectMutation, CreateDemoProjectMutationVariables> {
  document = CreateDemoProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateExpenseDocument = gql`
    mutation CreateExpense($input: CreateExpenseInputType!) {
  createExpense(input: $input) {
    expense {
      id
      projectId
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class CreateExpenseMutationService extends Apollo.Mutation<CreateExpenseMutation, CreateExpenseMutationVariables> {
  document = CreateExpenseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateFolderDocument = gql`
    mutation createFolder($input: CreateFolderInputType!) {
  createFolder(input: $input) {
    folder {
      ...MediaFields
    }
    errors {
      argument
      error
    }
  }
}
    ${MediaFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateFolderMutationService extends Apollo.Mutation<CreateFolderMutation, CreateFolderMutationVariables> {
  document = CreateFolderDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateGroupChatDocument = gql`
    mutation createGroupChat($input: CreateGroupChatInputType!) {
  createGroupChat(input: $input) {
    groupChat {
      ...ChatFields
      participants {
        ...ParticipantFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${ChatFieldsFragmentDoc}
${ParticipantFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateGroupChatMutationService extends Apollo.Mutation<CreateGroupChatMutation, CreateGroupChatMutationVariables> {
  document = CreateGroupChatDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateMaterialNoteItemDocument = gql`
    mutation CreateMaterialNoteItem($input: CreateMaterialNoteItemInputType!) {
  createMaterialNoteItem(input: $input) {
    materialNoteItem {
      id
      materialNoteId
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class CreateMaterialNoteItemMutationService extends Apollo.Mutation<CreateMaterialNoteItemMutation, CreateMaterialNoteItemMutationVariables> {
  document = CreateMaterialNoteItemDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateMaterialNoteDocument = gql`
    mutation CreateMaterialNote($input: CreateMaterialNoteInputType!) {
  createMaterialNote(input: $input) {
    materialNote {
      id
      projectId
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class CreateMaterialNoteMutationService extends Apollo.Mutation<CreateMaterialNoteMutation, CreateMaterialNoteMutationVariables> {
  document = CreateMaterialNoteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateMediaMessageDocument = gql`
    mutation createMediaMessage($input: CreateMediaMessageInputType!) {
  createMediaMessage(input: $input) {
    mediaMessage {
      ...EventFields
      user {
        ...UserFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${EventFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateMediaMessageMutationService extends Apollo.Mutation<CreateMediaMessageMutation, CreateMediaMessageMutationVariables> {
  document = CreateMediaMessageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateMediaDocument = gql`
    mutation createMedia($input: CreateMediaInputType!) {
  createMedia(input: $input) {
    media {
      ...MediaFields
    }
    errors {
      argument
      error
    }
  }
}
    ${MediaFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateMediaMutationService extends Apollo.Mutation<CreateMediaMutation, CreateMediaMutationVariables> {
  document = CreateMediaDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateMessageDocument = gql`
    mutation createMessage($input: CreateMessageInputType!) {
  createMessage(input: $input) {
    message {
      ...EventFields
      user {
        ...UserFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${EventFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateMessageMutationService extends Apollo.Mutation<CreateMessageMutation, CreateMessageMutationVariables> {
  document = CreateMessageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateParticipantsDocument = gql`
    mutation createParticipants($input: CreateParticipantsInputType!) {
  createParticipants(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class CreateParticipantsMutationService extends Apollo.Mutation<CreateParticipantsMutation, CreateParticipantsMutationVariables> {
  document = CreateParticipantsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreatePrivateChatDocument = gql`
    mutation createPrivateChat($input: CreatePrivateChatInputType!) {
  createPrivateChat(input: $input) {
    privateChat {
      ...ChatFields
      participants {
        ...ParticipantFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${ChatFieldsFragmentDoc}
${ParticipantFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreatePrivateChatMutationService extends Apollo.Mutation<CreatePrivateChatMutation, CreatePrivateChatMutationVariables> {
  document = CreatePrivateChatDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateProjectDocument = gql`
    mutation createProject($input: CreateProjectInputType!) {
  createProject(input: $input) {
    project {
      ...ProjectFields
      members {
        ...MemberFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${ProjectFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateProjectMutationService extends Apollo.Mutation<CreateProjectMutation, CreateProjectMutationVariables> {
  document = CreateProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateReplyMessageDocument = gql`
    mutation createReplyMessage($input: CreateReplyMessageInputType!) {
  createReplyMessage(input: $input) {
    replyMessage {
      ...EventFields
      user {
        ...UserFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${EventFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateReplyMessageMutationService extends Apollo.Mutation<CreateReplyMessageMutation, CreateReplyMessageMutationVariables> {
  document = CreateReplyMessageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($input: CreateSubscriptionInputType!) {
  createSubscription(input: $input) {
    subscribedToPlanAt
    mollieCheckoutUrl
    status
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class CreateSubscriptionMutationService extends Apollo.Mutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables> {
  document = CreateSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateTeamMemberDocument = gql`
    mutation createTeamMember($input: CreateTeamMemberInputType!) {
  createTeamMember(input: $input) {
    teamMember {
      ...TeamMemberFields
    }
    errors {
      argument
      error
    }
  }
}
    ${TeamMemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateTeamMemberMutationService extends Apollo.Mutation<CreateTeamMemberMutation, CreateTeamMemberMutationVariables> {
  document = CreateTeamMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateTenderDocument = gql`
    mutation CreateTender($input: CreateTenderInputType!) {
  createTender(input: $input) {
    tender {
      id
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class CreateTenderMutationService extends Apollo.Mutation<CreateTenderMutation, CreateTenderMutationVariables> {
  document = CreateTenderDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateTodoDocument = gql`
    mutation CreateTodo($input: CreateTodoInputType!) {
  createTodo(input: $input) {
    todo {
      ...TodoFields
      createdByMember {
        ...MemberFields
      }
      assignedMembers {
        ...MemberFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${TodoFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CreateTodoMutationService extends Apollo.Mutation<CreateTodoMutation, CreateTodoMutationVariables> {
  document = CreateTodoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeactivateMemberDocument = gql`
    mutation deactivateMember($input: DeactivateMemberInputType!) {
  deactivateMember(input: $input) {
    member {
      ...MemberFields
    }
    errors {
      argument
      error
    }
  }
}
    ${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class DeactivateMemberMutationService extends Apollo.Mutation<DeactivateMemberMutation, DeactivateMemberMutationVariables> {
  document = DeactivateMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeactivateParticipantDocument = gql`
    mutation deactivateParticipant($input: DeactivateParticipantInputType!) {
  deactivateParticipant(input: $input) {
    participant {
      ...ParticipantFields
    }
    errors {
      argument
      error
    }
  }
}
    ${ParticipantFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class DeactivateParticipantMutationService extends Apollo.Mutation<DeactivateParticipantMutation, DeactivateParticipantMutationVariables> {
  document = DeactivateParticipantDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteAgreementDocument = gql`
    mutation deleteAgreement($input: DeleteAgreementInputType!) {
  deleteAgreement(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class DeleteAgreementMutationService extends Apollo.Mutation<DeleteAgreementMutation, DeleteAgreementMutationVariables> {
  document = DeleteAgreementDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteEventDocument = gql`
    mutation deleteEvent($input: DeleteEventInputType!) {
  deleteEvent(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class DeleteEventMutationService extends Apollo.Mutation<DeleteEventMutation, DeleteEventMutationVariables> {
  document = DeleteEventDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteMaterialNoteItemDocument = gql`
    mutation deleteMaterialNoteItem($input: DeleteMaterialNoteItemInputType!) {
  deleteMaterialNoteItem(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class DeleteMaterialNoteItemMutationService extends Apollo.Mutation<DeleteMaterialNoteItemMutation, DeleteMaterialNoteItemMutationVariables> {
  document = DeleteMaterialNoteItemDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteMaterialNoteDocument = gql`
    mutation deleteMaterialNote($input: DeleteMaterialNoteInputType!) {
  deleteMaterialNote(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class DeleteMaterialNoteMutationService extends Apollo.Mutation<DeleteMaterialNoteMutation, DeleteMaterialNoteMutationVariables> {
  document = DeleteMaterialNoteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteMediaDocument = gql`
    mutation deleteMedia($input: DeleteMediaInputType!) {
  deleteMedia(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class DeleteMediaMutationService extends Apollo.Mutation<DeleteMediaMutation, DeleteMediaMutationVariables> {
  document = DeleteMediaDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteTeamMemberDocument = gql`
    mutation deleteTeamMember($input: DeleteTeamMemberInputType!) {
  deleteTeamMember(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class DeleteTeamMemberMutationService extends Apollo.Mutation<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables> {
  document = DeleteTeamMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteTodoDocument = gql`
    mutation deleteTodo($input: DeleteTodoInputType!) {
  deleteTodo(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class DeleteTodoMutationService extends Apollo.Mutation<DeleteTodoMutation, DeleteTodoMutationVariables> {
  document = DeleteTodoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DemoteMemberDocument = gql`
    mutation demoteMember($input: DemoteMemberInputType!) {
  demoteMember(input: $input) {
    member {
      ...MemberFields
    }
    errors {
      argument
      error
    }
  }
}
    ${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class DemoteMemberMutationService extends Apollo.Mutation<DemoteMemberMutation, DemoteMemberMutationVariables> {
  document = DemoteMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DemoteParticipantDocument = gql`
    mutation demoteParticipant($input: DemoteParticipantInputType!) {
  demoteParticipant(input: $input) {
    participant {
      ...ParticipantFields
    }
    errors {
      argument
      error
    }
  }
}
    ${ParticipantFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class DemoteParticipantMutationService extends Apollo.Mutation<DemoteParticipantMutation, DemoteParticipantMutationVariables> {
  document = DemoteParticipantDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ExportMaterialNotesDocument = gql`
    mutation exportMaterialNotes($input: ExportMaterialNotesInputType!) {
  exportMaterialNotes(input: $input) {
    csv
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class ExportMaterialNotesMutationService extends Apollo.Mutation<ExportMaterialNotesMutation, ExportMaterialNotesMutationVariables> {
  document = ExportMaterialNotesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ExportExpenseDocument = gql`
    mutation exportExpense($input: ExportExpenseInputType!) {
  exportExpense(input: $input) {
    csv
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class ExportExpenseMutationService extends Apollo.Mutation<ExportExpenseMutation, ExportExpenseMutationVariables> {
  document = ExportExpenseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ExportTimesheetDocument = gql`
    mutation exportTimesheet($input: ExportTimesheetInputType!) {
  exportTimesheet(input: $input) {
    csv
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class ExportTimesheetMutationService extends Apollo.Mutation<ExportTimesheetMutation, ExportTimesheetMutationVariables> {
  document = ExportTimesheetDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InviteMemberDocument = gql`
    mutation inviteMember($input: InviteMemberInputType!) {
  inviteMember(input: $input) {
    invitation {
      id
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class InviteMemberMutationService extends Apollo.Mutation<InviteMemberMutation, InviteMemberMutationVariables> {
  document = InviteMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InviteUserDocument = gql`
    mutation inviteUser($input: InviteUserInputType!) {
  inviteUser(input: $input) {
    invitation {
      id
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class InviteUserMutationService extends Apollo.Mutation<InviteUserMutation, InviteUserMutationVariables> {
  document = InviteUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InviteUsersDocument = gql`
    mutation inviteUsers($input: InviteUsersInputType!) {
  inviteUsers(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class InviteUsersMutationService extends Apollo.Mutation<InviteUsersMutation, InviteUsersMutationVariables> {
  document = InviteUsersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LeaveGroupChatDocument = gql`
    mutation leaveGroupChat($input: LeaveGroupChatInputType!) {
  leaveGroupChat(input: $input) {
    groupChat {
      ...ChatFields
    }
    errors {
      argument
      error
    }
  }
}
    ${ChatFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class LeaveGroupChatMutationService extends Apollo.Mutation<LeaveGroupChatMutation, LeaveGroupChatMutationVariables> {
  document = LeaveGroupChatDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const LogoutDocument = gql`
    mutation logout($input: LogoutInputType!) {
  logout(input: $input) {
    user {
      id
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class LogoutMutationService extends Apollo.Mutation<LogoutMutation, LogoutMutationVariables> {
  document = LogoutDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MailInvitationDocument = gql`
    mutation mailInvitation($input: MailInvitationInputType!) {
  mailInvitation(input: $input) {
    invitation {
      ...InvitationFields
    }
    errors {
      argument
      error
    }
  }
}
    ${InvitationFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class MailInvitationMutationService extends Apollo.Mutation<MailInvitationMutation, MailInvitationMutationVariables> {
  document = MailInvitationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MoveMediaDocument = gql`
    mutation moveMedia($input: MoveMediaInputType!) {
  moveMedia(input: $input) {
    media {
      id
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class MoveMediaMutationService extends Apollo.Mutation<MoveMediaMutation, MoveMediaMutationVariables> {
  document = MoveMediaDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const OpenedInvitationDocument = gql`
    mutation openedInvitation($input: OpenedInvitationInputType!) {
  openedInvitation(input: $input) {
    invitation {
      id
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class OpenedInvitationMutationService extends Apollo.Mutation<OpenedInvitationMutation, OpenedInvitationMutationVariables> {
  document = OpenedInvitationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const OrderActivitiesDocument = gql`
    mutation orderActivities($input: OrderActivitiesInputType!) {
  orderActivities(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class OrderActivitiesMutationService extends Apollo.Mutation<OrderActivitiesMutation, OrderActivitiesMutationVariables> {
  document = OrderActivitiesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const OrderTodosDocument = gql`
    mutation orderTodos($input: OrderTodosInputType!) {
  orderTodos(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class OrderTodosMutationService extends Apollo.Mutation<OrderTodosMutation, OrderTodosMutationVariables> {
  document = OrderTodosDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PdfActivitiesDocument = gql`
    mutation pdfActivities($input: PdfActivitiesInputType!) {
  pdfActivities(input: $input) {
    pdf
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class PdfActivitiesMutationService extends Apollo.Mutation<PdfActivitiesMutation, PdfActivitiesMutationVariables> {
  document = PdfActivitiesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PromoteMemberDocument = gql`
    mutation promoteMember($input: PromoteMemberInputType!) {
  promoteMember(input: $input) {
    member {
      ...MemberFields
    }
    errors {
      argument
      error
    }
  }
}
    ${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class PromoteMemberMutationService extends Apollo.Mutation<PromoteMemberMutation, PromoteMemberMutationVariables> {
  document = PromoteMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PromoteParticipantDocument = gql`
    mutation promoteParticipant($input: PromoteParticipantInputType!) {
  promoteParticipant(input: $input) {
    participant {
      ...ParticipantFields
    }
    errors {
      argument
      error
    }
  }
}
    ${ParticipantFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class PromoteParticipantMutationService extends Apollo.Mutation<PromoteParticipantMutation, PromoteParticipantMutationVariables> {
  document = PromoteParticipantDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RefreshTokenDocument = gql`
    mutation refreshToken($input: RefreshTokenInputType!) {
  refreshToken(input: $input) {
    accessToken
    refreshToken
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenMutationService extends Apollo.Mutation<RefreshTokenMutation, RefreshTokenMutationVariables> {
  document = RefreshTokenDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RegisterDeviceDocument = gql`
    mutation registerDevice($input: RegisterDeviceInputType!) {
  registerDevice(input: $input) {
    device {
      id
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class RegisterDeviceMutationService extends Apollo.Mutation<RegisterDeviceMutation, RegisterDeviceMutationVariables> {
  document = RegisterDeviceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RejectAgreementDocument = gql`
    mutation rejectAgreement($input: RejectAgreementInputType!) {
  rejectAgreement(input: $input) {
    agreement {
      ...AgreementFields
    }
    errors {
      argument
      error
    }
  }
}
    ${AgreementFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class RejectAgreementMutationService extends Apollo.Mutation<RejectAgreementMutation, RejectAgreementMutationVariables> {
  document = RejectAgreementDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RejectExpenseDocument = gql`
    mutation rejectExpense($input: RejectExpenseInputType!) {
  rejectExpense(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class RejectExpenseMutationService extends Apollo.Mutation<RejectExpenseMutation, RejectExpenseMutationVariables> {
  document = RejectExpenseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RejectMaterialNoteDocument = gql`
    mutation rejectMaterialNote($input: RejectMaterialNoteInputType!) {
  rejectMaterialNote(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class RejectMaterialNoteMutationService extends Apollo.Mutation<RejectMaterialNoteMutation, RejectMaterialNoteMutationVariables> {
  document = RejectMaterialNoteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RejectTimesheetDocument = gql`
    mutation rejectTimesheet($input: RejectTimesheetInputType!) {
  rejectTimesheet(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class RejectTimesheetMutationService extends Apollo.Mutation<RejectTimesheetMutation, RejectTimesheetMutationVariables> {
  document = RejectTimesheetDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ResetNotificationsDocument = gql`
    mutation resetNotifications($input: ResetNotificationsInputType!) {
  resetNotifications(input: $input) {
    notificationCount
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class ResetNotificationsMutationService extends Apollo.Mutation<ResetNotificationsMutation, ResetNotificationsMutationVariables> {
  document = ResetNotificationsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SendFeedbackDocument = gql`
    mutation sendFeedback($input: SendFeedbackInputType!) {
  sendFeedback(input: $input) {
    message
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class SendFeedbackMutationService extends Apollo.Mutation<SendFeedbackMutation, SendFeedbackMutationVariables> {
  document = SendFeedbackDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SetTypingDocument = gql`
    mutation setTyping($input: SetTypingInputType!) {
  setTyping(input: $input) {
    participant {
      id
      typingAt
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class SetTypingMutationService extends Apollo.Mutation<SetTypingMutation, SetTypingMutationVariables> {
  document = SetTypingDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SetPresenceDocument = gql`
    mutation setPresence($input: SetPresenceInputType!) {
  setPresence(input: $input) {
    chat {
      id
      latestActivityAt
      notificationCount
    }
    project {
      id
      latestActivityAt
      notificationCount
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class SetPresenceMutationService extends Apollo.Mutation<SetPresenceMutation, SetPresenceMutationVariables> {
  document = SetPresenceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ShareInvitationDocument = gql`
    mutation shareInvitation($input: ShareInvitationInputType!) {
  shareInvitation(input: $input) {
    invitation {
      id
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class ShareInvitationMutationService extends Apollo.Mutation<ShareInvitationMutation, ShareInvitationMutationVariables> {
  document = ShareInvitationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SubmitExpenseDocument = gql`
    mutation submitExpense($input: SubmitExpenseInputType!) {
  submitExpense(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class SubmitExpenseMutationService extends Apollo.Mutation<SubmitExpenseMutation, SubmitExpenseMutationVariables> {
  document = SubmitExpenseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SubmitMaterialNoteDocument = gql`
    mutation submitMaterialNote($input: SubmitMaterialNoteInputType!) {
  submitMaterialNote(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class SubmitMaterialNoteMutationService extends Apollo.Mutation<SubmitMaterialNoteMutation, SubmitMaterialNoteMutationVariables> {
  document = SubmitMaterialNoteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SubmitTimesheetDocument = gql`
    mutation submitTimesheet($input: SubmitTimesheetInputType!) {
  submitTimesheet(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class SubmitTimesheetMutationService extends Apollo.Mutation<SubmitTimesheetMutation, SubmitTimesheetMutationVariables> {
  document = SubmitTimesheetDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UnarchiveGroupChatDocument = gql`
    mutation unarchiveGroupChat($input: UnarchiveGroupChatInputType!) {
  unarchiveGroupChat(input: $input) {
    groupChat {
      ...ChatFields
    }
    errors {
      argument
      error
    }
  }
}
    ${ChatFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class UnarchiveGroupChatMutationService extends Apollo.Mutation<UnarchiveGroupChatMutation, UnarchiveGroupChatMutationVariables> {
  document = UnarchiveGroupChatDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UnarchiveProjectDocument = gql`
    mutation unarchiveProject($input: UnarchiveProjectInputType!) {
  unarchiveProject(input: $input) {
    project {
      ...ProjectFields
      members {
        ...MemberFields
      }
      chats {
        ...ChatFields
      }
      agreements {
        ...AgreementFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${ProjectFieldsFragmentDoc}
${MemberFieldsFragmentDoc}
${ChatFieldsFragmentDoc}
${AgreementFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class UnarchiveProjectMutationService extends Apollo.Mutation<UnarchiveProjectMutation, UnarchiveProjectMutationVariables> {
  document = UnarchiveProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UnsubscribeEmailDocument = gql`
    mutation unsubscribeEmail($input: UnsubscribeEmailInputType!) {
  unsubscribeEmail(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class UnsubscribeEmailMutationService extends Apollo.Mutation<UnsubscribeEmailMutation, UnsubscribeEmailMutationVariables> {
  document = UnsubscribeEmailDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateActivityDocument = gql`
    mutation updateActivity($input: UpdateActivityInputType!) {
  updateActivity(input: $input) {
    activity {
      ...ActivityFields
      assignedMembers {
        ...MemberFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${ActivityFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class UpdateActivityMutationService extends Apollo.Mutation<UpdateActivityMutation, UpdateActivityMutationVariables> {
  document = UpdateActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateAgreementDocument = gql`
    mutation updateAgreement($input: UpdateAgreementInputType!) {
  updateAgreement(input: $input) {
    agreement {
      ...AgreementFields
      media {
        ...MediaFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${AgreementFieldsFragmentDoc}
${MediaFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class UpdateAgreementMutationService extends Apollo.Mutation<UpdateAgreementMutation, UpdateAgreementMutationVariables> {
  document = UpdateAgreementDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateCompanyDocument = gql`
    mutation updateCompany($input: UpdateCompanyInputType!) {
  updateCompany(input: $input) {
    company {
      ...CompanyFields
    }
    errors {
      argument
      error
    }
  }
}
    ${CompanyFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class UpdateCompanyMutationService extends Apollo.Mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables> {
  document = UpdateCompanyDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateExpenseDocument = gql`
    mutation updateExpense($input: UpdateExpenseInputType!) {
  updateExpense(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class UpdateExpenseMutationService extends Apollo.Mutation<UpdateExpenseMutation, UpdateExpenseMutationVariables> {
  document = UpdateExpenseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateFolderDocument = gql`
    mutation updateFolder($input: UpdateFolderInputType!) {
  updateFolder(input: $input) {
    folder {
      ...MediaFields
    }
    errors {
      argument
      error
    }
  }
}
    ${MediaFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class UpdateFolderMutationService extends Apollo.Mutation<UpdateFolderMutation, UpdateFolderMutationVariables> {
  document = UpdateFolderDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateGroupChatDocument = gql`
    mutation updateGroupChat($input: UpdateGroupChatInputType!) {
  updateGroupChat(input: $input) {
    groupChat {
      ...ChatFields
    }
    errors {
      argument
      error
    }
  }
}
    ${ChatFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class UpdateGroupChatMutationService extends Apollo.Mutation<UpdateGroupChatMutation, UpdateGroupChatMutationVariables> {
  document = UpdateGroupChatDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateMaterialNoteItemDocument = gql`
    mutation UpdateMaterialNoteItem($input: UpdateMaterialNoteItemInputType!) {
  updateMaterialNoteItem(input: $input) {
    materialNoteItem {
      id
      materialNoteId
    }
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class UpdateMaterialNoteItemMutationService extends Apollo.Mutation<UpdateMaterialNoteItemMutation, UpdateMaterialNoteItemMutationVariables> {
  document = UpdateMaterialNoteItemDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateMemberDocument = gql`
    mutation updateMember($input: UpdateMemberInputType!) {
  updateMember(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class UpdateMemberMutationService extends Apollo.Mutation<UpdateMemberMutation, UpdateMemberMutationVariables> {
  document = UpdateMemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdatePasswordDocument = gql`
    mutation updatePassword($input: UpdatePasswordInputType!) {
  updatePassword(input: $input) {
    user {
      ...UserFields
    }
    errors {
      argument
      error
    }
  }
}
    ${UserFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class UpdatePasswordMutationService extends Apollo.Mutation<UpdatePasswordMutation, UpdatePasswordMutationVariables> {
  document = UpdatePasswordDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateProjectDocument = gql`
    mutation updateProject($input: UpdateProjectInputType!) {
  updateProject(input: $input) {
    project {
      ...ProjectFields
    }
    errors {
      argument
      error
    }
  }
}
    ${ProjectFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class UpdateProjectMutationService extends Apollo.Mutation<UpdateProjectMutation, UpdateProjectMutationVariables> {
  document = UpdateProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateTimesheetDocument = gql`
    mutation updateTimesheet($input: UpdateTimesheetInputType!) {
  updateTimesheet(input: $input) {
    errors {
      argument
      error
    }
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class UpdateTimesheetMutationService extends Apollo.Mutation<UpdateTimesheetMutation, UpdateTimesheetMutationVariables> {
  document = UpdateTimesheetDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateTodoDocument = gql`
    mutation updateTodo($input: UpdateTodoInputType!) {
  updateTodo(input: $input) {
    todo {
      ...TodoFields
      assignedMembers {
        ...MemberFields
      }
    }
    errors {
      argument
      error
    }
  }
}
    ${TodoFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class UpdateTodoMutationService extends Apollo.Mutation<UpdateTodoMutation, UpdateTodoMutationVariables> {
  document = UpdateTodoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateUserDocument = gql`
    mutation updateUser($input: UpdateUserInputType!) {
  updateUser(input: $input) {
    user {
      ...UserFields
    }
    errors {
      argument
      error
    }
  }
}
    ${UserFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class UpdateUserMutationService extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
  document = UpdateUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ActivitiesDocument = gql`
    query activities {
  activities {
    ...ActivityFields
    createdByMember {
      ...MemberFields
    }
    assignedMembers {
      ...MemberFields
    }
    project {
      id
      name
      description
      nameOrAddress
      policy {
        createActivity
        orderActivities
      }
    }
  }
}
    ${ActivityFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ActivitiesQueryService extends Apollo.Query<ActivitiesQuery, ActivitiesQueryVariables> {
  document = ActivitiesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ActivityDocument = gql`
    query activity($activityId: ID!) {
  activity(activityId: $activityId) {
    ...ActivityFields
    createdByMember {
      ...MemberFields
    }
    assignedMembers {
      ...MemberFields
    }
  }
}
    ${ActivityFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ActivityQueryService extends Apollo.Query<ActivityQuery, ActivityQueryVariables> {
  document = ActivityDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AgreementDocument = gql`
    query agreement($agreementId: ID!) {
  agreement(agreementId: $agreementId) {
    ...AgreementFields
    createdByMember {
      ...MemberFields
    }
    counterpartyMember {
      ...MemberFields
    }
    media {
      ...MediaFields
    }
  }
}
    ${AgreementFieldsFragmentDoc}
${MemberFieldsFragmentDoc}
${MediaFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class AgreementQueryService extends Apollo.Query<AgreementQuery, AgreementQueryVariables> {
  document = AgreementDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChatEventsDocument = gql`
    query chatEvents($chatId: ID!, $before: String) {
  chat(chatId: $chatId) {
    id
    events(before: $before, last: 50) {
      pageInfo {
        hasPreviousPage
      }
      edges {
        cursor
        node {
          ...EventFields
          user {
            ...UserFields
          }
        }
      }
    }
  }
}
    ${EventFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ChatEventsQueryService extends Apollo.Query<ChatEventsQuery, ChatEventsQueryVariables> {
  document = ChatEventsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChatDocument = gql`
    query chat($chatId: ID!) {
  chat(chatId: $chatId) {
    ...ChatFields
    participants {
      ...ParticipantFields
      member {
        ...MemberFields
      }
    }
  }
}
    ${ChatFieldsFragmentDoc}
${ParticipantFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ChatQueryService extends Apollo.Query<ChatQuery, ChatQueryVariables> {
  document = ChatDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CompanyTeamMemberSuggestionsDocument = gql`
    query companyTeamMemberSuggestions {
  currentUser {
    ...UserFields
    policy {
      createProject
      createDemoProject
      createTender
      createAddressValuation
      setupCompanyService
      dashboard
      addTeam
      das
      tutorialSlider
    }
    company {
      ...CompanyFields
      teamMemberSuggestions {
        ...UserFields
      }
    }
  }
}
    ${UserFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CompanyTeamMemberSuggestionsQueryService extends Apollo.Query<CompanyTeamMemberSuggestionsQuery, CompanyTeamMemberSuggestionsQueryVariables> {
  document = CompanyTeamMemberSuggestionsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CurrentUserDocument = gql`
    query currentUser {
  currentUser {
    ...UserFields
    subscriptionType
    subscriptionPeriod
    trialSubscriptionEndsAt
    company {
      ...CompanyFields
    }
    policy {
      createProject
      createDemoProject
      createTender
      createAddressValuation
      setupCompanyService
      addTeam
      dashboard
      das
      tutorialSlider
      onboarding
      onboardingActivity
      onboardingInviteMember
      webAccess
      nudgePro
      createProjectLimitReached
      trialExpired
      trial
    }
  }
}
    ${UserFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class CurrentUserQueryService extends Apollo.Query<CurrentUserQuery, CurrentUserQueryVariables> {
  document = CurrentUserDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ExpenseDocument = gql`
    query expense($expenseId: ID!) {
  expense(expenseId: $expenseId) {
    ...ExpenseFields
    media {
      ...MediaFields
    }
  }
}
    ${ExpenseFieldsFragmentDoc}
${MediaFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ExpenseQueryService extends Apollo.Query<ExpenseQuery, ExpenseQueryVariables> {
  document = ExpenseDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InvitationByTokenDocument = gql`
    query invitationByToken($invitationToken: String!) {
  invitationByToken(invitationToken: $invitationToken) {
    ...InvitationFields
  }
}
    ${InvitationFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class InvitationByTokenQueryService extends Apollo.Query<InvitationByTokenQuery, InvitationByTokenQueryVariables> {
  document = InvitationByTokenDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InvitationDocument = gql`
    query invitation($invitationId: ID!) {
  invitation(invitationId: $invitationId) {
    ...InvitationFields
  }
}
    ${InvitationFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class InvitationQueryService extends Apollo.Query<InvitationQuery, InvitationQueryVariables> {
  document = InvitationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const InvitationsDocument = gql`
    query invitations {
  invitations {
    ...InvitationFields
  }
}
    ${InvitationFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class InvitationsQueryService extends Apollo.Query<InvitationsQuery, InvitationsQueryVariables> {
  document = InvitationsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MaterialNoteItemDocument = gql`
    query materialNoteItem($materialNoteItemId: ID!) {
  materialNoteItem(materialNoteItemId: $materialNoteItemId) {
    ...MaterialNoteItemFields
  }
}
    ${MaterialNoteItemFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class MaterialNoteItemQueryService extends Apollo.Query<MaterialNoteItemQuery, MaterialNoteItemQueryVariables> {
  document = MaterialNoteItemDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MaterialNoteDocument = gql`
    query materialNote($materialNoteId: ID!) {
  materialNote(materialNoteId: $materialNoteId) {
    ...MaterialNoteFields
    materialNoteItems {
      ...MaterialNoteItemFields
    }
  }
}
    ${MaterialNoteFieldsFragmentDoc}
${MaterialNoteItemFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class MaterialNoteQueryService extends Apollo.Query<MaterialNoteQuery, MaterialNoteQueryVariables> {
  document = MaterialNoteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MediaDocument = gql`
    query media($mediaId: ID, $projectId: ID, $path: String) {
  media(mediaId: $mediaId, projectId: $projectId, path: $path) {
    ...MediaFields
  }
}
    ${MediaFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class MediaQueryService extends Apollo.Query<MediaQuery, MediaQueryVariables> {
  document = MediaDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MemberDocument = gql`
    query member($memberId: ID!) {
  member(memberId: $memberId) {
    ...MemberFields
    company {
      ...CompanyFields
    }
  }
}
    ${MemberFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class MemberQueryService extends Apollo.Query<MemberQuery, MemberQueryVariables> {
  document = MemberDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const OpenAgreementsDocument = gql`
    query openAgreements {
  openAgreements {
    ...AgreementFields
  }
}
    ${AgreementFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class OpenAgreementsQueryService extends Apollo.Query<OpenAgreementsQuery, OpenAgreementsQueryVariables> {
  document = OpenAgreementsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const OpenTodosDocument = gql`
    query openTodos {
  openTodos {
    ...TodoFields
  }
}
    ${TodoFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class OpenTodosQueryService extends Apollo.Query<OpenTodosQuery, OpenTodosQueryVariables> {
  document = OpenTodosDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectActivitiesDocument = gql`
    query projectActivities($projectId: ID!) {
  project(projectId: $projectId) {
    id
    policy {
      createActivity
      orderActivities
      pdfActivities
    }
    activities {
      ...ActivityFields
      createdByMember {
        ...MemberFields
      }
      assignedMembers {
        ...MemberFields
      }
    }
  }
}
    ${ActivityFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectActivitiesQueryService extends Apollo.Query<ProjectActivitiesQuery, ProjectActivitiesQueryVariables> {
  document = ProjectActivitiesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectAgreementsDocument = gql`
    query projectAgreements($projectId: ID!) {
  project(projectId: $projectId) {
    id
    policy {
      createAgreement
    }
    agreements {
      ...AgreementFields
      createdByMember {
        id
        name
        current
      }
      counterpartyMember {
        id
        name
        current
      }
    }
  }
}
    ${AgreementFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectAgreementsQueryService extends Apollo.Query<ProjectAgreementsQuery, ProjectAgreementsQueryVariables> {
  document = ProjectAgreementsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectChatsDocument = gql`
    query projectChats($projectId: ID!) {
  project(projectId: $projectId) {
    id
    policy {
      createPrivateChat
      createGroupChat
    }
    chats {
      ...ChatFields
      participants {
        ...ParticipantFields
        member {
          ...MemberFields
        }
      }
      events(last: 1) {
        pageInfo {
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ...EventFields
            user {
              ...UserFields
            }
          }
        }
      }
    }
  }
}
    ${ChatFieldsFragmentDoc}
${ParticipantFieldsFragmentDoc}
${MemberFieldsFragmentDoc}
${EventFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectChatsQueryService extends Apollo.Query<ProjectChatsQuery, ProjectChatsQueryVariables> {
  document = ProjectChatsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectCostsDocument = gql`
    query projectCosts($projectId: ID!) {
  project(projectId: $projectId) {
    id
    policy {
      createExpense
      manageExpenses
      createMaterialNote
      manageMaterialNotes
    }
    expenses {
      ...ExpenseFields
    }
    materialNotes {
      ...MaterialNoteFields
      materialNoteItems {
        ...MaterialNoteItemFields
      }
    }
  }
}
    ${ExpenseFieldsFragmentDoc}
${MaterialNoteFieldsFragmentDoc}
${MaterialNoteItemFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectCostsQueryService extends Apollo.Query<ProjectCostsQuery, ProjectCostsQueryVariables> {
  document = ProjectCostsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectCurrentMemberTimesheetsDocument = gql`
    query projectCurrentMemberTimesheets($projectId: ID!) {
  project(projectId: $projectId) {
    id
    currentMemberTimesheets {
      ...TimesheetFields
    }
  }
}
    ${TimesheetFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectCurrentMemberTimesheetsQueryService extends Apollo.Query<ProjectCurrentMemberTimesheetsQuery, ProjectCurrentMemberTimesheetsQueryVariables> {
  document = ProjectCurrentMemberTimesheetsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectExpensesDocument = gql`
    query projectExpenses($projectId: ID!) {
  project(projectId: $projectId) {
    id
    policy {
      createExpense
      manageExpenses
      createMaterialNote
      manageMaterialNotes
    }
    expenses {
      ...ExpenseFields
    }
  }
}
    ${ExpenseFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectExpensesQueryService extends Apollo.Query<ProjectExpensesQuery, ProjectExpensesQueryVariables> {
  document = ProjectExpensesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectInvitationSuggestionUsersDocument = gql`
    query projectInvitationSuggestionUsers($projectId: ID!) {
  project(projectId: $projectId) {
    id
    invitationSuggestions {
      ...UserFields
      company {
        name
      }
    }
  }
}
    ${UserFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectInvitationSuggestionUsersQueryService extends Apollo.Query<ProjectInvitationSuggestionUsersQuery, ProjectInvitationSuggestionUsersQueryVariables> {
  document = ProjectInvitationSuggestionUsersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectMaterialNotesDocument = gql`
    query projectMaterialNotes($projectId: ID!) {
  project(projectId: $projectId) {
    id
    policy {
      createMaterialNote
      manageMaterialNotes
    }
    materialNotes {
      ...MaterialNoteFields
      materialNoteItems {
        ...MaterialNoteItemFields
      }
    }
  }
}
    ${MaterialNoteFieldsFragmentDoc}
${MaterialNoteItemFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectMaterialNotesQueryService extends Apollo.Query<ProjectMaterialNotesQuery, ProjectMaterialNotesQueryVariables> {
  document = ProjectMaterialNotesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectMediaMoveFolderDocument = gql`
    query projectMediaMoveFolder($projectId: ID!, $type: MediaType, $after: String, $perPage: Int = 30, $path: String) {
  project(projectId: $projectId) {
    id
    policy {
      createMedia
    }
    media(type: $type, after: $after, first: $perPage, path: $path, folders: true) @connection(key: "media") {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          ...MediaFields
          user {
            ...UserFields
          }
        }
      }
    }
  }
}
    ${MediaFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectMediaMoveFolderQueryService extends Apollo.Query<ProjectMediaMoveFolderQuery, ProjectMediaMoveFolderQueryVariables> {
  document = ProjectMediaMoveFolderDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectMediaDocument = gql`
    query projectMedia($projectId: ID!, $type: MediaType, $after: String, $perPage: Int = 30, $path: String) {
  project(projectId: $projectId) {
    id
    policy {
      createMedia
    }
    media(type: $type, after: $after, first: $perPage, path: $path, folders: true) @connection(key: "media") {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          ...MediaFields
          user {
            ...UserFields
          }
        }
      }
    }
  }
}
    ${MediaFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectMediaQueryService extends Apollo.Query<ProjectMediaQuery, ProjectMediaQueryVariables> {
  document = ProjectMediaDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectTimesheetsDocument = gql`
    query projectTimesheets($projectId: ID!) {
  project(projectId: $projectId) {
    id
    policy {
      updateTimesheet
      manageTimesheets
    }
    timesheets {
      ...TimesheetFields
    }
  }
}
    ${TimesheetFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectTimesheetsQueryService extends Apollo.Query<ProjectTimesheetsQuery, ProjectTimesheetsQueryVariables> {
  document = ProjectTimesheetsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectTodosDocument = gql`
    query projectTodos($projectId: ID!) {
  project(projectId: $projectId) {
    id
    policy {
      createTodo
      orderTodos
    }
    todos {
      ...TodoFields
      createdByMember {
        ...MemberFields
      }
      assignedMembers {
        ...MemberFields
      }
    }
  }
}
    ${TodoFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectTodosQueryService extends Apollo.Query<ProjectTodosQuery, ProjectTodosQueryVariables> {
  document = ProjectTodosDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectDocument = gql`
    query project($projectId: ID!) {
  project(projectId: $projectId) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectQueryService extends Apollo.Query<ProjectQuery, ProjectQueryVariables> {
  document = ProjectDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

/////////  ///////  TemplatesQueryService
export const WkbQuestionsCompletedDocument = gql`
    query wkbQuestionsCompleted($projectId: ID!) {
      wkbQuestionsCompleted(projectId: $projectId) {
        id
        name
        totalQuestions
        postAnswers(projectId: $projectId)
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class wkbQuestionsCompletedQueryService extends Apollo.Query<WkbQuestionsCompletedQuery, WkbTemplatesQueryVariables> {
  document = WkbQuestionsCompletedDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
//////////////////
/////////  ///////  TemplatesQueryService
export const WkbTemplatesDocument = gql`
    query wkbTemplates {
      wkbTemplates {
        updatedAt
        createdAt
        id
        name
        totalQuestions
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class WkbTemplatesQueryService extends Apollo.Query<WkbTemplatesQuery, WkbTemplatesQueryVariables> {
  document = WkbTemplatesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
//////////////////
/////////  ///////  QuestionsQueryService
export const WkbQuestionsDocument = gql`
    query wkbQuestions($templateIds: [ID!]!, $projectId: ID!) {
      wkbQuestions(templateIds: $templateIds) {
        updatedAt
        createdAt
        id
        name
        category
        topic
        options {
          createdAt
          description
          id
          name
          placeholderCondition
          placeholderText
          updatedAt
          wkbQuestionId
          skip
          createToDo
          toDoTitle
          toDoDescription
          notificationTitle
        }
        answer(projectId: $projectId) {
          comment
          createdAt
          id
          updatedAt
          wkbQuestion {
            id
            name
            category
            topic
          }
          wkbOption {
            id
            name
            description
            placeholderCondition
            placeholderText
            conditional
            skip
          }
        }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})
export class WkbQuestionsQueryService extends Apollo.Query<WkbQuestionsQuery, WkbQuestionsQueryVariables> {
  document = WkbQuestionsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
//////////////////
/////////  ///////  TemplatesQueryService
export const CreateWkbAnswerDocument = gql`
  mutation createWkbAnswer($input: CreateWkbAnswerInputType!) {
    createWkbAnswer(input: $input) {
      errors {
        argument
        error
      }
    }
  }
`;

@Injectable({
  providedIn: 'root'
})

export class createWkbAnswerMutationService extends Apollo.Mutation<CreateWkbAnswerMutation, CreateWkbAnswerMutationVariables> {
  document = CreateWkbAnswerDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
//////////////////

export const ProjectMembersDocument = gql`
    query projectMembers($projectId: ID!) {
  project(projectId: $projectId) {
    id
    policy {
      inviteMember
      inviteUser
    }
    members {
      ...MemberFields
      company {
        ...CompanyFields
      }
    }
  }
}
    ${MemberFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectMembersQueryService extends Apollo.Query<ProjectMembersQuery, ProjectMembersQueryVariables> {
  document = ProjectMembersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectsWithMembersDocument = gql`
    query projectsWithMembers($pageNumber: Int, $activeProject: String, $searchText: String, $limit: Int, $offset: Int) {
  projects(pageNumber: $pageNumber, activeProject: $activeProject, searchText: $searchText, limit: $limit, offset: $offset) {
    ...ProjectFields
    members {
      ...MemberFields
    }
  }
}
    ${ProjectFieldsFragmentDoc2}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectsWithMembersQueryService extends Apollo.Query<ProjectsWithMembersQuery, ProjectsWithMembersQueryVariables> {
  document = ProjectsWithMembersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectsDocument = gql`
    query projects($pageNumber: Int, $activeProject: String, $limit: Int, $offset: Int) {
  projects(pageNumber: $pageNumber, activeProject: $activeProject, limit: $limit, offset: $offset) {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc2}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectsQueryService extends Apollo.Query<ProjectsQuery, ProjectsQueryVariables> {
  document = ProjectsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SubmittedExpensesDocument = gql`
    query submittedExpenses {
  submittedExpenses {
    ...ExpenseFields
  }
}
    ${ExpenseFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class SubmittedExpensesQueryService extends Apollo.Query<SubmittedExpensesQuery, SubmittedExpensesQueryVariables> {
  document = SubmittedExpensesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SubmittedMaterialNotesDocument = gql`
    query submittedMaterialNotes {
  submittedMaterialNotes {
    ...MaterialNoteFields
    materialNoteItems {
      ...MaterialNoteItemFields
    }
  }
}
    ${MaterialNoteFieldsFragmentDoc}
${MaterialNoteItemFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class SubmittedMaterialNotesQueryService extends Apollo.Query<SubmittedMaterialNotesQuery, SubmittedMaterialNotesQueryVariables> {
  document = SubmittedMaterialNotesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SubmittedTimesheetsDocument = gql`
    query submittedTimesheets {
  submittedTimesheets {
    ...TimesheetFields
  }
}
    ${TimesheetFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class SubmittedTimesheetsQueryService extends Apollo.Query<SubmittedTimesheetsQuery, SubmittedTimesheetsQueryVariables> {
  document = SubmittedTimesheetsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TeamDocument = gql`
    query team {
  currentUser {
    ...UserFields
    company {
      ...CompanyFields
      teamMembers {
        ...TeamMemberFields
      }
    }
    policy {
      createProject
      createDemoProject
      createTender
      createAddressValuation
      setupCompanyService
      addTeam
      dashboard
      das
      tutorialSlider
    }
  }
}
    ${UserFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}
${TeamMemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class TeamQueryService extends Apollo.Query<TeamQuery, TeamQueryVariables> {
  document = TeamDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TimesheetDocument = gql`
    query timesheet($timesheetId: ID!) {
  timesheet(timesheetId: $timesheetId) {
    ...TimesheetFields
  }
}
    ${TimesheetFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class TimesheetQueryService extends Apollo.Query<TimesheetQuery, TimesheetQueryVariables> {
  document = TimesheetDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TodoDocument = gql`
    query todo($todoId: ID!) {
  todo(todoId: $todoId) {
    ...TodoFields
    createdByMember {
      ...MemberFields
    }
    assignedMembers {
      ...MemberFields
    }
  }
}
    ${TodoFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class TodoQueryService extends Apollo.Query<TodoQuery, TodoQueryVariables> {
  document = TodoDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const WalterLivingAddressInformationDocument = gql`
    query walterLivingAddressInformation($addressObjectId: ID!) {
  addressInformation(id: $addressObjectId) {
    addressObjectId
    city
    extension
    id
    lat
    lon
    number
    propertyConstructionYear
    propertyEnergyLabel
    street
    wozDate
    wozValue
    zipcode
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class WalterLivingAddressInformationQueryService extends Apollo.Query<WalterLivingAddressInformationQuery, WalterLivingAddressInformationQueryVariables> {
  document = WalterLivingAddressInformationDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const WalterLivingAddressSearchDocument = gql`
    query walterLivingAddressSearch($address: String!) {
  addressSearch(address: $address) {
    addressObjectId
    city
    extension
    id
    lat
    lon
    number
    street
    zipcode
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class WalterLivingAddressSearchQueryService extends Apollo.Query<WalterLivingAddressSearchQuery, WalterLivingAddressSearchQueryVariables> {
  document = WalterLivingAddressSearchDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ActivitySubscriptionDocument = gql`
    subscription activitySubscription {
  activity {
    ...ActivityFields
    createdByMember {
      ...MemberFields
    }
    assignedMembers {
      ...MemberFields
    }
  }
}
    ${ActivityFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ActivitySubscriptionSubscription extends Apollo.Subscription<ActivitySubscription, ActivitySubscriptionVariables> {
  document = ActivitySubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AgreementSubscriptionDocument = gql`
    subscription agreementSubscription {
  agreement {
    ...AgreementFields
    createdByMember {
      ...MemberFields
    }
    counterpartyMember {
      ...MemberFields
    }
    media {
      ...MediaFields
    }
  }
}
    ${AgreementFieldsFragmentDoc}
${MemberFieldsFragmentDoc}
${MediaFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class AgreementSubscriptionSubscription extends Apollo.Subscription<AgreementSubscription, AgreementSubscriptionVariables> {
  document = AgreementSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ChatSubscriptionDocument = gql`
    subscription chatSubscription {
  chat {
    ...ChatFields
    participants {
      ...ParticipantFields
      member {
        ...MemberFields
      }
    }
  }
}
    ${ChatFieldsFragmentDoc}
${ParticipantFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ChatSubscriptionSubscription extends Apollo.Subscription<ChatSubscription, ChatSubscriptionVariables> {
  document = ChatSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DeleteSubscriptionDocument = gql`
    subscription deleteSubscription {
  delete {
    id
    type
  }
}
    `;

@Injectable({
  providedIn: 'root'
})
export class DeleteSubscriptionSubscription extends Apollo.Subscription<DeleteSubscription, DeleteSubscriptionVariables> {
  document = DeleteSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const EventSubscriptionDocument = gql`
    subscription eventSubscription {
  event {
    ...EventFields
    user {
      ...UserFields
    }
  }
}
    ${EventFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class EventSubscriptionSubscription extends Apollo.Subscription<EventSubscription, EventSubscriptionVariables> {
  document = EventSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ExpenseSubscriptionDocument = gql`
    subscription expenseSubscription {
  expense {
    ...ExpenseFields
    media {
      ...MediaFields
    }
  }
}
    ${ExpenseFieldsFragmentDoc}
${MediaFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ExpenseSubscriptionSubscription extends Apollo.Subscription<ExpenseSubscription, ExpenseSubscriptionVariables> {
  document = ExpenseSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MaterialNoteSubscriptionDocument = gql`
    subscription materialNoteSubscription {
  materialNote {
    ...MaterialNoteFields
    materialNoteItems {
      ...MaterialNoteItemFields
    }
  }
}
    ${MaterialNoteFieldsFragmentDoc}
${MaterialNoteItemFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class MaterialNoteSubscriptionSubscription extends Apollo.Subscription<MaterialNoteSubscription, MaterialNoteSubscriptionVariables> {
  document = MaterialNoteSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MediaSubscriptionDocument = gql`
    subscription mediaSubscription {
  media {
    ...MediaFields
  }
}
    ${MediaFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class MediaSubscriptionSubscription extends Apollo.Subscription<MediaSubscription, MediaSubscriptionVariables> {
  document = MediaSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MemberSubscriptionDocument = gql`
    subscription memberSubscription {
  member {
    ...MemberFields
    company {
      ...CompanyFields
    }
  }
}
    ${MemberFieldsFragmentDoc}
${CompanyFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class MemberSubscriptionSubscription extends Apollo.Subscription<MemberSubscription, MemberSubscriptionVariables> {
  document = MemberSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ParticipantSubscriptionDocument = gql`
    subscription participantSubscription {
  participant {
    ...ParticipantFields
    member {
      id
    }
  }
}
    ${ParticipantFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ParticipantSubscriptionSubscription extends Apollo.Subscription<ParticipantSubscription, ParticipantSubscriptionVariables> {
  document = ParticipantSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProjectSubscriptionDocument = gql`
    subscription projectSubscription {
  project {
    ...ProjectFields
  }
}
    ${ProjectFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class ProjectSubscriptionSubscription extends Apollo.Subscription<ProjectSubscription, ProjectSubscriptionVariables> {
  document = ProjectSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TimesheetSubscriptionDocument = gql`
    subscription timesheetSubscription {
  timesheet {
    ...TimesheetFields
  }
}
    ${TimesheetFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class TimesheetSubscriptionSubscription extends Apollo.Subscription<TimesheetSubscription, TimesheetSubscriptionVariables> {
  document = TimesheetSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const TodoSubscriptionDocument = gql`
    subscription todoSubscription {
  todo {
    ...TodoFields
    createdByMember {
      ...MemberFields
    }
    assignedMembers {
      ...MemberFields
    }
  }
}
    ${TodoFieldsFragmentDoc}
${MemberFieldsFragmentDoc}`;

@Injectable({
  providedIn: 'root'
})
export class TodoSubscriptionSubscription extends Apollo.Subscription<TodoSubscription, TodoSubscriptionVariables> {
  document = TodoSubscriptionDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

interface WatchQueryOptionsAlone<V>
  extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> { }

interface QueryOptionsAlone<V>
  extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> { }

interface MutationOptionsAlone<T, V>
  extends Omit<ApolloCore.MutationOptions<T, V>, 'mutation' | 'variables'> { }

interface SubscriptionOptionsAlone<V>
  extends Omit<ApolloCore.SubscriptionOptions<V>, 'query' | 'variables'> { }

@Injectable({ providedIn: 'root' })
export class HomerunSDK {
  constructor(
    private acceptAgreementMutationService: AcceptAgreementMutationService,
    private acceptInvitationMutationService: AcceptInvitationMutationService,
    private addressSearchMutationService: AddressSearchMutationService,
    private approveExpenseMutationService: ApproveExpenseMutationService,
    private approveMaterialNoteMutationService: ApproveMaterialNoteMutationService,
    private approveTimesheetMutationService: ApproveTimesheetMutationService,
    private archiveActivityMutationService: ArchiveActivityMutationService,
    private archiveGroupChatMutationService: ArchiveGroupChatMutationService,
    private archiveProjectMutationService: ArchiveProjectMutationService,
    private cancelSubscriptionMutationService: CancelSubscriptionMutationService,
    private contactDasMutationService: ContactDasMutationService,
    private createActivityMutationService: CreateActivityMutationService,
    private createAddressValuationMutationService: CreateAddressValuationMutationService,
    private createAgreementMutationService: CreateAgreementMutationService,
    private createDemoProjectMutationService: CreateDemoProjectMutationService,
    private createExpenseMutationService: CreateExpenseMutationService,
    private createFolderMutationService: CreateFolderMutationService,
    private createGroupChatMutationService: CreateGroupChatMutationService,
    private createMaterialNoteItemMutationService: CreateMaterialNoteItemMutationService,
    private createMaterialNoteMutationService: CreateMaterialNoteMutationService,
    private createMediaMessageMutationService: CreateMediaMessageMutationService,
    private createMediaMutationService: CreateMediaMutationService,
    private createMessageMutationService: CreateMessageMutationService,
    private createParticipantsMutationService: CreateParticipantsMutationService,
    private createPrivateChatMutationService: CreatePrivateChatMutationService,
    private createProjectMutationService: CreateProjectMutationService,
    private createReplyMessageMutationService: CreateReplyMessageMutationService,
    private createSubscriptionMutationService: CreateSubscriptionMutationService,
    private createTeamMemberMutationService: CreateTeamMemberMutationService,
    private createTenderMutationService: CreateTenderMutationService,
    private createTodoMutationService: CreateTodoMutationService,
    private deactivateMemberMutationService: DeactivateMemberMutationService,
    private deactivateParticipantMutationService: DeactivateParticipantMutationService,
    private deleteAgreementMutationService: DeleteAgreementMutationService,
    private deleteEventMutationService: DeleteEventMutationService,
    private deleteMaterialNoteItemMutationService: DeleteMaterialNoteItemMutationService,
    private deleteMaterialNoteMutationService: DeleteMaterialNoteMutationService,
    private deleteMediaMutationService: DeleteMediaMutationService,
    private deleteTeamMemberMutationService: DeleteTeamMemberMutationService,
    private deleteTodoMutationService: DeleteTodoMutationService,
    private demoteMemberMutationService: DemoteMemberMutationService,
    private demoteParticipantMutationService: DemoteParticipantMutationService,
    private exportMaterialNotesMutationService: ExportMaterialNotesMutationService,
    private exportExpenseMutationService: ExportExpenseMutationService,
    private exportTimesheetMutationService: ExportTimesheetMutationService,
    private inviteMemberMutationService: InviteMemberMutationService,
    private inviteUserMutationService: InviteUserMutationService,
    private inviteUsersMutationService: InviteUsersMutationService,
    private leaveGroupChatMutationService: LeaveGroupChatMutationService,
    private logoutMutationService: LogoutMutationService,
    private mailInvitationMutationService: MailInvitationMutationService,
    private moveMediaMutationService: MoveMediaMutationService,
    private openedInvitationMutationService: OpenedInvitationMutationService,
    private orderActivitiesMutationService: OrderActivitiesMutationService,
    private orderTodosMutationService: OrderTodosMutationService,
    private pdfActivitiesMutationService: PdfActivitiesMutationService,
    private promoteMemberMutationService: PromoteMemberMutationService,
    private promoteParticipantMutationService: PromoteParticipantMutationService,
    private refreshTokenMutationService: RefreshTokenMutationService,
    private registerDeviceMutationService: RegisterDeviceMutationService,
    private rejectAgreementMutationService: RejectAgreementMutationService,
    private rejectExpenseMutationService: RejectExpenseMutationService,
    private rejectMaterialNoteMutationService: RejectMaterialNoteMutationService,
    private rejectTimesheetMutationService: RejectTimesheetMutationService,
    private resetNotificationsMutationService: ResetNotificationsMutationService,
    private sendFeedbackMutationService: SendFeedbackMutationService,
    private setTypingMutationService: SetTypingMutationService,
    private setPresenceMutationService: SetPresenceMutationService,
    private shareInvitationMutationService: ShareInvitationMutationService,
    private submitExpenseMutationService: SubmitExpenseMutationService,
    private submitMaterialNoteMutationService: SubmitMaterialNoteMutationService,
    private submitTimesheetMutationService: SubmitTimesheetMutationService,
    private unarchiveGroupChatMutationService: UnarchiveGroupChatMutationService,
    private unarchiveProjectMutationService: UnarchiveProjectMutationService,
    private unsubscribeEmailMutationService: UnsubscribeEmailMutationService,
    private updateActivityMutationService: UpdateActivityMutationService,
    private updateAgreementMutationService: UpdateAgreementMutationService,
    private updateCompanyMutationService: UpdateCompanyMutationService,
    private updateExpenseMutationService: UpdateExpenseMutationService,
    private updateFolderMutationService: UpdateFolderMutationService,
    private updateGroupChatMutationService: UpdateGroupChatMutationService,
    private updateMaterialNoteItemMutationService: UpdateMaterialNoteItemMutationService,
    private updateMemberMutationService: UpdateMemberMutationService,
    private updatePasswordMutationService: UpdatePasswordMutationService,
    private updateProjectMutationService: UpdateProjectMutationService,
    private updateTimesheetMutationService: UpdateTimesheetMutationService,
    private updateTodoMutationService: UpdateTodoMutationService,
    private updateUserMutationService: UpdateUserMutationService,
    private activitiesQueryService: ActivitiesQueryService,
    private activityQueryService: ActivityQueryService,
    private agreementQueryService: AgreementQueryService,
    private chatEventsQueryService: ChatEventsQueryService,
    private chatQueryService: ChatQueryService,
    private companyTeamMemberSuggestionsQueryService: CompanyTeamMemberSuggestionsQueryService,
    private currentUserQueryService: CurrentUserQueryService,
    private expenseQueryService: ExpenseQueryService,
    private invitationByTokenQueryService: InvitationByTokenQueryService,
    private invitationQueryService: InvitationQueryService,
    private invitationsQueryService: InvitationsQueryService,
    private materialNoteItemQueryService: MaterialNoteItemQueryService,
    private materialNoteQueryService: MaterialNoteQueryService,
    private mediaQueryService: MediaQueryService,
    private memberQueryService: MemberQueryService,
    private openAgreementsQueryService: OpenAgreementsQueryService,
    private openTodosQueryService: OpenTodosQueryService,
    private projectActivitiesQueryService: ProjectActivitiesQueryService,
    private projectAgreementsQueryService: ProjectAgreementsQueryService,
    private projectChatsQueryService: ProjectChatsQueryService,
    private projectCostsQueryService: ProjectCostsQueryService,
    private projectCurrentMemberTimesheetsQueryService: ProjectCurrentMemberTimesheetsQueryService,
    private projectExpensesQueryService: ProjectExpensesQueryService,
    private projectInvitationSuggestionUsersQueryService: ProjectInvitationSuggestionUsersQueryService,
    private projectMaterialNotesQueryService: ProjectMaterialNotesQueryService,
    private projectMediaMoveFolderQueryService: ProjectMediaMoveFolderQueryService,
    private projectMediaQueryService: ProjectMediaQueryService,
    private projectTimesheetsQueryService: ProjectTimesheetsQueryService,
    private projectTodosQueryService: ProjectTodosQueryService,
    private projectQueryService: ProjectQueryService,
    private wkbTemplatesQueryService: WkbTemplatesQueryService,
    private wkbQuestionsCompletedQueryService: wkbQuestionsCompletedQueryService,
    private wkbQuestionsQueryService: WkbQuestionsQueryService,
    private createWkbAnswerMutationService: createWkbAnswerMutationService,
    private projectMembersQueryService: ProjectMembersQueryService,
    private projectsWithMembersQueryService: ProjectsWithMembersQueryService,
    private projectsQueryService: ProjectsQueryService,
    private submittedExpensesQueryService: SubmittedExpensesQueryService,
    private submittedMaterialNotesQueryService: SubmittedMaterialNotesQueryService,
    private submittedTimesheetsQueryService: SubmittedTimesheetsQueryService,
    private teamQueryService: TeamQueryService,
    private timesheetQueryService: TimesheetQueryService,
    private todoQueryService: TodoQueryService,
    private walterLivingAddressInformationQueryService: WalterLivingAddressInformationQueryService,
    private walterLivingAddressSearchQueryService: WalterLivingAddressSearchQueryService,
    private activitySubscriptionSubscription: ActivitySubscriptionSubscription,
    private agreementSubscriptionSubscription: AgreementSubscriptionSubscription,
    private chatSubscriptionSubscription: ChatSubscriptionSubscription,
    private deleteSubscriptionSubscription: DeleteSubscriptionSubscription,
    private eventSubscriptionSubscription: EventSubscriptionSubscription,
    private expenseSubscriptionSubscription: ExpenseSubscriptionSubscription,
    private materialNoteSubscriptionSubscription: MaterialNoteSubscriptionSubscription,
    private mediaSubscriptionSubscription: MediaSubscriptionSubscription,
    private memberSubscriptionSubscription: MemberSubscriptionSubscription,
    private participantSubscriptionSubscription: ParticipantSubscriptionSubscription,
    private projectSubscriptionSubscription: ProjectSubscriptionSubscription,
    private timesheetSubscriptionSubscription: TimesheetSubscriptionSubscription,
    private todoSubscriptionSubscription: TodoSubscriptionSubscription
  ) { }

  acceptAgreement(variables: AcceptAgreementMutationVariables, options?: MutationOptionsAlone<AcceptAgreementMutation, AcceptAgreementMutationVariables>) {
    return this.acceptAgreementMutationService.mutate(variables, options)
  }

  acceptInvitation(variables: AcceptInvitationMutationVariables, options?: MutationOptionsAlone<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
    return this.acceptInvitationMutationService.mutate(variables, options)
  }

  addressSearch(variables: AddressSearchMutationVariables, options?: MutationOptionsAlone<AddressSearchMutation, AddressSearchMutationVariables>) {
    return this.addressSearchMutationService.mutate(variables, options)
  }

  approveExpense(variables: ApproveExpenseMutationVariables, options?: MutationOptionsAlone<ApproveExpenseMutation, ApproveExpenseMutationVariables>) {
    return this.approveExpenseMutationService.mutate(variables, options)
  }

  approveMaterialNote(variables: ApproveMaterialNoteMutationVariables, options?: MutationOptionsAlone<ApproveMaterialNoteMutation, ApproveMaterialNoteMutationVariables>) {
    return this.approveMaterialNoteMutationService.mutate(variables, options)
  }

  approveTimesheet(variables: ApproveTimesheetMutationVariables, options?: MutationOptionsAlone<ApproveTimesheetMutation, ApproveTimesheetMutationVariables>) {
    return this.approveTimesheetMutationService.mutate(variables, options)
  }

  archiveActivity(variables: ArchiveActivityMutationVariables, options?: MutationOptionsAlone<ArchiveActivityMutation, ArchiveActivityMutationVariables>) {
    return this.archiveActivityMutationService.mutate(variables, options)
  }

  archiveGroupChat(variables: ArchiveGroupChatMutationVariables, options?: MutationOptionsAlone<ArchiveGroupChatMutation, ArchiveGroupChatMutationVariables>) {
    return this.archiveGroupChatMutationService.mutate(variables, options)
  }

  archiveProject(variables: ArchiveProjectMutationVariables, options?: MutationOptionsAlone<ArchiveProjectMutation, ArchiveProjectMutationVariables>) {
    return this.archiveProjectMutationService.mutate(variables, options)
  }

  cancelSubscription(variables?: CancelSubscriptionMutationVariables, options?: MutationOptionsAlone<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
    return this.cancelSubscriptionMutationService.mutate(variables, options)
  }

  contactDas(variables: ContactDasMutationVariables, options?: MutationOptionsAlone<ContactDasMutation, ContactDasMutationVariables>) {
    return this.contactDasMutationService.mutate(variables, options)
  }

  createActivity(variables: CreateActivityMutationVariables, options?: MutationOptionsAlone<CreateActivityMutation, CreateActivityMutationVariables>) {
    return this.createActivityMutationService.mutate(variables, options)
  }

  createAddressValuation(variables: CreateAddressValuationMutationVariables, options?: MutationOptionsAlone<CreateAddressValuationMutation, CreateAddressValuationMutationVariables>) {
    return this.createAddressValuationMutationService.mutate(variables, options)
  }

  createAgreement(variables: CreateAgreementMutationVariables, options?: MutationOptionsAlone<CreateAgreementMutation, CreateAgreementMutationVariables>) {
    return this.createAgreementMutationService.mutate(variables, options)
  }

  createDemoProject(variables?: CreateDemoProjectMutationVariables, options?: MutationOptionsAlone<CreateDemoProjectMutation, CreateDemoProjectMutationVariables>) {
    return this.createDemoProjectMutationService.mutate(variables, options)
  }

  createExpense(variables: CreateExpenseMutationVariables, options?: MutationOptionsAlone<CreateExpenseMutation, CreateExpenseMutationVariables>) {
    return this.createExpenseMutationService.mutate(variables, options)
  }

  createFolder(variables: CreateFolderMutationVariables, options?: MutationOptionsAlone<CreateFolderMutation, CreateFolderMutationVariables>) {
    return this.createFolderMutationService.mutate(variables, options)
  }

  createGroupChat(variables: CreateGroupChatMutationVariables, options?: MutationOptionsAlone<CreateGroupChatMutation, CreateGroupChatMutationVariables>) {
    return this.createGroupChatMutationService.mutate(variables, options)
  }

  createMaterialNoteItem(variables: CreateMaterialNoteItemMutationVariables, options?: MutationOptionsAlone<CreateMaterialNoteItemMutation, CreateMaterialNoteItemMutationVariables>) {
    return this.createMaterialNoteItemMutationService.mutate(variables, options)
  }

  createMaterialNote(variables: CreateMaterialNoteMutationVariables, options?: MutationOptionsAlone<CreateMaterialNoteMutation, CreateMaterialNoteMutationVariables>) {
    return this.createMaterialNoteMutationService.mutate(variables, options)
  }

  createMediaMessage(variables: CreateMediaMessageMutationVariables, options?: MutationOptionsAlone<CreateMediaMessageMutation, CreateMediaMessageMutationVariables>) {
    return this.createMediaMessageMutationService.mutate(variables, options)
  }

  createMedia(variables: CreateMediaMutationVariables, options?: MutationOptionsAlone<CreateMediaMutation, CreateMediaMutationVariables>) {
    return this.createMediaMutationService.mutate(variables, options)
  }

  createMessage(variables: CreateMessageMutationVariables, options?: MutationOptionsAlone<CreateMessageMutation, CreateMessageMutationVariables>) {
    return this.createMessageMutationService.mutate(variables, options)
  }

  createParticipants(variables: CreateParticipantsMutationVariables, options?: MutationOptionsAlone<CreateParticipantsMutation, CreateParticipantsMutationVariables>) {
    return this.createParticipantsMutationService.mutate(variables, options)
  }

  createPrivateChat(variables: CreatePrivateChatMutationVariables, options?: MutationOptionsAlone<CreatePrivateChatMutation, CreatePrivateChatMutationVariables>) {
    return this.createPrivateChatMutationService.mutate(variables, options)
  }

  createProject(variables: CreateProjectMutationVariables, options?: MutationOptionsAlone<CreateProjectMutation, CreateProjectMutationVariables>) {
    return this.createProjectMutationService.mutate(variables, options)
  }

  createReplyMessage(variables: CreateReplyMessageMutationVariables, options?: MutationOptionsAlone<CreateReplyMessageMutation, CreateReplyMessageMutationVariables>) {
    return this.createReplyMessageMutationService.mutate(variables, options)
  }

  createSubscription(variables: CreateSubscriptionMutationVariables, options?: MutationOptionsAlone<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
    return this.createSubscriptionMutationService.mutate(variables, options)
  }

  createTeamMember(variables: CreateTeamMemberMutationVariables, options?: MutationOptionsAlone<CreateTeamMemberMutation, CreateTeamMemberMutationVariables>) {
    return this.createTeamMemberMutationService.mutate(variables, options)
  }

  createTender(variables: CreateTenderMutationVariables, options?: MutationOptionsAlone<CreateTenderMutation, CreateTenderMutationVariables>) {
    return this.createTenderMutationService.mutate(variables, options)
  }

  createTodo(variables: CreateTodoMutationVariables, options?: MutationOptionsAlone<CreateTodoMutation, CreateTodoMutationVariables>) {
    return this.createTodoMutationService.mutate(variables, options)
  }

  deactivateMember(variables: DeactivateMemberMutationVariables, options?: MutationOptionsAlone<DeactivateMemberMutation, DeactivateMemberMutationVariables>) {
    return this.deactivateMemberMutationService.mutate(variables, options)
  }

  deactivateParticipant(variables: DeactivateParticipantMutationVariables, options?: MutationOptionsAlone<DeactivateParticipantMutation, DeactivateParticipantMutationVariables>) {
    return this.deactivateParticipantMutationService.mutate(variables, options)
  }

  deleteAgreement(variables: DeleteAgreementMutationVariables, options?: MutationOptionsAlone<DeleteAgreementMutation, DeleteAgreementMutationVariables>) {
    return this.deleteAgreementMutationService.mutate(variables, options)
  }

  deleteEvent(variables: DeleteEventMutationVariables, options?: MutationOptionsAlone<DeleteEventMutation, DeleteEventMutationVariables>) {
    return this.deleteEventMutationService.mutate(variables, options)
  }

  deleteMaterialNoteItem(variables: DeleteMaterialNoteItemMutationVariables, options?: MutationOptionsAlone<DeleteMaterialNoteItemMutation, DeleteMaterialNoteItemMutationVariables>) {
    return this.deleteMaterialNoteItemMutationService.mutate(variables, options)
  }

  deleteMaterialNote(variables: DeleteMaterialNoteMutationVariables, options?: MutationOptionsAlone<DeleteMaterialNoteMutation, DeleteMaterialNoteMutationVariables>) {
    return this.deleteMaterialNoteMutationService.mutate(variables, options)
  }

  deleteMedia(variables: DeleteMediaMutationVariables, options?: MutationOptionsAlone<DeleteMediaMutation, DeleteMediaMutationVariables>) {
    return this.deleteMediaMutationService.mutate(variables, options)
  }

  deleteTeamMember(variables: DeleteTeamMemberMutationVariables, options?: MutationOptionsAlone<DeleteTeamMemberMutation, DeleteTeamMemberMutationVariables>) {
    return this.deleteTeamMemberMutationService.mutate(variables, options)
  }

  deleteTodo(variables: DeleteTodoMutationVariables, options?: MutationOptionsAlone<DeleteTodoMutation, DeleteTodoMutationVariables>) {
    return this.deleteTodoMutationService.mutate(variables, options)
  }

  demoteMember(variables: DemoteMemberMutationVariables, options?: MutationOptionsAlone<DemoteMemberMutation, DemoteMemberMutationVariables>) {
    return this.demoteMemberMutationService.mutate(variables, options)
  }

  demoteParticipant(variables: DemoteParticipantMutationVariables, options?: MutationOptionsAlone<DemoteParticipantMutation, DemoteParticipantMutationVariables>) {
    return this.demoteParticipantMutationService.mutate(variables, options)
  }

  exportMaterialNotes(variables: ExportMaterialNotesMutationVariables, options?: MutationOptionsAlone<ExportMaterialNotesMutation, ExportMaterialNotesMutationVariables>) {
    return this.exportMaterialNotesMutationService.mutate(variables, options)
  }

  exportExpense(variables: ExportExpenseMutationVariables, options?: MutationOptionsAlone<ExportExpenseMutation, ExportExpenseMutationVariables>) {
    return this.exportExpenseMutationService.mutate(variables, options)
  }

  exportTimesheet(variables: ExportTimesheetMutationVariables, options?: MutationOptionsAlone<ExportTimesheetMutation, ExportTimesheetMutationVariables>) {
    return this.exportTimesheetMutationService.mutate(variables, options)
  }

  inviteMember(variables: InviteMemberMutationVariables, options?: MutationOptionsAlone<InviteMemberMutation, InviteMemberMutationVariables>) {
    return this.inviteMemberMutationService.mutate(variables, options)
  }

  inviteUser(variables: InviteUserMutationVariables, options?: MutationOptionsAlone<InviteUserMutation, InviteUserMutationVariables>) {
    return this.inviteUserMutationService.mutate(variables, options)
  }

  inviteUsers(variables: InviteUsersMutationVariables, options?: MutationOptionsAlone<InviteUsersMutation, InviteUsersMutationVariables>) {
    return this.inviteUsersMutationService.mutate(variables, options)
  }

  leaveGroupChat(variables: LeaveGroupChatMutationVariables, options?: MutationOptionsAlone<LeaveGroupChatMutation, LeaveGroupChatMutationVariables>) {
    return this.leaveGroupChatMutationService.mutate(variables, options)
  }

  logout(variables: LogoutMutationVariables, options?: MutationOptionsAlone<LogoutMutation, LogoutMutationVariables>) {
    return this.logoutMutationService.mutate(variables, options)
  }

  mailInvitation(variables: MailInvitationMutationVariables, options?: MutationOptionsAlone<MailInvitationMutation, MailInvitationMutationVariables>) {
    return this.mailInvitationMutationService.mutate(variables, options)
  }

  moveMedia(variables: MoveMediaMutationVariables, options?: MutationOptionsAlone<MoveMediaMutation, MoveMediaMutationVariables>) {
    return this.moveMediaMutationService.mutate(variables, options)
  }

  openedInvitation(variables: OpenedInvitationMutationVariables, options?: MutationOptionsAlone<OpenedInvitationMutation, OpenedInvitationMutationVariables>) {
    return this.openedInvitationMutationService.mutate(variables, options)
  }

  orderActivities(variables: OrderActivitiesMutationVariables, options?: MutationOptionsAlone<OrderActivitiesMutation, OrderActivitiesMutationVariables>) {
    return this.orderActivitiesMutationService.mutate(variables, options)
  }

  orderTodos(variables: OrderTodosMutationVariables, options?: MutationOptionsAlone<OrderTodosMutation, OrderTodosMutationVariables>) {
    return this.orderTodosMutationService.mutate(variables, options)
  }

  pdfActivities(variables: PdfActivitiesMutationVariables, options?: MutationOptionsAlone<PdfActivitiesMutation, PdfActivitiesMutationVariables>) {
    return this.pdfActivitiesMutationService.mutate(variables, options)
  }

  promoteMember(variables: PromoteMemberMutationVariables, options?: MutationOptionsAlone<PromoteMemberMutation, PromoteMemberMutationVariables>) {
    return this.promoteMemberMutationService.mutate(variables, options)
  }

  promoteParticipant(variables: PromoteParticipantMutationVariables, options?: MutationOptionsAlone<PromoteParticipantMutation, PromoteParticipantMutationVariables>) {
    return this.promoteParticipantMutationService.mutate(variables, options)
  }

  refreshToken(variables: RefreshTokenMutationVariables, options?: MutationOptionsAlone<RefreshTokenMutation, RefreshTokenMutationVariables>) {
    return this.refreshTokenMutationService.mutate(variables, options)
  }

  registerDevice(variables: RegisterDeviceMutationVariables, options?: MutationOptionsAlone<RegisterDeviceMutation, RegisterDeviceMutationVariables>) {
    return this.registerDeviceMutationService.mutate(variables, options)
  }

  rejectAgreement(variables: RejectAgreementMutationVariables, options?: MutationOptionsAlone<RejectAgreementMutation, RejectAgreementMutationVariables>) {
    return this.rejectAgreementMutationService.mutate(variables, options)
  }

  rejectExpense(variables: RejectExpenseMutationVariables, options?: MutationOptionsAlone<RejectExpenseMutation, RejectExpenseMutationVariables>) {
    return this.rejectExpenseMutationService.mutate(variables, options)
  }

  rejectMaterialNote(variables: RejectMaterialNoteMutationVariables, options?: MutationOptionsAlone<RejectMaterialNoteMutation, RejectMaterialNoteMutationVariables>) {
    return this.rejectMaterialNoteMutationService.mutate(variables, options)
  }

  rejectTimesheet(variables: RejectTimesheetMutationVariables, options?: MutationOptionsAlone<RejectTimesheetMutation, RejectTimesheetMutationVariables>) {
    return this.rejectTimesheetMutationService.mutate(variables, options)
  }

  resetNotifications(variables: ResetNotificationsMutationVariables, options?: MutationOptionsAlone<ResetNotificationsMutation, ResetNotificationsMutationVariables>) {
    return this.resetNotificationsMutationService.mutate(variables, options)
  }

  sendFeedback(variables: SendFeedbackMutationVariables, options?: MutationOptionsAlone<SendFeedbackMutation, SendFeedbackMutationVariables>) {
    return this.sendFeedbackMutationService.mutate(variables, options)
  }

  setTyping(variables: SetTypingMutationVariables, options?: MutationOptionsAlone<SetTypingMutation, SetTypingMutationVariables>) {
    return this.setTypingMutationService.mutate(variables, options)
  }

  setPresence(variables: SetPresenceMutationVariables, options?: MutationOptionsAlone<SetPresenceMutation, SetPresenceMutationVariables>) {
    return this.setPresenceMutationService.mutate(variables, options)
  }

  shareInvitation(variables: ShareInvitationMutationVariables, options?: MutationOptionsAlone<ShareInvitationMutation, ShareInvitationMutationVariables>) {
    return this.shareInvitationMutationService.mutate(variables, options)
  }

  submitExpense(variables: SubmitExpenseMutationVariables, options?: MutationOptionsAlone<SubmitExpenseMutation, SubmitExpenseMutationVariables>) {
    return this.submitExpenseMutationService.mutate(variables, options)
  }

  submitMaterialNote(variables: SubmitMaterialNoteMutationVariables, options?: MutationOptionsAlone<SubmitMaterialNoteMutation, SubmitMaterialNoteMutationVariables>) {
    return this.submitMaterialNoteMutationService.mutate(variables, options)
  }

  submitTimesheet(variables: SubmitTimesheetMutationVariables, options?: MutationOptionsAlone<SubmitTimesheetMutation, SubmitTimesheetMutationVariables>) {
    return this.submitTimesheetMutationService.mutate(variables, options)
  }

  unarchiveGroupChat(variables: UnarchiveGroupChatMutationVariables, options?: MutationOptionsAlone<UnarchiveGroupChatMutation, UnarchiveGroupChatMutationVariables>) {
    return this.unarchiveGroupChatMutationService.mutate(variables, options)
  }

  unarchiveProject(variables: UnarchiveProjectMutationVariables, options?: MutationOptionsAlone<UnarchiveProjectMutation, UnarchiveProjectMutationVariables>) {
    return this.unarchiveProjectMutationService.mutate(variables, options)
  }

  unsubscribeEmail(variables: UnsubscribeEmailMutationVariables, options?: MutationOptionsAlone<UnsubscribeEmailMutation, UnsubscribeEmailMutationVariables>) {
    return this.unsubscribeEmailMutationService.mutate(variables, options)
  }

  updateActivity(variables: UpdateActivityMutationVariables, options?: MutationOptionsAlone<UpdateActivityMutation, UpdateActivityMutationVariables>) {
    return this.updateActivityMutationService.mutate(variables, options)
  }

  updateAgreement(variables: UpdateAgreementMutationVariables, options?: MutationOptionsAlone<UpdateAgreementMutation, UpdateAgreementMutationVariables>) {
    return this.updateAgreementMutationService.mutate(variables, options)
  }

  updateCompany(variables: UpdateCompanyMutationVariables, options?: MutationOptionsAlone<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
    return this.updateCompanyMutationService.mutate(variables, options)
  }

  updateExpense(variables: UpdateExpenseMutationVariables, options?: MutationOptionsAlone<UpdateExpenseMutation, UpdateExpenseMutationVariables>) {
    return this.updateExpenseMutationService.mutate(variables, options)
  }

  updateFolder(variables: UpdateFolderMutationVariables, options?: MutationOptionsAlone<UpdateFolderMutation, UpdateFolderMutationVariables>) {
    return this.updateFolderMutationService.mutate(variables, options)
  }

  updateGroupChat(variables: UpdateGroupChatMutationVariables, options?: MutationOptionsAlone<UpdateGroupChatMutation, UpdateGroupChatMutationVariables>) {
    return this.updateGroupChatMutationService.mutate(variables, options)
  }

  updateMaterialNoteItem(variables: UpdateMaterialNoteItemMutationVariables, options?: MutationOptionsAlone<UpdateMaterialNoteItemMutation, UpdateMaterialNoteItemMutationVariables>) {
    return this.updateMaterialNoteItemMutationService.mutate(variables, options)
  }

  updateMember(variables: UpdateMemberMutationVariables, options?: MutationOptionsAlone<UpdateMemberMutation, UpdateMemberMutationVariables>) {
    return this.updateMemberMutationService.mutate(variables, options)
  }

  updatePassword(variables: UpdatePasswordMutationVariables, options?: MutationOptionsAlone<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
    return this.updatePasswordMutationService.mutate(variables, options)
  }

  updateProject(variables: UpdateProjectMutationVariables, options?: MutationOptionsAlone<UpdateProjectMutation, UpdateProjectMutationVariables>) {
    return this.updateProjectMutationService.mutate(variables, options)
  }

  updateTimesheet(variables: UpdateTimesheetMutationVariables, options?: MutationOptionsAlone<UpdateTimesheetMutation, UpdateTimesheetMutationVariables>) {
    return this.updateTimesheetMutationService.mutate(variables, options)
  }

  updateTodo(variables: UpdateTodoMutationVariables, options?: MutationOptionsAlone<UpdateTodoMutation, UpdateTodoMutationVariables>) {
    return this.updateTodoMutationService.mutate(variables, options)
  }

  updateUser(variables: UpdateUserMutationVariables, options?: MutationOptionsAlone<UpdateUserMutation, UpdateUserMutationVariables>) {
    return this.updateUserMutationService.mutate(variables, options)
  }

  activities(variables?: ActivitiesQueryVariables, options?: QueryOptionsAlone<ActivitiesQueryVariables>) {
    return this.activitiesQueryService.fetch(variables, options)
  }

  activitiesWatch(variables?: ActivitiesQueryVariables, options?: WatchQueryOptionsAlone<ActivitiesQueryVariables>) {
    return this.activitiesQueryService.watch(variables, options)
  }

  activity(variables: ActivityQueryVariables, options?: QueryOptionsAlone<ActivityQueryVariables>) {
    return this.activityQueryService.fetch(variables, options)
  }

  activityWatch(variables: ActivityQueryVariables, options?: WatchQueryOptionsAlone<ActivityQueryVariables>) {
    return this.activityQueryService.watch(variables, options)
  }

  agreement(variables: AgreementQueryVariables, options?: QueryOptionsAlone<AgreementQueryVariables>) {
    return this.agreementQueryService.fetch(variables, options)
  }

  agreementWatch(variables: AgreementQueryVariables, options?: WatchQueryOptionsAlone<AgreementQueryVariables>) {
    return this.agreementQueryService.watch(variables, options)
  }

  chatEvents(variables: ChatEventsQueryVariables, options?: QueryOptionsAlone<ChatEventsQueryVariables>) {
    return this.chatEventsQueryService.fetch(variables, options)
  }

  chatEventsWatch(variables: ChatEventsQueryVariables, options?: WatchQueryOptionsAlone<ChatEventsQueryVariables>) {
    return this.chatEventsQueryService.watch(variables, options)
  }

  chat(variables: ChatQueryVariables, options?: QueryOptionsAlone<ChatQueryVariables>) {
    return this.chatQueryService.fetch(variables, options)
  }

  chatWatch(variables: ChatQueryVariables, options?: WatchQueryOptionsAlone<ChatQueryVariables>) {
    return this.chatQueryService.watch(variables, options)
  }

  companyTeamMemberSuggestions(variables?: CompanyTeamMemberSuggestionsQueryVariables, options?: QueryOptionsAlone<CompanyTeamMemberSuggestionsQueryVariables>) {
    return this.companyTeamMemberSuggestionsQueryService.fetch(variables, options)
  }

  companyTeamMemberSuggestionsWatch(variables?: CompanyTeamMemberSuggestionsQueryVariables, options?: WatchQueryOptionsAlone<CompanyTeamMemberSuggestionsQueryVariables>) {
    return this.companyTeamMemberSuggestionsQueryService.watch(variables, options)
  }

  currentUser(variables?: CurrentUserQueryVariables, options?: QueryOptionsAlone<CurrentUserQueryVariables>) {
    return this.currentUserQueryService.fetch(variables, options)
  }

  currentUserWatch(variables?: CurrentUserQueryVariables, options?: WatchQueryOptionsAlone<CurrentUserQueryVariables>) {
    return this.currentUserQueryService.watch(variables, options)
  }

  expense(variables: ExpenseQueryVariables, options?: QueryOptionsAlone<ExpenseQueryVariables>) {
    return this.expenseQueryService.fetch(variables, options)
  }

  expenseWatch(variables: ExpenseQueryVariables, options?: WatchQueryOptionsAlone<ExpenseQueryVariables>) {
    return this.expenseQueryService.watch(variables, options)
  }

  invitationByToken(variables: InvitationByTokenQueryVariables, options?: QueryOptionsAlone<InvitationByTokenQueryVariables>) {
    return this.invitationByTokenQueryService.fetch(variables, options)
  }

  invitationByTokenWatch(variables: InvitationByTokenQueryVariables, options?: WatchQueryOptionsAlone<InvitationByTokenQueryVariables>) {
    return this.invitationByTokenQueryService.watch(variables, options)
  }

  invitation(variables: InvitationQueryVariables, options?: QueryOptionsAlone<InvitationQueryVariables>) {
    return this.invitationQueryService.fetch(variables, options)
  }

  invitationWatch(variables: InvitationQueryVariables, options?: WatchQueryOptionsAlone<InvitationQueryVariables>) {
    return this.invitationQueryService.watch(variables, options)
  }

  invitations(variables?: InvitationsQueryVariables, options?: QueryOptionsAlone<InvitationsQueryVariables>) {
    return this.invitationsQueryService.fetch(variables, options)
  }

  invitationsWatch(variables?: InvitationsQueryVariables, options?: WatchQueryOptionsAlone<InvitationsQueryVariables>) {
    return this.invitationsQueryService.watch(variables, options)
  }

  materialNoteItem(variables: MaterialNoteItemQueryVariables, options?: QueryOptionsAlone<MaterialNoteItemQueryVariables>) {
    return this.materialNoteItemQueryService.fetch(variables, options)
  }

  materialNoteItemWatch(variables: MaterialNoteItemQueryVariables, options?: WatchQueryOptionsAlone<MaterialNoteItemQueryVariables>) {
    return this.materialNoteItemQueryService.watch(variables, options)
  }

  materialNote(variables: MaterialNoteQueryVariables, options?: QueryOptionsAlone<MaterialNoteQueryVariables>) {
    return this.materialNoteQueryService.fetch(variables, options)
  }

  materialNoteWatch(variables: MaterialNoteQueryVariables, options?: WatchQueryOptionsAlone<MaterialNoteQueryVariables>) {
    return this.materialNoteQueryService.watch(variables, options)
  }

  media(variables?: MediaQueryVariables, options?: QueryOptionsAlone<MediaQueryVariables>) {
    return this.mediaQueryService.fetch(variables, options)
  }

  mediaWatch(variables?: MediaQueryVariables, options?: WatchQueryOptionsAlone<MediaQueryVariables>) {
    return this.mediaQueryService.watch(variables, options)
  }

  member(variables: MemberQueryVariables, options?: QueryOptionsAlone<MemberQueryVariables>) {
    return this.memberQueryService.fetch(variables, options)
  }

  memberWatch(variables: MemberQueryVariables, options?: WatchQueryOptionsAlone<MemberQueryVariables>) {
    return this.memberQueryService.watch(variables, options)
  }

  openAgreements(variables?: OpenAgreementsQueryVariables, options?: QueryOptionsAlone<OpenAgreementsQueryVariables>) {
    return this.openAgreementsQueryService.fetch(variables, options)
  }

  openAgreementsWatch(variables?: OpenAgreementsQueryVariables, options?: WatchQueryOptionsAlone<OpenAgreementsQueryVariables>) {
    return this.openAgreementsQueryService.watch(variables, options)
  }

  openTodos(variables?: OpenTodosQueryVariables, options?: QueryOptionsAlone<OpenTodosQueryVariables>) {
    return this.openTodosQueryService.fetch(variables, options)
  }

  openTodosWatch(variables?: OpenTodosQueryVariables, options?: WatchQueryOptionsAlone<OpenTodosQueryVariables>) {
    return this.openTodosQueryService.watch(variables, options)
  }

  projectActivities(variables: ProjectActivitiesQueryVariables, options?: QueryOptionsAlone<ProjectActivitiesQueryVariables>) {
    return this.projectActivitiesQueryService.fetch(variables, options)
  }

  projectActivitiesWatch(variables: ProjectActivitiesQueryVariables, options?: WatchQueryOptionsAlone<ProjectActivitiesQueryVariables>) {
    return this.projectActivitiesQueryService.watch(variables, options)
  }

  projectAgreements(variables: ProjectAgreementsQueryVariables, options?: QueryOptionsAlone<ProjectAgreementsQueryVariables>) {
    return this.projectAgreementsQueryService.fetch(variables, options)
  }

  projectAgreementsWatch(variables: ProjectAgreementsQueryVariables, options?: WatchQueryOptionsAlone<ProjectAgreementsQueryVariables>) {
    return this.projectAgreementsQueryService.watch(variables, options)
  }

  projectChats(variables: ProjectChatsQueryVariables, options?: QueryOptionsAlone<ProjectChatsQueryVariables>) {
    return this.projectChatsQueryService.fetch(variables, options)
  }

  projectChatsWatch(variables: ProjectChatsQueryVariables, options?: WatchQueryOptionsAlone<ProjectChatsQueryVariables>) {
    return this.projectChatsQueryService.watch(variables, options)
  }

  projectCosts(variables: ProjectCostsQueryVariables, options?: QueryOptionsAlone<ProjectCostsQueryVariables>) {
    return this.projectCostsQueryService.fetch(variables, options)
  }

  projectCostsWatch(variables: ProjectCostsQueryVariables, options?: WatchQueryOptionsAlone<ProjectCostsQueryVariables>) {
    return this.projectCostsQueryService.watch(variables, options)
  }

  projectCurrentMemberTimesheets(variables: ProjectCurrentMemberTimesheetsQueryVariables, options?: QueryOptionsAlone<ProjectCurrentMemberTimesheetsQueryVariables>) {
    return this.projectCurrentMemberTimesheetsQueryService.fetch(variables, options)
  }

  projectCurrentMemberTimesheetsWatch(variables: ProjectCurrentMemberTimesheetsQueryVariables, options?: WatchQueryOptionsAlone<ProjectCurrentMemberTimesheetsQueryVariables>) {
    return this.projectCurrentMemberTimesheetsQueryService.watch(variables, options)
  }

  projectExpenses(variables: ProjectExpensesQueryVariables, options?: QueryOptionsAlone<ProjectExpensesQueryVariables>) {
    return this.projectExpensesQueryService.fetch(variables, options)
  }

  projectExpensesWatch(variables: ProjectExpensesQueryVariables, options?: WatchQueryOptionsAlone<ProjectExpensesQueryVariables>) {
    return this.projectExpensesQueryService.watch(variables, options)
  }

  projectInvitationSuggestionUsers(variables: ProjectInvitationSuggestionUsersQueryVariables, options?: QueryOptionsAlone<ProjectInvitationSuggestionUsersQueryVariables>) {
    return this.projectInvitationSuggestionUsersQueryService.fetch(variables, options)
  }

  projectInvitationSuggestionUsersWatch(variables: ProjectInvitationSuggestionUsersQueryVariables, options?: WatchQueryOptionsAlone<ProjectInvitationSuggestionUsersQueryVariables>) {
    return this.projectInvitationSuggestionUsersQueryService.watch(variables, options)
  }

  projectMaterialNotes(variables: ProjectMaterialNotesQueryVariables, options?: QueryOptionsAlone<ProjectMaterialNotesQueryVariables>) {
    return this.projectMaterialNotesQueryService.fetch(variables, options)
  }

  projectMaterialNotesWatch(variables: ProjectMaterialNotesQueryVariables, options?: WatchQueryOptionsAlone<ProjectMaterialNotesQueryVariables>) {
    return this.projectMaterialNotesQueryService.watch(variables, options)
  }

  projectMediaMoveFolder(variables: ProjectMediaMoveFolderQueryVariables, options?: QueryOptionsAlone<ProjectMediaMoveFolderQueryVariables>) {
    return this.projectMediaMoveFolderQueryService.fetch(variables, options)
  }

  projectMediaMoveFolderWatch(variables: ProjectMediaMoveFolderQueryVariables, options?: WatchQueryOptionsAlone<ProjectMediaMoveFolderQueryVariables>) {
    return this.projectMediaMoveFolderQueryService.watch(variables, options)
  }

  projectMedia(variables: ProjectMediaQueryVariables, options?: QueryOptionsAlone<ProjectMediaQueryVariables>) {
    return this.projectMediaQueryService.fetch(variables, options)
  }

  projectMediaWatch(variables: ProjectMediaQueryVariables, options?: WatchQueryOptionsAlone<ProjectMediaQueryVariables>) {
    return this.projectMediaQueryService.watch(variables, options)
  }

  projectTimesheets(variables: ProjectTimesheetsQueryVariables, options?: QueryOptionsAlone<ProjectTimesheetsQueryVariables>) {
    return this.projectTimesheetsQueryService.fetch(variables, options)
  }

  projectTimesheetsWatch(variables: ProjectTimesheetsQueryVariables, options?: WatchQueryOptionsAlone<ProjectTimesheetsQueryVariables>) {
    return this.projectTimesheetsQueryService.watch(variables, options)
  }

  projectTodos(variables: ProjectTodosQueryVariables, options?: QueryOptionsAlone<ProjectTodosQueryVariables>) {
    return this.projectTodosQueryService.fetch(variables, options)
  }

  projectTodosWatch(variables: ProjectTodosQueryVariables, options?: WatchQueryOptionsAlone<ProjectTodosQueryVariables>) {
    return this.projectTodosQueryService.watch(variables, options)
  }

  project(variables: ProjectQueryVariables, options?: QueryOptionsAlone<ProjectQueryVariables>) {
    return this.projectQueryService.fetch(variables, options)
  }

  projectWatch(variables: ProjectQueryVariables, options?: WatchQueryOptionsAlone<ProjectQueryVariables>) {
    return this.projectQueryService.watch(variables, options)
  }

  wkbTemplates(variables: WkbTemplatesQueryVariables, options?: WatchQueryOptionsAlone<WkbTemplatesQueryVariables>) {
    return this.wkbTemplatesQueryService.watch(variables, options)
  }

  wkbQuestionsCompleted(variables: WkbTemplatesQueryVariables, options?: WatchQueryOptionsAlone<WkbTemplatesQueryVariables>) {
    return this.wkbQuestionsCompletedQueryService.watch(variables, options)
  }

  wkbQuestions(variables: WkbQuestionsQueryVariables, options?: WatchQueryOptionsAlone<WkbQuestionsQueryVariables>) {
    return this.wkbQuestionsQueryService.watch(variables, options)
  }

  createWkbAnswer(variables: CreateWkbAnswerMutationVariables, options?: MutationOptionsAlone<CreateWkbAnswerMutation, CreateWkbAnswerMutationVariables>) {
    return this.createWkbAnswerMutationService.mutate(variables, options)
  }

  projectMembers(variables: ProjectMembersQueryVariables, options?: QueryOptionsAlone<ProjectMembersQueryVariables>) {
    return this.projectMembersQueryService.fetch(variables, options)
  }

  projectMembersWatch(variables: ProjectMembersQueryVariables, options?: WatchQueryOptionsAlone<ProjectMembersQueryVariables>) {
    return this.projectMembersQueryService.watch(variables, options)
  }

  projectsWithMembers(variables?: ProjectsWithMembersQueryVariables, options?: QueryOptionsAlone<ProjectsWithMembersQueryVariables>) {
    return this.projectsWithMembersQueryService.fetch(variables, options)
  }

  projectsWithMembersWatch(variables?: ProjectsWithMembersQueryVariables, options?: WatchQueryOptionsAlone<ProjectsWithMembersQueryVariables>) {
    return this.projectsWithMembersQueryService.watch(variables, options)
  }

  projects(variables?: ProjectsQueryVariables, options?: QueryOptionsAlone<ProjectsQueryVariables>) {
    return this.projectsQueryService.fetch(variables, options)
  }

  projectsWatch(variables?: ProjectsQueryVariables, options?: WatchQueryOptionsAlone<ProjectsQueryVariables>) {
    return this.projectsQueryService.watch(variables, options)
  }

  submittedExpenses(variables?: SubmittedExpensesQueryVariables, options?: QueryOptionsAlone<SubmittedExpensesQueryVariables>) {
    return this.submittedExpensesQueryService.fetch(variables, options)
  }

  submittedExpensesWatch(variables?: SubmittedExpensesQueryVariables, options?: WatchQueryOptionsAlone<SubmittedExpensesQueryVariables>) {
    return this.submittedExpensesQueryService.watch(variables, options)
  }

  submittedMaterialNotes(variables?: SubmittedMaterialNotesQueryVariables, options?: QueryOptionsAlone<SubmittedMaterialNotesQueryVariables>) {
    return this.submittedMaterialNotesQueryService.fetch(variables, options)
  }

  submittedMaterialNotesWatch(variables?: SubmittedMaterialNotesQueryVariables, options?: WatchQueryOptionsAlone<SubmittedMaterialNotesQueryVariables>) {
    return this.submittedMaterialNotesQueryService.watch(variables, options)
  }

  submittedTimesheets(variables?: SubmittedTimesheetsQueryVariables, options?: QueryOptionsAlone<SubmittedTimesheetsQueryVariables>) {
    return this.submittedTimesheetsQueryService.fetch(variables, options)
  }

  submittedTimesheetsWatch(variables?: SubmittedTimesheetsQueryVariables, options?: WatchQueryOptionsAlone<SubmittedTimesheetsQueryVariables>) {
    return this.submittedTimesheetsQueryService.watch(variables, options)
  }

  team(variables?: TeamQueryVariables, options?: QueryOptionsAlone<TeamQueryVariables>) {
    return this.teamQueryService.fetch(variables, options)
  }

  teamWatch(variables?: TeamQueryVariables, options?: WatchQueryOptionsAlone<TeamQueryVariables>) {
    return this.teamQueryService.watch(variables, options)
  }

  timesheet(variables: TimesheetQueryVariables, options?: QueryOptionsAlone<TimesheetQueryVariables>) {
    return this.timesheetQueryService.fetch(variables, options)
  }

  timesheetWatch(variables: TimesheetQueryVariables, options?: WatchQueryOptionsAlone<TimesheetQueryVariables>) {
    return this.timesheetQueryService.watch(variables, options)
  }

  todo(variables: TodoQueryVariables, options?: QueryOptionsAlone<TodoQueryVariables>) {
    return this.todoQueryService.fetch(variables, options)
  }

  todoWatch(variables: TodoQueryVariables, options?: WatchQueryOptionsAlone<TodoQueryVariables>) {
    return this.todoQueryService.watch(variables, options)
  }

  walterLivingAddressInformation(variables: WalterLivingAddressInformationQueryVariables, options?: QueryOptionsAlone<WalterLivingAddressInformationQueryVariables>) {
    return this.walterLivingAddressInformationQueryService.fetch(variables, options)
  }

  walterLivingAddressInformationWatch(variables: WalterLivingAddressInformationQueryVariables, options?: WatchQueryOptionsAlone<WalterLivingAddressInformationQueryVariables>) {
    return this.walterLivingAddressInformationQueryService.watch(variables, options)
  }

  walterLivingAddressSearch(variables: WalterLivingAddressSearchQueryVariables, options?: QueryOptionsAlone<WalterLivingAddressSearchQueryVariables>) {
    return this.walterLivingAddressSearchQueryService.fetch(variables, options)
  }

  walterLivingAddressSearchWatch(variables: WalterLivingAddressSearchQueryVariables, options?: WatchQueryOptionsAlone<WalterLivingAddressSearchQueryVariables>) {
    return this.walterLivingAddressSearchQueryService.watch(variables, options)
  }

  activitySubscription(variables?: ActivitySubscriptionVariables, options?: SubscriptionOptionsAlone<ActivitySubscriptionVariables>) {
    return this.activitySubscriptionSubscription.subscribe(variables, options)
  }

  agreementSubscription(variables?: AgreementSubscriptionVariables, options?: SubscriptionOptionsAlone<AgreementSubscriptionVariables>) {
    return this.agreementSubscriptionSubscription.subscribe(variables, options)
  }

  chatSubscription(variables?: ChatSubscriptionVariables, options?: SubscriptionOptionsAlone<ChatSubscriptionVariables>) {
    return this.chatSubscriptionSubscription.subscribe(variables, options)
  }

  deleteSubscription(variables?: DeleteSubscriptionVariables, options?: SubscriptionOptionsAlone<DeleteSubscriptionVariables>) {
    return this.deleteSubscriptionSubscription.subscribe(variables, options)
  }

  eventSubscription(variables?: EventSubscriptionVariables, options?: SubscriptionOptionsAlone<EventSubscriptionVariables>) {
    return this.eventSubscriptionSubscription.subscribe(variables, options)
  }

  expenseSubscription(variables?: ExpenseSubscriptionVariables, options?: SubscriptionOptionsAlone<ExpenseSubscriptionVariables>) {
    return this.expenseSubscriptionSubscription.subscribe(variables, options)
  }

  materialNoteSubscription(variables?: MaterialNoteSubscriptionVariables, options?: SubscriptionOptionsAlone<MaterialNoteSubscriptionVariables>) {
    return this.materialNoteSubscriptionSubscription.subscribe(variables, options)
  }

  mediaSubscription(variables?: MediaSubscriptionVariables, options?: SubscriptionOptionsAlone<MediaSubscriptionVariables>) {
    return this.mediaSubscriptionSubscription.subscribe(variables, options)
  }

  memberSubscription(variables?: MemberSubscriptionVariables, options?: SubscriptionOptionsAlone<MemberSubscriptionVariables>) {
    return this.memberSubscriptionSubscription.subscribe(variables, options)
  }

  participantSubscription(variables?: ParticipantSubscriptionVariables, options?: SubscriptionOptionsAlone<ParticipantSubscriptionVariables>) {
    return this.participantSubscriptionSubscription.subscribe(variables, options)
  }

  projectSubscription(variables?: ProjectSubscriptionVariables, options?: SubscriptionOptionsAlone<ProjectSubscriptionVariables>) {
    return this.projectSubscriptionSubscription.subscribe(variables, options)
  }

  timesheetSubscription(variables?: TimesheetSubscriptionVariables, options?: SubscriptionOptionsAlone<TimesheetSubscriptionVariables>) {
    return this.timesheetSubscriptionSubscription.subscribe(variables, options)
  }

  todoSubscription(variables?: TodoSubscriptionVariables, options?: SubscriptionOptionsAlone<TodoSubscriptionVariables>) {
    return this.todoSubscriptionSubscription.subscribe(variables, options)
  }
}

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Media": [
      "Folder",
      "Document",
      "Image",
      "Video"
    ],
    "Chat": [
      "GroupChat",
      "PrivateChat"
    ],
    "Event": [
      "MediaMessage",
      "Message",
      "ReplyMessage",
      "ActivityMessage",
      "AgreementMessage",
      "DeletedEvent",
      "ExpenseMessage",
      "MaterialNoteMessage",
      "SystemMessage",
      "TimesheetMessage"
    ]
  }
};
export default result;
