import { Injectable, OnDestroy } from '@angular/core'
import { ChatService } from './chat.service'
import { ProjectService } from './project.service'
import { AgreementService } from './agreement.service'
import { EventService } from './event.service'
import { DeletedEntity, HomerunSDK } from '../generated/graphql.private'
import { ActivityService } from './activity.service'
import { Subscription } from 'rxjs'
import { MemberService } from './member.service'
import { ParticipantService } from './participant.service'
import { TimesheetService } from './timesheet.service'
import { ExpenseService } from './expense.service'
import { Apollo } from 'apollo-angular'
import { TodoService } from './todo.service'
import { MaterialNoteService } from './material-note.service'
import { MediaService } from './media.service'

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService implements OnDestroy {
  subscriptions: Subscription[] = []
  constructor(
    public homerunSDK: HomerunSDK,
    private projectService: ProjectService,
    private chatService: ChatService,
    private agreementService: AgreementService,
    private activityService: ActivityService,
    private todoService: TodoService,
    private eventService: EventService,
    private memberService: MemberService,
    private participantService: ParticipantService,
    private timesheetService: TimesheetService,
    private expenseService: ExpenseService,
    private materialNoteService: MaterialNoteService,
    private mediaService: MediaService,
    private apollo: Apollo
  ) {}

  setup() {
    this.subscribe('projectSubscription', (result) => this.projectService.updateCache(result.data.project))
    this.subscribe('memberSubscription', (result) => this.memberService.updateCache(result.data.member))
    this.subscribe('chatSubscription', (result) => this.chatService.updateCache(result.data.chat))
    this.subscribe('participantSubscription', (result) => this.participantService.updateCache(result.data.participant))
    this.subscribe('eventSubscription', (result) => this.eventService.updateCache(result.data.event))
    this.subscribe('agreementSubscription', (result) => this.agreementService.updateCache(result.data.agreement))
    this.subscribe('activitySubscription', (result) => this.activityService.updateCache(result.data.activity))
    this.subscribe('todoSubscription', (result) => this.todoService.updateCache(result.data.todo))
    this.subscribe('timesheetSubscription', (result) => this.timesheetService.updateCache(result.data.timesheet))
    this.subscribe('expenseSubscription', (result) => this.expenseService.updateCache(result.data.expense))
    this.subscribe('materialNoteSubscription', (result) =>
      this.materialNoteService.updateCache(result.data.materialNote)
    )
    this.subscribe('mediaSubscription', (result) => this.mediaService.updateCache(result.data.media))
    this.subscribe('deleteSubscription', (result) => this.deleteSubscription(result.data.delete))
  }

  private deleteSubscription(deletedEvent: DeletedEntity) {
    const service = this[`${deletedEvent.type.toLowerCase()}Service`]
    if (service && service['delete']) service.delete(deletedEvent)
    this.apollo.client.cache.evict({ id: `${deletedEvent.type}:${deletedEvent.id}` })
    this.apollo.client.cache.gc()
  }

  private subscribe(subscriptionName: string, handler) {
    this.subscriptions.push(this.homerunSDK[subscriptionName]().subscribe(handler))
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription.unsubscribe())
  }
}
