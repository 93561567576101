import { Injectable, OnDestroy } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { HomerunSDK } from '@core/generated/graphql.private'
import { AuthService } from '@core/services/auth.service'
import { takeUntil } from 'rxjs/operators'
import * as Sentry from '@sentry/browser'

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService implements OnDestroy {
  destroyed$: Subject<boolean> = new Subject()
  loggedInSubscription: Subscription

  constructor(private homerunSDK: HomerunSDK, private auth: AuthService) {}

  setup() {
    /*
     * This analytics service currently only sets the user id for Sentry error tracking.
     * Since Firebase is not used on web we do not track any other behaviour.
     * This service could be used for other analytics tracking in the future.
     */
    this.auth
      .isLoggedIn()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((loggedIn) => {
        if (loggedIn) {
          this.homerunSDK
            .currentUser()
            .pipe(takeUntil(this.destroyed$))
            .subscribe((result) => {
              if (result.data?.currentUser) {
                Sentry.setUser({ id: result.data?.currentUser?.id })
              }
            })
        }
      })
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.unsubscribe()
  }
}
