import { Component, Input } from '@angular/core'

@Component({
  selector: 'flexible-footer',
  template: `
    <div class="content" [class.center-content]="centerContent != null"><ng-content></ng-content></div>
    <div
      class="footer-container"
      [class.white-background]="whiteBackground != null"
      [class.no-padding]="noPadding != null"
      *ngIf="showFooter"
    >
      <div class="footer" [class.no-padding]="noPadding != null">
        <ng-content select="footer"></ng-content>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        min-height: 100%;
        display: flex;
        flex-direction: column;
      }

      .content {
        flex: 1;
      }

      .content.center-content {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .footer-container {
        padding: 0 0 var(--ion-safe-area-bottom) 0;
      }

      .footer-container.no-padding {
        padding: 0;
      }

      .footer-container.white-background {
        background-color: white;
        border-top: 0.55px solid var(--default-border-color);
      }

      .footer {
        padding: 1rem 1rem 1rem 1rem;
      }

      .footer.no-padding {
        padding: 0;
      }
    `
  ]
})
export class FlexibleFooterComponent {
  @Input() noPadding: string | null
  @Input() centerContent: string | null
  @Input() whiteBackground: string | null
  @Input() showFooter = true
}
