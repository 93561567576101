import { Component, Input, Output, EventEmitter } from '@angular/core'

@Component({
  selector: 'submit-button',
  template: `
    <div (click)="submit($event)">
      <ion-button [disabled]="disabled || clicked" size="default" [color]="color" [fill]="fill">
        <ng-content></ng-content>
        <ng-container *ngIf="clicked">
          &nbsp;
          <ion-spinner color="light" name="dots"></ion-spinner>
        </ng-container>
      </ion-button>
    </div>
  `,
  styles: [
    `
      ion-button {
        --border-radius: var(--default-border-radius);
        font-weight: bold;
        height: 3.5rem; /* Line-height plus default padding top and bottom */
        margin: 0;
        width: 100%;
      }
    `
  ]
})
export class SubmitButtonComponent {
  @Output() click = new EventEmitter()
  @Input() disabled = false
  @Input() color = 'primary'
  @Input() fill = 'solid'

  clicked = false

  submit($event) {
    $event.preventDefault()
    $event.stopPropagation()

    if (!this.clicked && !this.disabled) {
      this.click.emit()
      this.clicked = true
    }
  }

  reset() {
    this.clicked = false
  }
}
