import { Injectable, NgZone } from '@angular/core'

import { SubscriptionTriggersService } from '@core/services/subscription-triggers.service'
import { Chat, Event, HomerunSDK } from '@core/generated/graphql.private'
import { TitleService } from './title.service'
import { ToastController } from '@ionic/angular'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class WebSubscriptionTriggersService implements SubscriptionTriggersService {
  constructor(
    private titleService: TitleService,
    private homerunSDK: HomerunSDK,
    private toastController: ToastController,
    private router: Router,
    private ngZone: NgZone
  ) {}

  toast: HTMLIonToastElement
  async newEvent(event: Event, chat: Chat) {
    this.ngZone.run(async () => {
      const currentUser = (await this.homerunSDK.currentUser().toPromise()).data.currentUser
      if (
        event.user.id == currentUser.id ||
        window.location.pathname?.match(new RegExp(`chats/${event.chatId}(\/.*)?`)) // activeRoute does not work
      )
        return

      // depending on the cache state, chat can be empty
      const title = `${event.user.name} heeft je een bericht gestuurd${chat ? ' in ' + chat.name : null}.`
      this.titleService.blinkTitleUntilFocus(title)

      // don't stack
      try {
        if (this.toast) this.toast.dismiss()
      } catch {}

      this.toast = await this.toastController.create({
        header: title,
        message: event.payload
          ? event.payload.length <= 50
            ? event.payload
            : event.payload.slice(0, 50) + '...'
          : null,
        position: 'top',
        duration: 2000,
        translucent: false,
        color: 'secondary'
      })

      // toast messages don't support images by default so we will inject it
      const avatar = document.createElement('img')

      // depending on the cache state, chat can be empty
      avatar.src = !chat
        ? 'core-assets/users/avatar.svg'
        : chat.imageThumbnail
        ? chat.imageThumbnail
        : (chat as any).__typename == 'GroupChat'
        ? 'core-assets/chats/group_chat_avatar.svg'
        : 'core-assets/users/avatar.svg'

      avatar.setAttribute(
        'style',
        'width: 50px; height: 50px; background: #fff; float: left; display: inline-block; margin: 10px 0px 10px 10px; border-radius: 10px;'
      )

      this.toast.shadowRoot.querySelector('.toast-wrapper').prepend(avatar)
      this.toast.onclick = async () => await this.router.navigate(['/projects', event.projectId, 'chats', event.chatId])
      await this.toast.present()
    })
  }
}
