import { Injectable } from '@angular/core'
import { TodoService } from '@core/services/todo.service'
import { ToastService } from '@core/services/toast.service'
import { HomerunSDK } from '@core/generated/graphql.private'
import { Apollo } from 'apollo-angular'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class WebTodoService extends TodoService {
  public titleFromChatMessage
  constructor(
    private homerunSDK: HomerunSDK,
    private toastService: ToastService,
    apollo: Apollo,
    private router: Router
  ) {
    super(apollo)
  }

  openNewTodoAction(title, projectId) {
    this.titleFromChatMessage = title
    this.router.navigate(['/projects', projectId, 'todos', 'new'], { queryParams: { fromChat: true } })
  }
}
