import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {
  constructor(private platform: Platform) {}
  buildDeepLink(url, description = null) {
    const deepLinkBase = 'https://mijnhomerun.page.link'
    const urlEncodedURL = encodeURIComponent(url)
    const title = encodeURIComponent('Homigo mobiel 🔗')
    const encodedDescription = encodeURIComponent(
      description || "Homigo op mobiel werkt alleen met een app. Klik op 'open' om de app te installeren/openen"
    )
    const androidPackageName = 'com.mijnhomerun.homerun'
    const appleStoreId = '1514372640'
    const appleBundleId = 'com.mijnhomerun.homerun'
    const deepLinkImage = encodeURIComponent(`${window.location.origin}/assets/deeplink.png`)

    return (
      `${deepLinkBase}/?link=${urlEncodedURL}&apn=${androidPackageName}` +
      `&isi=${appleStoreId}&ibi=${appleBundleId}&st=${title}&sd=${encodedDescription}` +
      `&si=${deepLinkImage}`
    )
  }
}
