import { Injectable } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { ApolloClient, gql } from '@apollo/client/core'
import { MaterialNote, MaterialNoteItem } from '../generated/graphql.private'

@Injectable({
  providedIn: 'root'
})
export class MaterialNoteService {
  apolloClient: ApolloClient<any>

  constructor(private apollo: Apollo) {
    this.apolloClient = this.apollo.client
  }

  updateCache(materialNote: Pick<MaterialNote, 'id' | 'projectId' | 'createdByMember'>) {
    const fragmentId = `Project:${materialNote.projectId}`
    let project = null

    const fragment = gql`
      fragment projectMaterialNoteIds on Project {
        __typename
        materialNotes {
          __typename
          id
        }
      }
    `

    try {
      project = this.apolloClient.readFragment({ id: fragmentId, fragment })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }

    if (project.materialNotes.find((existingMaterialNote) => existingMaterialNote.id === materialNote.id)) return

    this.apolloClient.writeFragment({
      id: fragmentId,
      fragment,
      data: {
        __typename: project.__typename,
        materialNotes: [...project.materialNotes, { id: materialNote.id, __typename: 'MaterialNote' }]
      }
    })
  }

  newMaterialNote(projectId) {
    return {
      projectId
    } as Partial<MaterialNote>
  }

  newMaterialNoteItem(materialNoteId) {
    return {
      materialNoteId: materialNoteId || null,
      name: null,
      quantity: 1
    } as Partial<MaterialNoteItem>
  }
}
