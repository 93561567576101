import { Component } from '@angular/core'
import { Location } from '@angular/common'

@Component({
  template: `<ion-content color="light">
    <div class="container" data-cy="not-found-error">
      <div>
        <h4>Pagina niet gevonden</h4>
        <img class="image" src="/core-assets/projects/empty.svg" />

        <p>
          Deze pagina bestaat niet (meer).<br />
          Ga <a href="#" (click)="navigateBack()">terug</a> of ga naar de <a routerLink="/">homepagina</a>.
        </p>
      </div>
    </div>
  </ion-content>`,

  styles: [
    `
      .container {
        min-height: 60%;
        display: grid;
        place-items: center;
        text-align: center;
      }

      h4,
      p {
        margin: 2rem;
        line-height: 2rem;
      }
    `
  ]
})
export class NotFoundComponent {
  constructor(private location: Location) {}
  navigateBack() {
    this.location.back()
  }
}
