import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router'
import { HomerunSDK } from '@core/generated/graphql.private'
import { Platform } from '@ionic/angular'
import { map, take } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class CanAccessWebGuard implements CanActivateChild, CanActivate {
  constructor(private homerunSDK: HomerunSDK, private router: Router, private platform: Platform) { }
  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return this.canAccessWebOrRedirect()
  }

  canActivateChild() {
    return this.canAccessWebOrRedirect()
  }

  private canAccessWebOrRedirect() {
    return this.homerunSDK.currentUser().pipe(
      map((response) => {
        const { bouwmaatOption } = response?.data?.currentUser
        return response?.data?.currentUser?.policy?.webAccess
          // !(this.platform.is('ios') || this.platform.is('android'))
          ? true
          : bouwmaatOption
            ? this.router.parseUrl('/subscription/bouwmaat-plans')
            : this.router.parseUrl('/subscription/subscription-plans')
      }, take(1))
    )
  }
}
