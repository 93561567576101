import { Injectable } from '@angular/core'
import { ActionSheetController } from '@ionic/angular'
import { Router } from '@angular/router'
import { Member, Project } from '../generated/graphql.private'
import gql from 'graphql-tag'
import { ApolloClient } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  apolloClient: ApolloClient<any>
  finishedReturnUrl = null

  constructor(private apollo: Apollo, private actionSheetController: ActionSheetController, private router: Router) {
    this.apolloClient = this.apollo.getClient()
  }

  createNewMemberActionSheet = async (project: Pick<Project, 'id' | 'policy'>) => {
    return await this.actionSheetController.create({
      buttons: [
        ...(project?.policy?.inviteMember ? [this.inviteMemberButtonFor(project)] : []),
        ...(project?.policy?.inviteUser ? [this.inviteUserButtonFor(project)] : []),
        {
          text: 'Annuleren',
          role: 'cancel'
        }
      ]
    })
  }

  inviteMemberButtonFor(project) {
    return {
      text: 'Nieuw projectlid',
      icon: 'add-member',
      handler: () => this.router.navigate(['/projects', project.id, 'members', 'new'])
    }
  }

  inviteUserButtonFor(project) {
    return {
      text: 'Homigo adresboek',
      icon: 'address-book',
      handler: () => this.router.navigate(['/projects', project.id, 'members', 'new', 'user', 'select'])
    }
  }

  updateCache(member: Pick<Member, 'id' | 'projectId'>) {
    const fragmentId = `Project:${member.projectId}`
    let project = null

    const fragment = gql`
      fragment projectMemberIds on Project {
        __typename
        members {
          __typename
          id
        }
      }
    `

    try {
      project = this.apolloClient.readFragment({ id: fragmentId, fragment })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }

    if (!project) return
    if (project.members?.find((existingMember) => existingMember.id === member.id)) return

    this.apolloClient.writeFragment({
      id: fragmentId,
      fragment,
      data: { __typename: project.__typename, members: [...project.members, { id: member.id, __typename: 'Member' }] }
    })
  }
}
