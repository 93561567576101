import { Component, ElementRef } from '@angular/core'
import { TitleService } from '../../../services/title.service'

@Component({
  selector: 'browser-title',
  template: `<ng-content (contentChange)="getAndSetTitle()"> </ng-content>`
})
export class BrowserTitleComponent {
  currentTitle = null
  constructor(private elt: ElementRef, private titleService: TitleService) {}

  ngAfterViewInit() {
    this.getAndSetTitle()
  }

  getAndSetTitle() {
    const title = this.elt.nativeElement.childNodes[0].nodeValue
    if (title === this.currentTitle) return
    this.titleService.setTitle(title)
    this.currentTitle = title
  }
}
