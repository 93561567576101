import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { fromEvent, Subscription } from 'rxjs'
import { filter } from 'rxjs/operators'

@Component({
  selector: 'modal',
  template: `<div class="overlay-container">
    <div class="overlay-backdrop" (click)="onClickBackdrop()"></div>
    <div class="global-overlay-wrapper">
      <div class="overlay-pane">
        <div class="dialog-container">
          <ng-container *ngIf="title">
            <ion-header>
              <ion-toolbar color="secondary">
                <ion-title text-left>
                  {{ title }}
                  <header-subtitle *ngIf="subTitle">{{ subTitle }}</header-subtitle>
                </ion-title>
                <ion-buttons slot="end">
                  <ion-button (click)="onClickClose.emit()"><ion-icon name="close-outline"></ion-icon></ion-button>
                </ion-buttons> </ion-toolbar
            ></ion-header>
          </ng-container>
          <ng-container *ngIf="!title">
            <ion-button class="close-button" fill="clear" (click)="onClickClose.emit()"
              ><ion-icon color="secondary" name="close"></ion-icon
            ></ion-button>
          </ng-container>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div> `,
  styles: [
    `
      .overlay-container {
        pointer-events: none;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 1000;
      }
      .overlay-backdrop {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        pointer-events: auto;
        background: rgba(0, 0, 0, 0.32);
      }
      .global-overlay-wrapper {
        justify-content: center;
        align-items: center;
        display: -webkit-box;
        display: flex;
        position: absolute;
        z-index: 1000;
        pointer-events: none;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }

      .overlay-pane {
        pointer-events: auto;
        box-sizing: border-box;
        position: static;
        display: flex;
        z-index: 1000;
        overflow: hidden;
        border-radius: 0.5rem;

        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-name: anim-open;
      }

      .dialog-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 4px;
        box-sizing: border-box;
        outline: 0;
        box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2), 0 24px 38px 3px rgba(0, 0, 0, 0.14),
          0 9px 46px 8px rgba(0, 0, 0, 0.12);
        background: #fff;
        color: rgba(0, 0, 0, 0.87);
        position: relative;
      }

      .close-button {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 30000;
      }

      @keyframes anim-open {
        0% {
          opacity: 0;
          transform: scale3d(1.1, 1.1, 1);
        }
        100% {
          opacity: 1;
          transform: scale3d(1, 1, 1);
        }
      }
    `
  ]
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() title?: string
  @Input() subTitle?: string
  @Output() onClickClose: EventEmitter<void> = new EventEmitter()
  @Input() closeOnClickBackdrop = false

  escKeyPressed$: Subscription

  constructor(private router: Router) {}

  ngOnInit() {
    this.escKeyPressed$ = fromEvent(document, 'keydown')
      .pipe(filter((e: KeyboardEvent) => e.code === 'Escape'))
      .subscribe(() => this.onClickClose.emit())
  }

  ngOnDestroy() {
    this.escKeyPressed$.unsubscribe()
  }

  onClickBackdrop() {
    if (this.closeOnClickBackdrop) {
      this.onClickClose.emit()
    }
  }
}
