import { Component, ViewChild } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { FolderFormComponent } from '@core/modules/media/components/folder-form.component'
import { SubmitButtonComponent } from '@core/modules/shared/components/submit-button.component'
import { Member, Folder, HomerunSDK, Project } from '@core/generated/graphql.private'
import { ActivatedRoute, Router } from '@angular/router'
import { map, mergeMap, take, tap } from 'rxjs/operators'
import { defer, iif } from 'rxjs'
import { MediaService } from '@core/services/media.service'
import { MemberSelectComponent } from '@core/modules/shared/components/members/member-select.component'
@Component({
  selector: 'folder-modal',
  template: `<modal [title]="title" (onClickClose)="closeModal()">
    <dialog-form *ngIf="project">
      <folder-form [folder]="folder" [form]="form"></folder-form>
      <content-header-horizontal>
        <h4>Deel met</h4>
        <p>Selecteer de projectleden met wie je deze map wilt delen</p>
      </content-header-horizontal>
      <member-select [members]="project.members" [multiple]="true" [preselectedMembers]="preselectedMembers" color="">
      </member-select>

      <footer>
        <submit-button (click)="onSubmit()" [disabled]="!form?.valid" data-cy="new-folder-form-submit"
          >Opslaan</submit-button
        >
      </footer>
    </dialog-form>
  </modal>`,
  styles: []
})
export class FolderModal {
  @ViewChild(FolderFormComponent) folderForm: FolderFormComponent
  @ViewChild(SubmitButtonComponent) submitButton: SubmitButtonComponent
  @ViewChild(MemberSelectComponent) memberSelect: MemberSelectComponent

  form: FormGroup
  title: string
  project: Partial<Project>
  folder: Partial<Folder>
  preselectedMembers: Member[] = []

  constructor(
    public route: ActivatedRoute,
    private homerunSDK: HomerunSDK,
    private router: Router,
    private mediaService: MediaService
  ) {}

  async ngOnInit() {
    this.route.params
      .pipe(
        tap(async (params) => {
          this.project = (await this.homerunSDK.projectMembers({ projectId: params.projectId }).toPromise()).data
            .project as Project
          if (params.action == 'edit') {
            this.title = 'Map bewerken'
            this.folder = (
              await this.homerunSDK
                .media({ path: params.path, projectId: params.projectId }, { fetchPolicy: 'no-cache' })
                .toPromise()
            ).data.media as Folder
            this.preselectedMembers = this.project.members.filter((m) =>
              this.folder.members.map((x) => x.id).includes(m.id)
            )
          } else {
            this.title = 'Nieuwe map'
            this.folder = { ownerType: 'project', ownerId: params.projectId }
            this.preselectedMembers = this.project.members.filter((m) => m.current)
          }

          this.form = this.mediaService.buildForm(
            this.folder,
            this.preselectedMembers.map((m) => m.id)
          )
        })
      )
      .subscribe()
  }

  onSubmit() {
    if (this.folder.id) {
      this.homerunSDK
        .updateFolder({
          input: {
            ...this.form.value,
            memberIds: this.memberSelect.selectedMemberIds(),
            mediaId: this.folder.id
          }
        })
        .pipe(
          map((result) => result.data.updateFolder),
          mergeMap((payload) =>
            iif(
              () => payload.errors.length == 0,
              defer(async () => {
                this.closeModal(payload.folder)
              }),
              defer(() => {
                payload.errors.forEach((error) => this.folderForm.setError(error.argument, error.error))
                this.submitButton.reset()
              })
            )
          ),
          take(1)
        )
        .subscribe()
    } else {
      this.homerunSDK
        .createFolder({
          input: {
            ...this.form.value,
            memberIds: this.memberSelect.selectedMemberIds(),
            projectId: this.route.snapshot.params.projectId,
            path: this.route.snapshot.params.path
          }
        })
        .pipe(
          map((result) => result.data.createFolder),
          mergeMap((payload) =>
            iif(
              () => payload.errors.length == 0,
              defer(async () => {
                this.closeModal(payload.folder)
              }),
              defer(() => {
                payload.errors.forEach((error) => this.folderForm.setError(error.argument, error.error))
                this.submitButton.reset()
              })
            )
          ),
          take(1)
        )
        .subscribe()
    }
  }

  closeModal(_folder = null) {
    this.router.navigate([
      'projects',
      this.route.snapshot.params.projectId,
      'media',
      'browse',
      ...(this.route.snapshot.params.path?.split('/') || [])
    ])
  }
}
