import { Component, Input } from '@angular/core'
import { PopoverController } from '@ionic/angular'

export interface ActionMenuButton {
  text: string
  icon?: string
  role?: 'destructive'
  handler?: () => boolean | void | Promise<boolean | void>
}

@Component({
  template: `<ion-list>
    <ng-container *ngFor="let button of buttons; let last = last">
      <ion-item button="true" detail="false" (click)="invokeHanler(button.handler)" [lines]="last ? 'none' : null">
        <ion-icon
          *ngIf="button.icon"
          [color]="button.role === 'destructive' ? 'danger' : 'secondary'"
          name="{{ button.icon }}"
        ></ion-icon>
        <ion-label [color]="button.role === 'destructive' ? 'danger' : null">{{ button.text }}</ion-label>
      </ion-item>
    </ng-container>
  </ion-list>`,
  styles: [
    `
      ion-item {
        --padding-start: 10px;
        --padding-end: 10px;
        --min-height: 3rem;
      }
      ion-icon {
        padding-right: 0.5rem;
      }
      ion-label {
        font-size: var(--default-font-size) !important;
      }
    `
  ]
})
export class ActionMenuPopoverComponent {
  @Input() buttons: ActionMenuButton[]

  constructor(private popoverController: PopoverController) {}

  invokeHanler(handler) {
    this.popoverController.dismiss()
    handler()
  }
}
