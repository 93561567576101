import { Component, Input, OnInit } from '@angular/core'
import { Image, Document } from '../../../../generated/graphql.private'

@Component({
  selector: 'media-item',
  template: `
    <ion-item>
      <ng-container *ngIf="media; else skeleton">
        <media-tile slot="start" [media]="media" noFilename></media-tile>

        <ion-label>
          {{ media.filename }}
          <div class="details" *ngIf="showDetails">
            Door {{ media?.user?.firstName }} op {{ media?.createdAt | date: 'longDate' }}
          </div>
        </ion-label>
        <ion-label>
          <assigned-members *ngIf="showAssignedMembers" [members]="media.members" [size]="'xsmall'"> </assigned-members>
        </ion-label>
      </ng-container>

      <ng-template #skeleton>
        <ion-skeleton-text animated slot="start" class="document-thumbnail-container"></ion-skeleton-text>

        <ion-label>
          <ion-skeleton-text animated style="width: 40%"></ion-skeleton-text>
          <div class="details"><ion-skeleton-text animated style="width: 60%"></ion-skeleton-text></div>
        </ion-label>
      </ng-template>

      <ion-button *ngIf="showEllipsis == ''" slot="end" (click)="ellipsisClicked($event)" fill="clear">
        <ion-icon color="medium" name="ellipsis-vertical"></ion-icon>
      </ion-button>
    </ion-item>
  `,
  styles: [
    `
      media-tile {
        width: 3rem;
      }

      .details {
        color: var(--ion-color-medium);
        font-size: var(--smaller-font-size);
        line-height: 1rem;
      }

      ion-skeleton-text {
        display: inline-block;
      }
      ion-item {
        padding-bottom: 0.2rem;
      }
    `
  ]
})
export class MediaItemComponent implements OnInit {
  @Input() media: Image | Document
  @Input() showEllipsis: string | null
  @Input() showAssignedMembers = true
  @Input() showDetails = true

  ellipsisClicked($event) {
    $event.ellipsisClicked = true
  }

  isDocument = false

  ngOnInit() {
    this.isDocument = this.media && this.media.__typename == 'Document'
  }
}
