import { Component, EventEmitter, Input, NgZone, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CurrentUserQuery, HomerunSDK, ProjectQuery } from '@core/generated/graphql.private'
import { ApolloNetworkStatus } from '@core/services/apollo-network-status'
import { AuthService } from '@core/services/auth.service'
import { PopoverController } from '@ionic/angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { interval, Observable, of, throwError } from 'rxjs'
import { catchError, debounce, filter, map, switchMap } from 'rxjs/operators'
import { ActionMenuButton, ActionMenuPopoverComponent } from './action-menu-popover.component'

@Component({
  selector: 'page-header',
  template: `
    <div class="container">
      <div class="title" (click)="clickTitle.emit()">
        <div class="title-image" *ngIf="titleImage">
          <img [src]="titleImage" />
        </div>
        <div>
          <h3>
            <browser-title> {{ title }}</browser-title>
          </h3>
          <h4>
            <ng-container *ngIf="subtitle; else projectName">{{ subtitle }}</ng-container>
            <ng-template #projectName>
              <ng-container *ngIf="(projectQueryResult$ | async)?.data?.project as project">
                {{ project.nameOrAddress }}
              </ng-container>
            </ng-template>
          </h4>
        </div>
      </div>

      <div class="content-spacer"></div>

      <div class="content">
        <ng-content></ng-content>
      </div>

      <div class="content-spacer"></div>

      <ng-content select="[slot=end]"></ng-content>

      <ng-container *ngIf="(currentUserQueryResult$ | async)?.data?.currentUser as user">
        <ng-container *ngIf="user.professional">
          <ng-container *ngIf="user.policy.trial || !user.subscribedToPlanAt">
            <div class="pro-plan-cta" routerLink="/subscription/subscription-plans">
              <img src="/assets/plans/pro-plan.svg" />
              <div class="text">
                <div class="title">Met Homigo Pro</div>
                <div class="subtitle">Haal je meer uit Homigo</div>
              </div>
            </div>
            <div class="divider"></div>
          </ng-container>
          <ng-container *ngIf="user.subscribedToPlanAt">
            <div class="pro-plan" [routerLink]="['', { outlets: { 'root-modal': ['settings', 'plan'] } }]"></div>
            <div class="divider"></div>
          </ng-container>
        </ng-container>
        <div class="profile" (click)="openProfile($event)">
          <div class="avatar-container">
            <ion-avatar>
              <img-loader
                [src]="user.avatarThumbnail"
                useImg="true"
                spinner="false"
                fallbackAsPlaceholder="true"
                fallbackUrl="/core-assets/users/avatar.svg"
              ></img-loader>
            </ion-avatar>
          </div>

          <ion-label>
            <div class="name">{{ user.name }}</div>
            <div class="company" *ngIf="user.company">
              {{ user.company.name }}
            </div>
          </ion-label>
        </div>
      </ng-container>
    </div>

    <div class="loading-indicator-container">
      <div class="loading-indicator" *ngIf="networkStatusIsPending$ | async"></div>
    </div>
  `,
  styles: [
    `
      :host {
        border-bottom: 1px solid #ebebeb;
      }
      .container {
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0 1.5rem;
        height: 4.5rem; /* is 72px */
      }

      .title {
        display: flex;
        align-items: center;
        overflow: hidden;
      }

      .title-image {
        margin-right: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: var(--default-border-radius);
        overflow: hidden;
      }

      .title h3 {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
        line-height: 1.5rem;
        cursor: pointer;
        white-space: nowrap;
      }

      .title h4 {
        font-size: var(--default-font-size);
        color: var(--ion-color-medium);
        margin: 0;
        font-weight: normal;
        white-space: nowrap;
      }

      .content-spacer {
        flex-grow: 1;
      }

      .profile {
        cursor: pointer;
        display: flex;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
      }

      .profile .name {
        font-weight: 600;
      }

      .profile .avatar-container {
        flex-grow: 1;
      }
      .profile ion-avatar {
        overflow: hidden;
        height: 36px;
        width: 36px;
      }

      .profile ion-label {
        margin-left: 1rem;
      }

      .profile .company {
        color: var(--ion-color-medium);
        line-height: 1rem;
        font-size: var(--smaller-font-size);
      }

      .divider {
        height: 36px;
        margin-left: 2rem;
        margin-right: 1.5rem;
        border-left: 1px solid #ebebeb;
      }

      @media (max-width: 1500px) {
        .profile ion-label,
        .profile,
        .pro-plan-cta {
          overflow: visible;
        }
      }

      .loading-indicator-container {
        height: 2px;
        width: 100%;
      }

      .loading-indicator {
        height: 2px;
        background: repeating-linear-gradient(to right, #fff 0%, var(--ion-color-primary) 100%);
        width: 100%;
        background-size: 200% auto;
        background-position: 0 100%;
        animation: gradient 2s infinite;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
      }

      @keyframes gradient {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: -200% 0;
        }
      }

      .pro-plan img {
        margin-top: 5px;
      }

      .pro-plan-cta {
        display: flex;
        outline: none;
        cursor: pointer;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
      }
      .pro-plan-cta img {
        height: 44px;
        margin-right: 1rem;
        flex-grow: 1;
      }
      .pro-plan-cta .title {
        font-weight: bold;
      }
      .pro-plan-cta .subtitle {
        color: var(--ion-color-medium);
        line-height: 1rem;
        font-size: var(--smaller-font-size);
      }
    `
  ]
})
export class PageHeaderComponent {
  @Input() title = ''
  @Input() titleImage = null
  @Output() clickTitle: EventEmitter<{
    projectId: string
  }> = new EventEmitter()
  @Input() subtitle = ''

  projectQueryResult$: Observable<ApolloQueryResult<ProjectQuery>>
  currentUserQueryResult$: Observable<ApolloQueryResult<CurrentUserQuery>>

  networkStatusIsPending$: Observable<boolean>

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private homerunSDK: HomerunSDK,
    private popoverController: PopoverController,
    private authService: AuthService,
    private ngZone: NgZone,
    public networkStatus: ApolloNetworkStatus
  ) {}

  ngOnInit() {
    this.projectQueryResult$ = this.route.params.pipe(
      map((params) => params.projectId),
      // the projectId param can be undefined
      filter((projectId) => projectId),
      switchMap((projectId) => this.homerunSDK.projectWatch({ projectId }, { errorPolicy: 'none' }).valueChanges),
      catchError((error) => {
        if (error.message.match("Can't find")) {
          // the project page will redirect the user to the project not found page
          return of(null)
        }
        return throwError(error)
      })
    )

    this.currentUserQueryResult$ = this.homerunSDK.currentUserWatch({}).valueChanges
    this.networkStatusIsPending$ = this.networkStatus.isPending.pipe(
      debounce((isPending) => interval(isPending ? 100 : 0))
    )
  }

  async openProfile($event) {
    const popover = await this.popoverController.create({
      component: ActionMenuPopoverComponent,
      event: $event,
      translucent: true,
      componentProps: {
        buttons: [
          {
            text: 'Instellingen',
            icon: 'settings-outline',
            handler: () => {
              this.router.navigate([{ outlets: { 'root-modal': ['settings', 'user'] } }])
            }
          },
          {
            text: 'Uitloggen',
            icon: 'exit-outline',
            handler: () => {
              this.authService.logout().subscribe(() => {
                this.ngZone.run(() => {
                  this.router.navigate(['/login'], { replaceUrl: true })
                })
              })
            },
            role: 'destructive'
          }
        ] as ActionMenuButton[]
      }
    })
    return await popover.present()
  }
}
