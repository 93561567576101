import { DatePipe, TitleCasePipe } from '@angular/common'
import { Component, Inject, Input, LOCALE_ID } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { FormControl } from '@angular/forms'
import { QueryRef } from 'apollo-angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { HomerunSDK, Media, ProjectMediaQuery, ProjectMediaQueryVariables } from '../../../generated/graphql.private'
import { MediaService } from '../../../services/media.service'
import { Observable } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'

@Component({
  selector: 'media-list',
  template: `
    <ng-container *ngIf="(projectMediaQueryResult$ | async)?.data?.project as project; else skeleton">
      <max-width-container *ngIf="groupedMedia.length > 0; else noMedia">
        <ng-container *ngFor="let group of groupedMedia; trackBy: trackGroupBy">
          <section-header>{{ group.heading }}</section-header>

          <ion-grid *ngIf="view.value == 'tiles'">
            <ion-row class="tile-row">
              <ion-col *ngFor="let media of group.media; trackBy: trackMediaBy" size="4" size-md="3" size-lg="2">
                <media-actions (delete)="deleteMedia(media)" [media]="media">
                  <div class="tile">
                    <media-tile [media]="media" showEllipsis></media-tile>
                  </div>
                </media-actions>
              </ion-col>
            </ion-row>
          </ion-grid>

          <ion-list lines="full" *ngIf="view.value == 'list'">
            <media-actions
              (delete)="deleteMedia(media)"
              *ngFor="let media of group.media; trackBy: trackMediaBy"
              [media]="media"
            >
              <media-item [media]="media" showEllipsis></media-item>
            </media-actions>
          </ion-list>
        </ng-container>

        <ion-infinite-scroll
          threshold="5%"
          (ionInfinite)="loadMore($event)"
          [disabled]="!project.media.pageInfo.hasNextPage"
        >
          <ion-infinite-scroll-content loadingSpinner="crescent"></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </max-width-container>
      <ng-template #noMedia>
        <flexible-footer noPadding centerContent *ngIf="!(route.params | async).path">
          <empty-list-message type="media">Geen {{ title }}</empty-list-message>
          <footer *ngIf="project.policy.createMedia">
            <empty-list-cta>
              <h4>Voeg nu {{ title }} toe</h4>
              <p>
                Druk op de gele knop om bestanden zoals foto's, bouwtekeningen of plattegronden te delen met alle
                projectleden
              </p>
              <p class="fab-margin">
                Deel bestanden in een chat om ze te delen met je projectleden.
                <strong>Zij zijn dan de enigen</strong> die dit kunnen zien.
              </p>
            </empty-list-cta>
          </footer>
        </flexible-footer>
      </ng-template>
    </ng-container>

    <ng-template #skeleton>
      <max-width-container>
        <ion-grid>
          <ion-row>
            <ion-col size="4" size-md="3" size-lg="2" *ngFor="let item of [].constructor(20)"
              ><media-tile></media-tile
            ></ion-col>
          </ion-row>
        </ion-grid>
      </max-width-container>
    </ng-template>
  `,
  styles: [
    `
      div.tile {
        background: #fff;
      }
      ion-col {
        --ion-grid-column-padding: 2px;
      }

      ion-list {
        border-top: 0.55px solid var(--default-border-color);
      }
    `
  ]
})
export class MediaListComponent {
  @Input() type: FormControl
  @Input() view: FormControl
  @Input() projectId
  @Input() title: string
  @Input() perPage = 30

  projectMediaQuery: QueryRef<ProjectMediaQuery, ProjectMediaQueryVariables>
  projectMediaQueryResult$: Observable<ApolloQueryResult<ProjectMediaQuery>>
  groupedMedia: { heading: string; media: Partial<Media>[] }[]

  datePipe: DatePipe
  titleCasePipe = new TitleCasePipe()

  constructor(
    private homerunSDK: HomerunSDK,
    private mediaService: MediaService,
    public route: ActivatedRoute,
    @Inject(LOCALE_ID) locale: string
  ) {
    this.datePipe = new DatePipe(locale)
  }

  ngOnInit() {
    this.projectMediaQueryResult$ = this.route.params.pipe(
      switchMap((params) => {
        this.projectMediaQuery = this.homerunSDK.projectMediaWatch({
          projectId: this.projectId,
          perPage: this.perPage,
          path: params.path
        })

        return this.projectMediaQuery.valueChanges.pipe(
          tap((result) => {
            this.groupedMedia = result?.data?.project?.media?.edges?.reduce((groupedMedia, edge) => {
              if (!edge.node) return groupedMedia
              let heading = null

              const date = new Date(edge.node.createdAt)
              const today = new Date()

              if (date.getFullYear() == today.getFullYear()) {
                if (date.getMonth() == today.getMonth()) {
                  heading = 'Deze maand'
                } else heading = this.titleCasePipe.transform(this.datePipe.transform(date, 'MMMM'))
              } else heading = this.titleCasePipe.transform(this.datePipe.transform(date, 'MMMM yyyy'))

              if (groupedMedia[groupedMedia.length - 1]?.heading != heading) groupedMedia.push({ heading, media: [] })
              groupedMedia[groupedMedia.length - 1].media.push(edge.node)

              return groupedMedia
            }, [])
          })
        )
      })
    )
  }

  refetchQuery() {
    this.projectMediaQuery.refetch({
      projectId: this.projectId,
      type: this.type.value == 'all' ? null : this.type.value
    })
  }

  trackMediaBy(_, media: Media): string {
    return media.id
  }

  trackGroupBy(index) {
    return index
  }

  async loadMore(event: { target: { complete: () => void } }) {
    await this.projectMediaQuery.fetchMore({
      variables: {
        projectId: this.projectId,
        after: this.projectMediaQuery.getLastResult().data.project.media.pageInfo.endCursor
      }
    })

    event.target.complete()
  }

  deleteMedia(media) {
    this.mediaService.deleteMedia(media, () => this.refetchQuery())
  }
}
