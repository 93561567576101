import { Component, ViewChild } from '@angular/core'
import { takeUntil, tap } from 'rxjs/operators'
import { ActivatedRoute, Router } from '@angular/router'
import { FormControl } from '@angular/forms'
import { MediaListComponent } from '@core/modules/media/components/media-list.component'
import { Observable, Subject } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core'
import { HomerunSDK, ProjectQuery } from '@core/generated/graphql.private'
import { ChoosenMedia } from '@core/modules/shared/components/media/common'
import { AddMediaComponent } from '@web/modules/shared/components/add-media.component'
import { TitleCasePipe } from '@angular/common' // eslint-disable-line
import { DragDropUploadComponent } from '@web/modules/shared/components/drag-drop-upload.component'
import { ToastService } from '@core/services/toast.service'
import { ActionMenuService } from '@core/services/action-menu.service'

@Component({
  template: `
    <page-header [title]="title | titlecase">
      <div class="type-and-view">
        <media-type-select [type]="type"></media-type-select>
        <media-view-select [view]="view"></media-view-select>
      </div>
    </page-header>
    <address-bar></address-bar>

    <drag-drop-upload
      (fileUpload)="mediaDropped($event)"
      [allowUpload]="(projectQueryResult$ | async)?.data?.project?.policy?.createMedia"
    >
      <media-list
        [type]="type"
        [view]="view"
        [projectId]="projectId"
        [title]="title"
        [perPage]="perPage"
        (contextmenu)="addMediaMenu($event)"
      ></media-list>

      <ion-fab
        slot="fixed"
        vertical="bottom"
        horizontal="end"
        *ngIf="(projectQueryResult$ | async)?.data?.project?.policy?.createMedia"
        (click)="addMediaMenu($event)"
      >
        <plus-fab-button iconName="add" [showPlus]="false"></plus-fab-button>
      </ion-fab>
    </drag-drop-upload>

    <router-outlet></router-outlet>
    <add-media (complete)="mediaSelected($event)" multiple="true"></add-media>
    <folder-modal *ngIf="(route.data | async)?.folderModal"> </folder-modal>
    <media-modal *ngIf="(route.data | async)?.mediaModal"> </media-modal>
  `,
  styles: [
    `
      .type-and-view {
        display: flex;
        align-items: center;
      }

      media-type-select {
        margin-right: 1rem;
      }

      :host ::ng-deep media-actions {
        cursor: pointer;
      }
    `
  ]
})
export class MediaListPage {
  projectId
  projectQueryResult$: Observable<ApolloQueryResult<ProjectQuery>>
  perPage = 60

  destroyed$: Subject<boolean> = new Subject()

  title = 'bestanden'
  view = new FormControl('tiles')
  type = new FormControl('all')

  @ViewChild(DragDropUploadComponent) private dragDrop: DragDropUploadComponent
  @ViewChild(MediaListComponent) private mediaList: MediaListComponent
  @ViewChild(AddMediaComponent) addMedia: AddMediaComponent

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private homerunSDK: HomerunSDK,
    private toastService: ToastService,
    private actionMenuService: ActionMenuService
  ) {}

  ngOnInit() {
    // should be implemented dynamically by getting the real dimensions of the media container and tile sizes
    this.perPage = Math.floor(Math.max(Math.min(window.innerWidth - 360) * window.innerHeight - 80) / (110 * 110))
    this.projectId = this.route.snapshot.params.projectId

    this.view.valueChanges
      .pipe(
        tap(() => this.dragDrop?.ionContent?.scrollToTop()),
        takeUntil(this.destroyed$)
      )
      .subscribe()

    this.type.valueChanges
      .pipe(
        tap((value) => {
          this.title = { all: 'bestanden', image: 'afbeeldingen', video: "video's", document: 'documenten' }[value]
          this.dragDrop?.ionContent?.scrollToTop()
          this.mediaList?.refetchQuery()
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe()

    this.projectQueryResult$ = this.homerunSDK.projectWatch({
      projectId: this.projectId
    }).valueChanges
  }

  addMediaMenu($event) {
    $event.preventDefault()
    this.actionMenuService.showActionMenu(
      [
        { text: 'Nieuwe map', icon: 'folder-outline', handler: () => this.newFolder() },
        { text: 'Upload bestand', icon: 'document-outline', handler: () => this.addMedia.open() }
      ],
      $event
    )
  }

  async newFolder() {
    await this.router.navigate([
      'projects',
      this.projectId,
      'media',
      'folder',
      ...(this.route.snapshot.params.path?.split('/') || [])
    ])
  }

  mediaDropped(files: FileList) {
    Array.from(files).map(async (file: File) =>
      this.mediaSelected(({
        uri: await new Promise((resolve, reject) => {
          const fileReader = new FileReader()
          fileReader.onerror = reject
          fileReader.onload = () => resolve(fileReader.result)
          fileReader.readAsDataURL(file)
        }),
        contentType: file.type,
        file: file,
        filename: file.name
      } as unknown) as ChoosenMedia)
    )
  }

  mediaSelected(result: ChoosenMedia) {
    if (result) {
      this.dragDrop?.ionContent?.scrollToTop()
      return this.homerunSDK
        .createMedia({
          input: {
            file: result.file,
            projectId: this.projectId,
            description: result.description,
            path: this.route.snapshot.params?.path
          }
        })
        .subscribe((result) => {
          if (result.data.createMedia.media.__typename !== 'Folder')
            this.toastService.success(`${result.data.createMedia.media.filename} geüpload`)
          if (this.type.value == 'all' || this.type.value == result.data.createMedia.media.__typename.toLowerCase()) {
            this.mediaList?.refetchQuery()
          } else {
            this.type.setValue('all')
          }
        })
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.unsubscribe()
  }
}
