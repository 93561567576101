import { Injectable, ErrorHandler, Injector, NgZone } from '@angular/core'
import { NGXLogger } from 'ngx-logger'
import * as Sentry from '@sentry/browser'
import { EnvService } from '@core/services/env.service'
import { ToastService } from '@core/services/toast.service'
import { WebAuthService } from './web-auth.service'
import { ApolloError } from '@apollo/client/core'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private injector: Injector, private zone: NgZone, private envService: EnvService) {}

  async handleError(error: any) {
    this.logger.error('error-handler.ts - Handling error:', error)

    if (this.isApolloNotAuthorizedError(error)) {
      return this.zone.run(async () => await this.injector.get(WebAuthService).logout().toPromise())
    }

    try {
      Sentry.captureException(error.originalError || error)
    } catch (e) {
      this.logger.log('error-handler.ts - Caught sentry error', e)
    }

    if (this.envService.config().showExceptions) this.zone.run(() => this.toastService.error('Er is iets fout gegaan'))
  }

  get toastService() {
    return this.injector.get(ToastService)
  }

  get logger() {
    return this.injector.get(NGXLogger)
  }

  isApolloNotAuthorizedError(error: ApolloError): boolean {
    if (typeof error !== 'string' && 'message' in error) {
      return error.message.indexOf(`doesn't exist on type 'PublicQuery'`) > -1
    }

    return false
  }
}
