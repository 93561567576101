import { HttpLinkModule } from 'apollo-angular-link-http'
import { ModuleWithProviders } from '@angular/compiler/src/core'
import { GraphqlHelperService } from './services/graphql-helper.service'
import { NgModule, APP_INITIALIZER } from '@angular/core'
import { APOLLO_OPTIONS } from 'apollo-angular'
import { ApolloNetworkStatus } from '../../services/apollo-network-status'

export function resolveApolloClientOptions(apolloClientService: GraphqlHelperService) {
  return () => apolloClientService.getOptionsPromise()
}

export function apolloClientServiceFactoryWs(apolloClientService: GraphqlHelperService) {
  return apolloClientService.getWsOptions()
}

@NgModule({
  exports: [HttpLinkModule]
})
export class GraphQLModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: GraphQLModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: resolveApolloClientOptions,
          deps: [GraphqlHelperService],
          multi: true
        },
        GraphqlHelperService,
        {
          provide: APOLLO_OPTIONS,
          useFactory: apolloClientServiceFactoryWs,
          deps: [GraphqlHelperService, ApolloNetworkStatus, APP_INITIALIZER]
        }
      ]
    }
  }
}
