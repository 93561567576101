import { Injectable } from '@angular/core'
import {
  Message,
  MediaMessage,
  AgreementMessage,
  SystemMessage,
  DeletedEvent,
  ActivityMessage,
  TimesheetMessage,
  HomerunSDK,
  ExpenseMessage,
  MaterialNoteMessage
} from '../generated/graphql.private'
import gql from 'graphql-tag'
import { Apollo } from 'apollo-angular'
import { ApolloClient } from '@apollo/client/core'
import { ChatService } from './chat.service'
import { SubscriptionTriggersService } from './subscription-triggers.service'

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private apolloClient: ApolloClient<any>

  constructor(
    private apollo: Apollo,
    private chatService: ChatService,
    private homerunSDK: HomerunSDK,
    private subsciptionTriggersService: SubscriptionTriggersService
  ) {
    this.apolloClient = this.apollo.client
  }

  async updateCache(
    event:
      | Message
      | MediaMessage
      | AgreementMessage
      | TimesheetMessage
      | ExpenseMessage
      | MaterialNoteMessage
      | ActivityMessage
      | SystemMessage
      | DeletedEvent
  ) {
    // HACK: we have to wait a bit before we can read the chat/events from the cache as there is a race
    // condition bug where there will be duplicate events inserted in the cache when the user uploads a photo in chat.
    // This happens when the events subsciption is faster then the optimisticUpdate response / cache read/write.
    // With media messages we use HTTP requests because of file upload, subsciptions are always over websockets
    // that seem to sometimes be faster then a round trip calls.
    if (event.__typename == 'MediaMessage' && event.createdByCurrentUser)
      await (() => new Promise((resolve) => setTimeout(resolve, 500)))()

    if (this.chatService.presentInChat(event.chatId) || event.createdByCurrentUser) {
      this.updateChatCache(`Chat:${event.chatId}`, event, 0)
      this.updateProjectCache(`Project:${event.projectId}`, event, 0)
      this.homerunSDK.setPresence({ input: { chatId: event.chatId } }).subscribe()
    } else {
      this.updateChatCache(`Chat:${event.chatId}`, event, 1)
      this.updateProjectCache(`Project:${event.projectId}`, event, 1)
    }
  }

  private updateChatCache(fragmentId, event, notificationCountIncrement) {
    let chat = null

    chat = this.apolloClient.readFragment({
      id: fragmentId,
      fragment: gql`
        fragment chatWithEvents on Chat {
          __typename
          name
          imageThumbnail
          notificationCount
        }
      `
    })

    // Write fragment to cache (if it was there in the first place)
    if (chat) {
      this.apolloClient.writeFragment({
        id: fragmentId,
        fragment: gql`
          fragment writeChat on Chat {
            __typename
            notificationCount
            latestActivityAt
            events {
              __typename
              edges {
                cursor
                __typename
                node {
                  id
                  __typename
                }
              }
            }
          }
        `,
        data: {
          __typename: chat.__typename,
          notificationCount: (chat.notificationCount || 0) + notificationCountIncrement,
          latestActivityAt: event.createdAt,
          events: {
            __typename: 'EventConnection',
            edges: [
              {
                cursor: event.id,
                __typename: 'EventEdge',
                node: { id: event.id, __typename: event.__typename }
              }
            ]
          }
        }
      })

      this.subsciptionTriggersService.newEvent(event, chat)
    }
  }

  private updateProjectCache(fragmentId, event, notificationCountIncrement) {
    const project = this.apolloClient.readFragment({
      id: fragmentId,
      fragment: gql`
        fragment projectNotificationCount on Project {
          __typename
          notificationCount
        }
      `
    })

    // Write fragment to cache (if it was there in the first place)
    if (project) {
      this.apolloClient.writeFragment({
        id: fragmentId,
        fragment: gql`
          fragment writeProject on Project {
            __typename
            notificationCount
            latestActivityAt
          }
        `,
        data: {
          __typename: project.__typename,
          notificationCount: (project.notificationCount || 0) + notificationCountIncrement,
          latestActivityAt: event.createdAt
        }
      })
    }
  }
}
