import { Injectable, Injector, OnDestroy } from '@angular/core'
import { Storage } from '@ionic/storage'
import * as ActionCable from '../../../lib/action-cable-jwt'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import { setContext } from '@apollo/link-context'
import { take, takeUntil, tap } from 'rxjs/operators'
import { interval, Subject, Subscription } from 'rxjs'
import { defaultDataIdFromObject, fromPromise, InMemoryCache } from '@apollo/client/core'
import { createUploadLink } from 'apollo-upload-client'
import { TokenService } from '../../../services/token.service'
import privateFragments from '../../../generated/graphql.fragments.private.json'
import { EnvService } from '../../../services/env.service'
import { ApolloNetworkStatus } from '../../../services/apollo-network-status'
import { persistCache, IonicStorageWrapper } from 'apollo3-cache-persist'
import { ApolloLink } from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { AuthService } from 'projects/core/src/services/auth.service'
import { relayStylePagination } from '@apollo/client/utilities'
import { relayStyleChatPagination } from '../../../lib/chat-pagination'
import { Platform } from '@ionic/angular'

@Injectable()
export class GraphqlHelperService implements OnDestroy {
  destroyed$: Subject<boolean> = new Subject()
  wsClientOptions = {}
  httpClientOptions = {}
  wsAuthSubscription: Subscription
  environment: any
  cableUrl: string
  httpUrl: string

  constructor(
    private storage: Storage,
    private tokenService: TokenService,
    private envService: EnvService,
    private networkStatus: ApolloNetworkStatus,
    private platform: Platform,
    private injector: Injector
  ) {
    this.environment = this.envService.config()
    this.cableUrl = `${this.environment.backend.wsProtocol}${this.environment.backend.domain}/cable?auth_protocol=2`
    this.httpUrl = `${this.environment.backend.httpProtocol}${this.environment.backend.domain}/graphql?auth_protocol=2`
  }

  async getOptionsPromise() {
    const cache = new InMemoryCache({
      possibleTypes: privateFragments.possibleTypes,
      dataIdFromObject: (object) => {
        switch (object.__typename) {
          case 'Chat':
          case 'PrivateChat':
          case 'GroupChat':
            return `Chat:${object.id}`
          case 'Message':
          case 'MediaMessage':
          case 'SystemMessage':
          case 'AgreementMessage':
          case 'ActivityMessage':
          case 'TimesheetMessage':
          case 'ExpenseMessage':
          case 'MaterialNoteMessage':
          case 'DeletedEvent':
          case 'ReplyEvent':
            return `Event:${object.id}`
          case 'Image':
          case 'Document':
          case 'Video':
          case 'Folder':
            return `Media:${object.id}`
          default:
            return defaultDataIdFromObject(object)
        }
      },
      typePolicies: {
        ProjectPolicy: {
          merge: true
        },
        UserPolicy: {
          merge: true
        },
        Project: {
          fields: {
            media: relayStylePagination(),
            agreements: {
              merge(_existing, incoming) {
                return incoming
              }
            }
          }
        },
        Chat: {
          fields: {
            events: relayStyleChatPagination()
          }
        }
      }
    })

    await persistCache({
      cache,
      storage: new IonicStorageWrapper(this.storage)
    })

    this.wsClientOptions = {
      link: this.getLinkWithAuthHttpAndSocket(),
      cache,
      connectToDevTools: !this.environment.production,
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all',
          fetchPolicy: 'cache-and-network',
          nextFetchPolicy: 'cache-only'
        },
        query: {
          errorPolicy: 'all'
        }
      }
    }
  }

  getWsOptions() {
    return this.wsClientOptions
  }

  getLinkWithAuthHttpAndSocket = () => {
    const baseHttpLink = createUploadLink({ uri: this.httpUrl }) as any
    const httpAuthMiddleware = this.getHttpAuthMiddleware()
    const httpLink = this.networkStatus.concat(httpAuthMiddleware.concat(baseHttpLink))
    const wsLink = this.networkStatus.concat(this.getWsLink())

    const isFileUploadMutation = ({ variables, query: { definitions } }) => {
      return definitions.some(
        ({ kind, operation }) =>
          kind === 'OperationDefinition' &&
          operation === 'mutation' &&
          variables?.input &&
          (Object.entries(variables.input).some(([_, value]) => value instanceof Blob) ||
            Object.entries(variables.input).some(
              ([_, value]) =>
                value instanceof Array && Object.entries(value).some(([_, arg]) => arg.file instanceof Blob)
            ))
      )
    }

    // return ApolloLink.split(isFileUploadMutation, httpLink, wsLink)

    let isRefreshing = false
    let pendingRequests = []

    const resolvePendingRequests = () => {
      pendingRequests.map((callback) => callback())
      pendingRequests = []
    }

    const errorLink = onError(({ networkError, operation, forward }) => {
      if (networkError && networkError.message.match(/401/)) {
        let forward$

        if (!isRefreshing) {
          isRefreshing = true
          forward$ = fromPromise(
            this.injector
              .get(AuthService)
              .refreshToken()
              .pipe(take(1))
              .toPromise()
              .then((refreshToken) => {
                resolvePendingRequests()
                const oldHeaders = operation.getContext().headers
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${refreshToken}`
                  }
                })
                return refreshToken
              })
              .finally(() => {
                isRefreshing = false
              })
          ).filter((value) => Boolean(value))
        } else {
          forward$ = fromPromise(
            new Promise((resolve) => {
              pendingRequests.push(() => resolve())
            })
          )
        }

        return forward$.flatMap(() => forward(operation))
      }
    })
    return ApolloLink.split(isFileUploadMutation, errorLink.concat(httpLink), wsLink)
  }

  private getWsLink = (): ApolloLink => {
    // ActionCable.logger.enabled = true
    const cable = ActionCable.createConsumer(this.cableUrl, null)
    cable.onUnauthorized = () => this.injector.get(AuthService).refreshToken().pipe(take(1)).subscribe()

    this.tokenService
      .getTokenPrio()
      .pipe(
        tap((token) => {
          if (token && cable.connection.isOpen() && cable.jwt) return
          cable.disconnect()
          cable.jwt = token
          cable.connection.jwt = token
          cable.connection.consumer.jwt = token
          cable.connect()
        })
      )
      .subscribe()

    interval(1000)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((_) => {
        this.networkStatus.disconnected.next(cable.connection.disconnected)
      })

    if (this.platform.is('cordova')) {
      this.platform.pause.pipe(takeUntil(this.destroyed$)).subscribe(() => cable.disconnect())
      this.platform.resume.pipe(takeUntil(this.destroyed$)).subscribe(() => cable.connect())
    }
    return new ActionCableLink({ cable: cable }) as any
  }

  private getHttpAuthMiddleware = () => {
    return setContext(async (_, { headers }) => {
      const token = await this.storage.get('token')
      if (!token) {
        return headers
      }
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : ''
        }
      }
    })
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.unsubscribe()
  }
}
