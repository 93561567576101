import { Component, Input, OnInit } from '@angular/core'
import { Image, Document, Video, Folder } from '../../../../generated/graphql.private'
import { ImageAttribute } from 'ionic-image-loader'

@Component({
  selector: 'media-tile',
  template: `
    <div *ngIf="media; else skeleton" class="container square">
      <ng-container *ngIf="media.tile; else noTile">
        <img-loader [src]="media.tile" useImg [imgAttributes]="imgAttributes" [spinner]="false"></img-loader>
        <ion-icon name="play-circle-outline" *ngIf="isVideo" class="icon video-icon"></ion-icon>
      </ng-container>
      <ng-template #noTile>
        <div class="fallback square"></div>

        <ng-container *ngIf="isFolder; else notFolder">
          <svg width="46" height="46" viewBox="0 0 46 46" class="folder icon">
            <g clip-path="url(#clip0)">
              <path
                d="M41.9412 9.99611H23.2616L19.541 5.57199C19.4299 5.4385 19.2635 5.36364 19.09 5.36905H4.05882C1.80888 5.39137 -0.00259036 7.22292 2.78059e-06 9.47297V36.5318C-0.000109964 38.78 1.81069 40.609 4.05882 40.6312H41.9412C44.1893 40.609 46.0001 38.78 46 36.5318V14.0955C46.0001 11.8473 44.1893 10.0184 41.9412 9.99611Z"
                fill="#FFB80D"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="46" height="46" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div class="filename" *ngIf="!disableFilename && noFilename != ''">
            {{ media.description }}
          </div>
          <ellipsis-image-overlay *ngIf="showEllipsis == ''"></ellipsis-image-overlay>
        </ng-container>

        <ng-template #notFolder>
          <svg viewBox="0 0 23 29" class="icon fallback-icon">
            <path
              d="M2.20166 25.5603V3.40065C2.20166 2.92991 2.58322 2.54835 3.05395 2.54835H15.8384V5.95753C15.8384 6.89894 16.6015 7.66212 17.543 7.66212H20.9521V10.219H22.6567V6.80983C22.6581 6.58327 22.5691 6.36551 22.4096 6.2047L17.2958 1.09093C17.135 0.93139 16.9172 0.842485 16.6907 0.843764H3.05395C1.64181 0.843764 0.49707 1.98855 0.49707 3.40065V25.5603C0.49707 26.9725 1.64181 28.1172 3.05395 28.1172H9.02002V26.4126H3.05395C2.58327 26.4126 2.20166 26.031 2.20166 25.5603Z"
            />

            <text x="23" y="22" text-anchor="end" font-size="7" font-weight="bold">
              {{ fileExtension && (fileExtension | uppercase) }}
            </text>
          </svg>
        </ng-template>
      </ng-template>
      <div class="filename" *ngIf="!disableFilename && noFilename != '' && isDocument">
        {{ media.filename }}
      </div>
      <ellipsis-image-overlay *ngIf="showEllipsis == ''"></ellipsis-image-overlay>
    </div>

    <ng-template #skeleton>
      <ion-skeleton-text animated class="skeleton square"></ion-skeleton-text>
    </ng-template>
  `,
  styles: [
    `
      .container {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      .square {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.2rem;
      }

      img-loader {
        width: 100%;
        height: 100%;
      }

      .square:after {
        content: '';
        float: left;
        padding-top: 100%;
      }

      .icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }

      .video-icon {
        width: 60%;
        height: 60%;
      }

      .fallback {
        width: 100%;
      }

      .filename {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 50%);
        color: white;

        font-size: 0.8rem;
        line-height: 2rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      svg.fallback-icon path,
      svg.fallback-icon text {
        fill: #666;
      }

      .skeleton {
        width: 100%;
        margin: 0;
        line-height: 0;
      }
    `
  ]
})
export class MediaTileComponent implements OnInit {
  @Input() media: Image | Video | Document | Folder
  @Input() noFilename: string | null
  @Input() showEllipsis: string | null
  @Input() disableFilename = false

  isFolder = false
  isVideo = false
  isDocument = false
  fileExtension = null

  imgAttributes: ImageAttribute[]

  ngOnInit() {
    this.isFolder = this.media && this.media.__typename == 'Folder'
    this.isVideo = this.media && this.media.__typename == 'Video'
    this.isDocument = this.media && this.media.__typename == 'Document'
    this.fileExtension = this.isDocument && (this.media as Document).fileExtension

    this.imgAttributes = [{ element: 'style', value: 'vertical-align: top; width: 100%; height: 100%;' }]
  }
}
