import { Component } from '@angular/core'

@Component({
  selector: 'dialog-form',
  template: `
    <div class="header">
      <ng-content select="header"></ng-content>
    </div>
    <div class="content"><ng-content></ng-content></div>
    <div class="footer">
      <ng-content select="footer"></ng-content>
    </div>
  `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        width: 430px;
        height: 80vh;
        max-height: 650px;
        overflow: hidden;
      }

      .header {
        flex: 0 0 auto;
      }

      .content {
        flex: 1 1 auto;
        overflow-y: auto;
      }

      .footer {
        display: inline-block;
        flex: 0 0 auto;
        margin: 1rem;
      }
      .footer:empty {
        display: none;
      }
    `
  ]
})
export class DialogFormComponent {}
