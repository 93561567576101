import { Component } from '@angular/core'

@Component({
  selector: 'section-header',
  template: ` <ng-content> </ng-content> `,
  styles: [
    `
      :host {
        display: block;
        color: var(--ion-color-medium);
        padding: 1rem 1rem 0rem 1rem;
        line-height: 2rem;
        font-size: var(--smaller-font-size);
      }
    `
  ]
})
export class SectionHeaderComponent {}
