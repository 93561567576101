import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router'
import { AuthService } from '../services/auth.service'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivateChild, CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return this.isLoggedInOrRedirect()
  }

  canActivateChild() {
    return this.isLoggedInOrRedirect()
  }

  private isLoggedInOrRedirect() {
    return this.authService
      .isLoggedIn()
      .pipe(map((isLoggedIn) => (isLoggedIn ? isLoggedIn : this.router.parseUrl('/signup'))))
  }
}
