import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'subscriptionType'
})
export class SubscriptionTypePipe implements PipeTransform {
  transform(subscriptionType): string {
    return (
      { free: 'gratis', trial: 'trial', trial_extended: 'trial pro', budget: 'budget', pro: 'pro', expert: 'expert' }[
        subscriptionType
      ] || subscriptionType
    )
  }
}
