import { Component, Input } from '@angular/core'

@Component({
  selector: 'empty-list-message',
  template: `
    <img class="image" src="/core-assets/{{ type }}/empty.svg" />
    <div class="label"><ng-content></ng-content></div>
  `,
  styles: [
    `
      :host {
        display: block;
      }

      .image {
        display: block;
        width: 250px;
        margin: 0 auto;
      }

      .label {
        color: var(--ion-color-medium);
        width: 70%;
        text-align: center;
        margin: 1rem auto 0 auto;
      }
    `
  ]
})
export class EmptyListMessageComponent {
  @Input() type = 'projects' // Display assets/projects/empty.svg by default
}
