import { Component } from '@angular/core'

@Component({
  selector: 'max-width-container',
  template: `<div class="container"><ng-content></ng-content></div> `,
  styles: [
    `
      .container {
        max-width: 800px;
      }
    `
  ]
})
export class MaxWidthContainerComponent {}
