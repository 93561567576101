import { Component, Input } from '@angular/core'
import { Member } from '../../../../generated/graphql.private'
import { ImageAttribute } from 'ionic-image-loader'

@Component({
  selector: 'member-item',
  template: `
    <ion-item detail="false" [color]="color" [button]="button">
      <ng-container *ngIf="member; else skeleton">
        <ion-avatar [ngClass]="{ lighten: lighten }">
          <img-loader
            [src]="member.avatarThumbnail"
            [imgAttributes]="imgAttributes"
            useImg="true"
            spinner="false"
            fallbackAsPlaceholder="true"
            fallbackUrl="/core-assets/users/avatar.svg"
          ></img-loader>
        </ion-avatar>
        <ion-label>
          <span [ngClass]="{ lighten: lighten }" class="name">{{ member.name }}</span>
          <div class="details">
            {{ member | memberRole }}
            <ng-container *ngIf="label"> &ndash; {{ label }}</ng-container>
          </div>
        </ion-label>
        <ng-content></ng-content>
      </ng-container>
      <ng-template #skeleton>
        <ion-avatar><img src="/core-assets/users/avatar.svg" /></ion-avatar>
        <ion-label>
          <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          <div class="details"><ion-skeleton-text animated style="width: 40%"></ion-skeleton-text></div>
        </ion-label>
      </ng-template>
      <ion-icon slot="end" color="medium" name="ellipsis-vertical" size="small" *ngIf="showEllipsis == ''"></ion-icon>
    </ion-item>
  `,
  styles: [
    `
      .lighten {
        opacity: 0.5;
      }

      .name {
        color: var(--ion-text-color);
      }

      .details {
        color: var(--ion-color-medium);
        line-height: 1rem;
        font-size: var(--smaller-font-size);
      }

      ion-avatar {
        overflow: hidden;
        margin-right: 1rem; /* Instead of slot="start" so checkbox clickable area covers avatar */
      }
    `
  ]
})
export class MemberItemComponent {
  imgAttributes: ImageAttribute[] = [{ element: 'style', value: 'vertical-align: top;' }]
  @Input() color
  @Input() member: Member
  @Input() lightenInvited: string | null
  @Input() showEllipsis: string | null
  @Input() button = true

  label: string = null
  lighten = false

  ngOnChanges() {
    if (!this.member) return // No label or lighten for skeleton
    const active = this.member.active === undefined ? true : this.member.active
    this.label = !active ? 'Verwijderd' : !this.member.user ? 'Uitgenodigd' : null
    this.lighten = this.lightenInvited == '' && !this.member.user
  }
}
