import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AgreementPrice, Member, Project, Agreement, DeletedEvent } from '../generated/graphql.private'
import gql from 'graphql-tag'
import { Apollo } from 'apollo-angular'
import { ApolloClient } from '@apollo/client/core'
import { ActionMenuService } from './action-menu.service'

@Injectable({
  providedIn: 'root'
})
export class AgreementService {
  apolloClient: ApolloClient<any>

  constructor(private apollo: Apollo, private router: Router, private actionMenuService: ActionMenuService) {
    this.apolloClient = this.apollo.getClient()
  }

  newAgreementActionMenu(project: Pick<Project, 'id'>, counterpartMember?: Pick<Member, 'id'>, $event?: Event) {
    const pathFor = (priceType: AgreementPrice) => {
      if (counterpartMember) {
        return ['/projects', project.id, 'agreements', 'new', counterpartMember.id, priceType]
      } else {
        return ['/projects', project.id, 'agreements', 'new', 'select', priceType]
      }
    }

    return this.actionMenuService.showActionMenu(
      [
        {
          text: 'Meerwerkafspraak',
          icon: 'add-outline',
          handler: () => {
            this.router.navigate(pathFor(AgreementPrice.Increase))
          }
        },
        {
          text: 'Minderwerkafspraak',
          icon: 'remove-outline',
          handler: () => {
            this.router.navigate(pathFor(AgreementPrice.Decrease))
          }
        },
        {
          text: 'Afspraak zonder prijs',
          icon: 'document-text-outline',
          handler: () => {
            this.router.navigate(pathFor(AgreementPrice.None))
          }
        }
      ],
      $event
    )
  }

  delete(deleted: DeletedEvent) {
    const agreement = this.findAgreementInCache(deleted.id)
    if (agreement === null) return
    const project = this.findProjectInCache(agreement.projectId)

    if (project === null) return

    this.apolloClient.writeFragment({
      id: this.projectFragmentId(agreement.projectId),
      fragment: this.projectFragment(),
      data: {
        __typename: project.__typename,
        agreements: project.agreements.filter((ag: Agreement) => ag.id != agreement.id)
      }
    })
  }

  projectFragment() {
    return gql`
      fragment projectAgreementIds on Project {
        __typename
        agreements {
          __typename
          id
        }
      }
    `
  }

  findAgreementInCache(agreementId) {
    const fragmentId = `Agreement:${agreementId}`

    let agreement = null

    const fragment = gql`
      fragment agreementWithProjectId on Agreement {
        __typename
        id
        projectId
      }
    `
    try {
      agreement = this.apolloClient.readFragment({ id: fragmentId, fragment })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }
    return agreement
  }

  projectFragmentId(projectId) {
    return `Project:${projectId}`
  }

  findProjectInCache(projectId) {
    const fragmentId = this.projectFragmentId(projectId)
    let project = null

    try {
      project = this.apolloClient.readFragment({ id: fragmentId, fragment: this.projectFragment() })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }

    return project
  }

  updateCache(agreement: Pick<Agreement, 'id' | 'projectId'>) {
    const project = this.findProjectInCache(agreement.projectId)
    if (project === null) return
    if (project.agreements?.find((existingAgreement) => existingAgreement.id === agreement.id)) return

    this.apolloClient.writeFragment({
      id: this.projectFragmentId(agreement.projectId),
      fragment: this.projectFragment(),
      data: {
        __typename: project.__typename,
        agreements: [...project.agreements, { id: agreement.id, __typename: 'Agreement' }]
      }
    })
  }
}
