import { Injectable, NgZone } from '@angular/core'
import { Storage } from '@ionic/storage'
import { of, combineLatest, iif, from } from 'rxjs'
import { map, mergeMap, switchMap, take, tap } from 'rxjs/operators'
import { Apollo } from 'apollo-angular'
import { AuthService } from '@core/services/auth.service'
import { TokenService } from '@core/services/token.service'
import { EnvService } from '@core/services/env.service'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class WebAuthService implements AuthService {
  httpUrl: string
  environment: any
  constructor(
    private storage: Storage,
    private apollo: Apollo,
    private tokenService: TokenService,
    private envService: EnvService,
    private ngZone: NgZone,
    private router: Router
  ) {
    this.environment = this.envService.config()
    this.httpUrl = `${this.environment.backend.httpProtocol}${this.environment.backend.domain}/graphql?auth_protocol=2`
  }

  login(token: string, refreshToken: string) {
    return this.tokenService.setRefreshToken(refreshToken).pipe(switchMap(() => this.tokenService.setToken(token)))
  }

  logout() {
    return combineLatest([
      this.tokenService.setToken(null),
      this.tokenService.setRefreshToken(null),
      this.clearStoragePreservingConfig(),
      this.apollo.client.clearStore()
    ])
  }

  // clears entire key value storage except for certain config settings
  async clearStoragePreservingConfig() {
    const mobileAppNudgeShown = await this.storage.get('mobileAppNudgeShown')
    await this.storage.clear()
    if (mobileAppNudgeShown) {
      await this.storage.set('mobileAppNudgeShown', mobileAppNudgeShown)
    }
  }

  isLoggedIn() {
    return this.tokenService.getToken().pipe(map((token) => typeof token === 'string' && token != ''))
  }

  logoutAndRedirect() {
    return this.logout()
      .pipe(
        tap(() =>
          this.ngZone.run(async () => {
            await this.router.navigate(['/login'], { replaceUrl: true })
          })
        ),
        take(1)
      )
      .subscribe()
  }

  refreshToken() {
    return this.tokenService.getRefreshToken().pipe(
      mergeMap((refreshToken: string | null) =>
        iif(
          () => refreshToken != null,
          from(this.getRefreshAccessToken(refreshToken)).pipe(
            switchMap((result) => {
              if (result?.data) {
                return this.tokenService.setToken(result.data.refreshToken.accessToken).pipe(
                  switchMap(() => this.tokenService.setRefreshToken(result.data.refreshToken.refreshToken)),
                  map(() => result.data.refreshToken.accessToken)
                )
              } else {
                return of(null)
              }
            })
          ),
          of(null)
        )
      )
    )
  }

  async getRefreshAccessToken(refreshToken) {
    try {
      const payload = await (
        await fetch(this.httpUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            query: `mutation refreshToken($input: RefreshTokenInputType!) {
              refreshToken(input: $input) {
                accessToken
                refreshToken
              }
            }
            `,
            variables: {
              input: {
                refreshToken: refreshToken
              }
            }
          })
        })
      ).json()

      if (
        payload?.errors?.length > 0 ||
        payload?.data?.refreshToken?.accessToken === null ||
        payload?.data?.refreshToken?.refreshToken === null
      )
        this.logoutAndRedirect()
      return payload
    } catch (_e) {
      this.logoutAndRedirect()
    }
  }
}
