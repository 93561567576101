import { Component, Input, HostListener, Output, EventEmitter } from '@angular/core'
import { Image, Document, Video, Folder } from '../../../../generated/graphql.private'
import { MediaService } from '../../../../services/media.service'
import { ActionMenuService } from '../../../../services/action-menu.service'
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'media-actions',
  template: `<ng-content></ng-content>`
})
export class MediaActionsComponent {
  @Input() media: Image | Document | Video | Folder
  @Input() payload?: string
  @Input() alwaysOpenOptions: string | null
  @Output() delete = new EventEmitter()

  constructor(
    private actionMenuService: ActionMenuService,
    private mediaService: MediaService,
    private route: ActivatedRoute
  ) {}

  @HostListener('click', ['$event'])
  clicked($event) {
    $event.preventDefault()
    $event.stopPropagation()
    this.click($event)
  }

  public click($event) {
    if (this.alwaysOpenOptions == '' || $event.ellipsisClicked) this.openOptions($event)
    else this.mediaService.open(this.media, this.payload, this.route)
  }

  async openOptions($event) {
    const buttons = []

    if (this.delete.observers.length > 0)
      buttons.unshift({
        text: 'Verwijder',
        icon: 'trash-outline',
        role: 'destructive',
        handler: () => this.delete.emit()
      } as any)

    if (!!this.media.id) {
      buttons.unshift({
        text: 'Verplaats naar...',
        icon: 'folder-open-outline',
        handler: () => this.mediaService.move(this.media, this.payload, this.route)
      } as any)
    }
    // Only media with an id (stored on server) can be opened
    if (!!this.media.id && this.media.__typename != 'Folder')
      buttons.unshift({
        text: this.openText(),
        icon: this.openIcon(),
        handler: () => this.mediaService.open(this.media, this.payload, this.route)
      } as any)

    if (this.media.__typename == 'Folder')
      buttons.unshift({
        text: 'Bewerken',
        icon: 'create-outline',
        handler: () => this.mediaService.edit(this.media, this.payload, this.route)
      } as any)
    await this.actionMenuService.showActionMenu(buttons, $event)
  }

  sheetTitle(): string {
    if (this.media.__typename == 'Document') return 'Document'
    if (this.media.__typename == 'Video') return 'Video'
    return 'Afbeelding'
  }

  openIcon(): string {
    if (this.media.__typename == 'Document') return 'download-outline'
    if (this.media.__typename == 'Video') return 'videocam-outline'
    return 'camera-outline'
  }

  openText(): string {
    if (this.media.__typename == 'Document') return 'Download'
    return 'Open'
  }
}
