import { Injectable, OnDestroy } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Subject } from 'rxjs'
import { auditTime, takeUntil, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class TitleService implements OnDestroy {
  timeoutId = null
  destroyed$: Subject<boolean> = new Subject()
  titleChanged$: Subject<boolean> = new Subject()

  prefix = 'Homigo'
  constructor(private title: Title) {
    this.titleChanged$
      .pipe(
        auditTime(1000),
        tap((title) => this.blinkUntilFocus(title)),
        takeUntil(this.destroyed$)
      )
      .subscribe()
  }

  setTitle(newTitle) {
    this.title.setTitle([this.prefix, newTitle].join(' - '))
  }

  blinkTitleUntilFocus(newTitle) {
    this.titleChanged$.next(newTitle)
  }

  blinkUntilFocus(newTitle) {
    // if the tab already has focus we just blink it a few times
    if (document.hasFocus()) return this.blink(newTitle)
    // if the tab does not have focus, we blink the tab till it gets focus
    const oldTitle = document.title

    const stopBlinking = () => {
      if (this.timeoutId === null) return
      document.title = oldTitle
      clearInterval(this.timeoutId)
      unregisterEvents() // eslint-disable-line
    }

    const unregisterEvents = () => window.removeEventListener('focus', stopBlinking, true)
    if (this.timeoutId !== null) {
      unregisterEvents() // eslint-disable-line
      stopBlinking()
    }

    const setBlinkTitle = () => (document.title = document.title === newTitle ? oldTitle : newTitle)
    const registerEvents = () => window.addEventListener('focus', () => stopBlinking(), true)
    const startBlinking = () => (this.timeoutId = setInterval(setBlinkTitle, 1000))
    startBlinking()
    registerEvents()
  }

  blink(msg: string, count = 3): void {
    let timeout: NodeJS.Timeout
    const prevTitle = this.title.getTitle()

    const step = () => {
      const newTitle = this.title.getTitle() === prevTitle ? msg : prevTitle

      this.title.setTitle(newTitle)

      if (--count) {
        timeout = setTimeout(step.bind(this), 1000)
      } else {
        this.title.setTitle(prevTitle)
      }
    }

    clearTimeout(timeout)
    step()
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.unsubscribe()
  }
}
