import { Injectable } from '@angular/core'
import {
  Image,
  Document,
  Video,
  HomerunSDK,
  ChatEventsDocument,
  CreateMediaMessageMutation,
  Folder,
  Media
} from '../generated/graphql.private'
import { Apollo } from 'apollo-angular'
import { ApolloClient, gql } from '@apollo/client/core'
import { AlertController } from '@ionic/angular'
import { take } from 'rxjs/operators'
import { ToastService } from './toast.service'
import { ActivatedRoute } from '@angular/router'
import { mergeDeep } from '@apollo/client/utilities/common/mergeDeep'
import { FormBuilder } from '@angular/forms'
import { requiredValidator } from '../validators/required.validator'
import { folderValidator } from '../validators/folder.validator'

@Injectable({
  providedIn: 'root'
})
export abstract class MediaService {
  apolloClient: ApolloClient<any>

  constructor(
    private apollo: Apollo,
    private alertController: AlertController,
    private homerunSDK: HomerunSDK,
    private toastService: ToastService
  ) {
    this.apolloClient = this.apollo.client
  }

  localizeTypename(typename) {
    if (typename == 'Image') return 'afbeelding'
    if (typename == 'Document') return 'document'
    if (typename == 'Video') return 'video'
    if (typename == 'Folder') return 'map'
  }

  buildForm(folder: Partial<Folder>, memberIds) {
    return new FormBuilder().group({
      description: [folder.description, [requiredValidator, folderValidator]],
      memberIds: [memberIds, []]
    })
  }

  updateCache(media: Pick<Media, 'id' | 'projectId'>) {
    const fragmentId = `Project:${media.projectId}`
    let project = null

    const fragment = gql`
      fragment projectMediaIds on Project {
        __typename
        media {
          __typename
          edges {
            cursor
            __typename
            node {
              id
              __typename
            }
          }
          __typename
        }
      }
    `

    try {
      project = this.apolloClient.readFragment({ id: fragmentId, fragment })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }

    if (!project) return
    if (project.media.edges.map((e) => e.node?.id).includes(media.id)) return

    this.apolloClient.writeFragment({
      id: fragmentId,
      fragment: gql`
        fragment writeMedia on Project {
          media {
            __typename
            edges {
              cursor
              __typename
              node {
                id
                __typename
              }
            }
          }
        }
      `,
      data: {
        media: {
          __typename: 'Connection',
          edges: [
            {
              cursor: media.id,
              __typename: 'Edge',
              node: { id: media.id, __typename: (media as any).__typename }
            },
            ...project.media.edges
          ]
        }
      }
    })
  }

  abstract open(media: Image | Document | Video | Folder, payload: string | null, route: ActivatedRoute)
  abstract edit(media: Image | Document | Video | Folder, payload: string | null, route: ActivatedRoute)
  abstract move(media: Image | Document | Video | Folder, payload: string | null, route: ActivatedRoute)

  createMediaMessage(chatId, user, file, projectId, payload?: string) {
    return this.homerunSDK.createMediaMessage(
      { input: { chatId, file, payload } },
      {
        optimisticResponse: this.createMediaMessageOptimisticResponse(chatId, user, projectId),
        update: (proxy, { data: { createMediaMessage } }) =>
          this.optimisticUpdate(chatId, proxy, createMediaMessage.mediaMessage)
      }
    )
  }

  private createMediaMessageOptimisticResponse(chatId, user, projectId) {
    return {
      createMediaMessage: {
        __typename: 'CreateMediaMessagePayload',
        mediaMessage: {
          __typename: 'MediaMessage',
          id: `optimistic_${Math.round(Math.random() * -1000000).toString()}`,
          createdAt: new Date().toString(),
          updatedAt: new Date().toString(),
          payload: null,
          policy: {
            __typename: 'EventPolicy',
            delete: false
          },
          media: null,

          projectId: projectId,
          chatId,
          userId: user.id,
          user,
          createdByCurrentUser: true
        },
        errors: []
      }
    } as CreateMediaMessageMutation
  }

  private async optimisticUpdate(chatId, proxy, createdEvent) {
    const data = proxy.readQuery({
      query: ChatEventsDocument,
      variables: { chatId, before: null },
      returnPartialData: true // in some cases the edges are incomplete
    })

    const mergedData = mergeDeep(data, {
      chat: {
        events: {
          __typename: 'EventConnection',
          edges: [
            // in some cases the cursor is missing (probably because of the relayStylePagination merging)
            ...(data?.chat?.events?.edges.map((edge) => Object.assign({}, edge, { cursor: edge.node.id })) || []),
            { __typename: 'EventEdge', cursor: createdEvent.id, node: createdEvent }
          ]
        }
      }
    })

    proxy.writeQuery({
      query: ChatEventsDocument,
      variables: { chatId, before: null },
      data: mergedData,
      returnPartialData: true
    })
  }

  async deleteMedia(media: Image | Document | Video, afterDelete) {
    const thisMedia = `${media.__typename == 'Document' ? 'dit' : 'deze'} ${this.localizeTypename(media.__typename)}`
    const theMedia = `${media.__typename == 'Document' ? 'Het' : 'De'} ${this.localizeTypename(media.__typename)}`
    const ownedByAgreement = media.ownerType == 'Agreement'
    const ownedByEvent = media.ownerType == 'Event'

    if (media.policy.delete) {
      let warning = ''
      if (ownedByAgreement || ownedByEvent)
        warning = `Let op, ${thisMedia} wordt ook verwijderd uit een ${ownedByAgreement ? 'afspraak' : 'chat'}!`

      const confirmation = await this.alertController.create({
        header: 'Weet je het zeker?',
        message: `Weet je zeker dat je ${thisMedia} wil verwijderen? ${warning}`,
        buttons: [
          { text: 'Annuleren', role: 'cancel' },
          {
            text: 'Verwijderen',
            handler: () => {
              this.homerunSDK
                .deleteMedia({ input: { mediaId: media.id } }, { refetchQueries: ['projectMedia'] })
                .pipe(take(1))
                .subscribe(() => {
                  this.toastService.success(`${theMedia} is verwijderd`)
                  afterDelete()
                })
            }
          }
        ]
      })
      await confirmation.present()
    } else {
      let reason = ''
      if (ownedByAgreement) reason = ` omdat ${thisMedia} bij een geaccepteerde afspraak hoort`
      if (!media.user?.current) reason = ` omdat ${thisMedia} niet door jou is toegevoegd`

      const confirmation = await this.alertController.create({
        header: 'Niet toegestaan',
        message: `Je kan ${thisMedia} niet verwijderen ${reason}`,
        buttons: [{ text: 'Ok', role: 'cancel' }]
      })
      await confirmation.present()
    }
  }
}
