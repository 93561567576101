import { Injectable } from '@angular/core'
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router'
import { AuthService } from '../services/auth.service'
import { Observable } from 'rxjs'
import { take, map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class LoggedOutGuard implements CanActivateChild, CanActivate {
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute) { }
  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    return this.redirectWhenNotLoggedIn()
  }

  canActivateChild(): Observable<boolean> {
    return this.redirectWhenNotLoggedIn()
  }

  redirectWhenNotLoggedIn() {
    return this.authService.isLoggedIn().pipe(
      take(1),
      map((isLoggedIn) => {
        if (window.location.toString().match('logout=true')) {
          this.authService.logout().pipe(take(1)).subscribe()
          if (window.location.toString().match('redirect_to=subscription-plan')) {
            this.router.navigate(['/login'], { replaceUrl: true, queryParams: { redirect_to: 'subscription-plan' } })
          } else {
            this.router.navigate(['/login'], { replaceUrl: true })
          }
          return false
        }
        if (isLoggedIn) this.router.navigate(['/'], { replaceUrl: true })

        return !isLoggedIn
      })
    )
  }
}

