import { Injectable } from '@angular/core'
import { Activity } from '../generated/graphql.private'
import gql from 'graphql-tag'
import { Apollo } from 'apollo-angular'
import { ApolloClient } from '@apollo/client/core'
import { FormBuilder } from '@angular/forms'
import { requiredValidator } from '../validators/required.validator'

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  apolloClient: ApolloClient<any>

  constructor(private apollo: Apollo) {
    this.apolloClient = this.apollo.getClient()
  }

  updateCache(activity: Pick<Activity, 'id' | 'projectId'>) {
    const fragmentId = `Project:${activity.projectId}`
    let project = null

    const fragment = gql`
      fragment projectActivityIds on Project {
        __typename
        activities {
          __typename
          id
        }
      }
    `

    try {
      project = this.apolloClient.readFragment({ id: fragmentId, fragment })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }

    if (project?.activities?.find((existingActivity) => existingActivity.id === activity.id)) return
    if (!project) return

    this.apolloClient.writeFragment({
      id: fragmentId,
      fragment,
      data: {
        __typename: project.__typename,
        activities: [...project.activities, { __typename: 'Activity', id: activity.id }]
      }
    })
  }

  buildForm(activity: Partial<Activity>) {
    return new FormBuilder().group({
      name: [activity.name, [requiredValidator]],
      color: activity.color,
      date: activity.date,
      endDate: activity.endDate,
      duration: activity.duration,
      assignedMemberIds: activity.assignedMemberIds,
      description: [activity.description]
    })
  }
}
