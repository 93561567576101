import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MediaType } from '../../../generated/graphql.private'

@Component({
  selector: 'media-type-select',
  template: `
    <ion-select [formControl]="type" interface="popover" name="view">
      <ion-select-option value="all">Alle bestanden</ion-select-option>
      <ion-select-option [value]="mediaType.Image">Afbeeldingen</ion-select-option>
      <ion-select-option [value]="mediaType.Video">Video's</ion-select-option>
      <ion-select-option [value]="mediaType.Document">Documenten</ion-select-option>
      <ion-select-option [value]="mediaType.Folder">Mappen</ion-select-option>
    </ion-select>
  `
})
export class MediaTypeSelectComponent {
  @Input() type: FormControl

  mediaType = MediaType
}
