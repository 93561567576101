import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'
import { LoggedInGuard } from '@core/guards/logged-in.guard'
import { LoggedOutGuard } from '@core/guards/logged-out.guard'
import { NotFoundComponent } from '@web/modules/layout/components/not-found.component'
import { CanAccessWebGuard } from '@web/guards/can-access-web.guard'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'projects'
  },
  {
    path: 'subscription',
    loadChildren: () => import('./modules/subscription/subscription.module').then((m) => m.SubscriptionModule),
    canActivateChild: [LoggedInGuard]
  },
  {
    path: 'email',
    loadChildren: () => import('./modules/email/email.module').then((m) => m.EmailModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./modules/signup/signup.module').then((m) => m.SignupModule),
    canActivateChild: [LoggedOutGuard]
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./modules/password-reset/password-reset.module').then((m) => m.PasswordResetModule),
    canActivateChild: [LoggedOutGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'invitations',
    loadChildren: () => import('./modules/invitation/invitation.module').then((m) => m.InvitationModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('./modules/project/project.module').then((m) => m.ProjectModule),
    canActivateChild: [LoggedInGuard, CanAccessWebGuard]
  },
  /*
   * the create new project modal must be available globally, not just in the project context
   */
  {
    path: 'new-project',
    outlet: 'root-modal',
    loadChildren: () => import('./modules/new-project/new-project.module').then((m) => m.NewProjectModule),
    canActivateChild: [LoggedInGuard]
  },
  {
    path: 'templates',
    outlet: 'root-modal',
    loadChildren: () => import('./modules/templates/templates.module').then((m) => m.TemplatesModule),
    canActivateChild: [LoggedInGuard]
  },
  {
    path: 'settings',
    outlet: 'root-modal',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
    canActivateChild: [LoggedInGuard]
  },
  { path: '**', component: NotFoundComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
