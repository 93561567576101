import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { ActionMenuService } from '@core/services/action-menu.service'

@Component({
  selector: 'add-media',
  template: `<input type="file" (change)="fileInputChanged($event.target.files)" #fileInput [multiple]="multiple" />`,
  styles: [
    `
      input {
        display: none;
      }
    `
  ]
})
export class AddMediaComponent {
  constructor(private actionMenuService: ActionMenuService) {}

  @Output() complete: EventEmitter<any> = new EventEmitter()
  @ViewChild('fileInput') fileInput: ElementRef
  @Input() multiple = false

  async fileInputChanged(files: File[]) {
    Array.from(files).map(
      async (file: File) =>
        await this.complete.emit({
          uri: await new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.onerror = reject
            fileReader.onload = () => resolve(fileReader.result)
            fileReader.readAsDataURL(file)
          }),
          contentType: file.type,
          file: file,
          filename: file.name
        })
    )
  }

  public open() {
    return this.fileInput.nativeElement.click()
  }
}
