import { Injectable } from '@angular/core'
import { Project } from '../generated/graphql.private'
import gql from 'graphql-tag'
import { Apollo } from 'apollo-angular'
import { ApolloClient } from '@apollo/client/core'

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  apolloClient: ApolloClient<any>

  constructor(private apollo: Apollo) {
    this.apolloClient = this.apollo.getClient()
  }

  updateCache(project: Pick<Project, 'id'>) {
    const query = gql`
      query projects {
        projects {
          __typename
          id
        }
      }
    `
    const projects: Project[] = this.apolloClient.readQuery({ query })?.projects || []

    this.apolloClient.writeQuery({
      query,
      data: {
        projects: !projects.find((existingProject) => existingProject.id === project.id)
          ? [...projects, { id: project.id, __typename: 'Project' }]
          : projects
      }
    })
  }

  placeholderThumbnail(project: Pick<Project, 'id'>): string {
    return `/core-assets/projects/placeholder-thumbnail-${parseInt(project.id) % 4}.png`
  }

  placeholderBanner(project: Pick<Project, 'id'>): string {
    return `/core-assets/projects/placeholder-banner-${parseInt(project.id) % 4}.png`
  }
}
