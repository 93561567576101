import { BrowserModule } from '@angular/platform-browser'
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'

import { AppRoutingModule } from './app-routing.module'
import { HttpClientModule } from '@angular/common/http'
import { AppComponent } from './app.component'
import { GraphQLModule } from '@core/modules/graphql/graphql.module'
import { IonicStorageModule } from '@ionic/storage'
import { File } from '@ionic-native/file/ngx'
import { IonicModule } from '@ionic/angular'
import { EnvService } from '@core/services/env.service'
import { AuthService } from '@core/services/auth.service'
import { WebEnvService } from './services/web-env.service'
import { WebAuthService } from './services/web-auth.service'
import { WebView } from '@ionic-native/ionic-webview/ngx'
import { MediaService } from '@core/services/media.service'
import { WebMediaService } from './services/web-media.service'
import { WebActionMenuService } from '@web/services/web-action-menu.service'
import { ActionMenuService } from '@core/services/action-menu.service'
import { SubscriptionTriggersService } from '@core/services/subscription-triggers.service'
import { WebSubscriptionTriggersService } from './services/web-subscription-triggers.service'
import { ApolloNetworkStatusModule } from '@core/services/apollo-network-status.ts'
import { HelipopperModule } from '@ngneat/helipopper'
import { registerLocaleData } from '@angular/common'
import { GlobalErrorHandler } from './services/error-handler'
import localeNl from '@angular/common/locales/nl'
import { LoggerModule } from 'ngx-logger'
import * as Sentry from '@sentry/browser'
import { environment } from './../environments/environment'
import { TodoService } from '@core/services/todo.service'
import { WebTodoService } from '@web/services/web-todo.service'
import gitInfo from '../git-version.json'

registerLocaleData(localeNl, 'nl-NL')

Sentry.init({
  dsn: environment.sentry.dsn,
  environment: environment.sentry.environment,
  release: gitInfo.hash || undefined
})

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    GraphQLModule.forRoot(),
    IonicStorageModule.forRoot(),
    ApolloNetworkStatusModule,
    IonicModule.forRoot({ mode: 'ios' }),
    HelipopperModule.forRoot(),
    LoggerModule.forRoot({ level: environment.logLevel })
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LOCALE_ID, useValue: 'nl-NL' },
    { provide: EnvService, useClass: WebEnvService },
    { provide: AuthService, useClass: WebAuthService },
    { provide: MediaService, useClass: WebMediaService },
    { provide: TodoService, useClass: WebTodoService },
    { provide: ActionMenuService, useClass: WebActionMenuService },
    { provide: SubscriptionTriggersService, useClass: WebSubscriptionTriggersService },
    File,
    WebView
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
