import { EnvironmentInterface } from '@core/interfaces/environment.interface'
import { NgxLoggerLevel } from 'ngx-logger'

export const environment: EnvironmentInterface = {
  production: true,
  logLevel: NgxLoggerLevel.OFF,
  frontend: {
    httpProtocol: 'https://',
    domain: 'app.homigo.nl'
  },
  backend: {
    httpProtocol: 'https://',
    domain: 'api.homigo.nl',
    wsProtocol: 'wss://'
  },
  sentry: {
    environment: 'production',
    dsn: 'https://e0f1b9157e03424fb7bef81a7d085217@o315498.ingest.sentry.io/5520149'
  }
}
