import { Component, Input } from '@angular/core'

@Component({
  selector: 'plus',
  template: `
    <svg viewBox="0 0 20 20" [ngClass]="small == '' ? 'small' : 'regular'">
      <path class="background" d="M13,0 v7 h7 v6 h-7 v7 h-6 v-7 h-7 v-6 h7 v-7 z" />
      <path class="foreground" d="M11,2 v7 h7 v2 h-7 v7 h-2 v-7 h-7 v-2 h7 v-7 z" />
    </svg>
  `,
  styles: [
    `
      .background {
        fill: var(--ion-color-primary);
      }

      .foreground {
        fill: white;
      }

      .small {
        width: 15px;
        height: 15px;
      }

      .regular {
        width: 20px;
        height: 20px;
      }
    `
  ]
})
export class PlusComponent {
  @Input() small: string | null = null
}
