import { Component } from '@angular/core'

@Component({
  selector: 'empty-list-cta',
  template: `<div class="container"><ng-content></ng-content></div>`,
  styles: [
    `
      :host {
        display: flex;
        justify-content: flex-end;
      }

      .container {
        display: block;
        margin: 0.5rem;
        padding: 1rem 1rem 0.5rem 1rem;
        background-color: var(--ion-color-secondary);
        border-radius: var(--default-border-radius);
        color: #fff;
        max-width: 500px;
      }

      .container ::ng-deep h4 {
        font-size: var(--default-font-size);
        font-weight: bold;
      }

      .container ::ng-deep * {
        margin: 0 0 0.5rem 0;
      }

      .container ::ng-deep .fab-margin {
        margin-right: 4rem;
      }
    `
  ]
})
export class EmptyListCtaComponent {}
