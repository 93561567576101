interface ActionMenuButton {
  text: string
  icon?: string
  role?: 'destructive' | 'selected' | string
  handler?: () => boolean | void | Promise<boolean | void>
}

export abstract class ActionMenuService {
  abstract async showActionMenu(buttons: ActionMenuButton[], $event: Event, backdropDismiss?: boolean)
}
