import { Component, Input } from '@angular/core'
import { HomerunSDK } from '@core/generated/graphql.private'
import { ModalController } from '@ionic/angular'
import { ApolloQueryResult } from '@apollo/client/core'
import { Observable } from 'rxjs'
import { CurrentUserQuery } from '@core/generated/graphql.private'
import { Router } from '@angular/router'
import { EnvService } from 'projects/core/src/services/env.service'
import { tap } from 'rxjs/operators'

@Component({
  selector: 'limit-modal',
  template: `
    <ion-content>
      <ion-icon size="large" (click)="closeDialog()" name="close-outline" *ngIf="showClose"></ion-icon>
      <div class="content" *ngIf="(currentUserQueryResult$ | async)?.data?.currentUser as currentUser">
        <h1 [innerHTML]="title"></h1>
        <p [innerHTML]="copy"></p>

        <ng-container *ngIf="login">
          <p *ngIf="mobile">
            <ion-button (click)="loginWithBrowser()">Login en wijzig je abonement</ion-button>
          </p>
          <div *ngIf="!mobile">
            <ion-button (click)="navigate()"> Wijzig abonnement</ion-button>
          </div>
        </ng-container>

        <svg
          *ngIf="chillDude"
          width="257"
          height="210"
          viewBox="0 0 257 210"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.1"
            d="M118.513 204.473C167.755 204.473 207.674 194.136 207.674 181.385C207.674 168.634 167.755 158.297 118.513 158.297C69.2702 158.297 29.3513 168.634 29.3513 181.385C29.3513 194.136 69.2702 204.473 118.513 204.473Z"
            fill="#2B2B45"
          />
          <path
            d="M150.404 66.8804L39.5726 177.62C38.4044 178.787 36.623 178.671 35.6008 177.357C34.5787 176.044 34.6663 174.059 35.8345 172.891L146.665 62.152C147.834 60.9844 149.615 61.1012 150.637 62.4147C151.659 63.7281 151.572 65.7129 150.404 66.8804Z"
            fill="#4A2B06"
          />
          <path
            d="M42.0844 137.224L143.745 153.802C145.293 154.065 146.344 155.641 146.111 157.393C145.877 159.115 144.475 160.311 142.927 160.078L41.2667 143.499C39.7189 143.236 38.6675 141.66 38.9011 139.909C39.1348 138.158 40.5366 136.961 42.0844 137.224Z"
            fill="#4A2B06"
          />
          <path
            d="M140.357 77.3589V157.013C140.357 158.385 139.248 159.494 137.875 159.494H137.232C135.86 159.494 134.75 158.385 134.75 157.013V77.3589C134.75 75.987 135.86 74.8779 137.232 74.8779H137.875C139.248 74.8779 140.357 75.987 140.357 77.3589Z"
            fill="#4A2B06"
          />
          <path
            d="M143.774 69.3029C143.774 69.3029 145.819 64.662 149.352 65.4793C152.857 66.2966 205.629 88.0708 205.629 88.0708C205.629 88.0708 171.373 166.003 99.9385 165.361C78.444 158.21 41.9384 143.937 41.9384 143.937C41.9384 143.937 41.5587 138.216 45.6766 138.216C49.7944 138.245 98.0986 139.121 143.774 69.3029Z"
            fill="#FFB80D"
          />
          <path
            d="M175.228 75.6368C174.615 76.7168 174.002 77.7968 173.388 78.8767C169.679 85.3856 165.766 91.7778 161.093 97.4695C157.297 102.11 153.033 106.226 148.711 110.225C134.021 123.768 118.25 136.056 100.903 144.812C91.6158 149.512 81.8908 153.131 71.9028 155.379C81.5403 158.969 91.8203 162.676 99.9391 165.39C171.373 166.003 205.63 88.0709 205.63 88.0709C205.63 88.0709 190.035 81.6496 175.228 75.6368Z"
            fill="#FFCA61"
          />
          <path
            d="M209.251 90.7271L98.391 201.466C97.2228 202.634 95.4413 202.517 94.4192 201.204C93.397 199.89 93.4846 197.905 94.6528 196.738L205.484 85.9986C206.652 84.8311 208.433 84.9479 209.456 86.2613C210.507 87.5748 210.419 89.5596 209.251 90.7271Z"
            fill="#693B11"
          />
          <path
            d="M100.932 161.07L202.592 177.649C204.14 177.912 205.192 179.488 204.958 181.239C204.724 182.961 203.322 184.158 201.775 183.924L100.114 167.346C98.566 167.083 97.5147 165.507 97.7483 163.755C97.9527 162.004 99.3838 160.807 100.932 161.07Z"
            fill="#693B11"
          />
          <path
            d="M199.205 101.527V179.196C199.205 180.743 197.949 181.969 196.43 181.969H196.372C194.824 181.969 193.597 180.714 193.597 179.196V101.527C193.597 99.9796 194.853 98.7537 196.372 98.7537H196.43C197.949 98.7537 199.205 99.9796 199.205 101.527Z"
            fill="#693B11"
          />
          <path
            d="M63.1697 149.249C63.5201 149.833 57.8837 150.504 57.2412 150.679L51.7215 151.993C51.5171 152.051 51.3127 151.905 51.2543 151.701L46.9904 134.013C46.932 133.779 47.078 133.546 47.3117 133.487C48.6843 133.196 53.0065 132.699 54.2915 137.661C55.0216 140.113 55.2845 141.368 55.4013 141.923C55.4305 142.156 55.9854 143.937 56.1898 143.908C57.212 143.762 61.2714 141.397 61.6511 143.061"
            fill="#191928"
          />
          <path
            d="M51.6923 151.993C51.4879 152.051 51.2835 151.905 51.2251 151.701L46.9904 134.013C46.932 133.78 47.078 133.546 47.3117 133.488C47.5161 133.459 47.7497 133.4 48.0418 133.371L52.4517 151.789L51.6923 151.993Z"
            fill="#ABABB7"
          />
          <path
            d="M96.1999 132.437L96.2584 132.407C96.8717 132.291 97.5142 132.407 98.1567 132.787C99.062 133.342 100.113 134.538 100.727 137.048C101.69 141.018 98.5071 141.777 98.5071 141.777L65.3308 149.278L65.1264 148.636L64.5715 148.753L63.2281 142.711L63.3449 142.682L63.1697 142.069L95.6159 132.583L95.9955 132.466C96.0539 132.466 96.1415 132.437 96.1999 132.437Z"
            fill="#34344C"
          />
          <path
            d="M63.1405 150.475L61.0669 141.864C61.0377 141.748 61.1253 141.631 61.2422 141.602L64.6007 140.784C64.7175 140.755 64.8343 140.814 64.8635 140.93L67.1999 149.482C67.2291 149.599 67.1707 149.716 67.0247 149.745L63.4033 150.621C63.2865 150.65 63.1697 150.592 63.1405 150.475Z"
            fill="#2B2B45"
          />
          <path
            d="M78.7066 141.106C76.7499 141.66 75.1437 141.368 74.5596 139.238C73.9463 137.136 75.4941 136.581 77.4508 136.027C79.4075 135.472 81.0137 135.122 81.5978 137.224C82.1819 139.354 80.6341 140.551 78.7066 141.106Z"
            fill="#191928"
          />
          <path d="M67.2288 148.257L66.3818 144.725L76.545 142.302L81.0133 144.958L67.2288 148.257Z" fill="#191928" />
          <path
            d="M134.78 163.96C133.232 164.339 128.53 164.543 126.281 164.339C124.032 164.164 125.609 166.382 128.793 167.783C132.005 169.155 125.434 172.191 126.778 177.999C128.121 183.837 140.942 181.794 141.993 177.853C143.044 173.913 144.475 169.243 144.475 169.243L134.78 163.96Z"
            fill="#F7BDA4"
          />
          <path
            d="M77.6843 108.707C75.0559 107.481 72.6319 107.072 70.6168 107.539C69.215 107.86 67.9884 108.648 67.638 110.692C67.3167 112.735 68.1344 116.296 69.9451 119.069C71.931 122.133 74.209 123.067 75.9612 123.242C78.7357 123.505 81.1596 122.513 82.0358 119.302C82.5615 117.405 83.0871 115.566 84.3429 114.807"
            fill="#F7BDA4"
          />
          <path
            d="M163.05 115.07C154.113 134.538 134.78 163.931 134.78 163.931L144.475 169.243C166.32 154.62 177.331 137.399 182.792 124.79L163.05 115.07Z"
            fill="#F7BDA4"
          />
          <path
            d="M183.317 125.052C196.605 99.6002 181.185 83.0798 181.185 83.0798C181.185 83.0798 160.537 86.057 166.729 97.4403C168.51 100.68 166.145 106.897 162.582 114.69L162.494 114.866"
            fill="#EB5757"
          />
          <path
            d="M123.273 77.9719C106.422 90.9897 75.9326 108.59 75.9326 108.59L81.7151 116.442C107.327 112.034 124.909 101.585 135.539 93.0329L123.273 77.9719Z"
            fill="#F7BDA4"
          />
          <path
            d="M135.86 93.4707C159.282 77.0378 152.828 57.5402 152.828 57.5402C152.828 57.5402 141.467 52.8993 134.809 64.6037C133.144 67.4933 129.844 72.251 123.098 77.4756L122.952 77.5924"
            fill="#EB5757"
          />
          <path
            d="M143.629 136.377L122.981 156.925C122.981 156.925 115.563 155.904 105.867 148.548C105.721 148.461 105.604 148.344 105.487 148.257C105.341 148.169 105.225 148.052 105.079 147.965C95.3827 140.609 92.4038 133.75 92.4038 133.75L104.524 109.553"
            fill="#2B2B45"
          />
          <path
            d="M183.084 84.6558C180.485 75.0238 171.723 55.0008 145.994 56.8104C143.22 57.0148 140.971 58.7369 139.16 60.8968C96.6969 111.83 100.289 119.769 100.289 119.769C100.289 119.769 126.924 142.477 133.728 144.696C141.438 147.235 180.835 93.0912 180.835 93.0912C183.025 90.8437 183.901 87.6038 183.084 84.6558Z"
            fill="#EB5757"
          />
          <path
            d="M161.589 72.9515L161.998 58.7661C168.89 61.2179 172.775 64.954 176.192 69.7408L162.845 73.9147C162.232 74.0606 161.619 73.5936 161.589 72.9515Z"
            fill="#EDB39F"
          />
          <path
            d="M180.689 77.8258C180.601 77.9425 160.655 117.872 126.836 145.951C124.704 144.316 124.003 143.791 124.003 143.791C124.003 143.791 156.362 115.799 178.411 73.36C178.908 74.0897 180.251 76.3956 180.689 77.8258Z"
            fill="#2B2B45"
          />
          <path
            d="M152.098 56.7813C152.01 56.8981 119.097 86.5823 101.194 126.716C103.326 128.35 104.027 128.876 104.027 128.876C104.027 128.876 122.251 90.1433 157.18 57.4819C156.362 57.2192 153.587 56.8105 152.098 56.7813Z"
            fill="#2B2B45"
          />
          <path d="M131.947 147.965L98.1863 122.221L100.464 117.697L135.626 144.345L131.947 147.965Z" fill="#492517" />
          <path
            d="M116.994 137.369L111.329 133.079C110.511 132.466 110.365 131.328 110.978 130.51L112.584 128.409C113.198 127.591 114.337 127.445 115.154 128.058L120.82 132.349C121.638 132.962 121.784 134.1 121.17 134.918L119.564 137.019C118.922 137.836 117.783 137.982 116.994 137.369Z"
            fill="#FFCA61"
          />
          <path
            d="M177.71 68.5148L180.017 72.7179L162.115 74.6443C161.618 74.6735 161.472 74.0022 161.939 73.827L177.71 68.5148Z"
            fill="#8C2222"
          />
          <path
            d="M172.395 93.1495L169.212 97.6445C168.89 98.0239 167.897 97.8196 167.372 97.4694L166.116 96.6521C165.59 96.3018 165.006 95.4554 165.24 95.0176L168.219 90.3767C168.481 89.8805 169.182 89.7929 169.766 90.1724L171.986 91.6026C172.541 92.0112 172.745 92.7117 172.395 93.1495Z"
            stroke="#FFCA61"
            stroke-width="6.06483"
            stroke-miterlimit="10"
          />
          <path
            d="M143.19 72.1926L139.481 76.2789C139.131 76.6 138.138 76.2789 137.671 75.8994L136.502 74.9362C136.035 74.5276 135.539 73.6228 135.831 73.2141L139.364 68.9819C139.686 68.5441 140.387 68.5149 140.912 68.9527L142.956 70.6456C143.482 71.0834 143.599 71.8131 143.19 72.1926Z"
            stroke="#FFCA61"
            stroke-width="6.06483"
            stroke-miterlimit="10"
          />
          <path
            d="M162.67 57.3943L157.997 56.2851L161.268 73.9731C161.356 74.4401 162.056 74.4109 162.115 73.9147L162.67 57.3943Z"
            fill="#8C2222"
          />
          <path
            d="M167.371 33.6061C161.443 36.2038 156.069 39.9982 151.63 44.7267C149.411 47.0909 147.366 49.747 146.461 52.8701C145.643 55.643 145.789 58.591 146.344 61.4222C146.811 63.7573 147.571 66.0631 148.797 68.1063C150.958 71.6672 154.405 74.2358 157.967 76.4249C160.829 78.147 163.896 79.7231 167.225 80.1317C169.328 80.3944 171.46 80.2193 173.563 79.9566C176.337 79.6064 179.141 79.1102 181.681 77.9718C187.055 75.52 190.647 70.4121 193.977 65.5377C195.116 63.874 196.225 62.2395 197.364 60.5758C199.73 57.1316 202.183 53.3955 202.212 49.1924C202.241 45.6315 200.46 42.2165 197.948 39.6772C195.437 37.1378 192.253 35.3865 188.983 33.9271C185.449 32.3218 182.966 31.5337 179.111 31.2126C175.256 30.8916 171.694 31.8548 167.371 33.6061Z"
            fill="#F7BDA4"
          />
          <path
            d="M188.457 63.4655C187.815 65.3336 187.289 66.8513 187.143 67.2891C186.997 67.8145 186.822 68.3399 186.997 68.8653C187.201 69.4783 187.844 69.8577 188.486 69.9744C189.129 70.062 189.771 69.9161 190.414 69.7701C191.436 69.5366 192.458 69.3031 193.509 69.0696C194.064 68.9529 194.619 68.8069 195.116 68.6026C198.124 67.3767 200.635 62.4147 202.008 59.5251C204.169 54.9134 205.133 47.2953 203.41 42.4209C202.504 39.8816 200.577 37.1671 198.357 35.6201C197.423 34.978 196.284 34.9488 195.349 34.3358C194.152 33.5478 193.597 32.03 192.546 31.096C191.524 30.162 190.151 29.695 188.837 29.2571C186.705 28.5566 184.573 27.8561 182.441 27.1264C181.682 26.8637 180.893 26.601 180.075 26.5718C179.258 26.5135 178.469 26.6886 177.681 26.8053C175.198 27.1848 172.687 27.1556 170.204 26.7761C169.241 26.6302 168.277 26.4259 167.342 26.5718C165.707 26.8345 164.597 28.4982 163.458 29.695C162.64 30.5414 161.443 31.0084 160.713 31.9424C159.924 32.964 159.778 34.5694 159.691 35.8244C159.662 36.2331 159.662 36.6709 159.866 37.0212C160.1 37.4298 160.596 37.6633 161.034 37.8092C162.903 38.4805 164.889 38.86 166.904 38.86C167.342 38.86 167.81 38.86 168.218 38.9767C169.27 39.2978 169.445 40.4653 170.117 41.195C170.964 42.1291 172.103 42.888 173.3 43.1798C177.155 44.1722 178.323 47.3537 180.484 50.3601C180.689 50.652 181.068 50.7979 181.419 50.9438C183.084 51.5568 184.865 51.8195 186.646 51.7611C186.588 52.1114 186.53 52.4908 186.471 52.8411C187.902 53.0746 189.362 53.2789 190.794 53.5124C191.611 53.7751 189.83 59.4376 188.457 63.4655Z"
            fill="#7C3F15"
          />
          <path
            d="M193.555 71.5744C195.551 69.1448 195.643 65.9232 193.76 64.3788C191.878 62.8343 188.734 63.5518 186.738 65.9814C184.743 68.4109 184.651 71.6325 186.533 73.1769C188.416 74.7214 191.56 74.0039 193.555 71.5744Z"
            fill="#F7BDA4"
          />
          <path
            d="M162.523 30.3079C129.055 25.3751 192.283 59.7878 200.109 63.9909C200.577 64.2244 201.132 64.1076 201.424 63.6698C204.344 59.6127 217.369 39.4145 200.022 24.9956C180.63 8.88381 166.057 25.988 166.057 25.988L162.523 30.3079Z"
            fill="#FFB80D"
          />
          <path
            d="M207.176 34.365C201.189 32.4094 189.858 31.3295 175.957 45.8067C167.137 38.8308 167.487 39.3562 167.487 39.3562C167.487 39.3562 177.096 19.6251 183.2 18.1073C188.106 18.1073 193.801 19.8586 199.992 25.0248C203.555 27.9436 205.833 31.1251 207.176 34.365Z"
            fill="#FFCA61"
          />
          <path
            d="M162.523 30.3078C157.15 34.686 192.75 54.8842 203.585 60.3423C202.416 62.5022 202.066 62.7941 201.424 63.6698C201.131 64.1076 200.547 64.2243 200.109 63.9908C192.283 59.7877 129.055 25.3751 162.523 30.3078Z"
            fill="#FFCA61"
          />
          <path
            d="M162.753 53.9299C163.348 52.8404 163.062 51.5377 162.114 51.0201C161.165 50.5026 159.914 50.9662 159.319 52.0556C158.723 53.1451 159.01 54.4478 159.958 54.9653C160.907 55.4829 162.158 55.0193 162.753 53.9299Z"
            fill="#332623"
          />
          <path
            d="M174.042 61.1429C174.637 60.0535 174.304 58.7257 173.299 58.1772C172.294 57.6288 170.997 58.0673 170.402 59.1568C169.807 60.2462 170.139 61.574 171.144 62.1225C172.149 62.6709 173.447 62.2324 174.042 61.1429Z"
            fill="#332623"
          />
          <path
            d="M165.999 50.2141C166.291 50.36 166.641 50.3308 166.904 50.1265C167.255 49.8638 167.255 49.3676 166.963 49.0174C166.875 48.9298 164.714 46.624 161.647 46.1278C161.18 46.0402 160.771 46.3321 160.713 46.7699C160.684 47.2077 161.005 47.6164 161.472 47.6747C163.896 48.0542 165.707 49.9514 165.707 49.9806C165.794 50.0973 165.911 50.1849 165.999 50.2141Z"
            fill="#332623"
          />
          <path
            d="M178.878 58.6786C179.199 58.8537 179.608 58.8537 179.9 58.6786C180.309 58.4159 180.338 57.8613 179.987 57.4527C179.87 57.3359 177.417 54.4755 173.825 53.4831C173.299 53.3372 172.803 53.5999 172.715 54.0669C172.628 54.5339 172.978 55.0301 173.475 55.1468C176.395 55.9641 178.498 58.3867 178.527 58.4159C178.644 58.5327 178.761 58.6202 178.878 58.6786Z"
            fill="#332623"
          />
          <path
            d="M155.427 62.7649C156.04 64.487 157.004 66.0923 158.405 67.3182C159.778 68.4857 161.443 69.2446 163.195 69.5073C164.305 69.6825 165.415 69.6825 166.524 69.5657C167.079 69.5073 167.079 68.6317 166.524 68.6901C164.772 68.8944 162.991 68.7484 161.355 68.0771C159.866 67.4934 158.581 66.4426 157.646 65.1583C157.062 64.341 156.624 63.4654 156.273 62.5022C156.098 62.006 155.251 62.2103 155.427 62.7649Z"
            fill="#B5664E"
          />
          <path
            d="M154.143 56.1235C154.601 55.6241 154.123 54.4428 153.076 53.4852C152.028 52.5275 150.809 52.156 150.352 52.6555C149.894 53.1549 150.372 54.3362 151.419 55.2939C152.466 56.2515 153.686 56.623 154.143 56.1235Z"
            fill="#E2A48F"
          />
          <path
            d="M177.527 69.5514C177.803 68.9332 176.977 67.9631 175.681 67.3846C174.385 66.8061 173.11 66.8383 172.834 67.4565C172.558 68.0747 173.384 69.0448 174.68 69.6233C175.976 70.2018 177.251 70.1696 177.527 69.5514Z"
            fill="#E2A48F"
          />
          <path
            d="M189.859 65.4794C191.231 65.1584 193.714 65.567 193.947 67.3183C194.093 68.2815 193.714 69.2155 193.305 70.0911C193.246 70.2079 193.188 70.3538 193.071 70.4414C192.954 70.5289 192.779 70.5581 192.662 70.4706C192.546 70.3538 193.042 69.5657 193.071 69.3906C193.13 69.0403 193.071 68.6317 193.013 68.3106C192.954 67.9896 192.925 67.6393 192.779 67.3474C192.546 66.8512 192.078 66.5302 191.582 66.3259C191.085 66.0924 190.56 65.9756 190.063 65.7713"
            fill="#E2A48F"
          />
          <path
            d="M170.788 66.3551L168.803 65.0708C166.466 63.553 165.794 60.4299 167.313 58.0949L168.861 55.7015C169.182 55.2053 169.825 55.0593 170.321 55.3804L178.966 60.9845C179.462 61.3056 179.608 61.9477 179.287 62.4439L177.739 64.8373C176.25 67.2307 173.125 67.8728 170.788 66.3551Z"
            fill="black"
          />
          <path
            d="M158.114 58.1532L156.332 56.9857C153.938 55.4388 153.266 52.2281 154.814 49.8639L156.245 47.6748C156.595 47.1202 157.325 46.9742 157.88 47.3245L166.349 52.8118C166.904 53.1621 167.05 53.8918 166.7 54.4464L165.269 56.6355C163.692 59.0289 160.509 59.7002 158.114 58.1532Z"
            fill="black"
          />
          <path
            d="M164.481 55.9642C164.86 55.7015 165.357 55.6139 165.824 55.5556C166.291 55.4972 166.788 55.4972 167.197 55.7307C167.635 55.9934 167.956 56.4312 168.219 56.869C168.423 57.1901 168.774 57.6571 168.598 58.0365C168.452 58.3284 168.949 58.5035 169.095 58.1824C169.329 57.6571 168.978 57.1317 168.715 56.6939C168.394 56.1393 168.014 55.5847 167.459 55.2637C166.934 54.9718 166.35 54.9134 165.766 55.001C165.181 55.0886 164.597 55.2053 164.13 55.5556C163.838 55.7599 164.189 56.1393 164.481 55.9642Z"
            fill="black"
          />
          <path
            d="M156.917 51.7318C157.267 51.2648 157.588 50.7686 157.939 50.3016C158.085 50.1265 158.231 49.893 158.289 49.6887C158.318 49.5719 158.318 49.4844 158.318 49.3968C158.289 48.9298 158.085 48.6379 157.647 48.9882C157.296 49.2509 156.946 49.8346 156.625 50.3892C156.479 50.6519 156.303 50.9146 156.157 51.1773C155.982 51.4692 155.807 51.7902 155.69 52.0821C155.573 52.4324 155.544 52.7534 155.661 52.841C155.778 52.9285 156.011 52.8118 156.245 52.6075C156.479 52.4032 156.741 52.0821 156.975 51.761C157.267 51.3524 157.588 50.9146 157.822 50.4476"
            fill="#E5E5E5"
          />
          <path
            d="M170 59.4668C170.234 59.1165 170.438 58.7663 170.672 58.416C170.759 58.2701 170.847 58.1241 170.876 57.9782C170.905 57.8906 170.876 57.8323 170.876 57.7739C170.789 57.4528 170.584 57.2193 170.292 57.482C170.059 57.6863 169.825 58.1241 169.62 58.5036C169.533 58.7079 169.416 58.883 169.328 59.0873C169.212 59.3208 169.124 59.5251 169.066 59.7586C169.007 60.0213 169.036 60.2548 169.124 60.3132C169.241 60.3716 169.416 60.284 169.562 60.1381C169.737 59.963 169.883 59.7586 170.059 59.5251C170.263 59.2333 170.467 58.9122 170.584 58.5619"
            fill="#E5E5E5"
          />
          <path
            d="M86.5337 165.711C86.9133 166.295 81.2768 167.054 80.6051 167.229L75.1147 168.659C74.9102 168.717 74.7058 168.601 74.6474 168.367L70.0915 150.738C70.0331 150.504 70.1791 150.271 70.4128 150.212C71.7854 149.891 76.0784 149.307 77.451 154.269C78.2104 156.692 78.5316 157.947 78.6192 158.531C78.6776 158.764 79.2325 160.545 79.4662 160.516C80.4883 160.34 84.4893 157.918 84.9274 159.582"
            fill="#191928"
          />
          <path
            d="M75.144 168.66C74.9395 168.718 74.7351 168.601 74.6767 168.368L70.1208 150.738C70.0624 150.505 70.2084 150.271 70.442 150.213C70.6465 150.154 70.8801 150.125 71.1721 150.067L75.9033 168.426L75.144 168.66Z"
            fill="#ABABB7"
          />
          <path
            d="M119.301 148.315L119.36 148.286C119.973 148.169 120.615 148.257 121.258 148.636C122.163 149.191 123.244 150.358 123.886 152.839C124.908 156.809 121.754 157.597 121.754 157.597L88.7241 165.711L88.5197 165.069L87.9648 165.186L86.5338 159.173L86.6506 159.144L86.4753 158.531L118.717 148.49L119.097 148.373C119.155 148.344 119.214 148.344 119.301 148.315Z"
            fill="#2B2B45"
          />
          <path
            d="M86.6213 167.813L84.4017 159.231C84.3725 159.115 84.4309 158.998 84.5478 158.969L87.9063 158.093C88.0231 158.064 88.1399 158.122 88.1691 158.239L90.6515 166.733C90.6807 166.849 90.6223 166.966 90.5055 166.995L86.8841 167.929C86.7673 167.988 86.6505 167.929 86.6213 167.813Z"
            fill="#2B2B45"
          />
          <path
            d="M102.012 158.181C100.084 158.764 98.4781 158.502 97.8356 156.4C97.1931 154.298 98.741 153.715 100.669 153.131C102.596 152.547 104.202 152.168 104.845 154.269C105.487 156.371 103.939 157.597 102.012 158.181Z"
            fill="#191928"
          />
          <path d="M90.4471 164.865L89.6877 161.887L99.7925 159.29L104.202 161.333L90.4471 164.865Z" fill="#191928" />
          <path
            d="M71.2293 126.191H56.1598C55.1961 126.191 54.4368 125.402 54.4368 124.468V103.336C54.4368 102.84 54.8456 102.432 55.3421 102.432H72.0471C72.5435 102.432 72.9524 102.84 72.9524 103.336V124.468C72.9524 125.402 72.1639 126.191 71.2293 126.191Z"
            fill="#E5E5E5"
          />
          <path
            d="M72.9525 108.328C69.6524 108.328 66.9656 111.013 66.9656 114.311C66.9656 117.609 69.6524 120.295 72.9525 120.295C76.2526 120.295 78.9395 117.609 78.9395 114.311C78.9103 110.984 76.2526 108.328 72.9525 108.328ZM72.9525 118.135C70.8206 118.135 69.0975 116.413 69.0975 114.282C69.0975 112.151 70.8206 110.429 72.9525 110.429C75.0845 110.429 76.8075 112.151 76.8075 114.282C76.8075 116.413 75.0553 118.135 72.9525 118.135Z"
            fill="#E5E5E5"
          />
          <path
            d="M67.7533 108.269C64.8036 108.386 61.854 107.744 58.9043 107.364C57.4441 107.189 55.9255 107.072 54.436 107.16V122.688C54.436 123.651 55.2246 124.41 56.1591 124.41H71.2286C72.1924 124.41 72.9517 123.622 72.9517 122.688V107.393C71.2286 107.831 69.5055 108.182 67.7533 108.269Z"
            fill="#FFFCF0"
          />
          <path
            d="M67.7533 110.02C64.8036 110.137 61.854 109.495 58.9043 109.116C57.4441 108.94 55.9255 108.824 54.436 108.911V124.439C54.436 125.402 55.2246 126.161 56.1591 126.161H71.2286C72.1924 126.161 72.9517 125.373 72.9517 124.439V109.145C71.2286 109.583 69.5055 109.933 67.7533 110.02Z"
            fill="#FFCA61"
          />
          <path
            d="M62.1757 114.077C62.5789 114.077 62.9058 113.751 62.9058 113.348C62.9058 112.945 62.5789 112.618 62.1757 112.618C61.7724 112.618 61.4456 112.945 61.4456 113.348C61.4456 113.751 61.7724 114.077 62.1757 114.077Z"
            fill="#FFFCF0"
          />
          <path
            d="M61.417 116.442C61.675 116.442 61.8842 116.233 61.8842 115.975C61.8842 115.717 61.675 115.508 61.417 115.508C61.1589 115.508 60.9497 115.717 60.9497 115.975C60.9497 116.233 61.1589 116.442 61.417 116.442Z"
            fill="#FFFCF0"
          />
          <path
            d="M62.0004 119.886C62.3069 119.886 62.5553 119.638 62.5553 119.331C62.5553 119.025 62.3069 118.777 62.0004 118.777C61.694 118.777 61.4456 119.025 61.4456 119.331C61.4456 119.638 61.694 119.886 62.0004 119.886Z"
            fill="#FFFCF0"
          />
          <path
            d="M69.6816 112.239C69.8913 112.239 70.0613 112.069 70.0613 111.859C70.0613 111.65 69.8913 111.48 69.6816 111.48C69.472 111.48 69.302 111.65 69.302 111.859C69.302 112.069 69.472 112.239 69.6816 112.239Z"
            fill="#FFFCF0"
          />
          <path
            d="M70.7911 110.137C71.1943 110.137 71.5212 109.81 71.5212 109.407C71.5212 109.004 71.1943 108.678 70.7911 108.678C70.3879 108.678 70.061 109.004 70.061 109.407C70.061 109.81 70.3879 110.137 70.7911 110.137Z"
            fill="#FFFCF0"
          />
          <path
            d="M59.8103 110.546C60.5361 110.546 61.1245 109.958 61.1245 109.232C61.1245 108.507 60.5361 107.919 59.8103 107.919C59.0845 107.919 58.4961 108.507 58.4961 109.232C58.4961 109.958 59.0845 110.546 59.8103 110.546Z"
            fill="#FFFCF0"
          />
          <path
            d="M59.8399 119.886C60.0495 119.886 60.2195 119.716 60.2195 119.506C60.2195 119.297 60.0495 119.127 59.8399 119.127C59.6302 119.127 59.4602 119.297 59.4602 119.506C59.4602 119.716 59.6302 119.886 59.8399 119.886Z"
            fill="#FFFCF0"
          />
          <path
            d="M60.0151 122.892C60.2248 122.892 60.3948 122.722 60.3948 122.513C60.3948 122.303 60.2248 122.133 60.0151 122.133C59.8055 122.133 59.6355 122.303 59.6355 122.513C59.6355 122.722 59.8055 122.892 60.0151 122.892Z"
            fill="#FFFCF0"
          />
        </svg>
        <svg
          *ngIf="!chillDude"
          width="261"
          height="197"
          viewBox="0 0 261 197"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0)">
            <path
              d="M77.4282 139.699C92.1401 128.316 127.776 108.437 163.956 140.08C167.825 143.457 169.241 148.631 169.514 153.86C175.181 277.437 160.905 285.171 160.905 285.171C160.905 285.171 95.7364 288.929 83.0405 284.572C68.71 279.67 71.2165 154.785 71.2165 154.785C70.5626 148.958 72.9056 143.185 77.4282 139.699Z"
              fill="#EB5757"
            />
            <path
              d="M122.599 146.507L138.019 124.939C124.997 120.855 115.025 121.998 104.509 125.266L119.657 146.507C120.419 147.487 121.836 147.487 122.599 146.507Z"
              fill="#EDB39F"
            />
            <path
              d="M88.7619 132.238C88.7619 132.51 73.3416 214.369 91.9767 294.212C96.9896 294.212 98.6243 294.212 98.6243 294.212C98.6243 294.212 82.0597 216.111 97.0986 128.207C95.5729 128.697 90.9959 130.604 88.7619 132.238Z"
              fill="#2B2B45"
            />
            <path
              d="M154.911 133.163C154.911 133.436 170.331 214.641 151.696 294.539C146.683 294.539 145.048 294.539 145.048 294.539C145.048 294.539 161.613 216.438 146.574 128.534C148.1 129.024 152.677 131.529 154.911 133.163Z"
              fill="#2B2B45"
            />
            <path
              d="M103.692 121.781L95.5184 125.43L119.984 148.468C120.692 149.067 121.673 148.25 121.183 147.433L103.692 121.781Z"
              fill="#8C2222"
            />
            <path
              d="M83.8034 164.371L83.4764 174.665C83.5309 175.591 85.2746 176.407 86.3643 176.462L89.1433 176.68C90.2875 176.789 92.1402 176.19 92.3036 175.264L93.1209 165.025C93.2844 163.99 92.3581 163.064 91.0504 162.955L86.0919 162.574C84.8387 162.519 83.7489 163.336 83.8034 164.371Z"
              stroke="#FFCA61"
              stroke-width="5"
              stroke-miterlimit="10"
            />
            <path
              d="M150.824 166.168L151.75 176.407C151.914 177.333 153.712 177.932 154.856 177.878L157.69 177.769C158.834 177.715 160.632 176.898 160.632 175.972L160.196 165.678C160.251 164.643 159.161 163.826 157.908 163.881L152.949 164.099C151.641 164.153 150.661 165.134 150.824 166.168Z"
              stroke="#FFCA61"
              stroke-width="5"
              stroke-miterlimit="10"
            />
            <path
              d="M138.51 122.162L146.683 125.811L121.891 148.468C121.182 149.067 120.202 148.25 120.692 147.433L138.51 122.162Z"
              fill="#8C2222"
            />
            <path
              d="M145.266 69.3321C153.494 78.1007 159.978 88.5578 164.119 99.8862C166.19 105.55 167.716 111.65 167.007 117.641C166.353 122.979 163.956 127.989 160.959 132.401C158.453 136.104 155.456 139.481 151.859 142.095C145.539 146.616 137.747 148.577 130.064 149.666C123.907 150.537 117.477 150.973 111.483 149.285C107.669 148.195 104.182 146.344 100.749 144.328C96.2267 141.714 91.8131 138.828 88.2713 134.961C80.8609 126.846 78.4089 115.408 76.2294 104.625C75.521 100.975 74.7582 97.2719 74.0498 93.6229C72.5241 85.9435 71.053 77.774 74.0498 70.5303C76.6108 64.3759 82.1686 59.8555 88.3258 57.3501C94.483 54.8448 101.24 54.1913 107.887 54.0279C115.134 53.8645 119.929 54.3002 126.849 56.5332C133.769 58.7662 139.164 63.1777 145.266 69.3321Z"
              fill="#F7BDA4"
            />
            <path
              d="M87.1816 105.224C86.9091 108.927 86.7457 111.923 86.6367 112.74C86.5277 113.72 86.4188 114.809 85.7649 115.572C84.9476 116.498 83.5854 116.661 82.4411 116.389C81.2969 116.062 80.2615 115.354 79.2807 114.646C77.7006 113.502 76.1204 112.304 74.4857 111.16C73.6139 110.561 72.7966 109.907 72.0337 109.199C67.7836 104.897 67.0753 94.4943 66.8573 88.5578C66.4759 79.0811 70.4536 65.2474 76.9923 58.1671C80.425 54.4636 85.6559 51.1958 90.6689 50.161C92.7939 49.7253 94.7555 50.5422 96.7716 50.161C99.387 49.6708 101.458 47.4922 103.964 46.6208C106.416 45.7494 109.086 45.9673 111.647 46.1851C115.843 46.5119 119.984 46.8932 124.179 47.2199C125.705 47.3289 127.231 47.4922 128.647 47.9824C130.064 48.4726 131.317 49.344 132.625 50.1065C136.603 52.5574 140.962 54.3546 145.484 55.4984C147.228 55.9341 149.026 56.3153 150.552 57.2957C153.167 58.9296 154.42 62.034 155.51 64.9206C156.273 66.9902 157.472 69.2232 158.017 71.3473C158.616 73.6892 157.744 76.5213 156.927 78.8088C156.654 79.5168 156.382 80.2793 155.783 80.715C155.074 81.2597 154.093 81.2597 153.167 81.2052C149.462 80.9873 145.757 80.1704 142.324 78.6999C141.561 78.3731 140.798 78.0463 139.981 77.9374C137.91 77.7195 136.821 79.5713 135.131 80.3338C133.006 81.3141 130.5 81.8043 128.211 81.423C120.856 80.3338 116.551 84.9632 110.612 88.5033C110.012 88.8301 109.304 88.8301 108.596 88.8301C105.272 88.6122 102.002 87.7953 99.0056 86.3792C98.8421 87.0328 98.6787 87.6864 98.5152 88.3399C95.8453 87.6864 93.2298 86.9783 90.5598 86.3248C88.8707 86.2703 87.8354 97.3264 87.1816 105.224Z"
              fill="#7C3F15"
            />
            <path
              d="M72.5447 115.353C70.8957 109.724 73.0942 104.126 77.4552 102.849C81.8161 101.573 86.6881 105.102 88.3371 110.731C89.986 116.36 87.7875 121.958 83.4266 123.234C79.0656 124.51 74.1936 120.982 72.5447 115.353Z"
              fill="#F7BDA4"
            />
            <path
              d="M156.001 67.2625C217.082 83.2203 83.3674 96.1282 66.8574 97.6532C65.8766 97.7621 65.0048 97.1085 64.7869 96.1282C62.7708 87.0328 55.1424 42.8628 95.4639 30.7175C140.526 17.2105 153.058 57.2412 153.058 57.2412L156.001 67.2625Z"
              fill="#FFB80D"
            />
            <path
              d="M76.3383 41.5557C88.0533 42.536 108.323 49.0172 121.564 83.9828C141.833 78.4276 140.798 79.0811 140.798 79.0811C140.798 79.0811 138.728 38.179 129.356 31.1532C120.964 27.5586 109.849 26.4148 95.4637 30.7719C87.2905 33.2228 81.0243 36.9808 76.3383 41.5557Z"
              fill="#FFCA61"
            />
            <path
              d="M156.001 67.2625C162.049 78.6999 86.0919 87.3596 63.5336 88.8301C63.9695 93.405 64.351 94.1675 64.7869 96.1282C65.0048 97.0541 65.8766 97.7076 66.8574 97.6532C83.3674 96.1282 217.082 83.2203 156.001 67.2625Z"
              fill="#FFCA61"
            />
            <path
              d="M139.415 110.289C139.19 107.983 140.634 105.956 142.641 105.76C144.648 105.564 146.457 107.275 146.682 109.58C146.907 111.885 145.463 113.912 143.456 114.108C141.449 114.304 139.64 112.594 139.415 110.289Z"
              fill="#332623"
            />
            <path
              d="M114.73 114.451C114.505 112.146 116.046 110.109 118.173 109.902C120.299 109.694 122.206 111.395 122.431 113.7C122.656 116.005 121.114 118.042 118.988 118.25C116.861 118.457 114.955 116.756 114.73 114.451Z"
              fill="#332623"
            />
            <path
              d="M139.164 101.357C138.619 101.084 138.237 100.54 138.237 99.9406C138.237 99.1237 138.891 98.5246 139.763 98.579C140.035 98.579 145.92 99.0147 150.225 102.827C150.879 103.426 150.933 104.352 150.388 104.951C149.843 105.55 148.808 105.496 148.154 104.951C144.776 101.901 139.872 101.575 139.817 101.575C139.6 101.52 139.382 101.466 139.164 101.357Z"
              fill="#332623"
            />
            <path
              d="M110.285 112.358C109.685 112.74 108.923 112.794 108.378 112.467C107.615 112.032 107.451 110.997 108.051 110.234C108.214 110.016 112.519 104.407 119.057 102.119C119.984 101.792 120.965 102.228 121.182 103.045C121.4 103.916 120.801 104.842 119.875 105.169C114.535 107.021 110.884 111.814 110.83 111.868C110.721 112.032 110.503 112.249 110.285 112.358Z"
              fill="#332623"
            />
            <path
              d="M151.524 117.659C151.104 116.468 152.789 114.789 155.287 113.909C157.784 113.03 160.15 113.283 160.57 114.475C160.989 115.666 159.305 117.345 156.807 118.225C154.309 119.104 151.944 118.851 151.524 117.659Z"
              fill="#E2A48F"
            />
            <path
              d="M101.559 123.693C101.537 122.43 103.665 121.369 106.313 121.322C108.961 121.276 111.125 122.262 111.147 123.525C111.169 124.788 109.041 125.85 106.393 125.896C103.746 125.943 101.581 124.956 101.559 123.693Z"
              fill="#E2A48F"
            />
            <path
              d="M83.3674 107.62C81.2424 106.041 76.6653 104.951 74.9762 107.783C74.0499 109.308 73.9954 111.215 74.0499 113.012C74.0499 113.284 74.1044 113.557 74.2134 113.774C74.3223 113.992 74.6493 114.156 74.8673 114.101C75.1397 113.992 74.8672 112.249 74.9762 111.977C75.1397 111.378 75.5211 110.724 75.848 110.125C76.1749 109.581 76.5019 109.036 76.9923 108.655C77.7552 108.001 78.7904 107.783 79.8257 107.783C80.8065 107.783 81.8418 107.947 82.8225 107.947"
              fill="#E2A48F"
            />
            <path
              d="M134.75 136.704C131.59 136.704 129.029 134.143 129.029 130.985C129.029 127.827 131.59 125.266 134.75 125.266C137.91 125.266 140.471 127.827 140.471 130.985C140.471 134.143 137.91 136.704 134.75 136.704Z"
              fill="#332623"
            />
            <path
              d="M101.403 108.219C101.403 108.219 105.599 116.062 101.403 116.062C97.2075 116.062 101.403 108.219 101.403 108.219Z"
              fill="#9AC2DB"
            />
            <path
              d="M92.576 117.423C92.576 117.423 96.7716 125.266 92.576 125.266C88.3804 125.266 92.576 117.423 92.576 117.423Z"
              fill="#9AC2DB"
            />
            <path
              d="M45.6069 102.446C45.6069 102.446 37.6516 120.364 31.9848 113.012C26.2635 105.768 45.6069 102.446 45.6069 102.446Z"
              fill="#9AC2DB"
            />
            <path
              d="M45.6069 70.3669C45.6069 70.3669 26.5905 74.9963 28.879 66.0098C31.1675 57.0234 45.6069 70.3669 45.6069 70.3669Z"
              fill="#9AC2DB"
            />
            <path
              d="M94.919 99.6138C94.919 99.6138 99.1146 107.457 94.919 107.457C90.7233 107.402 94.919 99.6138 94.919 99.6138Z"
              fill="#9AC2DB"
            />
            <path
              d="M172.456 52.6118C172.456 52.6118 190.873 59.3108 189.566 50.1609C188.258 41.0111 172.456 52.6118 172.456 52.6118Z"
              fill="#9AC2DB"
            />
            <path
              d="M154.094 30.1184C154.094 30.1184 170.658 19.6614 162.594 15.0864C154.53 10.5115 154.094 30.1184 154.094 30.1184Z"
              fill="#9AC2DB"
            />
            <path
              d="M157.472 84.6364C156.328 87.0328 154.747 94.5487 154.475 98.1978C154.203 101.847 150.497 98.9058 148.808 93.5139C147.119 88.122 139.818 98.1433 129.41 94.93C119.003 91.7711 126.196 71.5107 133.715 70.5303C141.289 69.55 150.225 68.0795 150.225 68.0795L157.472 84.6364Z"
              fill="#F2B7A2"
            />
            <path
              d="M195.396 106.041C207.056 68.025 151.696 86.0524 151.696 86.0524L148.863 69.2232C185.043 44.3333 250.593 62.034 229.778 117.532L195.396 106.041Z"
              fill="#F2B7A2"
            />
            <path
              d="M230.759 117.805C217.409 169.491 169.187 176.68 169.187 176.68C169.187 176.68 159.161 145.799 158.67 136.649C164.446 134.416 189.674 121.181 194.36 105.986L194.469 105.659"
              fill="#EB5757"
            />
            <path
              d="M31.5486 199.5C40.4847 146.725 79.4985 138.174 79.4985 138.174C79.4985 138.174 78.8991 160.559 77.9728 176.734C71.6522 179.566 77.5369 191.821 74.1042 207.452L74.0497 207.778"
              fill="#EB5757"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="261" height="239" fill="white" transform="matrix(-1 0 0 1 261 0)" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </ion-content>
  `,
  styles: [
    `
      .content {
        margin: 1.5rem;
        margin-bottom: 0px;
        font-size: 0.9rem;
      }

     :host ::ng-deep  h1 {
       padding-top: 0.5rem;
       font-weight: 900;
       color: var(--ion-color-secondary);
      }

      :host ::ng-deep h1 strong {
        color: var(--ion-color-primary);
        display none;
      }

      svg {
        position: absolute;
        bottom: 0px;
      }
      ion-icon {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
      }
    `
  ]
})
export class LimitModal {
  @Input() copy = ''
  @Input() title = ''
  @Input() showClose = false
  @Input() mobile = true
  @Input() hack = false
  @Input() chillDude = false
  @Input() login = true
  currentUserQueryResult$: Observable<ApolloQueryResult<CurrentUserQuery>>
  constructor(
    private homerunSDK: HomerunSDK,
    private modalController: ModalController,
    private router: Router,
    private envService: EnvService
  ) {}

  ngOnInit() {
    this.currentUserQueryResult$ = this.homerunSDK.currentUserWatch().valueChanges
  }
  closeDialog() {
    this.modalController.dismiss()
  }

  loginWithBrowser() {
    this.modalController.dismiss()
    const environment = this.envService.config()
    const inAppBrowser = this.envService.externalBrowser()
    const ref = inAppBrowser.create(
      `${environment.frontend.httpProtocol}${environment.frontend.domain}/login?logout=true`,
      '_blank',
      {
        footer: 'yes',
        location: 'no',
        clearcache: 'yes'
      }
    )

    const observerStart = ref
      .on('loadstart')
      .pipe(
        tap(async (event: any) => {
          if (event?.url?.match('mobile/close')) {
            observerStart.unsubscribe()
            await this.homerunSDK.currentUser({}, { fetchPolicy: 'network-only' }).toPromise()
            this.router.navigate(['/projects'])
            ref.close()
          }
        })
      )
      .subscribe()
  }

  navigate() {
    this.modalController.dismiss()
    if (this.hack) {
      ;(window.location as any) = `${window.location.origin}/subscription/subscription-plans`
    } else {
      this.router.navigate(['./subscription', 'subscription-plans'])
    }
  }
}
