import { Injectable } from '@angular/core'
import { Storage } from '@ionic/storage'
import { BehaviorSubject, Observable, from } from 'rxjs'
import { switchMap, tap } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private tokenSubject = new BehaviorSubject(null)
  private tokenSubjectPrio = new BehaviorSubject(null)

  constructor(private storage: Storage) {}

  getToken(): Observable<string> {
    return this.tokenSubject.pipe(switchMap(() => this.storage.get('token')))
  }

  getTokenPrio(): Observable<string> {
    return this.tokenSubjectPrio.pipe(switchMap(() => this.storage.get('token')))
  }
  setToken(token: string): Observable<string> {
    return from(this.storage.set('token', token)).pipe(
      tap(() => this.tokenSubjectPrio.next(null)),
      tap(() => this.tokenSubject.next(null))
    )
  }

  getRefreshToken(): Observable<string> {
    return from(this.storage.get('refreshToken'))
  }

  setRefreshToken(token: string): Observable<string> {
    return from(this.storage.set('refreshToken', token))
  }
}
