import { Injectable } from '@angular/core'
import { MediaService } from '@core/services/media.service'
import { ToastService } from '@core/services/toast.service'
import { AlertController, ModalController } from '@ionic/angular'
import { Apollo } from 'apollo-angular'
import { Image, Document, Video, Folder, HomerunSDK } from '@core/generated/graphql.private'
import { ActivatedRoute, Router } from '@angular/router'
import { MediaListPage } from '@web/modules/media/pages/media-list.page'
import { MoveFolderModal } from '@core/modules/media/modals/move-folder.modal'

@Injectable({
  providedIn: 'root'
})
export class WebMediaService extends MediaService {
  constructor(
    apollo: Apollo,
    alertController: AlertController,
    homerunSDK: HomerunSDK,
    toastService: ToastService,
    private modalController: ModalController,
    private router: Router
  ) {
    super(apollo, alertController, homerunSDK, toastService)
  }

  async edit(media: Image | Document | Video | Folder, _payload, route: ActivatedRoute) {
    return await this.router.navigate(
      ['/projects', route.snapshot.params.projectId, 'media', 'folder-edit'].concat(media.path.split('/'))
    )
  }

  async open(media: Image | Document | Video | Folder, _payload, route: ActivatedRoute) {
    if (media.__typename == 'Folder') {
      return await this.router.navigate(
        ['/projects', route.snapshot.params.projectId, 'media', 'browse'].concat(media.path.split('/'))
      )
    }

    if (route.component == MediaListPage) {
      await this.router.navigate(
        ['/projects', route.snapshot.params.projectId, 'media', 'preview']
          .concat(media.path.split('/'))
          .concat([media.id])
      )
    } else await this.router.navigate(['media', media.id], { relativeTo: route })
  }

  async move(media: Image | Document | Video | Folder, _payload: string | null, _route: ActivatedRoute) {
    ;(
      await this.modalController.create({
        component: MoveFolderModal,
        componentProps: { media: media },
        cssClass: 'modal'
      })
    ).present()
    return
  }
}
