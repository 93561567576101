import { Component, Input } from '@angular/core'

@Component({
  selector: 'content-header-horizontal',
  template: `
    <div class="container">
      <ion-grid>
        <ion-row>
          <ion-col [size]="wideImage ? 3 : 2" class="ion-justify-content-left" *ngIf="slot == 'left'">
            <ion-img *ngIf="imgSrc" src="{{ imgSrc }}" style="{}"></ion-img>
          </ion-col>
          <ion-col [size]="wideImage ? 9 : imgSrc ? 10 : 12"><ng-content></ng-content></ion-col>
          <ion-col [size]="wideImage ? 3 : 2" class="ion-justify-content-right" *ngIf="slot == 'right'">
            <ion-img *ngIf="imgSrc" src="{{ imgSrc }}"></ion-img>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="arrow-container" *ngIf="arrow">
        <ion-icon class="chevron-icon" name="chevron-forward-outline" size="large"></ion-icon>
      </div>
    </div>
  `,
  styles: [
    `
      .arrow-container {
        align-self: center;
      }

      .chevron-icon {
        color: var(--default-border-color);
      }

      .container {
        display: flex;
        background-color: white;
        padding: 0.5rem;
        border-bottom: 1px solid var(--default-border-color);
      }

      :host ::ng-deep h4 {
        margin: 0 0 0.25rem 0;
        font-size: var(--default-font-size);
        color: var(--ion-text-color);
        font-weight: bold;
      }

      :host ::ng-deep p {
        margin: 0;
        font-size: var(--smaller-font-size);
        line-height: var(--smaller-line-height);
        color: var(--ion-text-color);
      }

      ion-img {
        max-width: 60px;
      }
    `
  ]
})
export class ContentHeaderHorizontalComponent {
  @Input() imgSrc?: string
  @Input() wideImage?: boolean
  @Input() slot: 'left' | 'right' = 'right'
  @Input() arrow = false
}
