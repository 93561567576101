import { Injectable } from '@angular/core'
import { ActionSheetController } from '@ionic/angular'
import { Router } from '@angular/router'
import { Project, Chat, Event, HomerunSDK } from '../generated/graphql.private'
import gql from 'graphql-tag'
import { Apollo } from 'apollo-angular'
import { ApolloClient } from '@apollo/client/core'
import { take, map, mergeMap } from 'rxjs/operators'
import { iif, defer, Subject } from 'rxjs'
import { ToastService } from './toast.service'

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  apolloClient: ApolloClient<any>
  private presenceChatId = null

  replyToActionChanged = new Subject<Event[]>()
  private _replyToEvents: Event[] = []

  constructor(
    private apollo: Apollo,
    private homerunSDK: HomerunSDK,
    private actionSheetController: ActionSheetController,
    private router: Router,
    private toastService: ToastService
  ) {
    this.apolloClient = this.apollo.client
  }

  setPresence(chatId) {
    this.presenceChatId = chatId
    return this.homerunSDK.setPresence({ input: { chatId } }).pipe(take(1)).subscribe()
  }

  clearPresence() {
    this.presenceChatId = null
  }

  presentInChat(chatId): boolean {
    return this.presenceChatId == chatId
  }

  setReplyToEvent(chatId: string, event: Event) {
    this._replyToEvents = [...this._replyToEvents.filter((value) => value.chatId !== chatId), event]
    this.replyToActionChanged.next(this._replyToEvents)
  }

  getReplyToEvent(chatId: string): Event | null {
    return this._replyToEvents.find((value) => value.chatId === chatId) || null
  }

  clearReplyToEvent(chatId: string) {
    this._replyToEvents = this._replyToEvents.filter((value) => value.chatId !== chatId)
    this.replyToActionChanged.next(this._replyToEvents)
  }

  createNewChatActionSheet = async (project: Pick<Project, 'id' | 'policy'>) => {
    const newPrivateChatButtonFor = (project) => {
      return {
        text: 'Nieuwe privéchat',
        icon: 'chatbubble-ellipses-outline',
        handler: () => this.router.navigate(['/projects', project.id, 'chats', 'new', 'private'])
      }
    }

    const newGroupChatButtonFor = (project) => {
      return {
        text: 'Nieuwe groepschat',
        icon: 'people-outline',
        handler: () => this.router.navigate(['/projects', project.id, 'chats', 'new', 'group', 'select'])
      }
    }

    return await this.actionSheetController.create({
      buttons: [
        ...(project.policy.createPrivateChat ? [newPrivateChatButtonFor(project)] : []),
        ...(project.policy.createGroupChat ? [newGroupChatButtonFor(project)] : []),
        {
          text: 'Annuleren',
          role: 'cancel'
        }
      ]
    })
  }

  updateCache(chat: Pick<Chat, 'id' | 'projectId'>) {
    const fragmentId = `Project:${chat.projectId}`
    let project = null

    const fragment = gql`
      fragment projectChatIds on Project {
        __typename
        chats {
          __typename
          id
        }
      }
    `

    try {
      project = this.apolloClient.readFragment({ id: fragmentId, fragment })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }

    if (!project) return
    if (project.chats?.find((existingChat) => existingChat.id === chat.id)) return

    this.apolloClient.writeFragment({
      id: fragmentId,
      fragment,
      data: {
        __typename: project.__typename,
        chats: [...project.chats, { id: chat.id, __typename: (chat as any).__typename }]
      }
    })
  }

  createPrivateChat(projectId, memberId, onSuccess = () => void 0, onFailure = () => void 0) {
    this.homerunSDK
      .createPrivateChat(
        {
          input: {
            projectId,
            memberId
          }
        },
        {
          refetchQueries: ['projectChats']
        }
      )
      .pipe(
        map((result) => result.data.createPrivateChat),
        mergeMap((payload) =>
          iif(
            () => payload.errors.length == 0,
            defer(() => {
              this.updateCache(payload.privateChat)
              onSuccess()
              this.router.navigate(['/projects', payload.privateChat.projectId, 'chats', payload.privateChat.id])
            }),
            defer(() => {
              onFailure()
              this.toastService.error('Er is iets fout gegaan')
            })
          )
        ),
        take(1)
      )
      .subscribe()
  }

  allParticipantsReadAt(chat) {
    let result = new Date(null)
    if (chat?.participants?.length > 0) {
      result = new Date(chat.participants[0].readAt)
      chat.participants.forEach((participant) => {
        const readAt = new Date(participant.readAt)
        if (readAt < result) result = readAt
      })
    }
    return result
  }
}
