import { __rest } from 'tslib'
import { FieldPolicy, Reference } from '@apollo/client/cache'
import { mergeDeep } from '@apollo/client/utilities'
type KeyArgs = FieldPolicy<any>['keyArgs']

// Whether TRelayEdge<TNode> is a normalized Reference or a non-normalized
// object, it needs a .cursor property where the relayStylePagination
// merge function can store cursor strings taken from pageInfo. Storing an
// extra reference.cursor property should be safe, and is easier than
// attempting to update the cursor field of the normalized StoreObject
// that the reference refers to, or managing edge wrapper objects
// (something I attempted in #7023, but abandoned because of #7088).
export type TRelayEdge<TNode> =
  | {
      cursor?: string
      node: TNode
    }
  | (Reference & { cursor?: string })

export type TRelayPageInfo = {
  hasPreviousPage: boolean
}

export type TExistingRelay<TNode> = Readonly<{
  edges: TRelayEdge<TNode>[]
  pageInfo: TRelayPageInfo
}>

export type TIncomingRelay<TNode> = {
  edges?: TRelayEdge<TNode>[]
  pageInfo?: TRelayPageInfo
}

export type RelayFieldPolicy<TNode> = FieldPolicy<TExistingRelay<TNode>, TIncomingRelay<TNode>, TIncomingRelay<TNode>>

// As proof of the flexibility of field policies, this function generates
// one that handles Relay-style pagination, without Apollo Client knowing
// anything about connections, edges, cursors, or pageInfo objects.
export function relayStyleChatPagination<TNode = Reference>(keyArgs: KeyArgs = false): RelayFieldPolicy<TNode> {
  return {
    keyArgs,
    read(existing) {
      return existing
    },

    merge(existing = makeEmptyData(), incoming, { args, isReference, readField }) {
      if (args?.first || args?.after) {
        throw new Error('Chat pagination cache cannot handle first or after args')
      }

      const incomingEdges = incoming.edges
        ? incoming.edges.map((edge) => {
            if (isReference((edge = { ...edge }))) {
              // In case edge is a Reference, we read out its cursor field and
              // store it as an extra property of the Reference object.
              edge.cursor = readField<string>('cursor', edge)
            }
            return edge
          })
        : []

      const pageInfo: TRelayPageInfo = {
        ...existing.pageInfo
      }

      // remove all edges from existing that also exist in incoming
      const mergedEdges: TRelayEdge<TNode>[] = mergeDeep(
        existing.edges.filter(
          (existingEdge) =>
            incomingEdges.findIndex((incomingEdge) => existingEdge.cursor === incomingEdge.cursor) === -1
        )
      )

      if (args?.before) {
        // find after cursor in existing
        const index = mergedEdges.findIndex((edge) => edge.cursor === args.before)

        if (index !== -1) {
          mergedEdges.splice(index, 0, ...incomingEdges)
        }

        if (mergedEdges[0]?.cursor === incomingEdges[0]?.cursor && incoming?.pageInfo?.hasPreviousPage !== undefined) {
          pageInfo.hasPreviousPage = incoming.pageInfo.hasPreviousPage
        }

        return {
          ...getExtras(existing),
          ...getExtras(incoming),
          edges: mergedEdges,
          pageInfo
        }
      }

      const newEdges = [...mergedEdges, ...incomingEdges]
      if (newEdges[0]?.cursor === incomingEdges[0]?.cursor && incoming?.pageInfo?.hasPreviousPage !== undefined) {
        pageInfo.hasPreviousPage = incoming.pageInfo.hasPreviousPage
      }

      // if no before cursor is available, always push the incoming edges to the end
      return {
        ...getExtras(existing),
        ...getExtras(incoming),
        edges: [...mergedEdges, ...incomingEdges].sort((a, b) => parseInt(a.cursor) - parseInt(b.cursor)),
        pageInfo
      }
    }
  }
}

// Returns any unrecognized properties of the given object.
const getExtras = (obj: Record<string, any>) => __rest(obj, notExtras)
const notExtras = ['edges', 'pageInfo']

function makeEmptyData(): TExistingRelay<any> {
  return {
    edges: [],
    pageInfo: {
      hasPreviousPage: true
    }
  }
}
