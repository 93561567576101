import { Component, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MediaQuery, HomerunSDK } from '@core/generated/graphql.private'
import { ActionMenuService } from '@core/services/action-menu.service'
import { MediaService } from '@core/services/media.service'
import { ApolloQueryResult } from '@apollo/client/core'
import { fromEvent, Observable, Subject } from 'rxjs'
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators'

@Component({
  selector: 'media-modal',
  template: `
    <div
      class="overlay-container"
      (onClickClose)="closeModal()"
      closeOnClickBackdrop="true"
      *ngIf="(mediaQueryResult$ | async)?.data?.media as media; else skeleton"
    >
      <div class="pane">
        <header>
          <div class="detail-container">
            <img [src]="media.user.avatarThumbnail || '/core-assets/users/avatar.svg'" />

            <div class="details">
              <div class="filename">{{ media.filename }}</div>
              <div class="description" *ngIf="media.description">{{ media.description }}</div>
              <div class="metadata">
                Toegevoegd door {{ media.user.name }} op {{ media.createdAt | date: 'longDate' }}
              </div>
            </div>
          </div>

          <div class="actions">
            <ion-toolbar slot="start">
              <ion-buttons slot="start" fil="clear">
                <ion-button fill="clear" (click)="download(media)"
                  ><ion-icon slot="icon-only" name="cloud-download-outline" color="dark"></ion-icon
                ></ion-button>

                <ion-button fill="clear" (click)="more($event, media)">
                  <ion-icon slot="icon-only" name="ellipsis-horizontal" color="dark"></ion-icon>
                </ion-button>
              </ion-buttons>

              <ion-buttons slot="end" fill="clear" class="close">
                <ion-button class="close-button" fill="clear" (click)="closeModal()"
                  ><ion-icon slot="icon-only" color="dark" name="close"></ion-icon
                ></ion-button>
              </ion-buttons>
            </ion-toolbar>
          </div>
        </header>

        <div class="content-container">
          <video controls *ngIf="isVideo; else preview">
            <source [src]="media.file" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <ng-template #preview>
            <img
              *ngIf="media.preview; else fallback"
              [src]="media.__typename == 'Image' ? media.file : media.preview || media.file"
            />
          </ng-template>

          <ng-template #fallback>
            <media-tile [media]="media" [disableFilename]="true"> </media-tile>
          </ng-template>
        </div>
      </div>
    </div>

    <ng-template #skeleton>
      <div class="overlay-container" (onClickClose)="closeModal()" closeOnClickBackdrop="true">
        <ion-spinner></ion-spinner>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .overlay-container {
        pointer-events: none;
        z-index: 9999;
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        background: rgba(0, 0, 0, 0.6);
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        animation-name: anim-open;
      }

      .pane {
        background: #ffff;
        border-radius: 1rem;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        pointer-events: auto;
        overflow: hidden;
      }

      .preview-container:hover {
        cursor: pointer;
      }

      .content-container {
        pointer-events: auto;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      ion-toolbar {
        --background: transparent;
        --border-color: transparent;
      }

      header {
        width: 100%;
        display: flex;
        border-bottom: solid 1px var(--default-border-color);
        background: var(--ion-color-light);
        color: var(--ion-color-contrast);
      }

      header .detail-container {
        margin-top: 0.8rem;
        margin-left: 0.8rem;
        margin-bottom: 0.5rem;
        flex-grow: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
      }

      header .details {
        margin-left: 0.5rem;
        flex-grow: 1;
      }

      header .details .filename {
        font-size: 1rem;
        font-weight: bold;
      }

      header .details .description {
        font-size: 0.9rem;
        line-height: 1rem;
      }

      header .details .metadata {
        line-height: 1.2rem;
        color: var(--ion-color-medium);
        font-size: 0.8rem;
      }

      header .actions {
        justify-content: flex-end;
        margin-right: 0.5rem;
        margin-top: 0.5rem;
      }

      .content-container video,
      media-tile {
        width: 30%;
      }
      .content-container video,
      img,
      media-tile {
        display: block;
        max-width: 80vw;
        max-height: 80vh;
      }

      header img {
        width: 60px;
        height: 60px;
        border-radius: 0.5rem;
      }

      ion-spinner {
        transform: scale(3);
      }

      video {
        max-height: 70vh;
        max-width: 90vh;
        outline: none;
      }

      ion-buttons.close {
        margin-left: 0.5rem;
        padding-left: 0.5rem;
        border-left: solid 1px var(--default-border-color);
      }

      .preview-icon {
      }

      @keyframes anim-open {
        0% {
          opacity: 0;
          transform: scale3d(1.1, 1.1, 1);
        }
        100% {
          opacity: 1;
          transform: scale3d(1, 1, 1);
        }
      }
    `
  ]
})
export class MediaModal implements OnDestroy {
  destroyed$: Subject<boolean> = new Subject()
  mediaQueryResult$: Observable<ApolloQueryResult<MediaQuery>>
  isVideo = false

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private homerunSDK: HomerunSDK,
    private mediaService: MediaService,
    private actionMenuService: ActionMenuService
  ) {}

  ngOnInit() {
    fromEvent(document, 'keydown')
      .pipe(
        filter((e: KeyboardEvent) => e.code === 'Escape'),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => this.closeModal())

    this.mediaQueryResult$ = this.route.params.pipe(
      switchMap((params) =>
        this.homerunSDK
          .mediaWatch({ mediaId: params.mediaId })
          .valueChanges.pipe(tap((result) => (this.isVideo = result?.data?.media?.__typename == 'Video')))
      ),
      takeUntil(this.destroyed$)
    )
  }

  download(media) {
    window.location.href = media.downloadFile
  }

  closeModal() {
    if (this.route.snapshot.params.path)
      return this.router.navigate(
        ['/projects', this.route.snapshot.params.projectId, 'media', 'browse'].concat(
          this.route.snapshot.params.path.split('/').slice(0, -1)
        )
      )
    this.router.navigate(Object.keys(this.route.snapshot.params).length > 2 ? ['../../'] : ['../'], {
      relativeTo: this.route
    })
  }

  openPreviewInNewTab(media, e = null) {
    if (e) e.preventDefault()
    window.open(`https://docs.google.com/gview?embedded=true&url=${media.file}`, '_blank')
  }

  async more($event, media) {
    const buttons = []

    if (media.policy.delete)
      buttons.unshift({
        text: 'Verwijder',
        icon: 'trash-outline',
        role: 'destructive',
        handler: () => this.deleteMedia(media)
      } as any)

    buttons.unshift({
      text: 'Openen in nieuw venster',
      icon: 'open-outline',
      handler: () => window.open(media.file, '_blank')
    } as any)
    if (media.__typename == 'Document') {
      buttons.unshift({
        text: 'Bekijken in nieuw venster',
        icon: 'eye-outline',
        handler: () => this.openPreviewInNewTab(media, $event)
      } as any)
    }

    await this.actionMenuService.showActionMenu(buttons, $event)
  }

  deleteMedia(media) {
    this.mediaService.deleteMedia(media, () => this.closeModal())
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.unsubscribe()
  }
}
