import { Component, Input, OnDestroy } from '@angular/core'
import { HomerunSDK, Media, MediaType, ProjectMediaQuery } from '../../../generated/graphql.private'
import { take, takeUntil, tap } from 'rxjs/operators'
import { ModalController } from '@ionic/angular'
import { Observable, ReplaySubject, Subject } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core'
@Component({
  selector: 'folder-modal-move',
  template: `
    <ion-content>
      <ion-toolbar class="header">
        <ion-buttons slot="secondary">
          <ion-button (click)="closeDialog()" color="medium">
            Annuleren
          </ion-button>
        </ion-buttons>
        <ion-title>
          Verplaats naar...
        </ion-title>
        <ion-buttons slot="primary">
          <ion-button (click)="onSubmit()">
            <strong> Ok </strong>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
      <ion-toolbar>
        <ion-buttons slot="secondary">
          <ion-button (click)="navigateFolderback(path)" color="medium">
            <ion-icon size="large" name="chevron-back-circle-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <input type="text" class="path" [value]="path ? '/' + path : '/'" disabled />
      </ion-toolbar>

      <ion-content>
        <ng-container *ngIf="(projectMediaQueryResult$ | async)?.data?.project as project; else skeleton">
          <ion-list lines="full">
            <ng-container *ngFor="let edge of project.media.edges; trackBy: trackMediaBy">
              <media-item
                [showAssignedMembers]="false"
                [showDetails]="false"
                [media]="edge.node"
                (click)="navigateToPath(edge.node)"
                *ngIf="edge.node.id != media.id"
              ></media-item>
            </ng-container>
          </ion-list>
        </ng-container>
      </ion-content>

      <ng-template #skeleton>
        <ion-list lines="full">
          <ion-item>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated style="width: 90%"></ion-skeleton-text>
          </ion-item>
          <ion-item>
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
          </ion-item>
        </ion-list>
      </ng-template>
    </ion-content>
  `,
  styles: [
    `
      .content {
        margin: 1.5rem;
        margin-bottom: 0px;
        font-size: 0.9rem;
      }
      .path {
        width: 100%;
        border: solid 1px var(--grid-border-color);
        padding: 0.4rem;
        font-size: 0.8rem;
      }
    `
  ]
})
export class MoveFolderModal implements OnDestroy {
  projectMediaQueryResult$: Observable<ApolloQueryResult<ProjectMediaQuery>>
  pathSubject: ReplaySubject<string> = new ReplaySubject(2)
  path: string
  destroyed$: Subject<boolean> = new Subject()

  @Input() get media(): Media {
    return this._media
  }

  set media(media: Media) {
    this._media = media
    this.pathSubject.next(this.extractPath(this._media, true))
  }

  private _media: Media

  constructor(private modalController: ModalController, private homerunSDK: HomerunSDK) {}

  ngOnInit() {
    this.pathSubject
      .pipe(
        tap((path) => {
          this.path = path

          this.projectMediaQueryResult$ = this.homerunSDK.projectMediaMoveFolderWatch(
            {
              projectId: this._media.projectId,
              path: path,
              type: MediaType.Folder,
              perPage: 999
            },
            { fetchPolicy: 'no-cache' }
          ).valueChanges
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe()
  }

  async navigateFolderback(path) {
    const newPath = (path?.split('/').slice(0, -1) || []).join('/')
    this.pathSubject.next(newPath)
  }

  async onSubmit() {
    this.homerunSDK
      .moveMedia(
        { input: { projectId: this._media.projectId, mediaId: this._media.id, path: this.path || '' } },
        { refetchQueries: ['projectMedia'] }
      )
      .pipe(take(1))
      .subscribe()
    this.modalController.dismiss()
  }

  trackMediaBy(_, media: Media): string {
    return media.id
  }

  extractPath(media: Media, initial = false) {
    if (initial) {
      return (media as any).__typename == 'Folder'
        ? media.path.match('/')
          ? (media.path?.split('/').slice(0, -1) || []).join('/')
          : null
        : media.path.match('/')
        ? media.path.split('/').slice(0, -1).join('/')
        : null
    }
    return (media as any).__typename == 'Folder'
      ? media.path
      : media.path.match('/')
      ? media.path.split('/').slice(0, -1).join('/')
      : null
  }

  navigateToPath(media: Media) {
    this.pathSubject.next(this.extractPath(media))
  }

  async closeDialog() {
    this.modalController.dismiss()
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.unsubscribe()
  }
}
