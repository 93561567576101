import { Component } from '@angular/core'

@Component({
  selector: 'header-subtitle',
  template: ` <div class="container"><ng-content></ng-content></div>`,
  styles: [
    `
      .container {
        font-weight: normal;
        color: var(--ion-color-medium-tint);
        font-size: var(--smaller-font-size);
        line-height: var(--smaller-line-height);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `
  ]
})
export class HeaderSubtitleComponent {}
