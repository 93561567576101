import { Injectable } from '@angular/core'
import { Expense, ExpenseStatus } from '../generated/graphql.private'
import gql from 'graphql-tag'
import { Apollo } from 'apollo-angular'
import { ApolloClient } from '@apollo/client/core'

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {
  apolloClient: ApolloClient<any>

  constructor(private apollo: Apollo) {
    this.apolloClient = this.apollo.getClient()
  }

  updateCache(expense: Pick<Expense, 'id' | 'projectId' | 'createdByMember'>) {
    const fragmentId = `Project:${expense.projectId}`
    let project = null

    const fragment = gql`
      fragment projectExpenseIds on Project {
        __typename
        expenses {
          __typename
          id
        }
      }
    `

    try {
      project = this.apolloClient.readFragment({ id: fragmentId, fragment })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }

    if (project.expenses.find((existingExpense) => existingExpense.id === expense.id)) return

    this.apolloClient.writeFragment({
      id: fragmentId,
      fragment,
      data: {
        __typename: project.__typename,
        expenses: [...project.expenses, { id: expense.id, __typename: 'Expense' }]
      }
    })
  }

  newExpense(projectId) {
    return {
      projectId,
      status: ExpenseStatus.Open,
      description: '',
      price: null,
      totalPrice: null,
      refund: false
    } as Partial<Expense>
  }
}
