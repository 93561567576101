import { Injectable } from '@angular/core'
import { Todo } from '../generated/graphql.private'
import gql from 'graphql-tag'
import { Apollo } from 'apollo-angular'
import { ApolloClient } from '@apollo/client/core'
import { FormBuilder } from '@angular/forms'
import { requiredValidator } from '../validators/required.validator'

@Injectable({
  providedIn: 'root'
})
export abstract class TodoService {
  apolloClient: ApolloClient<any>
  public titleFromChatMessage?: string

  constructor(private apollo: Apollo) {
    this.apolloClient = this.apollo.getClient()
  }

  updateCache(todo: Pick<Todo, 'id' | 'projectId'>) {
    const fragmentId = `Project:${todo.projectId}`
    let project = null

    const fragment = gql`
      fragment projectTodoIds on Project {
        __typename
        todos {
          __typename
          id
        }
      }
    `

    try {
      project = this.apolloClient.readFragment({ id: fragmentId, fragment })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }
    if (!project) return

    if (project?.todos?.find((existingTodo) => existingTodo.id === todo.id)) return

    this.apolloClient.writeFragment({
      id: fragmentId,
      fragment,
      data: {
        __typename: project.__typename,
        todos: [...project.todos, { __typename: 'Todo', id: todo.id }]
      }
    })
  }

  buildForm(todo: Partial<Todo>) {
    return new FormBuilder().group({
      name: [todo.name, [requiredValidator]],
      description: [todo.description],
      assignedMemberIds: todo.assignedMemberIds
    })
  }

  abstract openNewTodoAction(title: string, projectId: string)
}
