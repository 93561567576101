import { Component, OnDestroy, OnInit } from '@angular/core'
import { HomerunSDK } from '@core/generated/graphql.private'
import { AuthService } from '@core/services/auth.service'
import { Platform } from '@ionic/angular'
import { DeepLinkService } from '@core/services/deeplink.service'
import { Subject } from 'rxjs'
import { filter, shareReplay, takeUntil, tap } from 'rxjs/operators'
import { NavigationEnd, Router } from '@angular/router'
import { SubscriptionService } from '@core/services/subscription.service'
import { AnalyticsService } from '@web/services/analytics.service'

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet><router-outlet name="root-modal"></router-outlet>
    <div></div>`
})
export class AppComponent implements OnDestroy, OnInit {
  destroyed$: Subject<boolean> = new Subject()
  tokenRefresh$: Observable<any>
  constructor(
    private platform: Platform,
    private homerunSdk: HomerunSDK,
    private authService: AuthService,
    private deepLinkService: DeepLinkService,
    private router: Router,
    private subscriptionService: SubscriptionService,
    private analyticsService: AnalyticsService
  ) {}
  ngOnInit() {
    // google tag manager user id logging
    this.authService
      .isLoggedIn()
      .pipe(
        tap(async (loggedIn) => {
          ;(window as any).dataLayer = (window as any).dataLayer || []
          ;(window as any).dataLayer.push({
            userId: loggedIn ? (await this.homerunSdk.currentUser().toPromise()).data.currentUser.id : null
          }),
            takeUntil(this.destroyed$)
        })
      )
      .subscribe()

    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        shareReplay(1),
        takeUntil(this.destroyed$)
      )
      .subscribe((e) => this.checkIfMobileAndRedirectToDeeplink(e))

    this.subscriptionService.setup()

    this.analyticsService.setup()
  }

  checkIfMobileAndRedirectToDeeplink(e) {
    if (this.platform.is('tablet')) return
    // mobileweb is not enough apparently

    if ((this.platform.is('ios') || this.platform.is('android')) && this.platform.is('mobileweb')) {
      if (e.urlAfterRedirects.match(/email/)) return
      if (e.urlAfterRedirects.match(/subscription/)) return
      if (e.urlAfterRedirects.match(/login/)) return
      window.location.href = this.deepLinkService.buildDeepLink(window.location.href)
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true)
    this.destroyed$.unsubscribe()
  }
}
