import { Injectable } from '@angular/core'
import { Participant } from '../generated/graphql.private'
import gql from 'graphql-tag'
import { ApolloClient } from '@apollo/client/core'
import { Apollo } from 'apollo-angular'

@Injectable({
  providedIn: 'root'
})
export class ParticipantService {
  apolloClient: ApolloClient<any>

  constructor(private apollo: Apollo) {
    this.apolloClient = this.apollo.getClient()
  }

  updateCache(participant: Pick<Participant, 'id' | 'chatId'>) {
    const fragmentId = `Chat:${participant.chatId}`
    let chat = null

    const fragment = gql`
      fragment chat on Chat {
        __typename
        participants {
          __typename
          id
        }
      }
    `

    try {
      chat = this.apolloClient.readFragment({ id: fragmentId, fragment })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }

    if (!chat) return
    if (chat.participants.find((existingParticipant) => existingParticipant.id === participant.id)) return

    this.apolloClient.writeFragment({
      id: fragmentId,
      fragment,
      data: {
        __typename: chat.__typename,
        participants: [...chat.participants, { id: participant.id, __typename: 'Participant' }]
      }
    })
  }
}
