import { Component, Input } from '@angular/core'

@Component({
  selector: 'plus-fab-button',
  template: `
    <ion-fab-button class="button">
      <ion-icon name="{{ iconName || 'add-outline' }}" size="large"></ion-icon>
      <plus class="plus" *ngIf="showPlus && iconName"></plus>
    </ion-fab-button>
  `,
  styles: [
    `
      .button {
        position: relative;
      }

      .plus {
        position: absolute;
        left: 30px;
        top: 31px;
      }
    `
  ]
})
export class PlusFabButtonComponent {
  @Input() showPlus = true
  @Input() iconName: string | null
}
