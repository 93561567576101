import { Injectable } from '@angular/core'
import { Timesheet, TimesheetStatus } from '../generated/graphql.private'
import gql from 'graphql-tag'
import { Apollo } from 'apollo-angular'
import { ApolloClient } from '@apollo/client/core'
import { hoursValidator } from '../validators/hours.validator'
import { FormBuilder } from '@angular/forms'

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {
  apolloClient: ApolloClient<any>

  constructor(private apollo: Apollo) {
    this.apolloClient = this.apollo.getClient()
  }

  updateCache(timesheet: Pick<Timesheet, 'id' | 'projectId' | 'createdByMember'>) {
    const fragmentId = `Project:${timesheet.projectId}`

    this.updateProjectTimesheetsCache(timesheet, fragmentId)
    this.updateProjectCurrentMemberTimesheetsCache(timesheet, fragmentId)
  }

  updateProjectTimesheetsCache(timesheet, fragmentId) {
    let project = null

    const fragment = gql`
      fragment projectTimesheetIds on Project {
        __typename
        timesheets {
          __typename
          id
        }
      }
    `

    try {
      project = this.apolloClient.readFragment({ id: fragmentId, fragment })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }

    if (project.timesheets.find((existingTimesheet) => existingTimesheet.id === timesheet.id)) return

    this.apolloClient.writeFragment({
      id: fragmentId,
      fragment,
      data: {
        __typename: project.__typename,
        timesheets: [...project.timesheets, { id: timesheet.id, __typename: 'Timesheet' }]
      }
    })
  }

  updateProjectCurrentMemberTimesheetsCache(timesheet, fragmentId) {
    if (!timesheet.createdByMember.current) return
    let project = null

    const fragment = gql`
      fragment projectCurrentMemberTimesheetIds on Project {
        __typename
        currentMemberTimesheets {
          __typename
          id
        }
      }
    `

    try {
      project = this.apolloClient.readFragment({ id: fragmentId, fragment })
    } catch (e) {
      if (e.name != 'Invariant Violation') throw e
      return
    }

    if (project.currentMemberTimesheets.find((existingTimesheet) => existingTimesheet.id === timesheet.id)) return

    this.apolloClient.writeFragment({
      id: fragmentId,
      fragment,
      data: {
        __typename: project.__typename,
        currentMemberTimesheets: [...project.currentMemberTimesheets, { id: timesheet.id, __typename: 'Timesheet' }]
      }
    })
  }

  newTimesheet(daysOfTheWeek, projectId) {
    return {
      projectId,
      status: TimesheetStatus.Open,
      monday: daysOfTheWeek[0].toISOString().split('T')[0],
      mondayHours: null,
      mondayNotes: '',
      tuesday: daysOfTheWeek[1].toISOString().split('T')[0],
      tuesdayHours: null,
      tuesdayNotes: '',
      wednesday: daysOfTheWeek[2].toISOString().split('T')[0],
      wednesdayHours: null,
      wednesdayNotes: '',
      thursday: daysOfTheWeek[3].toISOString().split('T')[0],
      thursdayHours: null,
      thursdayNotes: '',
      friday: daysOfTheWeek[4].toISOString().split('T')[0],
      fridayHours: null,
      fridayNotes: '',
      saturday: daysOfTheWeek[5].toISOString().split('T')[0],
      saturdayHours: null,
      saturdayNotes: '',
      sunday: daysOfTheWeek[6].toISOString().split('T')[0],
      sundayHours: null,
      sundayNotes: '',
      description: '',
      policy: { update: true }
    } as Partial<Timesheet>
  }

  buildForm(timesheet: Partial<Timesheet>) {
    return new FormBuilder().group({
      projectId: timesheet.projectId,
      monday: timesheet.monday,
      mondayHours: [timesheet.mondayHours, hoursValidator],
      mondayNotes: [timesheet.mondayNotes],
      tuesdayHours: [timesheet.tuesdayHours, hoursValidator],
      tuesdayNotes: [timesheet.tuesdayNotes],
      wednesdayHours: [timesheet.wednesdayHours, hoursValidator],
      wednesdayNotes: [timesheet.wednesdayNotes],
      thursdayHours: [timesheet.thursdayHours, hoursValidator],
      thursdayNotes: [timesheet.thursdayNotes],
      fridayHours: [timesheet.fridayHours, hoursValidator],
      fridayNotes: [timesheet.fridayNotes],
      saturdayHours: [timesheet.saturdayHours, hoursValidator],
      saturdayNotes: [timesheet.saturdayNotes],
      sundayHours: [timesheet.sundayHours, hoursValidator],
      sundayNotes: [timesheet.sundayNotes],
      description: timesheet.description
    })
  }
}
