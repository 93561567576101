import { Component, Input } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'media-view-select',
  template: `
    <ion-segment [formControl]="view" mode="ios">
      <ion-segment-button value="tiles">
        <ion-icon name="grid-outline"></ion-icon>
      </ion-segment-button>
      <ion-segment-button value="list">
        <ion-icon name="list-outline"></ion-icon>
      </ion-segment-button>
    </ion-segment>
  `,
  styles: [
    `
      ion-segment {
        margin-right: 1rem;
      }
    `
  ]
})
export class MediaViewSelectComponent {
  @Input() view: FormControl
}
