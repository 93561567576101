import { Injectable } from '@angular/core'
import { ToastController } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  async success(message) {
    await (
      await this.toastController.create({
        message,
        position: 'top',
        duration: 3000,
        color: 'success',
        buttons: [{ text: 'Ok', role: 'cancel' }]
      })
    ).present()
  }

  async error(message) {
    await (
      await this.toastController.create({
        message,
        position: 'top',
        duration: 3000,
        color: 'danger',
        buttons: [{ text: 'Ok', role: 'cancel' }]
      })
    ).present()
  }
}
