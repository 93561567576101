import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Member, ProjectQuery, HomerunSDK } from '../../../../generated/graphql.private'
import { ActivatedRoute, Router } from '@angular/router'
import { MemberService } from '../../../../services/member.service'
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core'
import { ModalController, Platform } from '@ionic/angular'
import { LimitModal } from '../limit.modal'
import { SubscriptionTypePipe } from '../../pipes/subscription-type.pipe'

@Component({
  selector: 'member-select',
  template: `
    <ng-container *ngIf="(projectQueryResult$ | async)?.data?.project as project">
      <ion-list lines="full" *ngIf="project.policy.inviteMember || project.policy.inviteUser">
        <ion-item *ngIf="!hideInvideOption" button="true" (click)="gotoNewMember()" [color]="color"
          ><ion-avatar>
            <img src="core-assets/users/new.svg" />
          </ion-avatar>
          <ion-label>Voeg projectlid toe</ion-label>
        </ion-item>
      </ion-list>
    </ng-container>
    <ion-list lines="full" *ngIf="options.length > 0; else noMembers">
      <ng-content></ng-content>
      <ng-container *ngFor="let option of options">
        <member-item [member]="option.member" [color]="color" (click)="onClickItem(option.member, $event)">
          <ion-checkbox
            [ngClass]="multiple ? 'square' : 'circle'"
            (click)="onClickCheckbox(option.member, $event)"
            [(ngModel)]="option.selected"
          ></ion-checkbox>
        </member-item>
      </ng-container>
    </ion-list>
    <ng-template #noMembers>
      <div class="empty-list-message-container">
        <empty-list-message>{{ noMembersMessage }}</empty-list-message>
      </div>
    </ng-template>
  `,
  styles: [
    `
      .square {
        --border-radius: 0;
      }

      .circle {
        --border-radius: 50%;
      }

      .empty-list-message-container {
        padding-top: 4rem;
      }

      ion-avatar {
        overflow: hidden;
        margin-right: 1rem; /* Instead of slot="start" so checkbox clickable area covers avatar */
      }
    `
  ]
})
export class MemberSelectComponent implements OnInit {
  @Input() members: Member[]
  @Input() preselectedMembers: Member[] = []
  @Input() noMembersMessage = 'Er zijn geen projectleden die je kan selecteren'
  @Input() multiple = false
  @Input() color = 'light'
  @Input() projectId?
  @Output() onGoToNewMember? = new EventEmitter()
  @Input() hideInvideOption?: boolean = false

  projectQueryResult$: Observable<ApolloQueryResult<ProjectQuery>>

  options: { selected: boolean; member: Member }[] = []

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private memberService: MemberService,
    private homerunSDK: HomerunSDK,
    private modalController: ModalController,
    private platform: Platform
  ) {}

  ngOnInit() {
    const projectId = this.projectId || this.route.snapshot.params.projectId
    this.projectQueryResult$ = this.homerunSDK.projectWatch({ projectId }).valueChanges
  }

  ngOnChanges() {
    this.options = this.members.map((member) => ({
      member,
      selected: this.preselectedMembers.some((preselectedMember) => preselectedMember.id == member.id)
    }))
  }

  // Click handlers

  onClickCheckbox(member, $event) {
    $event.stopPropagation()
    $event.preventDefault()

    this.disableOthers(member)
  }

  onClickItem(member, $event) {
    $event.stopPropagation()
    $event.preventDefault()

    this.toggle(member)
    this.disableOthers(member)
  }

  // Manage options

  toggle(member) {
    const option = this.options.find((option) => option.member.id == member.id)
    option.selected = !option.selected
  }

  disableOthers(member) {
    if (this.multiple) return

    // Turn off others
    this.options.forEach((options) => {
      if (options.member.id != member.id) options.selected = false
    })
  }

  // Public interface

  public selectedMembers(): Member[] {
    return this.options.filter((option) => option.selected).map((option) => option.member)
  }

  public selectedMemberIds(): string[] {
    return this.selectedMembers().map((member) => member.id)
  }

  public anySelected(): boolean {
    return this.selectedMembers().length > 0
  }

  async gotoNewMember() {
    this.memberService.finishedReturnUrl = this.router.routerState.snapshot.url
    const projectId = this.projectId || this.route.snapshot.params.projectId

    const project = (await this.homerunSDK.project({ projectId: projectId }).toPromise()).data.project
    const currentUser = (await this.homerunSDK.currentUser().toPromise()).data.currentUser
    if (project.policy.inviteMemberLimitReached)
      return (
        await this.modalController.create({
          component: LimitModal,
          componentProps: {
            showClose: true,
            title: 'Je hebt het maximaal aantal <strong>projectleden</strong> bereikt',
            copy: `Je zit aan het <strong>maximum</strong> van het aantal projectleden die je kunt uitnodigen in een project met Homigo <strong>${new SubscriptionTypePipe().transform(
              currentUser.subscriptionType
            )}</strong>. Wijzig je abonnement om meer projectleden uit te nodigen.`,
            mobile: this.platform.is('cordova'),
            hack: true
          },
          cssClass: 'partial-modal',
          swipeToClose: true
        })
      ).present()
    this.onGoToNewMember.next()
    this.router.navigate(['projects', projectId, 'members', 'new'], {
      queryParams: { redirect: 'member-select' }
    })
  }
}
