import { AbstractControl } from '@angular/forms'

export const hoursValidator = (control: AbstractControl): { [key: string]: any } | null => {
  if (control.value == '' || control.value == null) return null

  if (control.value < 0 || control.value > 24) {
    return { hoursInvalid: true }
  }
  return null
}
