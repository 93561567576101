import { Component, Input } from '@angular/core'
import { Member } from 'projects/core/src/generated/graphql.private'

@Component({
  selector: 'assigned-members',
  template: ` <div class="avatar-container" [class]="size">
    <div *ngFor="let member of members" helipopperPlacement="bottom">
      <ion-avatar>
        <img-loader
          [src]="member.avatarThumbnail"
          useImg="true"
          spinner="false"
          fallbackAsPlaceholder="true"
          fallbackUrl="/core-assets/users/avatar.svg"
        ></img-loader>
      </ion-avatar>
    </div>
    <ion-avatar class="plus" *ngIf="plusButton">
      <ion-icon color="primary" name="add" size="large"></ion-icon>
    </ion-avatar>
  </div>`,
  styles: [
    `
      .small ion-avatar {
        max-width: 29px;
        max-height: 29px;
        margin-right: -17px;
      }
      .xsmall ion-avatar {
        max-width: 20px;
        max-height: 20px;
        margin-right: -10px;
      }
      .large ion-avatar {
        max-width: 44px;
        max-height: 44px;
        margin-right: -24px;
      }

      ion-avatar {
        overflow: hidden;

        border: solid 2px white;
        box-shadow: 1px 2px 12px 2px rgba(0, 0, 0, 0.1);
      }

      .plus {
        background: #fff;
      }

      ion-avatar.plus {
        display: grid;
        place-items: center;
      }

      .avatar-container {
        display: flex;
        margin-left: auto;
      }
    `
  ]
})
export class AssignedMembersComponent {
  @Input() members: Member[]
  @Input() size: 'large' | 'small' | 'xsmall' = 'small'
  @Input() plusButton = false
}
