import { Component, HostListener } from '@angular/core'

@Component({
  selector: 'ellipsis-image-overlay',
  template: `<ion-icon name="ellipsis-vertical"></ion-icon>`,
  styles: [
    `
      :host {
        position: absolute;
        top: 0;
        right: 0;
        height: 3rem;
        width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      ion-icon {
        color: #aaaaaa;
        font-size: 20px;
      }
    `
  ]
})
export class EllipsisImageOverlayComponent {
  @HostListener('click', ['$event'])
  clicked($event: { ellipsisClicked: boolean }) {
    $event.ellipsisClicked = true
  }
}
